type Theme = {
  [name: string]: {
    css: string;
    logo: string;
    name: string;
  };
};

const themes: Theme = {
  default: {
    css: '/8389ca82-be09-4ac2-8126-293eccffee9d.theme.css',
    logo: 'summit.svg',
    name: 'Summit Media',
  },
  three: {
    css: '/31ccccd7-02a4-4a9a-9b44-f1060c495d17.theme.css',
    logo: 'three.svg',
    name: 'Three',
  },
  therange: {
    css: '/44542cf2-2e9c-4295-939b-808a8f089069.theme.css',
    logo: 'therange.png',
    name: 'The Range',
  },
  hsamuel: {
    css: '/5caf4f32-5db3-4b16-8dc5-2620f8c261f9.theme.css',
    logo: 'hsamuel.png',
    name: 'H.Samuel',
  },
  ernestjones: {
    css: '/1de04d68-2e27-4f29-a59b-2a056f38c201.theme.css',
    logo: 'ernestjones.png',
    name: 'Ernest Jones',
  },
  theraggedpriest: {
    css: '/ac4d9bbb-89ff-4ef2-a181-b3f7c9a2ab14.theme.css',
    logo: 'theraggedpriest.jpg',
    name: 'The Ragged Priest',
  },
};

export default themes;
