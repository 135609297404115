import {
  Form,
  FormInstance,
  Checkbox,
  Row,
  Col,
  DatePicker,
  TimePicker,
} from 'antd';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { IBulkEmailForm } from './IBulkEmailForm';

const SendDateField = (props: {
  name: string;
  form: FormInstance<IBulkEmailForm>;
  sendNow: Boolean;
}) => {
  const [shouldSendNow, setShouldSendNow] = useState(
    props.form.getFieldValue('sendNow')
  );

  useEffect(() => {
    setShouldSendNow(props.sendNow);
    props.form.setFieldsValue({ sendNow: props.sendNow as boolean });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.sendNow]);

  return (
    <Col span={21}>
      <Row>
        <Form.Item name="sendNow" valuePropName="checked">
          <Checkbox onChange={({ target }) => setShouldSendNow(target.checked)}>
            Send now
          </Checkbox>
        </Form.Item>

        <Form.Item
          shouldUpdate={true}
          style={
            shouldSendNow
              ? { display: 'none' }
              : { display: 'flex', flexDirection: 'row' }
          }
          rules={shouldSendNow ? [] : [{ required: true }]}
        >
          <Form.Item name="sendDate" noStyle shouldUpdate={true}>
            <DatePicker
              allowClear={false}
              format="DD-MM-yyyy"
              placeholder="Send Date"
              disabledDate={(date) => date < moment().startOf('day')}
              style={{ marginRight: '5px' }}
            />
          </Form.Item>
          <Form.Item name="sendTime" noStyle shouldUpdate={true}>
            <TimePicker
              allowClear={false}
              placeholder="Send Time"
              format="HH:mm"
              minuteStep={15}
            />
          </Form.Item>
        </Form.Item>
      </Row>
    </Col>
  );
};

export default SendDateField;
