import { useTranslation } from 'react-i18next';
import { Button, Card, Space, Table } from 'components/antd';
import { Content, TitleRow } from 'components/shared';
import { Link } from 'react-router-dom';
import { Create, Edit, Cross, Check } from 'components/icons';
import useAgreements, {
  SelfBillingAgreement,
} from 'hooks/Agreements/agreements';
import useUser from 'hooks/User';
import { useEffect } from 'react';
import { Permissions } from 'constants/permissions';

const getColumns = (
  t: Function,
  c: Function,
  allowEdit: boolean,
  data: SelfBillingAgreement[]
) => [
  {
    title: t('version'),
    dataIndex: 'version',
    key: 'version',
    fixed: 'left',
  },
  {
    title: t('datecreated'),
    dataIndex: 'dateCreated',
    key: 'dateCreated',
    fixed: 'left',
    render: (date: Date) => c('date', { value: new Date(date) }),
  },
  {
    dataIndex: 'enforceAgreement',
    title: t('enforceAgreement'),
    key: 'enforceAgreement',
    render: (track: boolean) => (track ? <Check /> : <Cross />),
  },
  {
    title: c('action'),
    dataIndex: '',
    align: 'right',
    key: '',
    width: 200,
    render: (af: SelfBillingAgreement, _: SelfBillingAgreement) => (
      <Space>
        <Link to={`/admin/platformagreement/${af.id}/create`}>
          <Button
            disabled={!allowEdit}
            icon={<Edit style={{ marginLeft: -2, marginBottom: -2 }} />}
          />
        </Link>
      </Space>
    ),
  },
];

const Page = () => {
  const { t } = useTranslation('platformAgreementsMaint');
  const { t: c } = useTranslation('common');
  const { hasPermission } = useUser();

  const { agreementsLoading, allAgreements, loadAllAgreements } =
    useAgreements();
  const allowEdit = hasPermission(
    Permissions['Accounts.SelfBillingAgreements.Write']
  );

  useEffect(() => {
    loadAllAgreements();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Content>
      <TitleRow>
        <h1>{t('agreements')}</h1>
        <Link to={`/admin/platformagreement/create`}>
          <Button
            icon={
              <Create
                style={{ marginLeft: -2, marginBottom: -2, fontSize: '1.2em' }}
              />
            }
          >
            {t('common:add')}
          </Button>
        </Link>
      </TitleRow>

      <Card>
        <Table
          rowKey="id"
          columns={getColumns(t, c, allowEdit, allAgreements) as any}
          dataSource={allAgreements}
          loading={agreementsLoading}
          pagination={false}
          expandable={{
            expandedRowRender: (record: SelfBillingAgreement) => (
              <div style={{ padding: '1em' }}>
                <div
                  dangerouslySetInnerHTML={{ __html: record.agreementDetails }}
                />
              </div>
            ),
            rowExpandable: (record: SelfBillingAgreement) =>
              record.agreementDetails ? true : false,
          }}
        />
      </Card>
    </Content>
  );
};

export default Page;
