import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Card, Form, message, Switch } from 'components/antd';
import { Content } from 'components/shared';
import { useParams, useNavigate } from 'react-router-dom';
import useAgreements from 'hooks/Agreements/agreements';
import Editor from 'components/shared/Editor';

type Props = {
  isNew?: boolean;
  copyFrom?: string;
};

const Page = ({ isNew, copyFrom }: Props) => {
  const { t } = useTranslation('platformAgreementsMaint');
  const [form] = Form.useForm();
  let { id } = useParams();
  const {
    agreementsLoading,
    selectedAgreement,
    loadAgreements,
    loadLatestAgreement,
    storeAgreement,
  } = useAgreements();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isNew && id) {
      loadAgreements(id);
    } else {
      loadLatestAgreement();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFinish = async (values: any) => {
    const results = await form.validateFields();

    storeAgreement(results)
      .then(() => {
        message.success(t(`agreement.create.success`));
        navigate(`/admin/platformagreement/list`);
      })
      .catch((error) => {
        const errorMsg = error?.response?.data?.message;
        message.error(
          t(`agreement.create.error`, {
            msg: errorMsg ? t(errorMsg) : undefined,
          })
        );
      });
  };

  return (
    <Content>
      <h1>{t('createAgreement')}</h1>
      <Card loading={agreementsLoading}>
        <Form
          initialValues={selectedAgreement}
          name="basic"
          form={form}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 12 }}
          autoComplete="off"
          onFinish={onFinish}
        >
          <Form.Item
            label={t('agreementDetails')}
            name="agreementDetails"
            style={{ height: 'auto' }}
            rules={[{ required: true }]}
          >
            <Editor hideTracking={true} />
          </Form.Item>
          <Form.Item
            label={t('enforceAgreement')}
            name="enforceAgreement"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 8 }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </Content>
  );
};

export default Page;
