import { useState } from 'react';
import api from 'libs/api';
import useUser from 'hooks/User';
import Pagination from 'constants/pagination';

export type HighlightedProduct = {
  id: number;
  externalId: string;
  productSKU: string | undefined;
  name: string | undefined;
  attributes: object;
  commission: number;
  order: number;
  pagination: any;
};

export type SearchProduct = {
  id: number;
  productHighlighted?: boolean;
  externalId: string;
  productName: string;
  commission?: string;
};

export type ReturnedProdCommission = {
  productSKU: string;
  commissionValue: string;
};

const useProductHighlighting = () => {
  const [highlightedProducts, setHighlightedProducts] = useState<
    HighlightedProduct[]
  >([]);
  const [searchedProducts, setSearchedProducts] = useState<SearchProduct[]>([]);
  const [isHighlightedProductsLoading, setIsHighlightedProductsLoading] =
    useState(false);
  const [searchingForProducts, setSearchingForProducts] = useState(false);
  const [savingProducts, setSavingProducts] = useState(false);
  const [isConfigLoading, setIsConfigLoading] = useState(false);
  const [maxHighlightedProducts, setMaxHighlightedProducts] = useState(0);
  const [pagination, setPagination] = useState<any>(Pagination);
  const { user } = useUser();
  const { id: userId } = user;

  const fetchConfigData = async () => {
    try {
      setIsConfigLoading(true);
      const result: any = await api.get(`/products/config/public`);
      setMaxHighlightedProducts(result.data.maxNoOfHighlightedProducts);
    } catch (err) {
      throw err;
    } finally {
      setIsConfigLoading(false);
    }
  };

  const fetchHighlightedProducts = async () => {
    try {
      setIsHighlightedProductsLoading(true);
      const result: any = await api.get(`/products/product/highlighted`);
      setHighlightedProducts(result.data);
    } catch (err) {
      throw err;
    } finally {
      setIsHighlightedProductsLoading(false);
    }
  };

  const searchForProducts = async (
    pagination: any,
    searchType: string,
    searchColumn: string,
    searchValue: string
  ) => {
    setSearchingForProducts(true);

    const currentPage = pagination.current;

    let params: object = {
      page: pagination.current || 1,
      pageSize: pagination.pageSize || Pagination.pageSize,
      searchValue: searchValue,
      // Update this to new end point rules
      searchField: searchColumn,
      searchOperation: searchType,
    };
    try {
      const result: any = await api.get(`/products/product`, {
        params,
      });
      const initialSearchResults = result.data.data;

      // Add productHighlighted key with initial value of false
      const searchResults = initialSearchResults.map((results: any) => ({
        ...results,
        productHighlighted: false,
      }));

      const commissionValueLookUp = new Array<string>();

      // Add a default commission value for products with no commission
      for (let i = 0; i < searchResults.length; i++) {
        // Set the commission value to -, this will stay the same if no commission value is found
        searchResults[i].commission = '-';
        // Add the product to the commissionValueLookUp array
        commissionValueLookUp.push(searchResults[i].externalId);
      }

      const pageSize = result.data.pageSize;
      const entryCount = result.data.entryCount;

      //Pagination
      const newPagination = {
        current: currentPage,
        pageSize: pageSize,
        total: entryCount,
      };
      setPagination(newPagination);

      // Look up commission values
      params = {
        productID:
          commissionValueLookUp.length > 0 ? commissionValueLookUp : undefined,
      };

      const commissionsResult: any = await api
        .get(`/sales/user/${userId}/productCommissionValue`, {
          params,
        })
        .then(({ data }) => {
          const returnedCommissions = data;
          returnedCommissions.forEach(
            (productCommission: ReturnedProdCommission) => {
              searchResults.forEach((product: any) => {
                if (
                  productCommission.productSKU.toLowerCase() ===
                  product.externalId.toLowerCase()
                ) {
                  product.commission = productCommission.commissionValue;
                }
              });
            }
          );
        })
        .catch((err) => {
          throw err;
        });
      setSearchedProducts(searchResults);
    } catch (err) {
      throw err;
    } finally {
      setSearchingForProducts(false);
    }
  };

  const saveSearchProductChanges = (products: SearchProduct[]) => {
    setSearchingForProducts(true);
    setSearchedProducts(products);
    setSearchingForProducts(false);
  };

  const saveHighlighted = async (productsArr: HighlightedProduct[]) => {
    setSavingProducts(true);
    const updatedObj = {
      products: productsArr,
    };

    try {
      const result: any = await api.post(
        `/products/product/highlighted`,
        updatedObj
      );
      return result;
      //Go back to table
    } catch (err) {
      throw err;
    } finally {
      fetchHighlightedProducts();
      setSavingProducts(false);
    }
  };

  return {
    // Variables
    isConfigLoading,
    maxHighlightedProducts,
    highlightedProducts,
    isHighlightedProductsLoading,
    pagination,
    searchedProducts,
    searchingForProducts,
    savingProducts,
    // Methods
    saveHighlighted,
    searchForProducts,
    saveSearchProductChanges,
    fetchData: fetchHighlightedProducts,
    fetchConfig: fetchConfigData,
  };
};

export default useProductHighlighting;
