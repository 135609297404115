import { RcFile, UploadFile } from 'antd/lib/upload/interface';
import {
  Button,
  Col,
  Empty,
  Image,
  Pagination,
  Progress,
  Row,
  Select,
  Skeleton,
  Tag,
  Upload,
} from 'components/antd';
import { Upload as UploadIcon } from 'components/icons';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const ImageItem = styled.div`
  background: #f3f3f3;
  height: 100%;
  overflow: hidden;
  position: relative;

  .ant-image-img {
    height: '100%';
  }
`;
const ImageItemDimensions = styled.div`
  color: white;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  right: 0;
  top: 0;
  padding: 0 4px;
  pointer-events: none;
`;
const ImageItemSelect = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
`;

export type ImageSizeOption = {
  label: string;
  value: string;
};

export enum ImageState {
  done = 'done',
  uploading = 'uploading',
  error = 'error',
}

export type ImageData = {
  id: string;
  source?: string;
  dimensionX?: number;
  dimensionY?: number;
  state: ImageState;
  percentageUploaded?: number;
};

type Props = {
  value?: ImageData;
  images: ImageData[];
  sizeFilter?: string;
  height: string;
  loading: boolean;
  onChange?: (imageData: ImageData) => void;
  onUpload?: (
    file: RcFile,
    onComplete: (id: string) => void
  ) => PromiseLike<void>;
  hideDimensions?: any;
};

const defaults = {
  page: 1,
  perPage: 10,
};

function ImageSelector(props: Props) {
  const { t } = useTranslation();
  const [sizeFilter, setSizeFilter] = useState<string>('');
  const [fileList, setFileList] = useState<any[]>([]);
  const [imageSizes, setImageSizes] = useState<ImageSizeOption[]>([]);
  const [selected, setSelected] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(defaults.page);
  const [numPerPage, setNumPerPage] = useState<number>(defaults.perPage);
  const [total, setTotal] = useState<number>(0);

  useEffect(() => {
    const newImageSizes: string[] = [];
    const newFileList: any[] = [];
    props.images.forEach((image) => {
      const size = `${image.dimensionX}x${image.dimensionY}`;

      if (newImageSizes.find((r) => r === size) == null)
        newImageSizes.push(size);

      if (sizeFilter === '' || sizeFilter === size)
        newFileList.push({
          uid: image.id,
          url: image.source,
          status: image.state,
          name: size,
          percent: image.percentageUploaded,
        });
    });

    setTotal(newFileList.length);
    setImageSizes(newImageSizes.sort().map((v) => ({ label: v, value: v })));
    setFileList(
      newFileList.slice(
        numPerPage * (currentPage - 1),
        numPerPage * currentPage
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.images, sizeFilter, currentPage, numPerPage]);

  useEffect(() => {
    if (!props.value) return;

    setSelected(props.value.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value]);

  useEffect(() => {
    if (!props.sizeFilter) return;

    setSizeFilter(props.sizeFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.sizeFilter]);

  useEffect(() => {
    return;
  }, [selected]);

  function isSelected(id: string): boolean {
    return id === selected;
  }

  function onPageChange(current: number, pageSize?: number) {
    if (numPerPage !== pageSize) {
      setNumPerPage(pageSize!);
      setCurrentPage(1);
      return;
    }

    setCurrentPage(current);
  }

  function selectImage(id: string): void {
    if (props.onChange) {
      const imageData = props.images.find(
        (element: ImageData) => element.id === id
      );

      if (imageData) props.onChange(imageData);
    }

    setSelected(id);
  }

  const dummyRequest = () => {
    // I know this looks strange, but this stops the component trying to post the form on upload (See CDAP-224)
    setTimeout(() => {
      return;
    }, 0);
  };

  const itemRender = (reactElement: any, file: UploadFile<any>) => {
    if (file.status === ImageState.error)
      return (
        <div className="ant-upload-list-item ant-upload-list-item-error ant-upload-list-item-list-type-picture-card">
          {t('common:uploadErrorImage')}
        </div>
      );

    if (file.status === ImageState.uploading)
      return (
        <div className="ant-upload-list-item ant-upload-list-item-uploading ant-upload-list-item-list-type-picture-card">
          <div className="ant-upload-list-item-info">
            <span className="ant-upload-span">
              <div className="ant-upload-list-item-thumbnail">
                {t('common:uploading')}...
              </div>
            </span>
          </div>
          <div className="ant-upload-list-item-progress">
            <Progress
              type="line"
              size="small"
              percent={file.percent}
              width={50}
            />
          </div>
        </div>
      );

    const selected: boolean = isSelected(file.uid);

    return (
      <ImageItem>
        <Image
          title={file.name}
          src={file.url}
          height="100%"
          width="100%"
          style={{ height: 'auto', width: 'auto', margin: 'auto' }}
        />
        {!props.hideDimensions && (
          <ImageItemDimensions>{file.name}</ImageItemDimensions>
        )}
        <ImageItemSelect>
          {selected && (
            <Tag style={{ margin: '0' }} color="green">
              {t('common:selected')}
            </Tag>
          )}
          {!selected && (
            <Button size="small" onClick={() => selectImage(file.uid)}>
              {t('common:select')}
            </Button>
          )}
        </ImageItemSelect>
      </ImageItem>
    );
  };

  return (
    <Row justify="start">
      <Col span={24}>
        <Row>
          <Col span={16}>
            <Upload
              accept="image/*"
              customRequest={dummyRequest}
              action={(file: RcFile) => {
                if (props.onUpload)
                  props.onUpload(file, (id: string) => {
                    setSelected(id);
                  });

                return '';
              }}
              maxCount={1}
              showUploadList={false}
            >
              <Button
                icon={
                  <UploadIcon
                    style={{
                      marginLeft: -2,
                      marginRight: '4px',
                      marginBottom: -2,
                      fontSize: '1.2em',
                    }}
                  />
                }
              >
                {t('common:upload')}
              </Button>
            </Upload>
          </Col>
          <Col span={8}>
            {props.hideDimensions ? (
              ''
            ) : (
              <Select
                allowClear={true}
                onChange={(value) => {
                  setCurrentPage(1);
                  setSizeFilter(value ? value.toString() : '');
                }}
                options={imageSizes}
                placeholder={t('common:filterbysize')}
                style={{ marginBottom: '8px', width: '100%' }}
                value={sizeFilter}
              ></Select>
            )}
          </Col>
        </Row>
        {props.loading ? (
          <Row
            justify="center"
            style={{
              background: '#f3f3f3',
              padding: '0 16px',
            }}
          >
            <Skeleton active={true} />
          </Row>
        ) : (
          <></>
        )}
        <Row
          justify="start"
          style={{
            background: '#f3f3f3',
            textAlign: 'left',
            padding: '8px 0 0 8px',
          }}
        >
          {fileList.length === 0 && (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              style={{ width: 'calc(100% - 8px)' }}
            />
          )}
          <Image.PreviewGroup>
            <Upload
              listType="picture-card"
              fileList={fileList}
              itemRender={itemRender}
              name="image"
            ></Upload>
          </Image.PreviewGroup>
        </Row>
        <Row
          justify="end"
          style={{
            background: '#f3f3f3',
          }}
        >
          <Pagination
            onChange={onPageChange}
            current={currentPage}
            pageSize={numPerPage}
            total={total}
            hideOnSinglePage={fileList.length === 0}
            style={{
              margin: '8px 16px 16px 0',
            }}
          ></Pagination>
        </Row>
      </Col>
    </Row>
  );
}

export default ImageSelector;
