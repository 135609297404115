import api from 'libs/api';
import { useState } from 'react';
import { message } from 'components/antd';
import { useTranslation } from 'react-i18next';

export type UserFeed = {
  filterString?: string;
  type: number;
  lastFetched?: Date;
  url: string;
};

export type FileFormat = {
  id: number;
  name: string;
};

export type FeedFilter = {
  id: number;
  name: string;
  type: string;
  defaultValue: string;
  values?: any[];
  falseName?: string;
  trueName?: string;
};

export type SelectedFilter = {
  id: string;
  value: null | string;
};

export type NewUserFeed = {
  format: number;
  filters?: NewFeedFilter[];
};

export type NewFeedFilter = {
  id: number;
  parameters: { [id: string]: string };
};

const useFeeds = () => {
  const { t } = useTranslation('feedsBuild');
  const [userFeeds, setUserFeeds] = useState<UserFeed[]>([]);
  const [fileFormats, setFileFormats] = useState<FileFormat[]>([
    // Default file format object until it gets set by the config call
    { id: 0, name: '' },
  ]);
  const [feedFilters, setFeedFilters] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [updatedFeed, setUpdatedFeed] = useState<any[]>([]);

  const fetchFeedConfig = async () => {
    setIsLoading(true);
    // Get the Feeds config - file formats and filter options
    try {
      const result: any = await api.get(`/feeds2/config/public`);

      // Create file format type array
      const fileFormatsArr = [];
      for (const [key, value] of Object.entries(result.data.feedFormats)) {
        fileFormatsArr.push({ id: value, name: key });
      }

      // @ts-ignore
      setFileFormats(fileFormatsArr);
      setFeedFilters(result.data.filters);
    } catch (err) {
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  const fetchUserFeeds = async (props?: any) => {
    setIsLoading(true);
    try {
      const result: any = await api.get(`/feeds2/userfeed`);
      setUserFeeds(result.data.feeds);
    } finally {
      setIsLoading(false);
    }
  };

  const createFeed = async (fileFormat: number, filters: any[]) => {
    let feedObject = {
      type: fileFormat,
      filters: filters,
    };

    setIsLoading(true);
    try {
      const result: any = await api.post(`/feeds2/userfeed`, feedObject);

      // This is used by the useEffect, so the page knows to re-render once we have created a new feed
      setUpdatedFeed(result);

      message.success(t(`feeds.add.success`));
    } catch (err) {
      message.error(t(`feeds.load.error`));
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  const noLongerLoading = async () => {
    setIsLoading(false);
  };

  return {
    // Variables
    isLoading,
    userFeeds,
    feedFilters,
    fileFormats,
    updatedFeed,
    // Methods
    loadConfig: fetchFeedConfig,
    loadFeeds: fetchUserFeeds,
    createFeed: createFeed,
    noLongerLoading,
  };
};

export default useFeeds;
