import useLayout from 'hooks/Layout';

const Topbar = () => {
  const { showNav } = useLayout();
  if (!showNav) return null;

  return (
    <div
      style={{
        display: 'flex',
        backgroundColor: 'var(--body-background)',
        alignItems: 'center',
        gap: '0.5em',
        padding: 8,
        boxShadow: 'var(--shadow-1-down)',
      }}
    >
      Navbar content
    </div>
  );
};

export default Topbar;
