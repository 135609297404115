import { useEffect, useState } from 'react';
import api from 'libs/api';

export type BannerGroup = {
  isActive: boolean;
  bannerGroupID: number;
  bannerGroupName: string;
  totalBannersCount?: number;
  totalHiddenActiveBannersCount?: number;
  totalVisibleActiveBannersCount?: number;
  totalInactiveBannersCount?: number;
};

export type BannerGroupCompact = {
  isActive: boolean;
  bannerGroupID?: number;
  bannerGroupName: string;
};

export interface IUseBannerGroups {
  filterGroups: BannerGroupCompact[];
  groups: BannerGroup[];
  isLoading: boolean;
  load: (includeDeactivated: boolean) => Promise<void>;
  add: (group: BannerGroup) => Promise<void>;
  edit: (group: BannerGroup) => Promise<void>;
  remove: (bannerGroupID: number) => Promise<void>;
  activate: (id: number, value: boolean) => Promise<any>;
}

const useBannerGroups = (): IUseBannerGroups => {
  const [groups, setGroups] = useState<BannerGroup[]>([]);
  const [filterGroups, setFilterGroups] = useState<BannerGroup[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    fetchFilterGroups();
  }, []);

  const fetchGroups = async (includeDeactivated: boolean) => {
    const result: any = await api.get('/resources/BannerGroups', {
      params: { includeDeactivated },
    });

    setGroups(result.data.bannerGroups);
    setIsLoading(false);
  };

  const fetchFilterGroups = async () => {
    setIsLoading(true);
    const result: any = await api.get('/resources/FilterBannerGroups');

    setFilterGroups(result.data);
    setIsLoading(false);
  };

  const add = (group: BannerGroupCompact) => {
    return api.post('/resources/StoreBannerGroup', group).then((response) => {
      fetchFilterGroups();
      return response.data;
    });
  };

  const remove = async (bannerGroupID: number) => {
    // api call
  };

  const edit = async (group: BannerGroupCompact) => {
    let item = groups.find((c) => c.bannerGroupID === group.bannerGroupID);
    if (!item) throw new Error();

    item.bannerGroupName = group.bannerGroupName;
    item.isActive = group.isActive;

    return api.post('/resources/StoreBannerGroup', item).then((response) => {
      fetchFilterGroups();
      return response.data;
    });
  };

  const activate = (id: number, value: boolean) =>
    api
      .post('/resources/BannerGroupActivation', {
        bannerGroupID: id,
        isActive: value,
      })
      .then((response) => {
        fetchFilterGroups();

        return response.data;
      });

  return {
    // Variables
    filterGroups,
    groups,
    isLoading,
    // Methods
    load: fetchGroups,
    add,
    remove,
    edit,
    activate,
  };
};

export default useBannerGroups;
