import BannerListing from './BannerListing';
import {
  Button,
  Card,
  Col,
  Form,
  Select,
  Input,
  Pagination,
  Row,
  Space,
  Switch,
} from 'components/antd';
import { CardsIcon, Create, RowsIcon, Search } from 'components/icons';
import { Content, TitleRow } from 'components/shared';
import paginationDefaults from 'constants/pagination';
import ViewType from 'constants/viewType';
import useBanners from 'hooks/Resources/banners';
import useBannerDimensions from 'hooks/Resources/bannerDimensions';
import useBannerGroups from 'hooks/Resources/bannerGroups';
import useUser from 'hooks/User';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Permissions } from 'constants/permissions';

type Props = {
  isAdmin: boolean;
};

function Page(props: Props) {
  const defaultFilters = {
    includeHiddenBanners: false,
    includeInactiveBanners: false,
    limit: paginationDefaults.pageSize,
    offset: 0,
  };

  const { hasPermission } = useUser();

  const { load: loadBanners, banners, isLoading, pagination } = useBanners();
  const { load: loadDimensions, dimensions } = useBannerDimensions();
  const { load: loadGroups, groups } = useBannerGroups();
  const { t } = useTranslation('banners');
  const [dimensionTypeID, setDimensionTypeID] = useState<string>('');
  const [includeHiddenBanners, setIncludeHiddenBanners] = useState<boolean>(
    defaultFilters.includeHiddenBanners
  );
  const [includeInactiveBanners, setIncludeInactiveBanners] = useState<boolean>(
    defaultFilters.includeInactiveBanners
  );
  const [groupId, setGroupID] = useState<string>('');
  const [search, setSearch] = useState<string>('');
  const [viewType, setViewType] = useState<ViewType>(ViewType.Card);
  const [paginationCurrent, setPaginationCurrent] = useState<number>(
    pagination.current
  );

  useEffect(() => {
    loadDimensions();
    loadGroups(false);
    loadBanners(defaultFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadBanners({
      ...defaultFilters,
      includeInactiveBanners: includeInactiveBanners,
      includeHiddenBanners: includeHiddenBanners,
      bannerGroupID: groupId || undefined,
      bannerSearchText: search || undefined,
      dimensionTypeID: dimensionTypeID || undefined,
      limit: defaultFilters.limit,
      offset:
        pagination.pageSize * (paginationCurrent - 1) || defaultFilters.offset,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dimensionTypeID,
    groupId,
    includeHiddenBanners,
    includeInactiveBanners,
    paginationCurrent,
    search,
  ]);

  function onPageChange(current: number, pageSize?: number) {
    setPaginationCurrent(current);
  }

  const hasBannerExtra = hasPermission(Permissions['Resources.Banners.Extra']);

  return (
    <Content>
      <TitleRow>
        <h1>{t('banners')}</h1>
        {props.isAdmin && (
          <Link to={`/admin/banners/create`}>
            <Button
              icon={
                <Create
                  style={{
                    marginLeft: -2,
                    marginBottom: -2,
                    fontSize: '1.2em',
                  }}
                />
              }
            >
              {t('common:add')}
            </Button>
          </Link>
        )}
      </TitleRow>

      <Row justify="center" style={{ margin: '16px 0' }}>
        <Col span={24}>
          <Form layout="inline" style={{ justifyContent: 'center' }}>
            <Form.Item name="group">
              <Select
                placeholder={t('group')}
                allowClear
                options={groups.map((group) => ({
                  label: t(group.bannerGroupName),
                  value: group.bannerGroupID.toString(),
                }))}
                style={{ width: 200 }}
                onChange={(value) => {
                  setGroupID(value as string);
                  setPaginationCurrent(1);
                }}
              />
            </Form.Item>
            <Form.Item name="dimensions">
              <Select
                placeholder={t('dimensions')}
                allowClear
                options={dimensions
                  .map((dimension) => ({
                    label: t(dimension.dimensionDisplay),
                    value: dimension.dimensionTypeID.toString(),
                  }))
                  .sort((a, b) => {
                    var nameA = a.label.toUpperCase();
                    var nameB = b.label.toUpperCase();
                    if (nameA < nameB) return -1;
                    if (nameA > nameB) return 1;
                    return 0;
                  })}
                style={{ width: 200 }}
                onChange={(value) => {
                  setDimensionTypeID(value as string);
                  setPaginationCurrent(1);
                }}
              />
            </Form.Item>
            <Form.Item name="search">
              <Input.Search
                placeholder={t('searchText')}
                enterButton={
                  <Search style={{ marginTop: 5, fontSize: '1.2em' }} />
                }
                onSearch={(value) => {
                  setSearch(value);
                  setPaginationCurrent(1);
                }}
              />
            </Form.Item>
          </Form>
        </Col>
      </Row>

      {hasBannerExtra && (
        <Row justify="center">
          <Col span={3}></Col>
          <Col span={18}>
            <Form
              layout="inline"
              style={{ justifyContent: 'center', marginBottom: '16px' }}
            >
              <Form.Item label={t('include.hidden')} name="includeHidden">
                <Switch
                  defaultChecked={includeHiddenBanners}
                  onChange={(value) => setIncludeHiddenBanners(value)}
                />
              </Form.Item>
              <Form.Item label={t('include.inactive')} name="includeInactive">
                <Switch
                  defaultChecked={includeInactiveBanners}
                  onChange={(value) => setIncludeInactiveBanners(value)}
                />
              </Form.Item>
            </Form>
          </Col>
          <Col span={3}></Col>
        </Row>
      )}

      <Card loading={isLoading} style={{ width: '100%' }}>
        <Row justify="center">
          <Col span={3}></Col>
          <Col span={18} style={{ textAlign: 'center' }}>
            <Pagination
              onChange={onPageChange}
              current={pagination.current}
              pageSize={pagination.pageSize}
              total={pagination.total}
            />
          </Col>
          <Col span={3}>
            <Space>
              <div>{t('common:View')}:</div>
              <Button
                value="row"
                type={viewType === ViewType.Row ? 'primary' : 'default'}
                onClick={() => setViewType(ViewType.Row)}
                icon={<RowsIcon style={{ marginBottom: '-2px' }} />}
                size="small"
              />
              <Button
                value="card"
                type={viewType === ViewType.Card ? 'primary' : 'default'}
                onClick={() => setViewType(ViewType.Card)}
                icon={<CardsIcon style={{ marginBottom: '-2px' }} />}
                size="small"
              />
            </Space>
          </Col>
        </Row>
        <Row justify="center" style={{ marginTop: '2em' }}>
          <BannerListing
            banners={banners}
            isAdmin={props.isAdmin}
            loading={isLoading}
            viewType={viewType}
          />
        </Row>
        <Row justify="center" style={{ marginTop: '2em' }}>
          <Pagination
            onChange={onPageChange}
            current={pagination.current}
            pageSize={pagination.pageSize}
            total={pagination.total}
          />
        </Row>
      </Card>
    </Content>
  );
}

export default Page;
