import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Form, Select, Input, Card, Pagination, Row } from 'components/antd';
import CardView from './CardView';
import RowView from './RowView';

import useNews from 'hooks/Resources/news';
import { Search } from 'components/icons';
import ViewType from 'constants/viewType';
import useNewsCategories from 'hooks/Resources/newsCategories';

type Props = {
  view: ViewType;
};

const Page = ({ view }: Props) => {
  const { isLoading, load, pagination, news } = useNews();
  const { filterCategories } = useNewsCategories();

  const [category, setCategory] = useState<string>('');
  const [search, setSearch] = useState<string>('');

  const { t } = useTranslation('news');

  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (current: number, pageSize?: number) =>
    load({
      current,
      pageSize,
      articleSearchText: search,
      categories: category ? [category] : undefined,
    });

  const onFilterChanged = (category: string, search: string) => {
    pagination.current = 1;
    setCategory(category);
    setSearch(search);
    load({
      ...pagination,
      articleSearchText: search,
      categories: category ? [category] : undefined,
    });
  };

  return (
    <>
      <Row justify="center" style={{ paddingTop: '2em' }}>
        <Form layout="inline">
          <Form.Item name="category">
            <Select
              placeholder={t('category')}
              allowClear
              options={filterCategories.map((item) => ({
                label: t(item.newsCategoryName),
                value: item.newsCategoryID
                  ? item.newsCategoryID.toString()
                  : '-1', // review this later
              }))}
              style={{ width: 200 }}
              onChange={(value) => onFilterChanged(value as string, search)}
            />
          </Form.Item>
          <Form.Item name="search">
            <Input.Search
              value={search}
              onSearch={(value) => onFilterChanged(category, value)}
              placeholder={t('articleSearchText')}
              enterButton={
                <Search style={{ marginTop: 5, fontSize: '1.2em' }} />
              }
            />
          </Form.Item>
        </Form>
      </Row>

      <Card style={{ marginTop: '2em' }} loading={isLoading}>
        <Row justify="center">
          <Pagination onChange={onChange} {...pagination} />
        </Row>

        <Row style={{ marginTop: '1em', marginBottom: '1em', width: '100%' }}>
          {view === ViewType.Card && <CardView items={news} />}
          {view === ViewType.Row && <RowView items={news} />}
        </Row>

        <Row justify="center">
          <Pagination onChange={onChange} {...pagination} />
        </Row>
      </Card>
    </>
  );
};

export default Page;
