import { Navigate, Route, Routes } from 'react-router';
import List from './List';
import Reply from './Reply';
import Create from './Create';
import useRequestsHook from 'hooks/Support/requests';
import { PrivatePage } from 'components/shared';
import { Permissions } from 'constants/permissions';

const Page = () => {
  const useRequests = useRequestsHook();
  const { requestStatuses, contactReasons, supportAffiliates } = useRequests;

  return (
    <Routes>
      <Route path="create" element={<Create enquiryTypes={contactReasons} />} />
      <Route
        path="/:requestID/:affiliateID/reply"
        element={<Reply isReply />}
      />
      <Route path="/:requestID/:affiliateID/addmessage" element={<Reply />} />
      <Route
        path="list"
        element={
          <List
            requestStatuses={requestStatuses}
            contactReasons={contactReasons}
            supportAffiliates={supportAffiliates}
          />
        }
      />
      <Route index element={<Navigate to="list" />} />
    </Routes>
  );
};

export default Page;
