import styled from 'styled-components';
import { Table as AntdTable } from 'antd';
import pagination from 'constants/pagination';
type Props = {
  style?: React.CSSProperties;
  // All other props
  [x: string]: any;
};

const StyledAntdTable = styled(AntdTable)`
  tr:nth-child(even) {
    background-color: #f5f5f5;
  }
  .ant-table-cell-fix-left,
  .ant-table-cell-fix-right {
    background-color: inherit;
  }
`;

const StyledTable = ({ style, ...props }: Props) => {
  return (
    <StyledAntdTable
      size="small"
      {...props}
      pagination={
        props?.pagination === false
          ? false
          : { ...pagination, ...props?.pagination }
      }
      style={{ ...style }}
    />
  );
};

export const Table = StyledTable;
