import { ComponentState } from 'react';
import { Navigate } from 'react-router-dom';

type Props = {
  to: string;
  replace?: boolean;
  search?: any;
  rest?: any;
  state?: ComponentState;
};

const Component = ({ to, replace, state, search = '', ...rest }: Props) => {
  // if search is object ...convert to query string
  // if search is text just connect it to the query

  return (
    <Navigate replace={replace} state={state} to={`${to}${search}`} {...rest} />
  );
};

export default Component;
