import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button, Form, Input, Select } from 'components/antd';
// import { InfoBox } from 'components/shared';
import { FormInstance } from 'antd';
import { AffiliateDetail } from 'hooks/Account/affiliateDetails';
import { useEffect } from 'react';
import { isValidUrl } from 'libs/validations';

const { Option } = Select;

const Columns = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 992px) {
    display: grid;
    grid-template-columns: 1fr 400px;
  }
`;

type Props = {
  showSubmitButton?: boolean;
  onFinish?: (values: any) => any;
  form?: FormInstance<any>;
  readonly?: boolean;
  details?: AffiliateDetail;
};

function Page({
  onFinish,
  showSubmitButton = false,
  form,
  readonly = false,
  details,
}: Props) {
  const { t } = useTranslation('wizard');
  const [newForm] = Form.useForm();
  form = form || newForm;
  const disabled = readonly;

  useEffect(() => {
    details && form && form.resetFields();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [details]);

  return (
    <Columns>
      <Form
        name="basic"
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 12 }}
        initialValues={details}
        autoComplete="off"
        onFinish={onFinish}
      >
        <Form.Item
          label={t('affiliateName')}
          name="affiliateName"
          rules={[{ required: true }]}
        >
          <Input disabled={disabled} />
        </Form.Item>

        <Form.Item
          label={t('affiliateWebsite')}
          name="affiliateWebsite"
          rules={[
            { required: true },
            ({ getFieldValue }) => ({
              validator(_, value = '') {
                return isValidUrl(value)
                  ? Promise.resolve()
                  : Promise.reject(t('mustBeAValidUrl'));
              },
            }),
          ]}
        >
          <Input disabled={disabled} placeholder="https://www.website.com" />
        </Form.Item>

        <Form.Item name="title" label={t('title')} rules={[{ required: true }]}>
          <Select
            placeholder={t('titlePlaceholder')}
            style={{ width: 200 }}
            disabled={disabled}
          >
            <Option value="mr">{t('mr')}</Option>
            <Option value="mrs">{t('mrs')}</Option>
            <Option value="miss">{t('miss')}</Option>
            <Option value="ms">{t('ms')}</Option>
          </Select>
        </Form.Item>

        <Form.Item
          label={t('firstName')}
          name="firstName"
          rules={[{ required: true }, { min: 2 }]}
        >
          <Input disabled={disabled} />
        </Form.Item>

        <Form.Item
          label={t('surname')}
          name="surname"
          rules={[{ required: true }, { min: 2 }]}
        >
          <Input disabled={disabled} />
        </Form.Item>

        <Form.Item
          label={t('phone')}
          name="phoneNumber"
          rules={[
            { required: true },
            ({ getFieldValue }) => ({
              validator(_, value = '') {
                const isNumeric: boolean = value.match(/^[0-9+][0-9 ]+$/);

                return isNumeric
                  ? Promise.resolve()
                  : Promise.reject(t('phoneMustBeANumber'));
              },
            }),
          ]}
        >
          <Input disabled={disabled} />
        </Form.Item>

        {showSubmitButton && (
          <Form.Item wrapperCol={{ offset: 8 }}>
            <Button type="primary" htmlType="submit" disabled={disabled}>
              Submit
            </Button>
          </Form.Item>
        )}
      </Form>

      {/* <InfoBox>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec vel sem
          at arcu consequat pretium sed et nibh. Maecenas accumsan ligula ac
          justo pulvinar, at feugiat enim molestie. Cras non laoreet diam.
          Maecenas ultricies non est et mollis. Duis et eros nisl. Phasellus
        </p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec vel sem
          at arcu consequat pretium sed et nibh. Maecenas accumsan ligula ac
          justo pulvinar, at feugiat enim molestie. Cras non laoreet diam.
          Maecenas ultricies non est et mollis. Duis et eros nisl. Phasellus
        </p>
      </InfoBox> */}
    </Columns>
  );
}

export default Page;
