import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Form, Input, Radio, Button } from 'components/antd';
import { InfoBox, InputMask } from 'components/shared';
import { FormInstance } from 'antd';
import { AffiliateBank, AffiliateBankUk } from 'hooks/Account/affiliateDetails';
import { isAlphaNumeric } from 'libs/validations';

const Columns = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 992px) {
    display: grid;
    grid-template-columns: 1fr 400px;
  }
`;

const InnerColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

type Props = {
  showSubmitButton?: boolean;
  waitingForApproval?: boolean;
  onFinish?: (isLocal: boolean, values: any) => any;
  form?: FormInstance<any>;
  readonly?: boolean;
  bank?: AffiliateBank;
  defaultVatSelected?: boolean;
  defaultIsUkBased?: boolean;
  dataReviewDisplay?: boolean;
};

const Center = styled.div`
  display: flex;
  justify-content: center;
  padding: 2em;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: 20%;
`;

function Page({
  showSubmitButton,
  waitingForApproval,
  onFinish,
  form,
  readonly,
  bank,
  dataReviewDisplay,
}: Props) {
  const { t } = useTranslation('wizard');
  const [isResident, setIsResident] = useState(true);
  const [newForm] = Form.useForm();
  form = form || newForm;
  const disabled = readonly || waitingForApproval;

  useEffect(() => {
    if (bank && (bank as AffiliateBankUk).sortCode) {
      setIsResident(true);
    } else {
      setIsResident(false);
    }
  }, [bank]);

  return (
    <Columns style={dataReviewDisplay === true ? { display: 'flex' } : {}}>
      <InnerColumn>
        {waitingForApproval && (
          <Center style={{ width: '70%', height: '9em' }}>
            <InfoBox oneLine style={{ width: '100%', height: '100%' }}>
              <div>Waiting For Approval</div>
            </InfoBox>
          </Center>
        )}

        <Form
          labelCol={{ span: 12, offset: 4 }}
          wrapperCol={{ span: 12, offset: 8 }}
          layout="vertical"
        >
          <Form.Item label={t('locationQuestion')}>
            <Radio.Group
              disabled={disabled}
              value={isResident}
              onChange={(e) =>
                e?.target?.value !== undefined &&
                setIsResident(e?.target?.value)
              }
            >
              <Radio.Button value={true}>{t('common:yes')}</Radio.Button>
              <Radio.Button value={false}>{t('common:no')}</Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Form>

        <Form
          name="basic"
          form={form}
          onFinish={(values) => onFinish && onFinish(isResident, values)}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 8 }}
          initialValues={bank}
          autoComplete="off"
        >
          <Form.Item
            label="Account Name"
            name="accountName"
            rules={[
              {
                required:
                  true &&
                  (dataReviewDisplay === undefined ? true : !dataReviewDisplay),
              },
            ]}
          >
            <Input disabled={disabled} />
          </Form.Item>

          <Form.Item
            label="Bank Name"
            name="bankName"
            rules={[
              {
                required:
                  true &&
                  (dataReviewDisplay === undefined ? true : !dataReviewDisplay),
              },
            ]}
          >
            <Input disabled={disabled} />
          </Form.Item>

          <Form.Item
            label="Account Number"
            name="accountNumber"
            rules={[
              {
                required:
                  true &&
                  (dataReviewDisplay === undefined ? true : !dataReviewDisplay),
              },
              { min: !isResident ? 1 : 8, max: !isResident ? 34 : 8 },
              ({ getFieldValue }) => ({
                validator(_, value = '') {
                  if (!isResident) {
                    return isAlphaNumeric(value)
                      ? Promise.resolve()
                      : Promise.reject(t('mustBeAlphaNumeric'));
                  } else {
                    console.log('validating');
                    return value.match(/^[0-9]+$/)
                      ? Promise.resolve()
                      : Promise.reject(t('mustBeANumber'));
                  }
                },
              }),
            ]}
          >
            <Input disabled={disabled} />
          </Form.Item>

          {isResident ? (
            <Form.Item
              label="Sort Code"
              name="sortCode"
              rules={[
                {
                  required:
                    true &&
                    (dataReviewDisplay === undefined
                      ? true
                      : !dataReviewDisplay),
                },
                {
                  pattern: /^[0-9][0-9]-[0-9][0-9]-[0-9][0-9]$/,
                  message: t('sortcodLengthValidation'),
                },
              ]}
            >
              <InputMask
                disabled={disabled}
                mask="99-99-99"
                placeholder="__-__-__"
              />
            </Form.Item>
          ) : (
            <>
              <Form.Item
                label="Swift Code"
                name="swiftCode"
                rules={[
                  {
                    required:
                      true &&
                      (dataReviewDisplay === undefined
                        ? true
                        : !dataReviewDisplay),
                  },
                  { min: 8, max: 11 },
                  ({ getFieldValue }) => ({
                    validator(_, value = '') {
                      return isAlphaNumeric(value)
                        ? Promise.resolve()
                        : Promise.reject(t('mustBeAlphaNumeric'));
                    },
                  }),
                ]}
              >
                <Input disabled={disabled} />
              </Form.Item>
              <Form.Item
                label="IBAN Code"
                name="iban"
                rules={[
                  {
                    required:
                      true &&
                      (dataReviewDisplay === undefined
                        ? true
                        : !dataReviewDisplay),
                  },
                  { min: 16, max: 34 },
                  ({ getFieldValue }) => ({
                    validator(_, value = '') {
                      return isAlphaNumeric(value)
                        ? Promise.resolve()
                        : Promise.reject(t('mustBeAlphaNumeric'));
                    },
                  }),
                ]}
              >
                <Input disabled={disabled} />
              </Form.Item>
            </>
          )}
          {showSubmitButton && (
            <Form.Item wrapperCol={{ offset: 8 }}>
              <Button type="primary" htmlType="submit" disabled={disabled}>
                Submit
              </Button>
            </Form.Item>
          )}
        </Form>
      </InnerColumn>
      {/* <InfoBox>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec vel sem
          at arcu consequat pretium sed et nibh. Maecenas accumsan ligula ac
          justo pulvinar, at feugiat enim molestie. Cras non laoreet diam.
          Maecenas ultricies non est et mollis. Duis et eros nisl. Phasellus
        </p>
      </InfoBox> */}
    </Columns>
  );
}

export default Page;
