import { useEffect, useState } from 'react';
import api from 'libs/api';
import Pagination from 'constants/pagination';

export type PaymentStatus = {
  statusTypeNumber: number;
  statusTypeName: string;
};

export type PaymentAffiliate = {
  id: string;
  affiliateName: string;
  externalIdentifier?: string;
};

export type PaymentRequest = {
  id: string;
  affiliateId?: string;
  affiliateName?: string;
  purchaseOrderId?: string;
  notes?: string;
  amount: number;
  dateCreated: string;
  lastModifiedDate: string;
  status?: string;
  orderReference?: string;
};

export type NewPaymentRequest = {
  affiliateId?: string;
  amount: number;
  notes?: string;
  purchaseOrderId?: string;
  orderReference?: string;
};

const usePayments = () => {
  const [paymentRequests, setPaymentRequests] = useState<PaymentRequest[]>([]);
  const [paymentAffiliates, setAffiliates] = useState<PaymentAffiliate[]>([]);
  const [paymentsLoading, setIsLoading] = useState(false);
  const [paymentSubmitting, setIsSubmitting] = useState(false);
  const [paymentStatuses, setStatuses] = useState<PaymentStatus[]>([
    { statusTypeNumber: 1, statusTypeName: 'NotInvoiced' },
    { statusTypeNumber: 2, statusTypeName: 'Invoiced' },
    { statusTypeNumber: 3, statusTypeName: 'PaidByTenant' },
    { statusTypeNumber: 4, statusTypeName: 'PaidToAffiliate' },
    { statusTypeNumber: 5, statusTypeName: 'Created' },
    { statusTypeNumber: 6, statusTypeName: 'PaidToClient' },
  ]);
  const [pagination, setPagination] = useState<any>(Pagination);
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest>();

  useEffect(() => {
    setIsLoading(true);
    fetchAffiliates();
    setIsLoading(false);
  }, []);

  const fetchAffiliates = async () => {
    const result: any = await api.get(`/finance/affiliates`);

    setAffiliates(result.data);
  };

  const noLongerLoading = async () => {
    setIsLoading(false);
  };

  const noLongerSubmitting = async () => {
    setIsSubmitting(false);
  };

  const raiseBonusPaymentRequest = async (item: NewPaymentRequest) => {
    // api call
    setIsSubmitting(true);
    return await api.post(`/finance/bonusPayments`, item).then((response) => {
      setIsSubmitting(false);
      return response.data;
    });
  };

  const editBonusPaymentRequest = async (
    id: string,
    item: NewPaymentRequest
  ) => {
    // api call
    setIsSubmitting(true);
    return await api
      .put(`/finance/bonusPayments/${id}`, item)
      .then((response) => {
        setIsSubmitting(false);
        return response.data;
      });
  };

  const fetchBonusPaymentRequests = async (props?: any) => {
    setIsLoading(true);

    try {
      const params = {
        offset: props?.current || 1,
        limit: props?.pageSize || Pagination.pageSize,
        affiliateID: props?.affiliateID || undefined,
        fromDate: props?.rangeFrom || undefined,
        toDate: props?.rangeTo || undefined,
        status: props?.status || undefined,
      };

      const result: any = await api.get(`/finance/bonusPayments`, { params });

      setPaymentRequests(result.data.data);

      const newPagination = {
        current: result.data.offset,
        pageSize: result.data.limit,
        total: result.data.totalItems,
      };

      setPagination(newPagination);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchBonusPayment = async (id: string) => {
    setIsLoading(true);
    try {
      const result: any = await api.get(`finance/bonuspayments/${id}`);

      setPaymentRequest(result.data);
    } finally {
      setIsLoading(false);
    }
  };

  const raiseCommissionRequest = async (item: NewPaymentRequest) => {
    // api call
    setIsSubmitting(true);
    return await api.post(`/finance/commissions`, item).then((response) => {
      setIsSubmitting(false);
      return response.data;
    });
  };

  const editCommissionPaymentRequest = async (
    id: string,
    item: NewPaymentRequest
  ) => {
    // api call
    setIsSubmitting(true);
    return await api
      .put(`/finance/commissions/${id}`, item)
      .then((response) => {
        setIsSubmitting(false);
        return response.data;
      });
  };

  const fetchCommissionRequests = async (props?: any) => {
    setIsLoading(true);

    try {
      const params = {
        offset: props?.current || 1,
        limit: props?.pageSize || Pagination.pageSize,
        fromDate: props?.rangeFrom || undefined,
        toDate: props?.rangeTo || undefined,
        status: props?.status || undefined,
      };

      const result: any = await api.get(`/finance/commissions`, { params });

      setPaymentRequests(result.data.data);

      const newPagination = {
        current: result.data.offset,
        pageSize: result.data.limit,
        total: result.data.totalItems,
      };

      setPagination(newPagination);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchCommissionPayment = async (id: string) => {
    setIsLoading(true);
    try {
      const result: any = await api.get(`finance/commissions/${id}`);

      setPaymentRequest(result.data);
    } finally {
      setIsLoading(false);
    }
  };

  const raiseTenancyRequest = async (item: NewPaymentRequest) => {
    // api call
    setIsSubmitting(true);
    return await api.post(`/finance/tenancies`, item).then((response) => {
      setIsSubmitting(false);
      return response.data;
    });
  };

  const editTenancyPaymentRequest = async (
    id: string,
    item: NewPaymentRequest
  ) => {
    // api call
    setIsSubmitting(true);
    return await api.put(`/finance/tenancies/${id}`, item).then((response) => {
      setIsSubmitting(false);
      return response.data;
    });
  };

  const fetchTenancyRequests = async (props?: any) => {
    setIsLoading(true);

    try {
      const params = {
        offset: props?.current || 1,
        limit: props?.pageSize || Pagination.pageSize,
        affiliateID: props?.affiliateID || undefined,
        fromDate: props?.rangeFrom || undefined,
        toDate: props?.rangeTo || undefined,
        status: props?.status || undefined,
      };

      const result: any = await api.get(`/finance/tenancies`, { params });

      setPaymentRequests(result.data.data);

      const newPagination = {
        current: result.data.offset,
        pageSize: result.data.limit,
        total: result.data.totalItems,
      };

      setPagination(newPagination);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchTenancyPayment = async (id: string) => {
    setIsLoading(true);
    try {
      const result: any = await api.get(`finance/tenancies/${id}`);

      setPaymentRequest(result.data);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchCreditNoteRequests = async (props?: any) => {
    setIsLoading(true);

    try {
      const params = {
        offset: props?.current || 1,
        limit: props?.pageSize || Pagination.pageSize,
        fromDate: props?.rangeFrom || undefined,
        toDate: props?.rangeTo || undefined,
        status: props?.status || undefined,
      };

      const result: any = await api.get(`/finance/creditnotes`, { params });

      setPaymentRequests(result.data.data);

      const newPagination = {
        current: result.data.offset,
        pageSize: result.data.limit,
        total: result.data.totalItems,
      };

      setPagination(newPagination);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchCreditNote = async (id: string) => {
    setIsLoading(true);
    try {
      const result: any = await api.get(`finance/creditnotes/${id}`);

      setPaymentRequest(result.data);
    } finally {
      setIsLoading(false);
    }
  };

  const raiseCreditNoteRequest = async (item: NewPaymentRequest) => {
    // api call
    setIsSubmitting(true);
    return await api.post(`/finance/creditnotes`, item).then((response) => {
      setIsSubmitting(false);
      return response.data;
    });
  };

  const editCreditNoteRequest = async (id: string, item: NewPaymentRequest) => {
    // api call
    setIsSubmitting(true);
    return await api
      .put(`/finance/creditnotes/${id}`, item)
      .then((response) => {
        setIsSubmitting(false);
        return response.data;
      });
  };

  return {
    // Variables
    paymentStatuses,
    paymentAffiliates,
    paymentsLoading,
    pagination,
    paymentRequests,
    paymentSubmitting,
    paymentRequest,
    //categories,
    loadAffiliates: fetchAffiliates,
    loadBonusPaymentRequests: fetchBonusPaymentRequests,
    newBonusPaymentRequest: raiseBonusPaymentRequest,
    updateBonusPaymentRequest: editBonusPaymentRequest,
    loadBonusPayment: fetchBonusPayment,
    loadCommissionRequests: fetchCommissionRequests,
    newCommissionRequest: raiseCommissionRequest,
    updateCommissionRequest: editCommissionPaymentRequest,
    loadCommission: fetchCommissionPayment,
    loadTenancyRequests: fetchTenancyRequests,
    newTenancyRequest: raiseTenancyRequest,
    updateTenancyRequest: editTenancyPaymentRequest,
    loadTenancy: fetchTenancyPayment,
    loadCreditNoteRequests: fetchCreditNoteRequests,
    loadCreditNote: fetchCreditNote,
    newCreditNoteRequest: raiseCreditNoteRequest,
    updateCreditNoteRequest: editCreditNoteRequest,
    noLongerLoading,
    noLongerSubmitting,
  };
};

export default usePayments;
