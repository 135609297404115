import { Button, Space } from 'components/antd';
import { BarChart, LineChart } from 'components/icons';
import { useTranslation } from 'react-i18next';
import { ChartTypes } from './Chart';

interface Props {
  chartType: string;
  toggleChartHandler: (chart: string) => void;
}

const ChartToggle = ({ chartType, toggleChartHandler }: Props) => {
  const { t } = useTranslation();

  return (
    <Space>
      <div>{t('common:View')}:</div>
      <Button
        type={chartType === ChartTypes.Bar ? 'primary' : 'default'}
        onClick={() => toggleChartHandler(ChartTypes.Bar)}
        icon={<BarChart style={{ marginBottom: '-2px' }} />}
        size="small"
      />
      <Button
        type={chartType === ChartTypes.Line ? 'primary' : 'default'}
        onClick={() => toggleChartHandler(ChartTypes.Line)}
        icon={<LineChart style={{ marginBottom: '-2px' }} />}
        size="small"
      />
    </Space>
  );
};

export default ChartToggle;
