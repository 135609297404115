import api from 'libs/api';
import { useState } from 'react';
import downloadFile from 'libs/downloadFile';

export type BillingListItem = {
  id: string;
  triggerDate: string;
  invoiceCount: number;
};

const changeRequestUrl = '/finance';

export interface IUseInvoices {
  list: BillingListItem[];
  isLoading: boolean;
  load: (params?: any) => Promise<void>;
  exportPDF: (params?: any) => Promise<any>;
}

const useBilling = (): IUseInvoices => {
  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState<BillingListItem[]>([]);

  const fetch = async (props?: any) => {
    setIsLoading(true);

    const params = {
      month: props?.month || undefined,
      year: props?.year || undefined,
    };

    try {
      const { data: results }: any = await api.get(
        `${changeRequestUrl}/invoicebatches`,
        { params }
      );

      setList(results);
    } finally {
      setIsLoading(false);
    }
  };

  const download = (props: any) =>
    downloadFile(
      `${changeRequestUrl}/invoicebatches/export?month=${
        props?.month || undefined
      }&year=${props?.year}`
    );

  return {
    // variables
    list,
    isLoading,
    // methods
    load: fetch,
    exportPDF: download,
  };
};

export default useBilling;
