import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';

import { Card, Input, Form, Button, Checkbox, Row, Col } from 'components/antd';
import { Content, TitleRow } from 'components/shared';
import Editor from 'components/shared/Editor';

import useCommunications, { IEmailTemplate } from 'hooks/Communications/Emails';
import { useEffect } from 'react';
import { message } from 'antd';
import { TemplateType } from 'hooks/Communications/Models';

const Page = () => {
  const { t } = useTranslation('communicationsMaint');
  const navigate = useNavigate();

  let { emailTemplateId = '', emailTemplateTypeId = '' } = useParams();

  const [form] = Form.useForm<IEmailTemplate>();

  var {
    isLoading,
    isCreatingUpdatingTemplate,
    fetchTemplate,
    createOrUpdateTemplate,
  } = useCommunications();

  useEffect(() => {
    fetchTemplate(emailTemplateId as any, emailTemplateTypeId as any)
      .then((template: IEmailTemplate) => {
        form.setFieldsValue(template);
      })
      .catch(() => {
        message.error('Unable to load templates');
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateTemplate = async (data: IEmailTemplate) => {
    try {
      data.emailTemplateID = parseInt(emailTemplateId);
      data.templateTypeID = parseInt(emailTemplateTypeId);
      await createOrUpdateTemplate(data);
      navigate('../list');
      message.success('Successfully saved the template');
    } catch (err) {
      message.error('Error while saving template');
    }
  };

  return (
    <Content>
      <TitleRow>
        <h1>{t('emailtemplates')}</h1>
      </TitleRow>
      <Card loading={isLoading}>
        <Form form={form} layout="vertical" onFinish={updateTemplate}>
          <Form.Item
            label="Template Name"
            name="templateName"
            rules={[{ required: true }]}
          >
            <Input
              type="text"
              placeholder="Example"
              disabled={parseInt(emailTemplateTypeId) === TemplateType.System}
            />
          </Form.Item>
          <Row gutter={5}>
            <Col span={12}>
              <Form.Item
                label="From Name"
                name="emailFromName"
                rules={[{ required: true }]}
              >
                <Input type="text" placeholder="Example" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="From Address"
                name="emailFromAddress"
                rules={[{ required: true }]}
              >
                <Input type="text" placeholder="example@example.com" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={5}>
            <Col span={12}>
              <Form.Item label="CC" name="emailCCList">
                <Input
                  type="text"
                  placeholder="example@example.com; example2@example.com"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="BCC" name="emailBCCList">
                <Input
                  type="text"
                  placeholder="example@example.com; example2@example.com"
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            name="emailSubject"
            label="Subject"
            rules={[{ required: true }]}
          >
            <Input type="text"></Input>
          </Form.Item>
          <Form.Item
            name="emailMessageHTML"
            label="Email Body"
            rules={[{ required: true }]}
          >
            <Editor />
          </Form.Item>
          <Row>
            <Form.Item
              valuePropName="checked"
              initialValue={true}
              name="trackActivity"
            >
              <Checkbox>Track Activity</Checkbox>
            </Form.Item>
          </Row>
          <Form.Item style={{ textAlign: 'center' }}>
            <Button onClick={() => navigate('../list')}>Cancel</Button>{' '}
            <Button
              type="primary"
              onClick={() => form.submit()}
              loading={isCreatingUpdatingTemplate}
            >
              Update
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </Content>
  );
};

export default Page;
