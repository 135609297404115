import {
  Button,
  Card,
  Form,
  Input,
  message,
  Select,
  Upload,
} from 'components/antd';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Content } from 'components/shared';
import useFees, { UpdateRequest } from 'hooks/Financial/fees';
import { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import useUser from 'hooks/User';
import { Upload as UploadIcon } from 'components/icons';
import { Features } from 'constants/features';
import usePurchaseOrders from 'hooks/Financial/purchaseorders';

const Columns = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 992px) {
    display: grid;
    grid-template-columns: 1fr 400px;
  }
`;

type Props = {
  isNew?: boolean;
};

const Page = ({ isNew }: Props) => {
  const { t } = useTranslation('paymentFees');
  const { t: c } = useTranslation('common');
  let { id } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { getFeature } = useUser();
  const { purchaseOrderReferences } = usePurchaseOrders();
  const {
    feesLoading,
    feesRecord,
    feesSubmitting,
    feesStatuses,
    uploadSubmitting,
    loadFeesRecord,
    newFees,
    updateFees,
    updateFeesStatus,
    uploadInvoice,
    setStatuses,
    noLongerSubmitting,
    noLongerUploading,
  } = useFees();

  const hasPurchaseOrderFunctionality = getFeature(
    Features['Finance.Features.PurchaseOrders']
  ).enabled;

  useEffect(() => {
    if (!isNew && id) {
      loadFeesRecord(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (feesRecord !== undefined) {
      setStatuses(feesRecord.status!);

      form.setFieldsValue({
        status: feesRecord.status!,
        amount: formatNumber(feesRecord.amount),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feesRecord]);

  const formatNumber = (value: any) =>
    `${c('number', {
      value: parseFloat(value).toFixed(2),
    })}`;

  const onFinish = async (values: any) => {
    const results = await form.validateFields();

    const localePath = isNew ? 'add' : 'edit';

    if (isNew) {
      newFees(results)
        .then(() => {
          message.success(t(`payment.${localePath}.success`));
          navigate(`/admin/financial/fees/list`);
        })
        .catch((error) => {
          const errorMessages = Object.values(error?.response?.data?.errors);

          if (errorMessages.length >= 1) {
            errorMessages.flat().forEach((value) => {
              message.error(
                t(`payment.${localePath}.error`, {
                  msg: typeof value === 'string' ? t(value) : undefined,
                })
              );
            });
          } else {
            const errorMsg = error?.response?.data?.message;

            message.error(
              t(`payment.${localePath}.error`, {
                msg: errorMsg ? t(errorMsg) : undefined,
              })
            );
          }
          noLongerSubmitting();
        });
    } else {
      updateFees(id!, results)
        .then(() => {
          if (feesRecord?.status !== results.status) {
            let updateRequest: UpdateRequest = {
              status:
                results.status === 'NotInvoiced'
                  ? 1
                  : results.status === 'Invoiced'
                  ? 2
                  : results.status === 'PaidByTenant'
                  ? 3
                  : 4,
            };

            updateFeesStatus(id!, updateRequest)
              .then(() => {
                message.success(t(`payment.${localePath}.success`));
                navigate(`/admin/financial/fees/list`);
              })
              .catch((error) => {
                const errorMessages = Object.values(
                  error?.response?.data?.errors
                );

                if (errorMessages.length >= 1) {
                  errorMessages.flat().forEach((value) => {
                    message.error(
                      t(`payment.${localePath}.error`, {
                        msg: typeof value === 'string' ? t(value) : undefined,
                      })
                    );
                  });
                } else {
                  const errorMsg = error?.response?.data?.message;

                  message.error(
                    t(`payment.${localePath}.error`, {
                      msg: errorMsg ? t(errorMsg) : undefined,
                    })
                  );
                }
                noLongerSubmitting();
              });
          } else {
            message.success(t(`payment.${localePath}.success`));
            navigate(`/admin/financial/fees/list`);
          }
        })
        .catch((error) => {
          const errorMessages = Object.values(error?.response?.data?.errors);

          if (errorMessages.length >= 1) {
            errorMessages.flat().forEach((value) => {
              message.error(
                t(`payment.${localePath}.error`, {
                  msg: typeof value === 'string' ? t(value) : undefined,
                })
              );
            });
          } else {
            const errorMsg = error?.response?.data?.message;

            message.error(
              t(`payment.${localePath}.error`, {
                msg: errorMsg ? t(errorMsg) : undefined,
              })
            );
          }
          noLongerSubmitting();
        });
    }
  };

  const onUpload = (options: any) => {
    const { file } = options;
    const formData = new FormData();
    formData.append('file', file);

    uploadInvoice(id!, formData)
      .then(() => {
        message.success(t(`invoice.upload.success`));
        loadFeesRecord(id!);
      })
      .catch((error) => {
        const errorMsg = error?.response?.data;

        message.error(
          t(`invoice.upload.error`, {
            msg: errorMsg ? t(errorMsg) : undefined,
          })
        );
        noLongerUploading();
      });

    return false;
  };

  return (
    <Content>
      <h1>{t(isNew ? 'newFees' : 'editFees')}</h1>
      <Card loading={feesLoading}>
        <Columns>
          <Form
            initialValues={feesRecord}
            name="basic"
            form={form}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 12 }}
            autoComplete="off"
            onFinish={onFinish}
          >
            <Form.Item
              label={t('amount')}
              name="amount"
              rules={[
                {
                  required: true,
                  message: t('invalidAmount'),
                  pattern: new RegExp(/^\s*-?(\d+(\.\d{1,2})?|\.\d{1,2})\s*$/),
                },
              ]}
            >
              <Input
                disabled={
                  isNew
                    ? false
                    : feesRecord?.status === 'NotInvoiced'
                    ? false
                    : true
                }
              />
            </Form.Item>

            <Form.Item
              label={t('notes')}
              name="notes"
              rules={[{ required: false }]}
            >
              <Input />
            </Form.Item>

            {hasPurchaseOrderFunctionality && (
              <Form.Item
                name="purchaseOrderId"
                label={t('purchaseOrder')}
                rules={[{ required: true }]}
              >
                <Select
                  placeholder={t('selectPurchaseOrder')}
                  disabled={
                    isNew
                      ? false
                      : feesRecord?.status === 'NotInvoiced'
                      ? false
                      : true
                  }
                  options={purchaseOrderReferences.map((po) => ({
                    label: po.tenantPurchaseOrderReference,
                    value: po.id.toString(),
                  }))}
                />
              </Form.Item>
            )}

            {!isNew && (
              <Form.Item
                label={t('status')}
                name="status"
                rules={[{ required: true }]}
              >
                <Select
                  placeholder={t('selectStatus')}
                  options={feesStatuses.map((rs) => ({
                    label: t(rs.statusTypeName),
                    value: rs.statusTypeValue,
                  }))}
                />
              </Form.Item>
            )}

            {feesRecord?.status! === 'NotInvoiced' && (
              <Form.Item wrapperCol={{ offset: 8 }}>
                <Upload
                  showUploadList={false}
                  customRequest={onUpload}
                  accept=".pdf"
                >
                  <Button
                    loading={uploadSubmitting}
                    icon={
                      <UploadIcon
                        style={{
                          fontSize: '1.2em',
                          marginBottom: -3,
                          marginLeft: -3,
                          marginRight: 3,
                        }}
                      />
                    }
                  >
                    {t('uploadButton')}
                  </Button>
                </Upload>
              </Form.Item>
            )}

            <Form.Item wrapperCol={{ offset: 8 }}>
              <Button type="primary" htmlType="submit" loading={feesSubmitting}>
                {c('submit')}
              </Button>
            </Form.Item>
          </Form>
        </Columns>
      </Card>
    </Content>
  );
};

export default Page;
