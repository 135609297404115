import { useTranslation } from 'react-i18next';
import {
  Button,
  Card,
  DatePicker,
  message,
  Row,
  Select,
  Space,
  Table,
  Tooltip,
} from 'components/antd';
import { Content, TitleRow } from 'components/shared';
import useFees, { FeesStatus } from 'hooks/Financial/fees';
import moment, { Moment } from 'moment';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Download, Edit } from 'components/icons';
import { Permissions } from 'constants/permissions';
import { Features } from 'constants/features';
import useUser from 'hooks/User';
import usePurchaseOrders, { POReference } from 'hooks/Financial/purchaseorders';

const Page = () => {
  const { t } = useTranslation('paymentFees');
  const { t: c } = useTranslation('common');
  const [startDate, setStartDate] = useState<Moment | null>(null);
  const [endDate, setEndDate] = useState<Moment | null>(null);
  const { getFeature, hasPermission } = useUser();
  const {
    pagination,
    feesRecords,
    feesLoading,
    feesStatuses,
    loadFees,
    downloadInvoice,
  } = useFees();
  const [state, setState] = useState<any>({});
  const { purchaseOrderReferences } = usePurchaseOrders();

  const hasPurchaseOrderFunctionality = getFeature(
    Features['Finance.Features.PurchaseOrders']
  ).enabled;

  const canCreateFees = hasPermission(Permissions['Finance.Fees.Create']);

  const changeDate = (val: any) => {
    setStartDate(val?.[0]);
    setEndDate(val?.[1]);

    const sDate = val?.[0]?.utc().startOf('day');
    const eDate = val?.[1]?.utc().endOf('day');

    setState({
      ...state,
      rangeFrom: sDate?.toISOString(),
      rangeTo: eDate?.toISOString(),
    });

    loadFees({
      ...state,
      rangeFrom: sDate?.toISOString(),
      rangeTo: eDate?.toISOString(),
      current: 1,
    }).catch(() => message.error(t('requestRetrievalError')));
  };

  const setSelectedStatus = (selectedStatus: string) => {
    var actualValue = selectedStatus === '' ? undefined : selectedStatus;
    setState({ ...state, status: actualValue });

    loadFees({
      ...state,
      status: actualValue,
      current: 1,
    }).catch(() => message.error(t('requestRetrievalError')));
  };

  useEffect(() => {
    loadFees({
      ...state,
    }).catch(() => message.error(t('requestRetrievalError')));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTableChange = (pagination: any) => {
    setState({ ...state, ...pagination });

    loadFees({
      ...state,
      ...pagination,
    }).catch(() => message.error(t('requestRetrievalError')));
  };

  const formatCurrency = (value: any) =>
    `${t('currency.symbol')}${formatNumber(value)}`;

  const formatNumber = (value: any) =>
    `${c('number', {
      value: value,
    })}`;

  const getColumns = (t: Function, c: Function) => [
    {
      title: t('feeIdentifier'),
      dataIndex: 'feeIdentifier',
      key: 'feeIdentifier',
    },
    {
      title: t('amount'),
      dataIndex: 'amount',
      key: 'amount',
      align: 'right',
      render: (item: number) => formatCurrency(item),
    },
    {
      title: t('dateCreated'),
      dataIndex: 'dateCreated',
      key: 'dateCreated',
      render: (date: Date) => c('date', { value: new Date(date) }),
    },
    {
      title: t('notes'),
      dataIndex: 'notes',
      key: 'notes',
    },
    {
      title: t('status'),
      dataIndex: 'status',
      key: 'status',
      render: (item: string) => (
        <>
          {t(
            feesStatuses.find((c: FeesStatus) => c.statusTypeValue === item)
              ?.statusTypeName || item
          )}
        </>
      ),
    },
    {
      title: t('updatedDate'),
      dataIndex: 'lastModified',
      key: 'lastModified',
      render: (date: Date) => c('date', { value: new Date(date) }),
    },
    {
      title: t('purchaseOrderNumber'),
      dataIndex: 'purchaseOrderId',
      key: 'purchaseOrderId',
      render: (item: string) => (
        <>
          {purchaseOrderReferences.find((c: POReference) => c.id === item)
            ?.tenantPurchaseOrderReference || item}
        </>
      ),
      hidden: hasPurchaseOrderFunctionality ? false : true,
    },
    {
      title: t('fileReference'),
      dataIndex: 'invoiceReference',
      key: 'invoiceReference',
    },
    {
      title: 'Action',
      width: 100,
      key: 'actions',
      align: 'right',
      render: (af: any) => (
        <Space>
          <Tooltip title={t('editFees')}>
            <Link to={`/admin/financial/fees/${af.id}/edit`}>
              {
                <Button
                  disabled={!canCreateFees}
                  icon={<Edit style={{ marginLeft: -2, marginBottom: -2 }} />}
                />
              }
            </Link>
          </Tooltip>
          <Tooltip title={t('downloadInvoice')}>
            <Button
              disabled={
                af.invoiceReference === ''
                  ? true
                  : af.invoiceReference === undefined
                  ? true
                  : af.invoiceReference === null
                  ? true
                  : false
              }
              onClick={() => downloadInvoice(af.id, af.invoiceReference)}
              icon={<Download style={{ marginLeft: -2, marginBottom: -2 }} />}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  return (
    <Content>
      <TitleRow>
        <h1>{t('feesTitle')}</h1>
        <Link to={`/admin/financial/fees/create`}>
          <Button disabled={!canCreateFees}>{t('newFees')}</Button>
        </Link>
      </TitleRow>

      <Row justify="center" style={{ margin: '16px 0' }}>
        <Space>
          <DatePicker.RangePicker
            value={[startDate, endDate]}
            onChange={changeDate}
            disabledDate={(date) => date > moment()}
          />
          <Select
            allowClear
            placeholder={t('selectStatus')}
            options={feesStatuses.map((c) => ({
              label: t(c.statusTypeName),
              value: c.statusTypeValue,
            }))}
            style={{ width: 200 }}
            onChange={(value) => {
              setSelectedStatus(value as string);
            }}
          />
        </Space>
      </Row>

      <Card style={{ marginTop: '2em' }}>
        <Table
          rowKey="id"
          size="small"
          loading={feesLoading}
          columns={getColumns(t, c) as any}
          pagination={pagination}
          dataSource={feesRecords}
          onChange={handleTableChange}
        />
      </Card>
    </Content>
  );
};

export default Page;
