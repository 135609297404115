import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useMatch } from 'react-router';

import { Card, Tabs, message } from 'components/antd';
import { Content } from 'components/shared';

import AffiliateForm from 'pages/Apply/AffiliateForm';
import AddressForm from 'pages/Apply/AddressForm';
import PaymentsForm from 'pages/Apply/PaymentsForm';
import WebsiteForm from 'pages/Apply/WebsiteForm';
import VatForm from 'pages/Apply/VatForm';
import GroupFrom from './components/GroupForm';

import { useEffect } from 'react';
import useAffiliateDetails, {
  AffiliateAddress,
  AffiliateBank,
  AffiliateDetail,
  AffiliateWebsite,
  // AffiliateWebsite,
} from 'hooks/Account/affiliateDetails';
import useUser from 'hooks/User';
import { AffiliateGroup } from 'hooks/Account/affiliateGroups';
import { Permissions } from 'constants/permissions';

const { TabPane } = Tabs;

export enum TabOptions {
  Affiliate = 'affiliate',
  Address = 'address',
  Vat = 'vat',
  Payment = 'payment',
  Websites = 'websites',
  Group = 'group',
}

type Props = {
  originPath?: string;
  readonly?: boolean;
  groups: AffiliateGroup[];
};

function Page({
  originPath = '/accounts/me',
  readonly = false,
  groups,
}: Props) {
  const { t } = useTranslation('accounts.me');
  const navigate = useNavigate();
  const { user, hasPermission } = useUser();
  let { id } = useParams();

  const isMe = originPath.toLowerCase().includes('/me');
  const path = isMe
    ? originPath
    : `/admin/affiliates/${id}/${readonly ? 'view' : 'edit'}`;
  const affiliateId = isMe ? user.affiliateId : id;
  const match = useMatch(`${path}/:tab`);

  const tab: string = match?.params['tab'] || TabOptions.Affiliate;

  const {
    load,
    address,
    bank,
    detail,
    group,
    isLoading,
    promotionalMethods,
    vat,
    websites,
    deleteWebsite,
    saveAddress,
    saveBank,
    saveDetails,
    saveGroup,
    saveVat,
    saveWebsite,
    isClosed,
  } = useAffiliateDetails(affiliateId);

  const loadAffiliate = () => {
    load().catch(() => {
      message.error('Error loading affiliate data');
    });
  };

  useEffect(() => {
    if (affiliateId) {
      loadAffiliate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [affiliateId]);

  const onChange = (key: string) => {
    navigate(`${key}`);
  };

  const onAffiliateSave = (values: AffiliateDetail) => {
    saveDetails(values)
      .then(() => {
        message.success(t('affiliateDetails.save.success'));
      })
      .catch(() => {
        message.error(t('affiliateDetails.save.error'));
      })
      .finally(loadAffiliate);
  };

  const onVatSave = (isVat: boolean, values: any) => {
    saveVat(isVat ? values : { vatRegistrationNumber: null })
      .then(() => {
        message.success(t('affiliateVat.save.success'));
      })
      .catch(({ response }) => {
        message.error(
          t('affiliateVat.save.error', {
            error:
              response?.data?.errors?.VATRegistrationNumber ||
              response?.data?.message ||
              '',
          })
        );
      })
      .finally(loadAffiliate);
  };

  const onGroupSave = (values: any) => {
    saveGroup(values)
      .then(() => {
        message.success(t('affiliateGroup.save.success'));
      })
      .catch(({ response }) => {
        message.error(
          t('affiliateGroup.save.error', {
            error:
              response?.data?.errors?.VATRegistrationNumber ||
              response?.data?.message ||
              '',
          })
        );
      })
      .finally(loadAffiliate);
  };

  const onAddressSave = (values: AffiliateAddress) => {
    saveAddress(values)
      .then(() => {
        message.success(t('affiliateAddress.save.success'));
      })
      .catch(() => {
        message.error(t('affiliateAddress.save.error'));
      })
      .finally(loadAffiliate);
  };

  const onPaymentSave = (isResident: boolean, values: AffiliateBank) => {
    saveBank(values)
      .then(() => {
        message.success(t('affiliatePayment.add.success'));
      })
      .catch(() => {
        message.error(t('affiliatePayment.add.error'));
      })
      .finally(loadAffiliate);
  };

  const onWebsitesSave = async (values: { websites: AffiliateWebsite[] }) => {
    const prevWebsitesHash = websites?.reduce(
      (prev: any, cur: AffiliateWebsite) => {
        prev[cur.id] = cur;
        return prev;
      },
      {} as AffiliateWebsite[]
    );

    const { websites: w } = values;

    const newWebsites = w.filter((x) => !x.id); // filter only new websites
    const updatedWebsites = w // filter only changed websites that are not new
      .filter((x) => x.id)
      .filter((x) => {
        const item = prevWebsitesHash[x.id];
        if (item && JSON.stringify(item) === JSON.stringify(x)) return false;
        return true;
      });

    const returnedWebsiteIds = w.map((x) => x.id);
    const removedWebsites = Object.keys(prevWebsitesHash).reduce(
      (prev: any, cur: string) => {
        if (returnedWebsiteIds.includes(cur)) return prev;
        return [...prev, cur];
      },
      []
    );

    // Save new websites
    if (newWebsites.length > 0) {
      const promises = newWebsites.map((x) =>
        saveWebsite(x)
          .then(() => {
            message.success(`Website ${x.websiteName} was saved`);
          })
          .catch(() => {
            message.error(`Error while saving website ${x.websiteName}`);
          })
      );
      await Promise.all(promises);
    }

    // Save updated websites
    if (updatedWebsites.length > 0) {
      const promises = updatedWebsites.map((x) =>
        saveWebsite(x)
          .then(() => {
            message.success(`Website ${x.websiteName} was updated`);
          })
          .catch(() => {
            message.error(`Error while updating website ${x.websiteName}`);
          })
      );
      await Promise.all(promises);
    }

    // Delete removed websites
    if (removedWebsites.length > 0) {
      const promises = removedWebsites.map((x: string) =>
        deleteWebsite(x)
          .then(() => {
            message.success(
              `Website ${prevWebsitesHash[x]?.websiteName} was deleted`
            );
          })
          .catch(() => {
            message.error(
              `Error while deleting website ${prevWebsitesHash[x]?.websiteName}`
            );
          })
      );
      await Promise.all(promises);
    }

    load();
  };

  return (
    <Content>
      <h1>{t(isMe ? 'me' : 'affiliateDetails')}</h1>
      <Card loading={isLoading}>
        <>
          <Tabs activeKey={tab} onChange={onChange}>
            <TabPane tab={t('basic')} key={TabOptions.Affiliate}>
              <AffiliateForm
                details={detail}
                onFinish={onAffiliateSave}
                showSubmitButton
                readonly={readonly || isClosed}
              />
            </TabPane>
            <TabPane tab={t('address')} key={TabOptions.Address}>
              <AddressForm
                address={address}
                onFinish={onAddressSave}
                showSubmitButton
                readonly={readonly || isClosed}
              />
            </TabPane>
            <TabPane tab={t(TabOptions.Vat)} key={TabOptions.Vat}>
              <VatForm
                vat={vat}
                onFinish={onVatSave}
                showSubmitButton
                readonly={readonly || isClosed}
              />
            </TabPane>
            <TabPane tab={t(TabOptions.Payment)} key={TabOptions.Payment}>
              <PaymentsForm
                waitingForApproval={!!bank?.changeRequestId}
                bank={bank}
                onFinish={onPaymentSave}
                showSubmitButton
                readonly={readonly || isClosed}
              />
            </TabPane>
            <TabPane tab={t(TabOptions.Websites)} key={TabOptions.Websites}>
              <WebsiteForm
                websites={websites}
                onFinish={onWebsitesSave}
                showSubmitButton={true}
                readonly={readonly || isClosed}
                methods={promotionalMethods}
              />
            </TabPane>

            {hasPermission(Permissions['Accounts.Affiliate.Administer']) && (
              <TabPane tab={t(TabOptions.Group)} key={TabOptions.Group}>
                <GroupFrom
                  onFinish={onGroupSave}
                  showSubmitButton
                  group={group}
                  readonly={readonly || isClosed}
                  groups={groups}
                />
              </TabPane>
            )}
          </Tabs>
        </>
      </Card>
    </Content>
  );
}

export default Page;
