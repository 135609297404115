import { Features } from 'constants/features';
import { Permissions } from 'constants/permissions';
import useUser from 'hooks/User';

type CheckProps = {
  permission?: Permissions;
  permissions?: Permissions[];
  feature?: Features;
  features?: Features[];
  children?: JSX.Element | JSX.Element[];
  component?: any;
};

const Check = ({
  permission,
  permissions = [],
  feature,
  features = [],
  children,
  component: Component,
}: CheckProps) => {
  const { hasPermission, getFeature } = useUser();

  if (permission && !hasPermission(permission)) return null;

  if (permissions.length > 0 && !permissions.every(hasPermission)) {
    return null;
  }

  if (feature && !getFeature(feature).show) return null;

  if (features.length > 0 && !features.every((f) => getFeature(f).show)) {
    return null;
  }

  if (Component) return Component;

  return children;
};

export default Check;
