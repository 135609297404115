import { useEffect, useState } from 'react';
import api from 'libs/api';

export type NewsCategory = {
  isActive: boolean;
  newsCategoryID?: number;
  newsCategoryName: string;
  totalDashboardNewsArticlesActiveCount?: number;
  totalExpiredNewsArticlesCount?: number;
  totalNewsArticlesCount?: number;
  totalNonDashboardNewsArticlesActiveCount?: number;
};

export type NewsCategoryCompact = {
  isActive: boolean;
  newsCategoryID?: number;
  newsCategoryName: string;
};

export interface IUseNewsCategories {
  filterCategories: NewsCategoryCompact[];
  categories: NewsCategory[];
  isLoading: boolean;
  load: (includeDeactivated: boolean) => Promise<void>;
  add: (category: NewsCategory) => Promise<void>;
  edit: (category: NewsCategory) => Promise<void>;
  remove: (newsCategoryID: number) => Promise<void>;
  activate: (id: number, value: boolean) => Promise<any>;
}

const useNewsCategories = (): IUseNewsCategories => {
  const [categories, setCategories] = useState<NewsCategory[]>([]);
  const [filterCategories, setFilterCategories] = useState<NewsCategory[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    fetchFilterCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchCategories = async (includeDeactivated: boolean) => {
    const result: any = await api.get('/content/NewsCategories', {
      params: { includeDeactivated },
    });

    setCategories(result.data.newsCategories);
  };

  const fetchFilterCategories = async () => {
    setIsLoading(true);
    const result: any = await api.get('/content/FilterNewsCategories');

    setFilterCategories(result.data);
    setIsLoading(false);
  };

  const add = (category: NewsCategoryCompact) => {
    return api.post('/content/StoreNewsCategory', category).then((response) => {
      fetchFilterCategories();
      return response.data;
    });
  };

  const remove = async (newsCategoryID: number) => {
    const item = categories.find((c) => c.newsCategoryID === newsCategoryID);
    if (!item) throw new Error();

    // api call

    const index = categories.indexOf(item);
    categories.splice(index, 1);

    setCategories([...categories]);
  };

  const edit = async (category: NewsCategoryCompact) => {
    let item = categories.find(
      (c) => c.newsCategoryID === category.newsCategoryID
    );
    if (!item) throw new Error();

    item.newsCategoryName = category.newsCategoryName;
    item.isActive = category.isActive;

    // api call
    return api.post('/content/StoreNewsCategory', item).then((response) => {
      fetchFilterCategories();
      return response.data;
    });
  };

  const activate = (id: number, value: boolean) =>
    api
      .post('/content/NewsCategoryActivation', {
        newsCategoryID: id,
        isActive: value,
      })
      .then((response) => {
        fetchFilterCategories();

        return response.data;
      });

  return {
    // Variables
    filterCategories,
    categories,
    isLoading,
    // Methods
    load: fetchCategories,
    add,
    remove,
    edit,
    activate,
  };
};

export default useNewsCategories;
