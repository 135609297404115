import { Button, Card, Form, Input, Select, message } from 'components/antd';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Content } from 'components/shared';
import usePayments from 'hooks/Financial/payments';
import { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import useUser from 'hooks/User';
import { Features } from 'constants/features';
import usePurchaseOrders from 'hooks/Financial/purchaseorders';

const Columns = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 992px) {
    display: grid;
    grid-template-columns: 1fr 400px;
  }
`;

type Props = {
  isNew?: boolean;
};

const Page = ({ isNew }: Props) => {
  const { t } = useTranslation('financialAdminPayments');
  const { t: c } = useTranslation('common');
  let { id } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { getFeature } = useUser();
  const {
    paymentsLoading,
    paymentRequest,
    paymentSubmitting,
    paymentAffiliates,
    loadTenancy,
    newTenancyRequest,
    updateTenancyRequest,
    noLongerSubmitting,
  } = usePayments();
  const { purchaseOrderReferences } = usePurchaseOrders();

  useEffect(() => {
    if (!isNew && id) {
      loadTenancy(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hasPurchaseOrderFunctionality = getFeature(
    Features['Finance.Features.PurchaseOrders']
  ).enabled;

  const onFinish = async (values: any) => {
    const results = await form.validateFields();

    const localePath = isNew ? 'add' : 'edit';

    if (isNew) {
      newTenancyRequest(results)
        .then(() => {
          message.success(t(`payment.${localePath}.success`));
          navigate(`/admin/financial/tenancypayments/list`);
        })
        .catch((error) => {
          const errorMessages = Object.values(error?.response?.data?.errors);

          if (errorMessages.length >= 1) {
            errorMessages.flat().forEach((value) => {
              message.error(
                t(`payment.${localePath}.error`, {
                  msg: typeof value === 'string' ? t(value) : undefined,
                })
              );
            });
          } else {
            const errorMsg = error?.response?.data?.message;

            message.error(
              t(`payment.${localePath}.error`, {
                msg: errorMsg ? t(errorMsg) : undefined,
              })
            );
          }
          noLongerSubmitting();
        });
    } else {
      updateTenancyRequest(id!, results)
        .then(() => {
          message.success(t(`payment.${localePath}.success`));
          navigate(`/admin/financial/tenancypayments/list`);
        })
        .catch((error) => {
          const errorMessages = Object.values(error?.response?.data?.errors);

          if (errorMessages.length >= 1) {
            errorMessages.flat().forEach((value) => {
              message.error(
                t(`payment.${localePath}.error`, {
                  msg: typeof value === 'string' ? t(value) : undefined,
                })
              );
            });
          } else {
            const errorMsg = error?.response?.data?.message;

            message.error(
              t(`payment.${localePath}.error`, {
                msg: errorMsg ? t(errorMsg) : undefined,
              })
            );
          }
          noLongerSubmitting();
        });
    }
  };

  return (
    <Content>
      <h1>{t(isNew ? 'newTenancy' : 'editTenancy')}</h1>
      <Card loading={paymentsLoading}>
        <Columns>
          <Form
            initialValues={paymentRequest}
            name="basic"
            form={form}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 12 }}
            autoComplete="off"
            onFinish={onFinish}
          >
            <Form.Item
              name="affiliateId"
              label={t('affiliate')}
              rules={[{ required: true }]}
            >
              <Select
                showSearch
                placeholder={t('selectAffiliate')}
                allowClear
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option!.label as unknown as string)
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                disabled={
                  isNew
                    ? false
                    : paymentRequest?.status === 'NotInvoiced'
                    ? false
                    : true
                }
                options={paymentAffiliates.map((affiliate) => ({
                  label:
                    (affiliate.externalIdentifier == null
                      ? ''
                      : '(' + affiliate.externalIdentifier + ') - ') +
                    affiliate.affiliateName,
                  value: affiliate.id.toString(),
                }))}
              />
            </Form.Item>

            <Form.Item
              label={t('amount')}
              name="amount"
              rules={[
                {
                  required: true,
                  message: t('invalidPaymentAmount'),
                  pattern: new RegExp(/^\s*-?(\d+(\.\d{1,2})?|\.\d{1,2})\s*$/),
                },
              ]}
            >
              <Input
                disabled={
                  isNew
                    ? false
                    : paymentRequest?.status === 'NotInvoiced'
                    ? false
                    : true
                }
              />
            </Form.Item>

            {hasPurchaseOrderFunctionality && (
              <Form.Item
                name="purchaseOrderId"
                label={t('purchaseOrder')}
                rules={[{ required: true }]}
              >
                <Select
                  placeholder={t('selectPurchaseOrder')}
                  disabled={
                    isNew
                      ? false
                      : paymentRequest?.status === 'NotInvoiced'
                      ? false
                      : true
                  }
                  options={purchaseOrderReferences.map((po) => ({
                    label: po.tenantPurchaseOrderReference,
                    value: po.id.toString(),
                  }))}
                />
              </Form.Item>
            )}

            <Form.Item
              label={t('notes')}
              name="notes"
              rules={[{ required: false }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={t('orderReference')}
              name="orderReference"
              rules={[{ required: false }]}
            >
              <Input
                disabled={
                  isNew
                    ? false
                    : paymentRequest?.status === 'NotInvoiced'
                    ? false
                    : true
                }
              />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8 }}>
              <Button
                type="primary"
                htmlType="submit"
                loading={paymentSubmitting}
              >
                {c('submit')}
              </Button>
            </Form.Item>
          </Form>
        </Columns>
      </Card>
    </Content>
  );
};

export default Page;
