import { useState } from 'react';
import api from 'libs/api';
import useUser from 'hooks/User';

export type Commission = {
  id?: number;
  name: string;
  standardCommissions: number;
  isTieredCommission: boolean;
  totalMappings: number;
  tier?: CommissionTier;
};

export type CommissionCompact = {
  id?: number;
  name: string;
  amount: number;
};

export type CommissionTier = {
  period: string;
  threshold: number;
  backdate: boolean;
  increaseAmount: number;
};

export type AffiliateCompact = {
  id: string;
  name: string;
};

export interface IUseCommission {
  isLoading: boolean;
  isLoadingOne: boolean;
  commissions: Commission[];
  affiliates: AffiliateCompact[];
  loadAffiliates: () => Promise<void>;

  load: () => Promise<void>;
  fetchOne: (id: number, loadTiered: boolean) => Promise<Commission>;
  add: (
    item: CommissionCompact,
    tier?: CommissionTier
  ) => Promise<CommissionCompact>;
  update: (
    item: CommissionCompact,
    status: TierActionStatus,
    tier?: CommissionTier
  ) => Promise<CommissionCompact>;
  remove: (id: number) => Promise<void>;
  // removeCommissionTier: (id: number) => Promise<void>;
  // addCommissionTier: (id: number, item: CommissionTier) => Promise<void>;
  // updateCommissionTier: (id: number, item: CommissionTier) => Promise<void>;
}

export enum TierActionStatus {
  None,
  Add,
  Remove,
  Update,
}

const path = '/sales/user';

const useComission = (commissionCodeId?: number): IUseCommission => {
  const { user } = useUser();
  const { id: userId } = user;
  const [commissions, setCommissions] = useState<Commission[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingOne, setIsLoadingOne] = useState(false);
  const [affiliates, setAffiliates] = useState<AffiliateCompact[]>([]);

  const fetchCommissions = async () => {
    setIsLoading(true);

    try {
      const result: any = await api.get(
        `${path}/${userId}/commissionCode/${commissionCodeId}/commission`
      );

      setCommissions(result.data);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchOne = (id: number, loadTiered: boolean = false) => {
    setIsLoadingOne(true);

    const commissionPromise = api.get(
      `${path}/${userId}/commissionCode/${commissionCodeId}/commission/${id}`
    );

    const tieredPromise = loadTiered
      ? api.get(
          `${path}/${userId}/commissionCode/${commissionCodeId}/commission/${id}/commissionTier`
        )
      : Promise.resolve(null);

    return Promise.all([commissionPromise, tieredPromise])
      .then(([resultCommission, resultTiered]) => {
        const data = resultCommission.data;
        return !loadTiered
          ? data
          : {
              ...data,
              isTieredCommission: !!resultTiered?.data,
              tier: resultTiered?.data,
            };
      })
      .catch(() => {
        throw new Error();
      })
      .finally(() => setIsLoadingOne(false));
  };

  const addCommissionTier = (id: number, item: CommissionTier) => {
    return api
      .post(
        `${path}/${userId}/commissionCode/${commissionCodeId}/commission/${id}/commissionTier`,
        item
      )
      .then((result) => result.data);
  };

  const removeCommissionTier = (id: number) => {
    return api
      .delete(
        `${path}/${userId}/commissionCode/${commissionCodeId}/commission/${id}/commissionTier`
      )
      .then((result) => result.data);
  };

  const updateCommissionTier = (id: number, item: CommissionTier) => {
    return api
      .patch(
        `${path}/${userId}/commissionCode/${commissionCodeId}/commission/${id}/commissionTier`,
        item
      )
      .then((result) => result.data);
  };

  const remove = async (id: number) => {
    //api call
  };

  const add = (item: CommissionCompact, tier?: CommissionTier) => {
    let result: CommissionCompact | undefined;
    return api
      .post(
        `${path}/${userId}/commissionCode/${commissionCodeId}/commission`,
        item
      )
      .then((response) => {
        result = response.data;
        const id = response.data.id;

        return tier ? addCommissionTier(id, tier) : undefined;
      })
      .then(() => {
        return result as CommissionCompact;
      });
  };

  const update = async (
    item: CommissionCompact,
    status: TierActionStatus,
    tier?: CommissionTier
  ) => {
    let result: CommissionCompact;
    return api
      .patch(
        `${path}/${userId}/commissionCode/${commissionCodeId}/commission/${item.id}`,
        item
      )
      .then((response) => {
        result = response.data;
        const id = response.data.id;

        switch (status) {
          case TierActionStatus.None:
            return undefined;
          case TierActionStatus.Add:
            return tier ? addCommissionTier(id, tier) : undefined;
          case TierActionStatus.Update:
            return tier ? updateCommissionTier(id, tier) : undefined;
          case TierActionStatus.Remove:
            return removeCommissionTier(id);
        }
      })
      .then(() => {
        return result;
      });
  };

  const loadAffiliates = async () => {
    const { data } = await api.get(`${path}/${userId}/commission/affiliate`);

    setAffiliates(data);
  };

  return {
    // Variables
    affiliates,
    commissions,
    isLoading,
    isLoadingOne,
    // Methods
    loadAffiliates,
    load: fetchCommissions,
    fetchOne,
    add,
    remove,
    update,
  };
};

export default useComission;
