import { useTranslation } from 'react-i18next';
import { Card, Form, message, Row, Select, Table } from 'components/antd';
import { Content, TitleRow } from 'components/shared';
import { useEffect, useState } from 'react';
import usePayments from 'hooks/Financial/payments';
import useDashboard from 'hooks/Financial/dashboard';

const { Option } = Select;

const currentDate = new Date();
const currentMonth = currentDate.getMonth() + 1;
const currentYear = currentDate.getFullYear();
let years: number[] = [];
for (let i = 2013; i <= currentYear; i++) years = [i, ...years];

const Page = () => {
  const { t } = useTranslation('financialBankingDashboard');
  const { t: c } = useTranslation('common');
  const [state, setState] = useState<any>({
    month: currentMonth,
    year: currentYear,
  });
  const { paymentAffiliates } = usePayments();
  const { dashboardLoading, bankingRecords, pagination, loadBankingDashboard } =
    useDashboard();

  const formatCurrency = (value: any) =>
    `${t('currency.symbol')}${formatNumber(value)}`;

  const formatNumber = (value: any) =>
    `${c('number', {
      value: value,
    })}`;

  useEffect(() => {
    loadBankingDashboard({
      ...state,
    }).catch(() => message.error(t('requestRetrievalError')));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTableChange = (pagination: any) => {
    setState({ ...state, ...pagination });

    loadBankingDashboard({
      ...state,
      ...pagination,
    }).catch(() => message.error(t('requestRetrievalError')));
  };

  const onFinish = (values: any) => {
    setState({ ...state, ...values });
    loadBankingDashboard({ ...state, ...values }).catch(() =>
      message.error(t('requestRetrievalError'))
    );
  };

  const getColumns = (t: Function, c: Function) => [
    {
      title: t('affiliateName'),
      dataIndex: 'affiliateName',
      key: 'affiliateName',
    },
    {
      title: t('affiliateExternalIdentifier'),
      dataIndex: 'affiliateExternalIdentifier',
      key: 'affiliateExternalIdentifier',
    },
    {
      title: t('invoiceId'),
      dataIndex: 'invoiceId',
      key: 'invoiceId',
    },

    {
      title: t('totalExcVat'),
      dataIndex: 'totalExcVat',
      key: 'totalExcVat',
      align: 'right',
      render: (item: number) => formatCurrency(item),
    },
    {
      title: t('totalIncVat'),
      dataIndex: 'totalIncVat',
      key: 'totalIncVat',
      align: 'right',
      render: (item: number) => formatCurrency(item),
    },
    {
      title: t('bankAccountType'),
      dataIndex: 'bankAccountType',
      key: 'bankAccountType',
    },
    {
      title: t('bankName'),
      dataIndex: 'bankName',
      key: 'bankName',
    },
    {
      title: t('accountName'),
      dataIndex: 'accountName',
      key: 'accountName',
    },
    {
      title: t('accountNumber'),
      dataIndex: 'accountNumber',
      key: 'accountNumber',
    },
    {
      title: t('sortCode'),
      dataIndex: 'sortCode',
      key: 'sortCode',
    },
    {
      title: t('iban'),
      dataIndex: 'iban',
      key: 'iban',
    },
    {
      title: t('swiftCode'),
      dataIndex: 'swiftCode',
      key: 'swiftCode',
    },
  ];

  return (
    <Content>
      <TitleRow>
        <h1>{t('bankingDashboardTitle')}</h1>
      </TitleRow>

      <Row justify="center" style={{ margin: '16px 0' }}>
        <Form layout="inline" initialValues={state}>
          <Form.Item name="month">
            <Select
              style={{ minWidth: 140 }}
              onChange={(value) => onFinish({ ...state, month: value })}
            >
              <Option value={1}>{t('January')}</Option>
              <Option value={2}>{t('February')}</Option>
              <Option value={3}>{t('March')}</Option>
              <Option value={4}>{t('April')}</Option>
              <Option value={5}>{t('May')}</Option>
              <Option value={6}>{t('June')}</Option>
              <Option value={7}>{t('July')}</Option>
              <Option value={8}>{t('August')}</Option>
              <Option value={9}>{t('September')}</Option>
              <Option value={10}>{t('October')}</Option>
              <Option value={11}>{t('November')}</Option>
              <Option value={12}>{t('December')}</Option>
            </Select>
          </Form.Item>
          <Form.Item name="year">
            <Select
              style={{ minWidth: 100 }}
              onChange={(value) => onFinish({ ...state, year: value })}
              options={years.map((y) => ({
                label: y,
                value: y,
              }))}
            />
          </Form.Item>
          <Form.Item name="affiliateId">
            <Select
              showSearch
              placeholder={t('selectAffiliate')}
              allowClear
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option!.label as unknown as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={paymentAffiliates.map((affiliate) => ({
                label:
                  (affiliate.externalIdentifier == null
                    ? ''
                    : '(' + affiliate.externalIdentifier + ') - ') +
                  affiliate.affiliateName,
                value: affiliate.id.toString(),
              }))}
              style={{ width: 200 }}
              onChange={(value) => onFinish({ ...state, affiliateId: value })}
            />
          </Form.Item>
        </Form>
      </Row>

      <Card style={{ marginTop: '2em' }}>
        <Table
          rowKey="id"
          size="small"
          loading={dashboardLoading}
          columns={getColumns(t, c) as any}
          pagination={pagination}
          dataSource={bankingRecords}
          onChange={handleTableChange}
        />
      </Card>
    </Content>
  );
};

export default Page;
