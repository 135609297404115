import { Button, Form, Input, Select } from 'components/antd';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  SocialNetworkHandle,
  SocialNetworkType,
} from 'hooks/Account/affiliateDetails';
import { Create, Delete } from 'components/icons';

const Block = styled.div`
  border-top: 1px solid var(--border-color-base);
  padding-top: 2em;
`;

type Props = {
  value: any;
  disabled: boolean;
  networks: SocialNetworkType[];
  onChange: Function;
};

function SocialMediaView({
  value = [],
  disabled,
  networks = [],
  onChange = (value: SocialNetworkHandle[]) => {},
}: Props) {
  const [addingNew, setAddingNew] = useState<boolean>(false);
  const [selectedNetworks, setSelectedNetworks] =
    useState<SocialNetworkHandle[]>(value);

  useEffect(() => {
    if (value.length === 0) {
      return;
    }

    setSelectedNetworks(value);
  }, [value]);

  const getUnselectedNetworks = () => {
    const selectedIds = selectedNetworks.map((x) => x.id);
    return networks.filter((x) => !selectedIds.includes(x.id));
  };

  const saveNetwork = (value: number) => {
    if (value !== -1) {
      const newItem = {
        id: value,
        accountHandle: '',
      };

      let newNetworks = selectedNetworks;
      newNetworks.push(newItem);

      setSelectedNetworks([...newNetworks]);
      onChange(newNetworks);
    }

    setAddingNew(false);
  };

  const removeNetwork = (item: SocialNetworkHandle) => {
    const newNetworks: SocialNetworkHandle[] = [
      ...selectedNetworks.filter((x) => x.id !== item.id),
    ];
    setSelectedNetworks([...newNetworks]);
    onChange(newNetworks);
  };

  const onUpdateNetworkChange = (
    item: SocialNetworkHandle,
    newValue: string
  ) => {
    item.accountHandle = newValue;
    onChange([...selectedNetworks]);
  };

  return (
    <Block style={{ width: '100%' }}>
      {selectedNetworks.map((item: SocialNetworkHandle) => (
        <Form.Item
          key={item.id}
          label={
            networks.find((n: SocialNetworkType) => n.id === item.id)?.name
          }
          rules={[{ required: true }]}
        >
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Input
              disabled={disabled}
              value={item.accountHandle}
              onChange={(e) => onUpdateNetworkChange(item, e.target.value)}
            />
            <Button
              icon={
                <Delete
                  style={{ marginLeft: -3, marginTop: 5 }}
                  onClick={() => {
                    removeNetwork(item);
                  }}
                />
              }
            />
          </div>
        </Form.Item>
      ))}
      {addingNew ? (
        <>
          <Form.Item name="newNetworkDetails" label="Network">
            <Select
              onChange={(value: any) => saveNetwork(value)}
              placeholder={'Select Network Type'}
              options={getUnselectedNetworks().map((item) => ({
                label: item.name,
                value: item.id,
              }))}
            />
          </Form.Item>
        </>
      ) : (
        <>
          {getUnselectedNetworks().length > 0 && (
            <Form.Item wrapperCol={{ offset: 7 }}>
              <Button
                type="dashed"
                onClick={() => setAddingNew(true)}
                block
                style={{ width: 200 }}
                icon={
                  <Create
                    style={{
                      marginLeft: -2,
                      marginBottom: -2,
                      fontSize: '1.2em',
                    }}
                  />
                }
              >
                Add Social
              </Button>
            </Form.Item>
          )}
        </>
      )}
    </Block>
  );
}

export default SocialMediaView;
