import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Card } from 'components/antd';
import { Content } from 'components/shared';
import useFAQCategories from 'hooks/Support/faqCategories';
import useFAQs from 'hooks/Support/faqs';
import CategoryView from './CategoryView';
import CategoryFAQView from './CategoryFAQView';
import styled from 'styled-components';

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const Page = () => {
  const [current, setCurrent] = useState(0);
  const { loadFilter, filterCategories, categoriesLoading } =
    useFAQCategories();
  const { loadForCategory, faqsLoading, faqs } = useFAQs();
  const { t } = useTranslation('faqs');

  useEffect(() => {
    loadFilter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadFAQsForCategory = (e: any, id: number) => {
    setCurrent(id);
    loadForCategory(id);
  };

  return (
    <Content>
      <h1>{t('faqs')}</h1>

      <Card loading={categoriesLoading}>
        <Row>
          <div style={{ width: 200, paddingRight: '2em' }}>
            <CategoryView
              isLoading={faqsLoading}
              items={filterCategories}
              loadFAQsForCategory={loadFAQsForCategory}
              selectedCategory={current}
            />
          </div>

          <div style={{ width: '100%' }}>
            <CategoryFAQView isLoading={faqsLoading} items={faqs} />
          </div>
        </Row>
      </Card>
    </Content>
  );
};

export default Page;
