import { FormInstance } from 'antd';

import { Form, Checkbox, Input } from 'components/antd';
import { useTranslation } from 'react-i18next';

type Props = {
  form?: FormInstance;
  terms: any;
  termsId?: string;
};

function Page({ form, terms = '', termsId }: Props) {
  const { t } = useTranslation();

  return (
    <>
      <div
        style={{ padding: '2em 0', overflowY: 'auto', height: 300 }}
        dangerouslySetInnerHTML={{ __html: terms }}
      />
      <Form
        name="basic"
        form={form}
        autoComplete="off"
        initialValues={{
          termsandConditionsId: termsId,
        }}
        onValuesChange={(values) => {}}
      >
        <Form.Item name="termsandConditionsId" hidden>
          <Input type="hidden" />
        </Form.Item>
        <Form.Item
          name="termsandConditionsIdAccepted"
          valuePropName="checked"
          wrapperCol={{
            offset: 0,
            span: 16,
          }}
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                return value
                  ? Promise.resolve()
                  : Promise.reject(
                      new Error(t('agreementConfirmationRequired'))
                    );
              },
            }),
          ]}
        >
          <Checkbox>{t('confirmAgreement')}</Checkbox>
        </Form.Item>
      </Form>
      {/* <p>
        Aenean auctor egestas ipsum ac laoreet. Sed tempor mollis sapien, sit
        amet aliquam tortor lacinia vel. Duis porttitor nibh tellus, non
        ullamcorper metus dignissim quis. Praesent pharetra eleifend tellus, nec
        ultricies turpis malesuada at. Cras vitae ultrices purus. Lorem ipsum
        dolor sit amet, consectetur adipiscing elit. Praesent sed ultrices
        purus, sit amet consectetur sem.
      </p>
      <p>
        Aenean efficitur rhoncus augue eu rhoncus. Pellentesque iaculis blandit
        tellus sed finibus. Duis vel ultricies velit. Orci varius natoque
        penatibus et magnis dis parturient montes, nascetur ridiculus mus.
        Curabitur fringilla tellus est, quis consequat quam ultrices id. Morbi
        rutrum ex id nulla eleifend viverra a sed tellus. Nullam faucibus diam
        vehicula vulputate eleifend
      </p> */}
    </>
  );
}

export default Page;
