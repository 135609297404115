import { InfoPage } from 'components/shared';
import useUser from 'hooks/User';
import { IUser } from 'hooks/User/Context';
import { User } from 'oidc-client';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

const Callback = () => {
  const { signinRedirectCallback, fetchUserInfo, impersonateAffiliate } =
    useUser();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    signinRedirectCallback()
      .then((user: User) => fetchUserInfo(user?.state?.returnUrl))
      .then((user: IUser | null) => {
        const imporsonateUser = sessionStorage.getItem('impersonationUser');
        if (imporsonateUser) {
          const user: any = JSON.parse(imporsonateUser);
          impersonateAffiliate(user?.affiliateId, user, user.tenantId);
        }
        navigate(user?.returnUrl || '/', { replace: true });
      })
      .catch(() => {
        window.location.pathname = '/logout';
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // .finally(() => {});
  return (
    <InfoPage
      message={t('loggingIn')}
      additionalText={t('pleaseWaitDotDotDot')}
    />
  );
};

export default Callback;
