import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { format as formatDateFns } from 'date-fns';
import { Locale } from 'antd/es/locale-provider';

// formats
import { countryFormats } from './constants/countryFormats';
import numeral from 'numeral';
import 'numeral/locales/en-gb';
import 'numeral/locales/cs';

// antd locales
import enGB from 'antd/es/locale/en_GB';
import csCZ from 'antd/es/locale/cs_CZ';

//moment locales
import 'moment/locale/en-gb';
import 'moment/locale/cs';

import { resources, defaultNS, ns, lng, fallbackLng } from 'locales';

numeral.locale('en-gb');

let format = countryFormats[0];

// rerender all interpolated strings when changing format
const reloadTranslations = () => i18n.changeLanguage(i18n.language);

export const setFormat = (countryId: string) => {
  const countryDictionary: {
    [key: string]: string;
  } = {
    en: 'enGB',
    cs: 'csCZ',
  };
  const country = countryFormats.find((c) => c.id === countryId);
  format = country || countryFormats[0];
  (window as any).__localeId__ = countryDictionary[countryId] || 'enGB';
  numeral.locale(country?.id || 'en-gb');

  reloadTranslations();
};

export const getFormat = () => format.id;

export const getAntdLocale = () => {
  const lang = i18n.language;

  const mapping: {
    [key: string]: Locale;
  } = {
    en: enGB,
    cs: csCZ,
  };
  return mapping[lang] || enGB;
};

export const getDateFormat = () => (format || countryFormats[0]).dateFormat;
export const getDateTimeFormat = () =>
  (format || countryFormats[0]).dateTimeFormat;

i18n.use(initReactI18next).init({
  lng,
  fallbackLng,
  ns,
  defaultNS: defaultNS,
  resources,
  debug: false,
  interpolation: {
    escapeValue: false,
    format: (value: any, formatParam: any) => {
      if (value instanceof Date) {
        if (formatParam === 'dayOnly') formatParam = format.dateFormat;
        return formatDateFns(value, formatParam || format.fullDateFormat);
      }
      if (typeof value == 'number') {
        return numeral(value).format(formatParam || '0,0.[00]');
      }
      return value;
    },
  },
});

export default i18n;
