type UrlList = {
  APP_API_URL?: string;
  APP_PUBLIC_URL?: string;
  APP_IDENTITY_URL?: string;
  APP_CLIENT_ID?: string;
  APP_TENANT_ID?: string;
};

declare global {
  interface Window {
    urls: UrlList;
  }
}

export const apiUrl =
  window.urls.APP_API_URL ||
  process.env.REACT_APP_API_URL ||
  'https://sc-api-dev.summitmedia.com';
export const tenantId =
  window.urls.APP_TENANT_ID ||
  process.env.REACT_APP_TENANT_ID ||
  'cd8624db-3b72-4f72-9cdc-b8a6e3e0713a';
export const identityServerUrl =
  window.urls.APP_IDENTITY_URL ||
  process.env.REACT_APP_IDENTITY_URL ||
  'https://https//identity-staging.productcaster-am.com';
export const publicUrl =
  window.urls.APP_PUBLIC_URL ||
  process.env.REACT_APP_PUBLIC_URL ||
  'http://localhost:3000';
export const clientId =
  window.urls.APP_CLIENT_ID || process.env.REACT_APP_CLIENT_ID;
