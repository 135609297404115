import { Button, Upload, Card, Table, message } from 'components/antd';
import { Content, InfoBox, TitleRow } from 'components/shared';
import { Upload as UploadIcon } from 'components/icons';

import { useTranslation } from 'react-i18next';
import useUser from 'hooks/User';
import { useState } from 'react';
import api from 'libs/api';

const columns = [
  {
    title: 'Transaction Id',
    dataIndex: 'transactionId',
    key: 'transactionId',
    width: 150,
  },
  {
    title: 'Message',
    dataIndex: 'message',
    key: 'message',
  },
];

const Page = () => {
  const { user } = useUser();
  const { t } = useTranslation();
  const [status, setStatus] = useState('waiting');
  const [result, setResult] = useState<any>();

  const path = `/sales/user/${user.id}/saleStatus`;

  const onUpload = (options: any) => {
    const { file } = options;
    const formData = new FormData();
    formData.append('data', file);
    setStatus('loading');
    setResult(null);

    api
      .post(path, formData)
      .then((response) => {
        const data = response.data;
        setStatus('done');
        setResult(data);
        message.success('The file has been successfully uploaded');
      })
      .catch(({ response }) => {
        setResult(null);
        setStatus('waiting');
        message.error(`Error uploading file: ${response?.data?.message || ''}`);
      });

    return false;
  };

  return (
    <Content>
      <TitleRow>
        <h1>{t('bulkSalesStatusChange')}</h1>
        <Upload showUploadList={false} customRequest={onUpload}>
          <Button
            icon={
              <UploadIcon
                style={{
                  fontSize: '1.2em',
                  marginBottom: -3,
                  marginLeft: -3,
                  marginRight: 3,
                }}
              />
            }
          >
            Click to Upload
          </Button>
        </Upload>
      </TitleRow>

      {status === 'waiting' && (
        <Card style={{ width: '550px' }}>
          <InfoBox style={{ maxWidth: 500 }} showImage={false}>
            <p>
              Upload a CSV file with these columns: <br />
            </p>

            <div
              style={{ padding: '2em', backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
            >
              <div>TransactionId,AffiliateId,Original Status,New Status</div>
              <div>19091657,1065,Pending,Confirmed</div>
              <div>19203495,1932,Confirmed,Declined</div>
              <div>21343244,2345,Pending,Confirmed</div>
            </div>

            <Upload showUploadList={false} customRequest={onUpload}>
              <Button
                style={{ marginTop: '1em' }}
                icon={
                  <UploadIcon
                    style={{
                      fontSize: '1.2em',
                      marginBottom: -3,
                      marginLeft: -3,
                      marginRight: 3,
                    }}
                  />
                }
              >
                Click to Upload
              </Button>
            </Upload>
          </InfoBox>
        </Card>
      )}

      {status !== 'waiting' && (
        <Card
          loading={status === 'loading'}
          style={{ width: '350px', marginBottom: '1em' }}
        >
          <InfoBox style={{ maxWidth: 300 }}>
            <p>The upload finished with these results:</p>
            <div>Transactions updated: {result?.updated}</div>
            <div>Transactions skipped: {result?.skipped}</div>
          </InfoBox>
        </Card>
      )}

      {status === 'done' && !!result?.details?.length && (
        <>
          <h2>
            Failed Details {result?.details?.length && result?.details?.length}
          </h2>
          <Card>
            <Table columns={columns} dataSource={result?.details || []} />
          </Card>
        </>
      )}
    </Content>
  );
};

export default Page;
