import { Navigate, Route, Routes } from 'react-router';
import { PrivatePage } from 'components/shared';
import List from './List';
import Edit from './Edit';
import { Permissions } from 'constants/permissions';

const Page = () => {
  return (
    <Routes>
      <Route
        path="create"
        element={
          <PrivatePage
            children={<Edit isNew />}
            requirement={Permissions['Finance.PurchaseOrders.Create']}
          />
        }
      />
      <Route path="list" element={<List />} />
      <Route index element={<Navigate to="list" />} />
    </Routes>
  );
};

export default Page;
