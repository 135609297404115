import { Button, Card, Form, Input, message } from 'components/antd';
import { Content, InfoBox } from 'components/shared';
import Editor from 'components/shared/Editor';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import useSupportMessages from 'hooks/Support/messages';
import { useEffect } from 'react';
import useUser from 'hooks/User';
import { Permissions } from 'constants/permissions';

const Columns = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 992px) {
    display: grid;
    grid-template-columns: 1fr 400px;
  }
`;

type Props = {
  isReply?: boolean;
};

//const Page = ({ isNew, categories = [] }: Props) => {
const Page = ({ isReply }: Props) => {
  const { t } = useTranslation('supportRequests');
  const [form] = Form.useForm();
  let { requestID, affiliateID } = useParams();
  const navigate = useNavigate();
  const {
    messagesLoading,
    supportMessage,
    prepareMessage,
    noLongerLoading,
    messagesSubmitting,
    submitMessage,
  } = useSupportMessages();
  const { hasPermission } = useUser();

  const hasAdminRights = hasPermission(Permissions['Support.Query.Admin']);

  useEffect(() => {
    prepareMessage(requestID!, affiliateID!, hasAdminRights ? true : false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (supportMessage !== undefined) noLongerLoading();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supportMessage]);

  const onFinish = async (values: any) => {
    const results = await form.validateFields();
    results.userIsAdmin = supportMessage?.userIsAdmin;
    results.affiliateID = supportMessage?.affiliateID;
    results.supportRequestID = supportMessage?.supportRequestID;

    submitMessage(results)
      .then(() => {
        message.success(t(`message.add.success`));
        navigate(`/support/requests/list`);
      })
      .catch((error) => {
        const errorMsg = error?.response?.data?.message;
        message.error(
          t(`message.add.error`, {
            msg: errorMsg ? t(errorMsg) : undefined,
          })
        );
      });
  };

  return (
    <Content>
      <h1>{t(isReply ? 'requestReply' : 'requestAddMessage')}</h1>
      <Card loading={messagesLoading}>
        <Columns>
          <Form
            initialValues={supportMessage}
            name="basic"
            form={form}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 12 }}
            autoComplete="off"
            onFinish={onFinish}
          >
            <Form.Item
              label={t('contactName')}
              name="contactName"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={t('supportMessage')}
              name="supportMessage"
              style={{ height: 'auto' }}
              rules={[{ required: true }]}
            >
              <Editor hideTracking={true} />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8 }}>
              <Button
                type="primary"
                htmlType="submit"
                loading={messagesSubmitting}
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
          <InfoBox>
            <p>{t(hasAdminRights ? 'adminReply' : 'standardReply')}</p>
          </InfoBox>
        </Columns>
      </Card>
    </Content>
  );
};

export default Page;
