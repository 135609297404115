export enum Permissions {
  'UI.CanViewAdminSection' = 'UI.CanViewAdminSection',
  'Accounts.Affiliate.Administer' = 'Accounts.Affiliate.Administer',
  'Accounts.Affiliate.Read' = 'Accounts.Affiliate.Read',
  'Accounts.Affiliate.ReadAll' = 'Accounts.Affiliate.ReadAll',
  'Accounts.Affiliate.Update' = 'Accounts.Affiliate.Update',
  'Accounts.Sales.Sales' = 'Accounts.Sales.Sales',
  'Accounts.Tenant.Read' = 'Accounts.Tenant.Read',
  'Sales.Commission.Admin.ReadWrite' = 'Sales.Commission.Admin.ReadWrite',
  'Sales.Commission.Affiliate.Read' = 'Sales.Commission.Affiliate.Read',
  'Sales.SalesStatus.Admin.ReadWrite' = 'Sales.SalesStatus.Admin.ReadWrite',
  'Communications.BulkEmails.Admin' = 'Communications.BulkEmails.Admin',
  'Communications.BulkEmails.Basic' = 'Communications.BulkEmails.Basic',
  'Communications.Configuration.Admin' = 'Communications.Configuration.Admin',
  'Communications.EmailTemplates.Admin' = 'Communications.EmailTemplates.Admin',
  'Communications.EmailTemplates.Basic' = 'Communications.EmailTemplates.Basic',
  'Communications.QueueEmail.Admin' = 'Communications.QueueEmail.Admin',
  'Content.Configuration.Admin' = 'Content.Configuration.Admin',
  'Content.FAQCategories.Admin' = 'Content.FAQCategories.Admin',
  'Content.FAQCategories.Basic' = 'Content.FAQCategories.Basic',
  'Content.FAQCategories.Extra' = 'Content.FAQCategories.Extra',
  'Content.FAQs.Admin' = 'Content.FAQs.Admin',
  'Content.FAQs.Basic' = 'Content.FAQs.Basic',
  'Content.FAQs.Extra' = 'Content.FAQs.Extra',
  'Content.News.Admin' = 'Content.News.Admin',
  'Content.News.Basic' = 'Content.News.Basic',
  'Content.News.Extra' = 'Content.News.Extra',
  'Content.NewsCategories.Admin' = 'Content.NewsCategories.Admin',
  'Content.NewsCategories.Basic' = 'Content.NewsCategories.Basic',
  'Content.NewsCategories.Extra' = 'Content.NewsCategories.Extra',
  'Products.Highlighted.ReadWrite' = 'Products.Highlighted.ReadWrite',
  'Products.Highlighted.Read' = 'Products.Highlighted.Read',
  'Products.PublicConfig.Read' = 'Products.Highlighted.Read',
  'Products.ReadWrite' = 'Products.ReadWrite',
  'Products.Read' = 'Products.Read',
  'Reporting.Admin' = 'Reporting.Admin',
  'Reporting.Report.Sales' = 'Reporting.Report.Sales',
  'Reporting.Report.SalesTrafficClicks' = 'Reporting.Report.SalesTrafficClicks',
  'Reporting.Report.NewsStatistics' = 'Reporting.Report.NewsStatistics',
  'Reporting.Report.CommunicationStatistics' = 'Reporting.Report.CommunicationStatistics',
  'Reporting.Report.YearlySummary' = 'Reporting.Report.YearlySummary',
  'Reporting.Report.TopSellers' = 'Reporting.Report.TopSellers',
  'Reporting' = 'Reporting',
  'Resources.BannerGroups.Admin' = 'Resources.BannerGroups.Admin',
  'Resources.BannerGroups.Basic' = 'Resources.BannerGroups.Basic',
  'Resources.BannerGroups.Extra' = 'Resources.BannerGroups.Extra',
  'Resources.Banners.Admin' = 'Resources.Banners.Admin',
  'Resources.Banners.Basic' = 'Resources.Banners.Basic',
  'Resources.Banners.Extra' = 'Resources.Banners.Extra',
  'Resources.Configuration.Admin' = 'Resources.Configuration.Admin',
  'Resources.Links.Basic' = 'Resources.Links.Basic',
  'System.Feature.Read' = 'System.Feature.Read',
  'System.Permission.Read' = 'System.Permission.Read',
  'System.Tenant.Create' = 'System.Tenant.Create',
  'System.UserPermission.Read' = 'System.UserPermission.Read',
  'Feeds.UserFeed.Read' = 'Feeds.UserFeed.Read',
  'Feeds.UserFeed.ReadWrite' = 'Feeds.UserFeed.ReadWrite',
  'Accounts.TermsAndConditions.ReadAll' = 'Accounts.TermsAndConditions.ReadAll',
  'Accounts.TermsAndConditions.Write' = 'Accounts.TermsAndConditions.Write',
  'Support.Query.Admin' = 'Support.Query.Admin',
  'Accounts.SelfBillingAgreements.Write' = 'Accounts.SelfBillingAgreements.Write',
  'Accounts.SelfBillingAgreements.ReadAll' = 'Accounts.SelfBillingAgreements.ReadAll',
  'Finance.Tenancies.Read' = 'Finance.Tenancies.Read',
  'Finance.BonusPayments.Read' = 'Finance.BonusPayments.Read',
  'Finance.Commissions.Read' = 'Finance.Commissions.Read',
  'Finance.PaymentRequests.Read' = 'Finance.PaymentRequests.Read',
  'Finance.PurchaseOrders.Read' = 'Finance.PurchaseOrders.Read',
  'Finance.Tenancies.Create' = 'Finance.Tenancies.Create',
  'Finance.BonusPayments.Create' = 'Finance.BonusPayments.Create',
  'Finance.Commissions.Create' = 'Finance.Commissions.Create',
  'Finance.PaymentRequests.Edit' = 'Finance.PaymentRequests.Edit',
  'Finance.PurchaseOrders.Create' = 'Finance.PurchaseOrders.Create',
  'Finance.AdminDashboard.Read' = 'Finance.AdminDashboard.Read',
  'Finance.Fees.Read' = 'Finance.Fees.Read',
  'Finance.Fees.Create' = 'Finance.Fees.Create',
  'Finance.BankingDashboard.Read' = 'Finance.BankingDashboard.Read',
  'Finance.CreditNotes.Create' = 'Finance.CreditNotes.Create',
  'Finance.CreditNotes.Read' = 'Finance.CreditNotes.Read',
}
