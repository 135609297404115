type Props = {
  providers: any[];
  children: JSX.Element | JSX.Element[];
};

// Use this if you need to wrap many components into each other
const Compose = ({ providers = [], children }: Props) => (
  <>
    {providers.reduceRight(
      (acc, Comp) => (
        <Comp>{acc}</Comp>
      ),
      children
    )}
  </>
);
export default Compose;
