import { FormInstance } from 'antd';
import { Button, Card, Form, Input, Select } from 'components/antd';
import { Cross } from 'components/icons';
import useAffiliateDetails, {
  AffiliateWebsite,
  PromotionalMethod,
} from 'hooks/Account/affiliateDetails';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import styled from 'styled-components';
import SocialMediaView from './SocialMediaForm';
import { isValidUrl } from 'libs/validations';

const { Option } = Select;
const { TextArea } = Input;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const Block = styled(Card)`
  position: relative;
  width: 510px;
  margin-right: 2em;
  margin-bottom: 2em;
  background: var(--content-background);
  border-radius: var(--border-radius-base);
  border-width: var(--border-width-base);
  border-color: var(--border-color-base);
`;

type Props = {
  showSubmitButton?: boolean;
  onFinish: (values: any) => any;
  form?: FormInstance<any>;
  readonly?: boolean;
  websites?: AffiliateWebsite[];
  methods?: PromotionalMethod[];
};

function Page({
  form,
  onFinish,
  showSubmitButton = false,
  readonly,
  websites = [{} as any],
  methods = [],
}: Props) {
  const { t } = useTranslation('wizard');
  const [newForm] = Form.useForm();

  form = form || newForm;
  const disabled = readonly;

  const { loadNetworks, socialNetworks } = useAffiliateDetails();

  useEffect(() => {
    loadNetworks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Form
      name="dynamic_form_nest_item"
      form={form}
      initialValues={{ websites: JSON.parse(JSON.stringify(websites)) }}
      onFinish={onFinish}
      autoComplete="off"
      labelCol={{ span: 7 }}
      wrapperCol={{ span: 15 }}
    >
      <Row>
        <Form.List name="websites">
          {(fields, props) => (
            <>
              {!disabled && (
                <Form.Item>
                  <Button
                    style={{
                      minWidth: 250,
                      minHeight: 250,
                      marginRight: '2em',
                    }}
                    type="dashed"
                    onClick={() => props.add({}, 0)}
                    block
                    icon={
                      <div style={{ fontSize: '1.5em' }}>{t('addWebsite')}</div>
                    }
                  />
                </Form.Item>
              )}
              {fields.map(({ key, name, ...restField }, i) => {
                return (
                  <Block key={i} style={{ backgroundColor: 'rgba(0,0,0,0.1)' }}>
                    <Form.Item
                      style={{ display: 'none' }}
                      {...restField}
                      label={t('id')}
                      name={[name, 'id']}
                    >
                      <Input disabled />
                    </Form.Item>

                    <Form.Item
                      {...restField}
                      label={t('websiteName')}
                      name={[name, 'websiteName']}
                      rules={[{ required: true }]}
                    >
                      <Input
                        placeholder={t('namePlaceholder')}
                        disabled={disabled}
                      />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      label={t('websiteUrl')}
                      name={[name, 'websiteUrl']}
                      rules={[
                        { required: true },
                        ({ getFieldValue }) => ({
                          validator(_, value = '') {
                            return isValidUrl(value)
                              ? Promise.resolve()
                              : Promise.reject(t('mustBeAValidUrl'));
                          },
                        }),
                      ]}
                    >
                      <Input
                        placeholder="https://www.websiteurl.com"
                        disabled={disabled}
                      />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      label={t('visitorCount')}
                      name={[name, 'uniqueMonthlyVisitors']}
                      rules={[{ required: true }]}
                    >
                      <Select
                        placeholder={t('visitorCountPlaceholder')}
                        disabled={disabled}
                      >
                        <Option value={1000}>Fewer than 1,000</Option>
                        <Option value={5000}>1,000 to 5,000</Option>
                        <Option value={10000}>5,000 to 10,000</Option>
                        <Option value={25000}>10,000 to 25,000</Option>
                        <Option value={75000}>25,000 to 75,000</Option>
                        <Option value={100000}>75,000 to 100,000</Option>
                        <Option value={101000}>More than 100,000</Option>
                      </Select>
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      label={t('description')}
                      name={[name, 'websiteDescription']}
                    >
                      <TextArea
                        placeholder={t('describeWebsitePlaceholder')}
                        disabled={disabled}
                      />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      label={t('promoMethods')}
                      name={[name, 'promotionalMethodIds']}
                    >
                      <Select
                        placeholder={t('promoMethodsPlaceholder')}
                        mode="multiple"
                        allowClear
                        disabled={disabled}
                        options={methods.map((m) => ({
                          label: t(m.name),
                          value: m.id,
                        }))}
                      />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'socialNetworks']}
                      noStyle
                    >
                      {/* @ts-ignore */}
                      <SocialMediaView
                        disabled={disabled!}
                        networks={socialNetworks}
                      />
                    </Form.Item>
                    {fields.length > 1 && (
                      <Button
                        style={{
                          position: 'absolute',
                          top: -16,
                          right: -16,
                        }}
                        onClick={() => props.remove(name)}
                        shape="circle"
                        icon={
                          <Cross style={{ marginLeft: -3, marginBottom: -2 }} />
                        }
                        type="primary"
                        disabled={disabled}
                      />
                    )}
                  </Block>
                );
              })}
            </>
          )}
        </Form.List>
      </Row>
      {showSubmitButton && (
        <Form.Item noStyle>
          <Button type="primary" htmlType="submit" disabled={disabled}>
            Submit
          </Button>
        </Form.Item>
      )}
    </Form>
  );
}

export default Page;
