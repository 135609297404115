import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useCommitionCodes, { ProductGroup } from 'hooks/Commissions/code';

import useComission from 'hooks/Commissions';

import { Content, TitleRow } from 'components/shared';
import {
  Button,
  Card,
  Form,
  Row,
  Table,
  Select,
  Input,
  Switch,
  DatePicker,
  InputNumber,
  message,
} from 'components/antd';

const getColumns = (t: Function, groups: ProductGroup[]) => [
  {
    title: t('code'),
    dataIndex: 'code',
    key: 'code',
    fixed: 'left',
  },
  {
    title: t('productGroup'),
    dataIndex: 'commissionGroupId',
    key: 'commissionGroupId',
    render: (id: string) => id && t(groups.find((x) => x.id === id)?.name),
  },
  {
    title: t('name'),
    dataIndex: 'name',
    key: 'name',
  },
];

const Page = () => {
  const [state, setState] = useState<any>({
    productGroup: null,
  });
  const [showGroups, setShowGroups] = useState<boolean>(false);
  const [increaseDefault, setIncreaseDefault] = useState<boolean>(false);
  const { t } = useTranslation('commissions');
  const { t: c } = useTranslation('common');

  const {
    commissionCodes,
    isLoading,
    load,
    pagination,
    productGroups,
    loadProductGroups,
    saveCommissionIncrease,
  } = useCommitionCodes();

  const [form] = Form.useForm();

  const { affiliates, loadAffiliates } = useComission(Number(0));

  const loadData = (a: any, b: any) => {
    load(a, productGroups).catch(() => {
      message.error('There was an error loading commissions');
    });
  };

  useEffect(() => {
    loadProductGroups().catch(() => {
      message.error(t('productGroup.load.error'));
    });

    loadAffiliates().catch(() => {
      message.error('Error downloading affiliate list');
    });
    form.resetFields();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    setState({ ...state, ...sorter, ...pagination });
    loadData({ ...state, ...sorter, ...pagination }, productGroups);
  };

  const onFinish = (values: any) => {
    setState({ ...state, ...values });
    // we need to reset the offset because filter can change the number of items
    loadData({ ...state, ...values, current: 1 }, productGroups);
  };

  const save = (values: any) => {
    values.effectiveFrom = values.effectiveFrom
      ?.startOf('day')
      .utcOffset(0, true)
      .format();
    values.effectiveTo = values.effectiveTo
      ?.endOf('day')
      .utcOffset(0, true)
      .format();

    saveCommissionIncrease(values.productGroup, values)
      .then(() => {
        message.success(t('commissionIncrease.add.success'));
        form.resetFields();
      })
      .catch(({ response }) => {
        message.error(
          t('commissionIncrease.add.error', {
            error: response?.data?.message || response?.data?.title,
          })
        );
      });
  };

  return (
    <Content>
      <TitleRow>
        <h1>{t('commissionIncrease')}</h1>
      </TitleRow>
      <Row>
        <Card>
          <Form
            onFinish={save}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 12 }}
            style={{ minWidth: 520 }}
          >
            <Form.Item
              label={c('productGroup')}
              name="productGroup"
              valuePropName="checked"
              rules={[{ required: true }]}
            >
              <Select
                placeholder={c('productGroup')}
                options={productGroups.map((item) => ({
                  label: t(item.name),
                  value: item.id,
                }))}
                style={{ width: 200 }}
                onChange={(value) => {
                  onFinish({ ...state, productGroup: value });
                  setShowGroups(true);
                }}
              />
            </Form.Item>
            <Form.Item
              name="name"
              rules={[{ required: true }]}
              label={t('commissionIncreaseName')}
            >
              <Input
                placeholder={t('commissionIncreaseName')}
                style={{ width: 200 }}
              />
            </Form.Item>
            <Form.Item
              validateStatus={increaseDefault ? 'warning' : ''}
              name="increaseDefaultCommission"
              label={t('increaseDefault')}
              valuePropName="checked"
              help={increaseDefault ? t('increaseDefaultCommissionHelp') : ''}
            >
              <Switch
                onChange={() => setIncreaseDefault(!increaseDefault)}
                style={{ marginLeft: 20 }}
              />
            </Form.Item>
            <Form.Item
              name="amount"
              rules={[{ required: true }]}
              label={t('commissionAmount')}
            >
              <InputNumber
                placeholder={t('commissionAmount')}
                style={{ width: 200 }}
              />
            </Form.Item>
            <Form.Item
              name="effectiveFrom"
              valuePropName="state.effectiveFrom"
              rules={[{ required: true }]}
              label={t('commissionIncreaseEffectiveFrom')}
            >
              <DatePicker
                placeholder={t('commissionIncreaseEffectiveFrom')}
                style={{ width: 200 }}
              />
            </Form.Item>
            <Form.Item
              name="effectiveTo"
              valuePropName="state.effectiveTo"
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value = '') {
                    let from = getFieldValue('effectiveFrom');
                    if (value === '' || value === null)
                      return Promise.resolve();

                    return value >= from
                      ? Promise.resolve()
                      : Promise.reject(t('fromToValidationErr'));
                  },
                }),
              ]}
              label={t('commissionIncreaseEffectiveTo')}
            >
              <DatePicker
                placeholder={t('commissionIncreaseEffectiveTo')}
                style={{ width: 200 }}
              />
            </Form.Item>
            <Form.Item
              name="affiliates"
              valuePropName="value"
              rules={[{ required: true }]}
              label={t('commissionIncreaseAffiliates')}
              style={{ maxWidth: 500 }}
            >
              <Select
                placeholder={t('commissionIncreaseAffiliates')}
                mode="multiple"
                style={{ minWidth: 100 }}
                allowClear
                optionFilterProp="label"
                options={affiliates.map((a) => ({
                  value: a.id,
                  label: a.name,
                }))}
              />
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 8 }}>
              <Button type="primary" htmlType="submit">
                {c('submit')}
              </Button>
            </Form.Item>
          </Form>
        </Card>
        {showGroups && (
          <Card style={{ marginLeft: 0 }}>
            <h2>{t('commissionIncreaseText')}:</h2>
            <Table
              rowKey="id"
              columns={getColumns(c, productGroups) as any}
              dataSource={commissionCodes}
              onChange={handleTableChange}
              pagination={pagination}
              loading={isLoading}
            />
          </Card>
        )}
      </Row>
    </Content>
  );
};

export default Page;
