function Page() {
  return (
    <div>
      <h2>Application Pending</h2>
      <p>
        Thanks for applying to join our affiliate program. We will be reviewing
        all the information you have provided and will present you with a
        response as soon as we can.
      </p>
    </div>
  );
}

export default Page;
