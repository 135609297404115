import { Navigate, Route, Routes } from 'react-router';

import useNewsCategories from 'hooks/Resources/newsCategories';

import List from './List';
import Edit from './Edit';
import Categories from './Categories';
import { PrivatePage } from 'components/shared';

import { Permissions } from 'constants/permissions';

const Page = () => {
  const useCategories = useNewsCategories();
  const { filterCategories } = useCategories;

  return (
    <Routes>
      <Route
        path="/:id/edit"
        element={
          <PrivatePage
            requirement={Permissions['Content.News.Admin']}
            children={<Edit categories={filterCategories} />}
          />
        }
      />
      <Route
        path="/create"
        element={
          <PrivatePage
            requirement={Permissions['Content.News.Admin']}
            children={<Edit categories={filterCategories} isNew />}
          />
        }
      />
      <Route
        path="/categories"
        element={
          <PrivatePage
            requirement={Permissions['Content.NewsCategories.Admin']}
            children={<Categories useCategories={useCategories} />}
          />
        }
      />
      <Route path="/list" element={<List categories={filterCategories} />} />
      <Route path="/" element={<Navigate to="/list" />} />
    </Routes>
  );
};

export default Page;
