type CountryType = {
  id: string;
  name: string;
  fullDateFormat: string;
  dateTimeFormat: string;
  dateFormat: string;
};

export const countryFormats: CountryType[] = [
  {
    id: 'en-gb',
    name: 'United Kingdom',
    // fullDateFormat: "d/M/yyyy HH:mm (UTCZ)",
    fullDateFormat: 'd/M/yyyy HH:mm',
    dateTimeFormat: 'd/M/yyyy HH:mm',
    dateFormat: 'd/M/yyyy',
  },
  {
    id: 'cs',
    name: 'Test',
    // fullDateFormat: "d/M/yyyy HH:mm (UTCZ)",
    fullDateFormat: 'd. M. yyyy HH:mm',
    dateTimeFormat: 'd. M. yyyy HH:mm',
    dateFormat: 'd. M. yyyy',
  },
];
