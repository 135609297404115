export enum Features {
  'Resources.Banners' = 'Resources.Banners',
  'Resources.Links' = 'Resources.Links',
  'Reporting' = 'Reporting',
  'Reporting.Report.Sales' = 'Reporting.Report.Sales',
  'Reporting.Report.SalesTrafficClicks' = 'Reporting.Report.SalesTrafficClicks',
  'Reporting.Report.NewsStatistics' = 'Reporting.Report.NewsStatistics',
  'Reporting.Report.CommunicationStatistics' = 'Reporting.Report.CommunicationStatistics',
  'Reporting.Report.YearlySummary' = 'Reporting.Report.YearlySummary',
  'Reporting.Report.TopSellers' = 'Reporting.Report.TopSellers',
  'Content.News' = 'Content.News',
  'Content.FAQs' = 'Content.FAQs',
  'Communications.Emails' = 'Communications.Emails',
  'Finance.Features.PurchaseOrders' = 'Finance.Features.PurchaseOrders',
  'Finance.Features.Fees' = 'Finance.Features.Fees',
  'Finance.Features.BankingDashboard' = 'Finance.Features.BankingDashboard',
}
