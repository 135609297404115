import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Form, Button, Select } from 'components/antd';
import { InfoBox } from 'components/shared';
import { FormInstance } from 'antd';
import { AffiliateGroup } from 'hooks/Account/affiliateGroups';

const Column = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 992px) {
    display: grid;
    grid-template-columns: 1fr 400px;
  }
`;

const InnerColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

type Props = {
  showSubmitButton?: boolean;
  onFinish?: (values: any) => any;
  form?: FormInstance<any>;
  readonly?: boolean;
  group?: string;
  groups: AffiliateGroup[];
};

function Page({
  showSubmitButton,
  onFinish,
  form,
  readonly,
  group,
  groups,
}: Props) {
  const { t } = useTranslation('wizard');
  const [newForm] = Form.useForm();
  form = form || newForm;
  const disabled = readonly;

  return (
    <Column>
      <InnerColumn>
        <Form
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 12 }}
          initialValues={{ affiliateGroupId: group }}
          onFinish={onFinish}
        >
          <Form.Item
            label={t('affiliateGroup')}
            name="affiliateGroupId"
            rules={[{ required: true }]}
          >
            <Select
              disabled={disabled}
              options={groups.map((g) => ({
                label: g.name,
                value: g.id || '',
              }))}
            />
          </Form.Item>

          {showSubmitButton && (
            <Form.Item wrapperCol={{ offset: 8 }}>
              <Button type="primary" htmlType="submit" disabled={disabled}>
                Submit
              </Button>
            </Form.Item>
          )}
        </Form>
      </InnerColumn>
      <InfoBox>
        <p>Set the affiliate group where the affiliate belongs</p>
      </InfoBox>
    </Column>
  );
}

export default Page;
