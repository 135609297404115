import { Card, message, Skeleton } from 'components/antd';
import { Content } from 'components/shared';
import TagList from 'components/shared/TagList';
import useNews from 'hooks/Resources/news';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import styled from 'styled-components';
import moment from 'moment';

const Image = styled.img`
  width: fit-content;
  height: 200px;
  object-fit: contain;
  border: calc(2 * var(--border-width-base)) solid var(--border-color-base);
`;

const Row = styled.div`
  display: flex;
  justify-content: baseline;
  width: 100%;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  gap: 1em;
`;

const ArticleHeader = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  gap: 0px;
`;

const NoGapHeader = styled.h1`
  gap: 0px;
  margin-bottom: 0px;
`;

const Center = styled.div`
  text-align: center;
`;

const Page = () => {
  let { id } = useParams();
  let { isLoading, loadArticle, article } = useNews();
  const { t } = useTranslation('news');

  useEffect(() => {
    if (!id) return;
    loadArticle(id, false).catch(() => {
      message.error(t('news.fetch.error'));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  if (isLoading || !article)
    return (
      <Content>
        <Skeleton />
      </Content>
    );

  return (
    <Content>
      {!isLoading && (
        <Column>
          <ArticleHeader>
            <NoGapHeader>{article.articleHeadline}</NoGapHeader>
            <h5>
              {moment(article.createdDate).local().format('DD MMM yyyy HH:mm')}
            </h5>
          </ArticleHeader>
          <h2>{article.articleSubHeading}</h2>
          <Center>
            <div style={{ display: 'inline-block' }}>
              <Image
                style={{ width: 500, height: 360 }}
                alt={article.articleHeadline}
                src={article.articleImagePath}
              />
              <Row style={{ marginTop: '0.5em' }}>
                <TagList
                  title={t('categories')}
                  tagCount={5}
                  tagData={article.newsArticleCategories.map((cat) => ({
                    id: cat.newsCategoryID?.toString(),
                    label: cat.newsCategoryName,
                  }))}
                  tagMaxWidth={'100px'}
                />
              </Row>
            </div>
          </Center>
          <Card>
            <div dangerouslySetInnerHTML={{ __html: article.articleBody }} />
          </Card>
        </Column>
      )}
    </Content>
  );
};

export default Page;
