import useLayout from 'hooks/Layout';
import { useEffect } from 'react';

import { useNavigate, useLocation } from 'react-router';
import { Content } from 'components/shared';
import { Button, Card, message, Space } from 'components/antd';
import useAgreements from 'hooks/Agreements/agreements';
import useUser from 'hooks/User';

const Page = () => {
  const { setMinimized } = useLayout();
  const { loadLatestTerms, termsLoading, selectedTerms, acceptTerms } =
    useAgreements();
  const { user, approveAgreement } = useUser();
  const navigate = useNavigate();
  const { search } = useLocation();
  const q = new URLSearchParams(search);
  const returnUrl = q.get('returnUrl');

  useEffect(() => {
    loadLatestTerms().catch(() => {
      message.error('There was an error loding the Agreement document');
    });
    // setSidebar(false);
    setMinimized(true);
    return () => {
      setMinimized(false);
      // setSidebar(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onAccept = () => {
    if (!selectedTerms || !selectedTerms.id) return;
    acceptTerms(selectedTerms.id)
      .then(() => {
        message.success('Successfully accepted the agreement');

        approveAgreement();
        navigate(returnUrl || '/');
      })
      .catch(() => {
        message.error('There was an error accepting the agreement');
      });
  };

  const onLater = () => {
    sessionStorage.setItem('acceptLater', 'true');

    navigate(returnUrl || '/');
  };

  return (
    <Content
      style={{
        textAlign: 'center',
        maxWidth: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <h1>Agreement</h1>

      <Card
        style={{ width: '', minWidth: 500, maxWidth: 1900 }}
        loading={termsLoading}
      >
        <div
          style={{ padding: '2em', overflowY: 'auto', height: 700 }}
          dangerouslySetInnerHTML={{ __html: selectedTerms?.terms || '' }}
        />
        {user.hasNewAgreement && (
          <>
            <br />

            <Space>
              <Button onClick={onAccept}>Agree</Button>
              {!selectedTerms?.enforceTerms && (
                <Button onClick={onLater}>Later</Button>
              )}
            </Space>
          </>
        )}
      </Card>
    </Content>
  );
};

export default Page;
