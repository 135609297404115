import api from 'libs/api';
import { useState } from 'react';
import Pagination from 'constants/pagination';
import downloadFile from 'libs/downloadFile';

export type InvoiceLineItem = {
  lineDescription: string;
  commissionAmount: number;
  vatRate: number;
  vatAmount: number;
  commissionAmountIncludingVAT: number;
};

export type InvoiceItem = {
  id: string;
  affiliateName: string;
  affiliateId: string;
  reference: string;
  createdDate: string;
  totalCommission: number;
  totalVat: number;
  totalCommissionIncludingVAT: number;
  userFriendlyInvoiceID: string;
  invoiceLines: InvoiceLineItem[];
};

export interface IUseInvoices {
  invoices: InvoiceItem[];
  pagination: any;
  isLoading: boolean;
  load: (params?: any) => Promise<void>;
  download: (inv: InvoiceItem) => Promise<any>;
  downloadAll: (props: any) => Promise<any>;
}

const invoicesPath = '/finance/invoices';

const useInvoices = (): IUseInvoices => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [pagination, setPagination] = useState<any>(Pagination);
  const [invoices, setInvoices] = useState<InvoiceItem[]>([]);

  const load = async (props?: any) => {
    setIsLoading(true);

    const params = {
      offset: props?.current || 1,
      limit: props?.pageSize || Pagination.pageSize,
      externalIdentifier: props?.externalIdentifier || undefined,
      invoiceReference: props?.invoiceReference || undefined,
      invoiceLineCount: props?.invoiceLineCount || undefined,
      month: props?.month || undefined,
      year: props?.year || undefined,
    };
    try {
      const { data: results }: any = await api.get(invoicesPath, { params });
      const { data, limit, offset, totalItems } = results;

      setInvoices(data);

      const newPagination = {
        current: offset,
        pageSize: limit,
        total: totalItems,
      };

      setPagination(newPagination);
    } finally {
      setIsLoading(false);
    }
  };

  const download = async (inv: InvoiceItem) => {
    let filename = `Invoice-${inv.affiliateName}-${inv.id}`;

    return downloadFile(`/finance/invoice/${inv.id}/file`, filename, 'pdf');
  };

  const downloadAll = async (props: any) => {
    const params = {
      externalIdentifier: props?.externalIdentifier || undefined,
      invoiceReference: props?.invoiceReference || undefined,
      invoiceLineCount: props?.invoiceLineCount || undefined,
      month: props?.month || undefined,
      year: props?.year || undefined,
    };

    const p = new URLSearchParams();
    Object.entries(params).forEach(([k, v]) => {
      if (v) {
        p.append(k, v);
      }
    });

    return downloadFile(`/finance/invoices/download?${p.toString()}`);
  };

  return {
    // Methods
    load,
    download,
    downloadAll,
    // Variables
    invoices,
    isLoading,
    pagination,
  };
};

export default useInvoices;
