import { PrivatePage } from 'components/shared';
import { Navigate, Route, Routes } from 'react-router';

import TenancyPayments from './TenancyPayments/index';
import CommissionPayments from './CommissionPayments/index';
import BonusPayments from './BonusPayments/index';
import CreditNotePayments from './CreditNotes/index';
import PaymentRequests from './PaymentRequests/index';
import PurchaseOrders from './PurchaseOrders/index';
import Fees from './Fees/index';
import BankingDashboard from './BankingDashboard/index';

import { Permissions } from 'constants/permissions';

const Page = () => {
  return (
    <Routes>
      <Route
        path="/bonuspayments/*"
        element={
          <PrivatePage
            requirement={Permissions['Finance.BonusPayments.Read']}
            component={BonusPayments}
          />
        }
      />
      <Route
        path="/commissionpayments/*"
        element={
          <PrivatePage
            requirement={Permissions['Finance.Commissions.Read']}
            component={CommissionPayments}
          />
        }
      />
      <Route
        path="/tenancypayments/*"
        element={
          <PrivatePage
            requirement={Permissions['Finance.Tenancies.Read']}
            component={TenancyPayments}
          />
        }
      />
      <Route
        path="/creditnotes/*"
        element={
          <PrivatePage
            requirement={Permissions['Finance.CreditNotes.Read']}
            component={CreditNotePayments}
          />
        }
      />
      <Route
        path="/paymentrequests/*"
        element={
          <PrivatePage
            requirement={Permissions['Finance.PaymentRequests.Read']}
            component={PaymentRequests}
          />
        }
      />
      <Route
        path="/purchaseorders/*"
        element={
          <PrivatePage
            requirement={Permissions['Finance.PurchaseOrders.Read']}
            component={PurchaseOrders}
          />
        }
      />
      <Route
        path="/bankingdashboard/*"
        element={
          <PrivatePage
            requirement={Permissions['Finance.BankingDashboard.Read']}
            component={BankingDashboard}
          />
        }
      />
      <Route
        path="/fees/*"
        element={
          <PrivatePage
            requirement={Permissions['Finance.Fees.Read']}
            component={Fees}
          />
        }
      />

      <Route index element={<Navigate to="paymentrequests" />} />
    </Routes>
  );
};

export default Page;
