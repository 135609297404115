import { useRef, useEffect, useLayoutEffect } from 'react';
import CustomScroll from 'react-custom-scroll';
import styled from 'styled-components';

import { LOGINAS_COLOR } from 'constants/index';
import useLayout from 'hooks/Layout';
import { SIDEBAR_WIDTH, MINIMIZED_SIDEBAR_WIDTH } from 'constants/index';
import { ArrowBack } from 'components/icons';
import { Button } from 'components/antd';
import { useLocation, Outlet } from 'react-router';
import useUser from 'hooks/User';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from 'components/shared/ErrorFallback';

type SBP = {
  minimized: boolean;
  isImpersonating: boolean;
  // All other props
  [key: string]: any;
};

const SidebarColumn = styled(
  ({ minimized, isImpersonating, ...props }: SBP) => <div {...props} />
)`
  width: ${(props) =>
    props.minimized ? MINIMIZED_SIDEBAR_WIDTH : SIDEBAR_WIDTH};
  top: 3em; // this is here because of top menu
  min-height: calc(100vh - 3em);
  position: fixed;
  transition: width 0.5s ease-in-out;
  height: calc(100% - 3em);
  /* overflow-y: auto;
  overflow-x: hidden; */

  .rcs-inner-handle {
    background: var(--primary-color);
  }

  ${(props) =>
    props.isImpersonating && `border-right: 1px solid ${LOGINAS_COLOR};`}
`;

const LoggedInAsBorder = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  border: 4px solid ${LOGINAS_COLOR};
  z-index: 100;
  pointer-events: none;
`;

const PageLayout = styled.div<{ hide?: boolean }>`
  display: ${(props) => (props.hide ? 'none' : 'grid')};
  grid-template-columns: min-content auto;
  width: 100%;
  min-height: 100%;
`;

const ContentColumn = styled.div<{
  minimized?: boolean;
  higher?: boolean;
  isSidebarHidden?: boolean;
}>`
  /* display: grid;
  grid-template-rows: min-content auto;*/
  position: absolute;
  width: ${(props) =>
    `calc(100%-${props.minimized ? MINIMIZED_SIDEBAR_WIDTH : SIDEBAR_WIDTH})`};
  top: ${(props) =>
    props.higher ? '6em' : '3em'}; // this is here because of top menu
  right: 0;
  left: ${(props) =>
    props.minimized ? MINIMIZED_SIDEBAR_WIDTH : SIDEBAR_WIDTH};
  ${(props) => props.isSidebarHidden && 'left: 0;'}
  bottom: 0;
  transition: left 0.5s ease-in-out;
`;

const MinButton = styled(Button)`
  font-size: 1em !important;
  position: fixed;
  /* left: -1em; */
  line-height: 1em;
  top: 8em;
  z-index: 1;
  box-shadow: 0px 0px 2px 2px white;
  transform: translate(-50%, -50%);
`;

// INFO - This way we can filter out unstandard properties for custom components but use them for styling anyway :-)
const ArrowIcon = styled(({ minimized: boolean, ...props }) => (
  <ArrowBack {...props} />
))`
  margin-top: 2px;
  margin-right: 1px;
  transition: all 0.5s;
  transform: ${(props) =>
    props.minimized ? 'rotate(180deg)' : 'rotate(0deg)'};
`;

const TopLevelBar = styled.div`
  background: black;
  height: 3em;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
`;

const TopLevelBarHigher = styled.div`
  background: black;
  height: 6em;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
`;

type Props = {
  sidebar?: JSX.Element;
  children?: JSX.Element | JSX.Element[];
  header?: JSX.Element;
  topbar?: JSX.Element;
  higherTopbar?: boolean;
};

const Layout = ({
  sidebar,
  children,
  header,
  topbar,
  higherTopbar = false,
}: Props) => {
  const scrollRef = useRef(null);
  const { showNav, showSidebar, minimized, setMinimized } = useLayout();
  const { isImpersonating } = useUser();
  const hideSidebar = !(sidebar && showSidebar);
  const hideNav = !(topbar && showNav);
  const currLocation = useLocation();

  const updateScrollbar = () => {
    (scrollRef?.current as any)?.forceUpdate();
  };

  useLayoutEffect(() => {
    // typedef not complete
    window.onresize = () => updateScrollbar();
    setInterval(() => updateScrollbar(), 1000);
  }, []);

  useEffect(() => {
    // typedef not complete
    setTimeout(() => updateScrollbar(), 500);
  }, [minimized]);

  const Bar = higherTopbar ? TopLevelBarHigher : TopLevelBar;

  if (hideNav && hideSidebar)
    return (
      <>
        <ContentColumn higher={higherTopbar} isSidebarHidden={true}>
          <ErrorBoundary
            FallbackComponent={ErrorFallback}
            resetKeys={[currLocation.pathname]}
          >
            <div>
              <Outlet />
              {children}
            </div>
          </ErrorBoundary>
          {isImpersonating && <LoggedInAsBorder />}
        </ContentColumn>
        <Bar>{topbar}</Bar>
      </>
    );

  if (hideSidebar)
    return (
      <>
        <ContentColumn higher={higherTopbar} isSidebarHidden={true}>
          <div>{header}</div>

          {/* content */}
          <ErrorBoundary
            FallbackComponent={ErrorFallback}
            resetKeys={[currLocation.pathname]}
          >
            <div>
              <Outlet />
              {children}
            </div>
          </ErrorBoundary>
          {isImpersonating && <LoggedInAsBorder />}
        </ContentColumn>
        <Bar>{topbar}</Bar>
      </>
    );

  if (hideNav)
    return (
      <>
        <PageLayout>
          {/* navbar */}
          <ContentColumn higher={higherTopbar} minimized={minimized}>
            <div />

            {/* content */}
            <ErrorBoundary
              FallbackComponent={ErrorFallback}
              resetKeys={[currLocation.pathname]}
            >
              <div>
                <Outlet />
                {children}
              </div>
            </ErrorBoundary>

            {/* minimize sidebar btn */}
            <MinButton
              type="primary"
              size="small"
              onClick={() => setMinimized(!minimized)}
              icon={<ArrowIcon minimized={minimized} />}
              shape="circle"
            />
            {isImpersonating && <LoggedInAsBorder />}
          </ContentColumn>
          {/* sidebar */}
          <SidebarColumn
            isImpersonating={isImpersonating}
            minimized={minimized}
            style={{
              boxShadow: 'var(--shadow-1-right)',
              backgroundColor: 'var(--body-background)',
            }}
          >
            <CustomScroll ref={scrollRef} heightRelativeToParent="100%">
              {sidebar}
            </CustomScroll>
          </SidebarColumn>
        </PageLayout>
        <Bar>{topbar}</Bar>
      </>
    );

  return (
    <>
      <PageLayout>
        {/* navbar */}
        <ContentColumn higher={higherTopbar} minimized={minimized}>
          <div>{header}</div>

          {/* content */}
          <ErrorBoundary
            FallbackComponent={ErrorFallback}
            resetKeys={[currLocation.pathname]}
          >
            <div>
              <Outlet />
              {children}
            </div>
          </ErrorBoundary>

          {/* minimize sidebar btn */}
          <MinButton
            type="primary"
            size="small"
            onClick={() => setMinimized(!minimized)}
            icon={<ArrowIcon minimized={minimized} />}
            shape="circle"
          />
          {isImpersonating && <LoggedInAsBorder />}
        </ContentColumn>

        {/* sidebar */}
        <SidebarColumn
          isImpersonating={isImpersonating}
          minimized={minimized}
          style={{
            boxShadow: 'var(--shadow-1-right)',
            backgroundColor: 'var(--body-background)',
          }}
        >
          <CustomScroll ref={scrollRef} heightRelativeToParent="100%">
            {sidebar}
          </CustomScroll>
        </SidebarColumn>
      </PageLayout>
      <Bar>{topbar}</Bar>
    </>
  );
};

export default Layout;
