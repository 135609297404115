import useUser from 'hooks/User';
import { useEffect, useState } from 'react';
import api from 'libs/api';

export type EnquiryType = {
  contactReasonID: number;
  contactReasonName: string;
};

export type SupportQuery = {
  contactName: string;
  contactPhone: string;
  emailAddress: string;
  contactReasonID: number;
  queryMessage: string;
  userIsAdmin: boolean;
  additionalCapturedDetails?: string;
};

const useSupport = () => {
  const [enquiryTypes, setEnquiryTypes] = useState<EnquiryType[]>([]);
  const [supportLoading, setIsLoading] = useState(false);
  const [supportSubmitting, setIsSubmitting] = useState(false);
  const { user } = useUser();
  const [supportQuery, setSupportQuery] = useState<SupportQuery>();

  useEffect(() => {
    setIsLoading(true);
    fetchEnquiryTypes();
    setIsLoading(false);
  }, []);

  const setStartQuery = async () => {
    setIsLoading(true);

    let newQuery: SupportQuery = {
      contactName: user.name,
      contactPhone: '',
      emailAddress: user.email,
      contactReasonID: 0,
      queryMessage: '',
      userIsAdmin: false,
    };
    setSupportQuery(newQuery);
  };

  const fetchEnquiryTypes = async () => {
    setIsLoading(true);
    const result: any = await api.get('/support/ContactReasons');

    setEnquiryTypes(result.data);
    setIsLoading(false);
  };

  const raiseSupportQuery = async (item: SupportQuery) => {
    // api call
    setIsSubmitting(true);
    return await api
      .post(`/support/affiliate/${user.affiliateId}/Query`, item)
      .then((response) => {
        setIsSubmitting(false);
        return response.data;
      });
  };

  const noLongerLoading = async () => {
    setIsLoading(false);
  };

  return {
    // Variables
    enquiryTypes,
    supportLoading,
    supportQuery,
    supportSubmitting,
    // Methods
    loadEnquiryTypes: fetchEnquiryTypes,
    raise: raiseSupportQuery,
    prepareQuery: setStartQuery,
    noLongerLoading,
  };
};

export default useSupport;
