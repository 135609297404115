import { Card, Modal, Table } from 'antd';
import { TQUploadStatus } from 'hooks/Support/requests';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Content, InfoBox, TitleRow } from 'components/shared';

const UploadResultModal = (props: {
  visible: boolean;
  results?: TQUploadStatus;
  processType: string;
  onFinished: () => void;
}) => {
  const { t } = useTranslation('supportRequests');

  const columns = [
    {
      title:
        props.processType == 'uploadTQs'
          ? t('orderReference')
          : t('supportRequestID'),
      dataIndex:
        props.processType == 'uploadTQs'
          ? 'orderReference'
          : 'supportRequestID',
      key:
        props.processType == 'uploadTQs'
          ? 'orderReference'
          : 'supportRequestID',
      width: 150,
    },
    {
      title: t('processErrorMessage'),
      dataIndex: 'message',
      key: 'message',
    },
  ];

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.visible]);

  return (
    <Modal
      title={t('processFileUpload')}
      okText={t('okFileUpload')}
      visible={props.visible}
      onOk={props.onFinished}
      cancelButtonProps={{ disabled: true }}
      width={700}
    >
      <Content>
        <TitleRow>
          <h1>{t('fileUploadResults')}</h1>
        </TitleRow>
        <Card style={{ width: '600px', marginBottom: '1em' }}>
          <InfoBox style={{ maxWidth: 550, padding: 0 }}>
            <p>{t('fileUploadResultsIntro')}</p>
            <div>
              {t('fileUploadSuccessfulLabel')}
              {props.results?.processedOK}
            </div>
            <div>
              {t('fileUploadFailureLabel')}
              {props.results?.failedProcessing}
            </div>
          </InfoBox>
        </Card>
        <>
          <h2>{t('fileUploadFailuresIntro')}</h2>
          <Card>
            <Table
              columns={columns}
              dataSource={props.results?.details || []}
            />
          </Card>
        </>
      </Content>
    </Modal>
  );
};

export default UploadResultModal;
