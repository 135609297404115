import useUser from 'hooks/User';
import api from 'libs/api';

const useLinks = () => {
  const { user } = useUser();
  const generateLink = (linkUrl: string, linkRef: string) =>
    api.post(`/resources/affiliate/${user.affiliateId}/GenerateLink`, {
      AffiliateID: `${user.affiliateId}`,
      LinkURL: linkUrl,
      LinkRef: linkRef,
    });

  return {
    // Methods
    generate: generateLink,
  };
};

export default useLinks;
