import api from 'libs/api';
import { useState } from 'react';
import useUser from 'hooks/User';

export type CommissionsItem = {
  commissionGroupId: number;
  code: string;
  decription: string;
  amount: number;
  effectiveFrom: Date;
  tiers: boolean;
};

export type CommissionGroupItem = {
  id: string;
  name: string;
};

export interface IUseFinancialCommissions {
  commissions: CommissionsItem[];
  commissionGroups: CommissionGroupItem[];
  isLoading: boolean;
  load: (params?: any) => Promise<void>;
  loadGroups: () => Promise<void>;
}

const path = '/sales/user';

const useFinancialCommissions = (): IUseFinancialCommissions => {
  const { user } = useUser();
  const { id: userId } = user;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [commissions, setCommissions] = useState<CommissionsItem[]>([]);
  const [commissionGroups, setCommissionGroups] = useState<
    CommissionGroupItem[]
  >([]);

  const loadGroups = async () => {
    const { data: groups }: any = await api.get(
      `${path}/${userId}/commissionGroup`
    );

    setCommissionGroups(groups);
  };

  const load = async (props?: any) => {
    setIsLoading(true);

    const params = {
      commissionGroupId: props?.commissionGroupId || undefined,
    };

    try {
      const { data: results }: any = await api.get(
        `${path}/${userId}/commission`,
        { params }
      );

      setCommissions(results);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    // Methods
    load,
    loadGroups,
    // Variables
    isLoading,
    commissions,
    commissionGroups,
  };
};

export default useFinancialCommissions;
