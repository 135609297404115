import { Card as AntdCard } from 'antd';
// import styled from 'styled-components';

type Props = {
  style?: React.CSSProperties;
  // All other props
  [x: string]: any;
};

const StyledCard = ({ style, ...props }: Props) => (
  <AntdCard {...props} style={{ ...style }} />
);

export const Card = StyledCard;
