import api from 'libs/api';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const useHighlightedProducts = () => {
  const { t } = useTranslation('reporting');

  const [isHighlightedProductsLoading, setIsHighlightedProductsLoading] =
    useState<boolean>(false);
  const [highlightedProducts, setHighlightedProducts] = useState<any[]>([]);

  const fetchHighlightedProducts = async () => {
    try {
      setIsHighlightedProductsLoading(true);
      const result: any = await api.get(`/products/product/highlighted`);
      setHighlightedProducts(result.data);
    } catch (err) {
      throw err;
    } finally {
      setIsHighlightedProductsLoading(false);
    }
  };

  return {
    // Variables
    isHighlightedProductsLoading,
    highlightedProducts,
    // Methods
    fetchHighlightedProducts: fetchHighlightedProducts,
  };
};

export default useHighlightedProducts;
