import api from 'libs/api';
import { useState } from 'react';
import Pagination from 'constants/pagination';

export enum AffiliateApprovalStatus {
  Pending = 1,
  Approved = 2,
  Declined = 3,
}

export type AffiliateApproveListItem = {
  name: string;
  applicationDate: string;
  company: string;
  email: string;
  cautionLevel: 0;
};

const applicationsUrl = '/accounts/affiliateapplications';
const applicationUrl = '/accounts/affiliateapplication';
// AffiliateApplication/{applicationId:Guid}
export interface IUseInvoices {
  affiliates: AffiliateApproveListItem[];
  isLoading: boolean;
  pagination: any;
  load: (params?: any) => Promise<void>;
  approve: (id: string, groupId: string, reason: string) => Promise<any>;
  decline: (id: string, reason: string) => Promise<any>;
  loadApplicationDetails: (id: string) => Promise<any>;
}

const useAffiliateApprove = (): IUseInvoices => {
  const [isLoading, setIsLoading] = useState(false);
  // const [isLoadingOne, setIsLoadingOne] = useState(false);
  const [pagination, setPagination] = useState<any>(Pagination);
  const [affiliates, setAffiliates] = useState<AffiliateApproveListItem[]>([]);

  const fetch = async (props?: any) => {
    setIsLoading(true);
    const params = {
      offset: props?.current || 1,
      limit: props?.pageSize || Pagination.pageSize,
      fromDate: props?.fromDate?.startOf('day')?.toISOString() || undefined,
      toDate: props?.toDate?.endOf('day')?.toISOString() || undefined,
      status: props?.status || undefined,
    };
    try {
      const { data: results }: any = await api.get(applicationsUrl, { params });
      const { data, limit, offset, totalItems } = results;

      setAffiliates(data.map((x: any, i: number) => ({ id: i, ...x })));

      const newPagination = {
        current: offset,
        pageSize: limit,
        total: totalItems,
      };

      setPagination(newPagination);
    } finally {
      setIsLoading(false);
    }
  };

  const approve = (id: string, groupId: string, reason: string) =>
    api
      .put(`${applicationUrl}/${id}/approve`, {
        affiliateGroupId: groupId,
        reason,
      })
      .then((response) => response.data);

  const decline = (id: string, reason: string) =>
    api
      .put(`${applicationUrl}/${id}/decline`, { reason })
      .then((response) => response.data);

  const loadApplicationDetails = (id: string) => {
    return api
      .get(`${applicationUrl}/${id}`)
      .then((response) => response.data)
      .then((d) => {
        const {
          applicationAddress,
          applicationBankAccount,
          applicationWebsites: websites,
        } = d;

        const {
          addressLineOne,
          addressLineTwo,
          city,
          county,
          country,
          postCode,
        } = applicationAddress;

        const {
          bankName,
          accountName,
          accountNumber,
          swiftCode,
          iban,
          sortCode,
        } = applicationBankAccount;

        const {
          title,
          firstName,
          surname,
          phoneNumber,
          vatRegistrationNumber,
        } = d;

        const convertWebsites = (w: any) => ({
          websiteName: w.name,
          websiteUrl: w.url,
          websiteDescription: w.description,
          uniqueMonthlyVisitors: w.uniqueMonthlyVisitors,
          promotionalMethodIds: w.websitePromotionalMethodIds,
          socialNetworks: w.socialNetworks,
        });

        return {
          ...d,
          affiliateName: d.company,
          affiliateWebsite: d.companyWebsite,
          title,
          firstName,
          surname,
          phoneNumber,
          addressLineOne,
          addressLineTwo,
          city,
          county,
          country,
          postCode,
          vatRegistrationNumber,
          bankName,
          accountName,
          accountNumber,
          swiftCode,
          iban,
          sortCode,
          websites: websites.map(convertWebsites),
        };
      });
  };

  return {
    // variables
    affiliates,
    isLoading,
    pagination,
    // methods
    load: fetch,
    approve,
    decline,
    loadApplicationDetails,
  };
};

export default useAffiliateApprove;
