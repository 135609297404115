import { Col } from 'components/antd';

type TitleValueProps = {
  title: string;
  value: any;
};

const TitleValue = ({ title, value }: TitleValueProps) => {
  return (
    <>
      <Col
        xs={12}
        lg={6}
        style={{
          marginBottom: 2,
          backgroundColor: '#00000010',
        }}
      >
        <div style={{ fontWeight: 'bold', padding: '0.8em' }}>{title}</div>
      </Col>
      <Col
        xs={12}
        lg={6}
        style={{
          marginBottom: 2,
        }}
      >
        <div style={{ fontWeight: 'bold', padding: '0.8em' }}>{value}</div>
      </Col>
    </>
  );
};

export default TitleValue;
