import { useEffect, useState } from 'react';
import api from 'libs/api';

export type AffiliateGroup = {
  id?: string;
  name: string;
  description: string;
};

export interface IUseAffiliateGroup {
  groups: AffiliateGroup[];
  isLoading: boolean;
  load: () => Promise<AffiliateGroup[]>;
  add: (category: AffiliateGroup) => Promise<AffiliateGroup>;
  edit: (category: AffiliateGroup) => Promise<AffiliateGroup>;
  remove: (id: string) => Promise<void>;
}

const affiliatesApiUrl = '/accounts/affiliategroups';
const affiliateApiUrl = '/accounts/affiliategroup';

const useAffiliateGroup = (): IUseAffiliateGroup => {
  const [groups, setGroups] = useState<AffiliateGroup[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchGroups = async () => {
    setIsLoading(true);
    return api
      .get(affiliatesApiUrl)
      .then(({ data }) => {
        setGroups(data);
        setIsLoading(false);
        return data;
      })
      .catch((err) => {
        setIsLoading(false);
        throw err;
      });
  };

  const add = (group: AffiliateGroup) =>
    api.post(affiliateApiUrl, group).then((response) => {
      fetchGroups();
      return response.data;
    });

  const remove = async (id: string) => {
    alert('todo');
    return;
  };

  const edit = async (category: AffiliateGroup) => {
    const { id, name, description } = category;
    return api
      .put(`${affiliateApiUrl}/${id}`, { name, description })
      .then((response) => {
        fetchGroups();
        return response.data;
      });
  };

  return {
    // Variables
    groups,
    isLoading,
    // Methods
    load: fetchGroups,
    add,
    edit,
    remove,
  };
};

export default useAffiliateGroup;
