import { useEffect } from 'react';
import { Route, Routes } from 'react-router';
import { PrivatePage } from 'components/shared';

import Banners from './Banners';
import Feeds from './Feeds';
import Links from './Links';

import { Permissions } from 'constants/permissions';
import { Features } from 'constants/features';

function Page() {
  useEffect(() => {
    return () => {
      // setNav(true);
      // setSidebar(true);
      // unblock();
    };
  }, []);

  return (
    <Routes>
      <Route
        path="/banners"
        element={
          <PrivatePage
            children={<Banners isAdmin={false} />}
            feature={Features['Resources.Banners']}
            requirement={Permissions['Resources.Banners.Basic']}
          />
        }
      />
      <Route
        path="/feeds"
        element={
          <PrivatePage
            children={<Feeds />}
            requirement={Permissions['Feeds.UserFeed.ReadWrite']}
          />
        }
      />
      <Route
        path="/links"
        element={
          <PrivatePage
            feature={Features['Resources.Links']}
            requirement={Permissions['Resources.Links.Basic']}
            children={<Links />}
          />
        }
      />
      <Route path="/">Creatives main page</Route>
    </Routes>
  );
}

export default Page;
