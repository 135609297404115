import React from 'react';
import { useLocation, Outlet } from 'react-router-dom';

import useUser from 'hooks/User';

import { InfoPage, Error, Navigate } from 'components/shared';
import { useTranslation } from 'react-i18next';
import { Permissions } from 'constants/permissions';
import { Features } from 'constants/features';

type Props = {
  component?: React.FC;
  children?: JSX.Element | JSX.Element[];
  requirement?: Permissions;
  feature?: Features;
  onlyNoTenant?: boolean;
};

const PrivatePage = ({
  children,
  requirement,
  feature,
  component: Component,
  onlyNoTenant = false,
}: Props) => {
  const { isAuthenticated, hasPermission, getFeature, user } = useUser();
  const { t } = useTranslation();
  const location = useLocation();

  if (requirement && !hasPermission(requirement)) {
    return <Error code={403} message={t('insufficientPermissions')} />;
  }

  if (feature && !getFeature(feature).show)
    return <Error code={403} message={t('insufficientFeatures')} />;

  const isAuth = isAuthenticated();

  // The user is not authorized
  if (!isAuth) {
    return (
      <Navigate
        to="/login"
        state={{ from: location }}
        search={`?returnUrl=${encodeURIComponent(
          `${location.pathname}${location.search}`
        )}`}
      />
    );
  }

  // We are authorized but we are still waiting for the User
  // object to be populated
  if (!user)
    return (
      <InfoPage
        message={t('downloadingUserInfo')}
        additionalText={t('pleaseWaitDotDotDot')}
      />
    );

  if (onlyNoTenant) {
    if (user.tenantId && user.tenantId !== '') return <Navigate to="/" />;
  } else {
    if (!user.tenantId || user?.tenantId === '')
      return <Navigate to="/apply" />;
  }

  // Display whatever content
  if (Component) return <Component />;
  if (children) return <>{children}</>;

  return <Outlet />;
};

export default PrivatePage;
