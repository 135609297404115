import api from 'libs/api';
import { Button, Space, message, Switch } from 'components/antd';
import { Edit } from 'components/icons';
import { useState } from 'react';
import Pagination from 'constants/pagination';
import { Link } from 'react-router-dom';

const useAdminProductsTable = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [approvedProductId, setApprovedProductId] = useState<any[]>([]);
  const [pagination, setPagination] = useState<any>(Pagination);
  const [columns, setColumns] = useState<any[]>([]);
  const [localStorageColumns, setLocalStorageColumns] = useState<any>(
    // @ts-ignore
    JSON.parse(localStorage.getItem('productFieldNames'))
  );
  const [products, setProducts] = useState<any[]>([]);
  const [selectedProducts, setSelectedProducts] = useState<any[]>([]);
  const [productsNotApproved, setProductsNotApproved] = useState<any[]>([]);
  const [approvalSwitchOverride, setApprovalSwitchOverride] = useState<
    boolean | string
  >('falseDefault');
  const [isSelectAllDisabled, setIsSelectAllDisabled] = useState<boolean>(true);
  const [isDeselectDisabled, setIsDeselectDisabled] = useState<boolean>(true);
  const [isApproveSelectedDisabled, setIsApproveSelectedDisabled] =
    useState<boolean>(true);
  const [tableBoolean, setTableBoolean] = useState<boolean>(true);
  const [doProductsRequireApproval, setDoProductsRequireApproval] =
    useState<any>(
      // @ts-ignore
      JSON.parse(localStorage.getItem('doProductsRequireApproval'))
    );
  const [usePreviousPage, setUsePreviousPage] = useState<boolean>(false);

  const approveSelectedProducts = async () => {
    // Get the selected products ready to send
    const bodyObj = { productIds: selectedProducts };
    setIsLoading(true);
    try {
      const result: any = await api.put(`/products/product/approve`, bodyObj);
      message.success('Products has been approved!');

      // If all products on the current page were approved, move back 1 page
      // to prevent an empty page being displayed
      const totalNumberOfProducts = products.length;
      if (totalNumberOfProducts === selectedProducts.length) {
        setUsePreviousPage(true);
      }
    } catch (err) {
      message.error('Error approving products');
    } finally {
      // Change the unapproved switches back to false
      if (!approvalSwitchOverride) setApprovalSwitchOverride('falseDefault');
      if (approvalSwitchOverride) setApprovalSwitchOverride(false);

      // Empty the array holding products to be approved
      const emptySelectedProductsArr = selectedProducts;
      emptySelectedProductsArr.length = 0;
      setSelectedProducts(emptySelectedProductsArr);

      // Reset the product apporval buttons
      setIsSelectAllDisabled(false);
      setIsDeselectDisabled(true);
      setIsApproveSelectedDisabled(true);

      setIsLoading(false);
    }
  };

  const fetchProductsConfig = async (props?: any) => {
    setIsLoading(true);
    // Get the products config, where the field names are held
    try {
      if (localStorageColumns && typeof doProductsRequireApproval === 'boolean')
        return {
          fieldNames: localStorageColumns,
          includeApprovalColumn: doProductsRequireApproval,
        };
      const result: any = await api.get(`/products/config/public`);
      // Add the field names to the session storage
      localStorage.setItem(
        'productFieldNames',
        JSON.stringify(result.data.fieldNames)
      );
      localStorage.setItem(
        'doProductsRequireApproval',
        JSON.stringify(result.data.doProductsRequireApproval)
      );
      setLocalStorageColumns(result.data.fieldNames);
      setDoProductsRequireApproval(result.data.doProductsRequireApproval);
      return {
        fieldNames: result.data.fieldNames,
        includeApprovalColumn: result.data.doProductsRequireApproval,
      };
    } catch (err) {
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  const productSwitchHandler = (productId: number) => {
    const newSelectedProductsArr = selectedProducts;

    if (selectedProducts.includes(productId)) {
      // Get the index of the current product
      const indexOfCurrentProduct = selectedProducts.findIndex(
        (selectedProducts: any) => {
          return selectedProducts === productId;
        }
      );

      // If the user is unselecting the current product remove it from the list
      newSelectedProductsArr.splice(indexOfCurrentProduct, 1);
      setIsSelectAllDisabled(false);
    } else {
      newSelectedProductsArr.push(productId);
    }
    setSelectedProducts(newSelectedProductsArr);

    if (selectedProducts.length > 0) {
      setIsDeselectDisabled(false);
      setIsApproveSelectedDisabled(false);
    }

    if (selectedProducts.length === 0) {
      setIsDeselectDisabled(true);
      setIsApproveSelectedDisabled(true);
    }
  };

  const deslectAllProductsHandler = () => {
    const newSelectedProductsArr = selectedProducts;
    newSelectedProductsArr.length = 0;
    setSelectedProducts(newSelectedProductsArr);
    if (!approvalSwitchOverride) setApprovalSwitchOverride('falseDefault');
    if (approvalSwitchOverride) setApprovalSwitchOverride(false);
    if (selectedProducts.length > 0) {
      setIsDeselectDisabled(false);
      setIsApproveSelectedDisabled(false);
    }

    if (selectedProducts.length === 0) {
      setIsSelectAllDisabled(false);
      setIsDeselectDisabled(true);
      setIsApproveSelectedDisabled(true);
    }
  };

  const selectAllProductsHandler = () => {
    if (
      !approvalSwitchOverride ||
      approvalSwitchOverride === 'trueDefault' ||
      approvalSwitchOverride === 'falseDefault'
    )
      setApprovalSwitchOverride(true);
    if (approvalSwitchOverride === true)
      setApprovalSwitchOverride('trueDefault');

    setSelectedProducts([...productsNotApproved]);

    setIsSelectAllDisabled(true);
    setIsApproveSelectedDisabled(false);
    setIsDeselectDisabled(false);
  };

  const fetchProducts = async (
    props?: any,
    columnData: any = localStorageColumns,
    includeApprovalColumn: any = doProductsRequireApproval
  ) => {
    setIsLoading(true);
    setTableBoolean((prevValue) => !prevValue);

    const isTableChange = props?.tableChange;
    if (isTableChange) {
      // Reset the product approval buttons
      setIsSelectAllDisabled(false);
      setIsApproveSelectedDisabled(true);
      setIsDeselectDisabled(true);

      // Empty the array holding products to be approved
      const emptySelectedProductsArr = selectedProducts;
      emptySelectedProductsArr.length = 0;
      setSelectedProducts(emptySelectedProductsArr);
    }

    // Remove 'all' from params
    let approvedValue = props?.approved;
    if (approvedValue === 'all') approvedValue = undefined;

    // Set search variables
    let searchType = props?.searchType;
    let searchColumn = props?.searchColumn;

    // Adds $ to front of string if name or externalId
    if (
      props?.searchColumn === 'name' ||
      props?.searchColumn === 'externalId'
    ) {
      searchColumn = `$${searchColumn}`;
    }

    // Changes search column to name if no column is entered
    if (props?.search && !props?.searchColumn) {
      searchColumn = 'name';
    }

    // Removes search type from param if no value is entered
    if (!props?.search) {
      searchType = undefined;
    }

    // Removes column/field from param if no value is entered
    if (props?.searchColumn && !props?.search) {
      searchColumn = undefined;
    }

    // If all products were approved, go back one page to prevent no data being displayed
    if (usePreviousPage) {
      props.current--;
      setUsePreviousPage(false);
    }

    const params = {
      // Set params ready to send
      // Page offset starts at 1 for this endpoint
      page: props?.current || 1,
      pageSize: props?.pageSize || Pagination.pageSize,
      searchValue: props?.search || undefined,
      searchField: searchColumn || undefined,
      searchOperation: searchType || undefined,
      approved: approvedValue || undefined,
    };
    try {
      const result: any = await api.get(`/products/product`, {
        params,
      });

      // Get data from API result and set variables
      const pageSize = result.data.pageSize;
      const currentPage = props?.current || 1;
      const entryCount = result.data.entryCount;

      const productsDataArr = result.data.data;

      // Get approval data for each row
      const isApprovedArr: any = [];
      const isNotApprovedArr: any = [];
      for (let i = 0; i < productsDataArr.length; ++i) {
        isApprovedArr.push({
          id: productsDataArr[i].id,
          approved: productsDataArr[i].approved,
        });
        if (!productsDataArr[i].approved) {
          isNotApprovedArr.push(productsDataArr[i].id);
        }
      }

      if (isNotApprovedArr.length === 0) {
        setIsSelectAllDisabled(true);
      }
      setProductsNotApproved(isNotApprovedArr);

      // Get columnm data for each row
      let initialProductsArr: any = [];
      for (let i = 0; i < productsDataArr.length; ++i) {
        initialProductsArr.push(productsDataArr[i]);
      }

      // Map through original data and add all attributes
      // eslint-disable-next-line array-callback-return
      initialProductsArr.map((productData: any, i: number) => {
        Object.entries(productData.attributes).forEach((attribute) => {
          const [key, value] = attribute;
          initialProductsArr[i] = {
            ...initialProductsArr[i],
            [key]: value,
          };
        });
      });

      // Add approval column with switches and action column with edit button directing to product page
      // We need two versions of true and false due to the way switches work and state shallow comparison
      // If the checked attribute is used then the user can't change this so we need to use defaultChecked

      // Only show approved column if product approval is switched on for tenant
      let productsArr = [];
      if (includeApprovalColumn) {
        productsArr = initialProductsArr.map(({ ...rest }, i: number) => ({
          productApproved: (
            <Space>
              {isTableChange && tableBoolean && (
                <Switch
                  onChange={() => {
                    productSwitchHandler(productsDataArr[i].id);
                  }}
                  defaultChecked={false}
                  {...(isApprovedArr[i].approved ? { checked: true } : {})}
                  disabled={isApprovedArr[i].approved}
                />
              )}
              {isTableChange && !tableBoolean && (
                <Switch
                  onChange={() => {
                    productSwitchHandler(productsDataArr[i].id);
                  }}
                  defaultChecked={false}
                  {...(isApprovedArr[i].approved ? { checked: true } : {})}
                  disabled={isApprovedArr[i].approved}
                />
              )}
              {approvalSwitchOverride === 'falseDefault' && !isTableChange && (
                <Switch
                  onChange={() => {
                    productSwitchHandler(productsDataArr[i].id);
                  }}
                  defaultChecked={false}
                  {...(isApprovedArr[i].approved ? { checked: true } : {})}
                  disabled={isApprovedArr[i].approved}
                />
              )}

              {approvalSwitchOverride === 'trueDefault' && !isTableChange && (
                <Switch
                  onChange={() => {
                    productSwitchHandler(productsDataArr[i].id);
                  }}
                  defaultChecked={true}
                  {...(isApprovedArr[i].approved ? { checked: true } : {})}
                  disabled={isApprovedArr[i].approved}
                />
              )}
              {approvalSwitchOverride === true && !isTableChange && (
                <Switch
                  onChange={() => {
                    productSwitchHandler(productsDataArr[i].id);
                  }}
                  defaultChecked={true}
                  {...(isApprovedArr[i].approved ? { checked: true } : {})}
                  disabled={isApprovedArr[i].approved}
                />
              )}
              {approvalSwitchOverride === false && !isTableChange && (
                <Switch
                  onChange={() => {
                    productSwitchHandler(productsDataArr[i].id);
                  }}
                  defaultChecked={false}
                  {...(isApprovedArr[i].approved ? { checked: true } : {})}
                  disabled={isApprovedArr[i].approved}
                />
              )}
            </Space>
          ),
          productAction: (
            <Space>
              <Link to={`/admin/products/product/${productsDataArr[i].id}`}>
                <Button
                  icon={<Edit style={{ marginLeft: -2, marginBottom: -2 }} />}
                />
              </Link>
            </Space>
          ),
          ...rest,
        }));
      } else {
        productsArr = initialProductsArr.map(({ ...rest }, i: number) => ({
          productAction: (
            <Space>
              <Link to={`/admin/products/product/${productsDataArr[i].id}`}>
                <Button
                  icon={<Edit style={{ marginLeft: -2, marginBottom: -2 }} />}
                />
              </Link>
            </Space>
          ),
          ...rest,
        }));
      }

      if (productsDataArr.length > 0) {
        // Get an array of the keys used to help determin which column names to use

        // Create an array of column objects
        const columnsArr = [];
        columnsArr.push(
          {
            key: 0,
            title: columnData.$externalId,
            dataIndex: 'externalId',
          },
          {
            key: 1,
            title: columnData.$name,
            dataIndex: 'name',
          }
        );

        // Add attribute columns
        const rowDataKeys = Object.keys(productsDataArr[0].attributes);
        for (let rowData = 0; rowData < rowDataKeys.length; rowData++) {
          columnsArr.push({
            key: rowData + 2,
            title: columnData[rowDataKeys[rowData]],
            dataIndex: rowDataKeys[rowData],
          });
        }

        // Add Approved column
        if (includeApprovalColumn) {
          columnsArr.push({
            key: rowDataKeys.length + 2,
            title: 'Approved',
            dataIndex: 'productApproved',
          });
        }
        // Add actions column
        columnsArr.push({
          key: rowDataKeys.length + 3,
          title: 'Action',
          dataIndex: 'productAction',
        });
        setColumns(columnsArr);
        setIsLoading(false);
      }
      setProducts(productsArr);

      //Pagination
      const newPagination = {
        current: currentPage,
        pageSize: pageSize,
        total: entryCount,
      };
      setPagination(newPagination);
    } catch (err) {
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    // Variables
    isLoading,
    approvedProductId,
    columns,
    products,
    pagination,
    isSelectAllDisabled,
    isDeselectDisabled,
    isApproveSelectedDisabled,
    selectedProducts,
    approvalSwitchOverride,
    doProductsRequireApproval,
    // Methods
    loadConfig: fetchProductsConfig,
    loadData: fetchProducts,
    deslectAllProducts: deslectAllProductsHandler,
    selectAllProducts: selectAllProductsHandler,
    approveSelectedProducts: approveSelectedProducts,
  };
};

export default useAdminProductsTable;
