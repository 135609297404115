import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Row,
  Table,
  Card,
  Button,
  Form,
  Input,
  Select,
  message,
  Space,
} from 'components/antd';
import { Content, TitleRow } from 'components/shared';
import { Download, Search } from 'components/icons';
import useInvoices, { InvoiceItem } from 'hooks/Account/invoices';

const { Option } = Select;

const currentDate = new Date();
const currentMonth = currentDate.getMonth() + 1;
const currentYear = currentDate.getFullYear();
let years: number[] = [];
for (let i = 2013; i <= currentYear; i++) years = [i, ...years];

function Page() {
  const { t } = useTranslation('invoices');
  const { t: c } = useTranslation('common');
  const { pagination, isLoading, invoices, load, download, downloadAll } =
    useInvoices();
  const [state, setState] = useState<any>({
    month: currentMonth,
    year: currentYear,
  });

  const handleTableChange = (pagination: any, filters?: any, sorter?: any) => {
    setState({ ...state, ...pagination });
    load({ ...state, ...pagination }).catch(() => {
      message.error('Error downloading invoice data');
    });
  };

  const onFinish = (values: any) => {
    setState({ ...state, ...values, current: 1 });
    load({ ...state, ...values, current: 1 }).catch(() => {
      message.error('Error downloading invoice data');
    });
  };

  useEffect(() => {
    load(state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const { isFeatureEnabled } = useUser();
  // const [selectedRows, setSelectedRows] = useState({});

  const expandedRowRender = (record: InvoiceItem) => {
    const linedData = record.invoiceLines.map((x, i) => ({ id: i, ...x }));
    const columns = [
      {
        title: t('lineDescription'),
        dataIndex: 'lineDescription',
        key: 'lineDescription',
      },
      {
        title: t('commissionAmount'),
        dataIndex: 'commissionAmount',
        key: 'commissionAmount',
        render: (value: Number) => '£' + c('number', { value }),
        width: 160,
      },
      {
        title: t('vatRate'),
        dataIndex: 'vatRate',
        key: 'vatRate',
        width: 100,
      },
      {
        title: t('vatAmount'),
        dataIndex: 'vatAmount',
        key: 'vatAmount',
        render: (value: Number) => '£' + c('number', { value }),
        width: 100,
      },
      {
        title: t('commissionAmountIncludingVAT'),
        dataIndex: 'commissionAmountIncludingVAT',
        key: 'commissionAmountIncludingVAT',
        width: 150,
        render: (value: Number) => '£' + c('number', { value }),
      },
    ];

    return (
      <div
        style={{
          position: 'relative',
          maxHeight: 250,
          overflowY: 'auto',
          paddingLeft: '1em',
          paddingRight: '2em',
        }}
      >
        <Table
          columns={columns}
          dataSource={linedData}
          rowKey="id"
          pagination={false}
        />
      </div>
    );
  };

  const getColumns = (download: Function) => {
    const t = c;
    return [
      {
        title: t('affiliateName'),
        dataIndex: 'affiliateName',
        key: 'affiliateName',
      },
      { title: t('affiliateId'), dataIndex: 'affiliateId', key: 'affiliateId' },
      {
        title: t('reference'),
        dataIndex: 'userFriendlyInvoiceID',
        key: 'userFriendlyInvoiceID',
      },
      { title: t('createdDate'), dataIndex: 'createdDate', key: 'createdDate' },
      {
        title: t('totalCommission'),
        dataIndex: 'totalCommission',
        key: 'totalCommission',
        render: (value: Number) => '£' + t('number', { value }),
      },
      {
        title: t('totalCommissionIncludingVAT'),
        dataIndex: 'totalCommissionIncludingVAT',
        key: 'totalCommissionIncludingVAT',
        render: (value: Number) => '£' + t('number', { value }),
      },
      {
        title: t('invoiceLines'),
        dataIndex: 'invoiceLines',
        key: 'invoiceLines',
        render: (value: any[]) => value?.length || 0,
      },
      {
        title: t('action'),
        key: 'action',
        width: 60,

        align: 'right',
        render: (inv: InvoiceItem) => (
          <Space>
            <Button
              title="Download"
              onClick={() => download(inv)}
              icon={
                <Download
                  style={{
                    marginLeft: -3,
                    marginBottom: -3,
                  }}
                />
              }
            />
          </Space>
        ),
      },
    ];
  };

  const downloadHandler = () => {
    downloadAll(state);
  };

  const onSingleDownload = download;

  return (
    <Content>
      <TitleRow>
        <h1>{t('invoices')}</h1>
        <Button type="primary" onClick={downloadHandler}>
          Download
        </Button>
      </TitleRow>

      <Row justify="center" style={{ paddingTop: '2em', paddingBottom: '2em' }}>
        <Form layout="inline" initialValues={state}>
          <Form.Item name="externalIdentifier">
            <Input.Search
              onSearch={(value: string) =>
                onFinish({ ...state, externalIdentifier: value })
              }
              onChange={(e) =>
                setState({ ...state, externalIdentifier: e.target.value })
              }
              placeholder={t('externalIdentifier')}
              allowClear
              enterButton={
                <Search style={{ marginTop: 5, fontSize: '1.2em' }} />
              }
            />
          </Form.Item>
          <Form.Item name="invoiceReference">
            <Input.Search
              onSearch={(value: string) =>
                onFinish({ ...state, invoiceReference: value })
              }
              onChange={(e) =>
                setState({ ...state, invoiceReference: e.target.value })
              }
              placeholder={t('invoiceReference')}
              allowClear
              enterButton={
                <Search style={{ marginTop: 5, fontSize: '1.2em' }} />
              }
            />
          </Form.Item>

          <Form.Item name="month">
            <Select
              style={{ minWidth: 140 }}
              onChange={(value) => onFinish({ ...state, month: value })}
            >
              <Option value={-1}>(All)</Option>
              <Option value={1}>January</Option>
              <Option value={2}>February</Option>
              <Option value={3}>March</Option>
              <Option value={4}>April</Option>
              <Option value={5}>May</Option>
              <Option value={6}>June</Option>
              <Option value={7}>July</Option>
              <Option value={8}>August</Option>
              <Option value={9}>September</Option>
              <Option value={10}>October</Option>
              <Option value={11}>November</Option>
              <Option value={12}>December</Option>
            </Select>
          </Form.Item>
          <Form.Item name="year">
            <Select
              style={{ minWidth: 100 }}
              onChange={(value) => onFinish({ ...state, year: value })}
              options={years.map((y) => ({
                label: y,
                value: y,
              }))}
            />
          </Form.Item>

          <Form.Item name="invoiceLineCount">
            <Select
              placeholder="Line Count"
              style={{ minWidth: 140 }}
              onChange={(value) =>
                onFinish({ ...state, invoiceLineCount: value })
              }
            >
              <Option value={1}>=1</Option>
              <Option value={2}>&gt;1</Option>
              <Option value={3}>&gt;2</Option>
              <Option value={4}>&gt;3</Option>
              <Option value={5}>&gt;4</Option>
              <Option value={6}>&gt;5</Option>
              <Option value={7}>&gt;6</Option>
              <Option value={8}>&gt;7</Option>
              <Option value={9}>&gt;8</Option>
              <Option value={10}>&gt;9</Option>
              <Option value={11}>&gt;10</Option>
              <Option value={12}>&gt;11</Option>
              <Option value={13}>&gt;12</Option>
              <Option value={14}>&gt;13</Option>
              <Option value={15}>&gt;14</Option>
              <Option value={16}>&gt;15</Option>
              <Option value={21}>&gt;20</Option>
              <Option value={31}>&gt;30</Option>
              <Option value={51}>&gt;50</Option>
              <Option value={101}>&gt;100</Option>
            </Select>
          </Form.Item>
        </Form>
      </Row>

      <Card>
        <Table
          style={{ width: '100%' }}
          rowKey="id"
          loading={isLoading}
          columns={getColumns(onSingleDownload)}
          expandable={{ expandedRowRender }}
          onChange={handleTableChange}
          pagination={pagination}
          dataSource={invoices}
        />
      </Card>
    </Content>
  );
}

export default Page;
