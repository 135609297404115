import useUser from 'hooks/User';
import api from 'libs/api';
import { useState } from 'react';

import moment, { Moment } from 'moment';

export type Terms = {
  id: string;
  version: number;
  terms: string;
  dateCreated: Date;
  enforceTerms: boolean;
};

export type SelfBillingAgreement = {
  id: string;
  version: number;
  agreementDetails: string;
  dateCreated: Date;
  enforceAgreement: boolean;
};

export type EditingTerms = {
  terms: string;
  enforceTerms: boolean;
  id?: string;
};

export type EditingSelfBillingAgreement = {
  agreementDetails: string;
  enforceAgreement: boolean;
  id?: string;
};

export type LastAcceptedSelfBillingAgreement = {
  agreementDetails: string;
  agreementID: string;
  agreementVersion: number;
  dateAccepted: string;
};

export type LastAcceptedTermsAndConditionsAgreement = {
  termsDetails: string;
  termsID: string;
  termsVersion: number;
  dateAccepted: string;
};

const useAgreements = () => {
  const [allTerms, setAllTerms] = useState<Terms[]>([]);
  const [termsLoading, setIsLoading] = useState<boolean>(false);
  const [selectedTerms, setEditingTerms] = useState<EditingTerms>();
  const [lastAcceptedTerms, setLastAcceptedTerms] =
    useState<LastAcceptedTermsAndConditionsAgreement>();
  const [allAgreements, setAllAgreements] = useState<SelfBillingAgreement[]>(
    []
  );
  const [agreementsLoading, setAgreementsLoading] = useState<boolean>(false);
  const [selectedAgreement, setEditingAgreement] =
    useState<EditingSelfBillingAgreement>();
  const [lastAcceptedAgreement, setLastAcceptedAgreement] =
    useState<LastAcceptedSelfBillingAgreement>();
  const { user } = useUser();

  const fetchAllTerms = async () => {
    setIsLoading(true);
    try {
      const result: any = await api.get(`/accounts/allterms`);

      setAllTerms(result.data);

      return result.data;
    } finally {
      setIsLoading(false);
    }
  };

  const fetchLatestTerms = async () => {
    setIsLoading(true);
    try {
      const result: any = await api.get(`/accounts/terms`);

      setEditingTerms(result.data);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchSpecificTerms = async (id: string) => {
    setIsLoading(true);

    const result: any = await api.get(`/accounts/allterms`);

    var retrievedTerms: Terms[];
    retrievedTerms = result.data;

    retrievedTerms.forEach((agreement: Terms) => {
      if (agreement.id === id) {
        var newEditingTerms: EditingTerms = {
          terms: agreement.terms,
          enforceTerms: agreement.enforceTerms,
        };

        setEditingTerms(newEditingTerms);
      }
    });

    setIsLoading(false);
  };

  const saveNewTerms = (item: EditingTerms) => {
    // api call
    setIsLoading(true);
    return api.post('/accounts/terms', item).then((response) => {
      setIsLoading(false);
      return response.data;
    });
  };

  const acceptGivenTerms = (id: string) => {
    // api call
    setIsLoading(true);

    const params = {
      termsId: id,
    };

    return api
      .post(`/accounts/affiliate/${user.affiliateId}/acceptterms`, params)
      .then((response) => {
        return response.data;
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const fetchLatestAffiliateTerms = async () => {
    setIsLoading(true);
    try {
      const result: any = await api.get(
        `/accounts/affiliate/${user.affiliateId}/terms`
      );

      setLastAcceptedTerms(result.data);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchAllSelfBillingAgreements = async () => {
    setAgreementsLoading(true);
    try {
      const result: any = await api.get(`/accounts/allbillingagreements`);

      setAllAgreements(result.data);

      return result.data;
    } finally {
      setAgreementsLoading(false);
    }
  };

  const fetchLatestSelfBillingAgreement = async () => {
    setAgreementsLoading(true);
    try {
      const result: any = await api.get(`/accounts/selfbilling`);

      setEditingAgreement(result.data);
    } finally {
      setAgreementsLoading(false);
    }
  };

  const fetchLatestAffiliateSelfBillingAgreement = async () => {
    setAgreementsLoading(true);
    try {
      const result: any = await api.get(
        `/accounts/affiliate/${user.affiliateId}/selfbilling`
      );

      setLastAcceptedAgreement(result.data);
    } finally {
      setAgreementsLoading(false);
    }
  };

  const fetchSpecificSelfBillingAgreement = async (id: string) => {
    setAgreementsLoading(true);

    const result: any = await api.get(`/accounts/allbillingagreements`);

    var retrievedBillingAgreements: SelfBillingAgreement[];
    retrievedBillingAgreements = result.data;

    retrievedBillingAgreements.forEach((agreement: SelfBillingAgreement) => {
      if (agreement.id === id) {
        var newEditingAgreement: EditingSelfBillingAgreement = {
          agreementDetails: agreement.agreementDetails,
          enforceAgreement: agreement.enforceAgreement,
        };

        setEditingAgreement(newEditingAgreement);
      }
    });

    setAgreementsLoading(false);
  };

  const saveNewSelfBillingAgreement = (item: EditingSelfBillingAgreement) => {
    // api call
    setAgreementsLoading(true);
    return api.post('/accounts/selfbilling', item).then((response) => {
      setAgreementsLoading(false);
      return response.data;
    });
  };

  const acceptGivenSelfBillingAgreement = (id: string) => {
    // api call
    setAgreementsLoading(true);

    const params = {
      agreementId: id,
    };

    return api
      .post(`/accounts/affiliate/${user.affiliateId}/acceptselfbilling`, params)
      .then((response) => {
        return response.data;
      })
      .finally(() => {
        setAgreementsLoading(false);
      });
  };

  return {
    // Variables
    termsLoading,
    allTerms,
    selectedTerms,
    agreementsLoading,
    allAgreements,
    selectedAgreement,
    lastAcceptedAgreement,
    lastAcceptedTerms,
    // Methods
    loadAllTerms: fetchAllTerms,
    loadTerms: fetchSpecificTerms,
    loadLatestTerms: fetchLatestTerms,
    store: saveNewTerms,
    acceptTerms: acceptGivenTerms,
    loadAllAgreements: fetchAllSelfBillingAgreements,
    loadAgreements: fetchSpecificSelfBillingAgreement,
    loadLatestAgreement: fetchLatestSelfBillingAgreement,
    loadLatestAffiliateAgreement: fetchLatestAffiliateSelfBillingAgreement,
    loadLatestAffiliateTerms: fetchLatestAffiliateTerms,
    storeAgreement: saveNewSelfBillingAgreement,
    acceptAgreement: acceptGivenSelfBillingAgreement,
  };
};

export default useAgreements;
