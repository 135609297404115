import useUser from 'hooks/User';
import { useEffect, useState } from 'react';
import api from 'libs/api';
import Pagination from 'constants/pagination';
import downloadFile from 'libs/downloadFile';

export type BulkRequestUpdate = {
  contactName: string;
  resultCode: number;
  notes?: string;
  supportRequestIDs: string[];
};

export type SupportQueryAdditionalCapturedUpdate = {
  contactName: string;
  userIsAdmin: boolean;
  supportRequestID: string;
  affiliateID: string;
  additionalCapturedDetails: string;
};

export type RequestStatus = {
  statusTypeNumber: number;
  statusTypeName: string;
};

export type ContactResultType = {
  resultTypeID: number;
  resultTypeDescription: string;
};

export type CaptureDetails = {
  attributeName: string;
  required: boolean;
  dataType: string;
};

export type AdditionalCaptureDetails = {
  captureDetails?: CaptureDetails[];
};

export type ScreenFunctionsDetails = {
  isAllowed: boolean;
  isBulkAllowed: boolean;
  buttonText: string;
  bulkButtonText: string;
  statusesAllowed: string;
  apiMethod: string;
};

export type ConfigurationDetails = {
  acceptFunctions?: ScreenFunctionsDetails;
  denyFunctions?: ScreenFunctionsDetails;
};

export type AdditionalConfigrationDetails = {
  screenConfiguration?: ConfigurationDetails;
};

export type EnquiryType = {
  contactReasonID: number;
  contactReasonName: string;
  allowAcceptance: boolean;
  allowDenial: boolean;
  allowAdditionalUpdating: boolean;
  acceptanceReasons?: ContactResultType[];
  denialReasons?: ContactResultType[];
  additionalCaptureDetails?: AdditionalCaptureDetails;
  additionalScreenConfiguration?: AdditionalConfigrationDetails;
};

export type SupportRequest = {
  affiliateID: string;
  affiliateName: string;
  contactName: string;
  contactPhone: string;
  contactReasonID: number;
  queryMessage: string;
  requestDate: string;
  requestStatus: number;
  requestStatusName: string;
  supportRequestID: string;
  emailAddress: string;
  lastUpdatedDate: string;
  bulkProcessingAllowed: boolean;
  bulkProcessing: boolean;
};

export type RequestMessage = {
  fromSupportAdmin: boolean;
  messageSender: string;
  messageReceiver: string;
  messageContactName: string;
  messageCreationUserID: string;
  messageDate: string;
  messageText: string;
  supportMessageID: string;
  supportRequestID: string;
};

export type SupportAffiliate = {
  affiliateID: string;
  affiliateName: string;
};

export type AdditionalCaptured = {
  supportRequestID: string;
  additionalCapturedDetails: string;
};

export type TQUploadStatusDetail = {
  orderReference?: string;
  supportRequestID?: string;
  message: string;
};

export type TQUploadStatus = {
  failedProcessing: number;
  processedOK: number;
  details: TQUploadStatusDetail[];
};

const useSupportRequests = () => {
  const [requestStatuses, setRequestStatuses] = useState<RequestStatus[]>([]);
  const [contactReasons, setContactReasons] = useState<EnquiryType[]>([]);
  const [supportAffiliates, setSupportAffiliates] = useState<
    SupportAffiliate[]
  >([]);
  const [requestsLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState<any>(Pagination);
  const [supportRequests, setRequests] = useState<SupportRequest[]>([]);
  const [isSelectAllDisabled, setSelectAllDisabled] = useState<boolean>(false);
  const [isDeselectDisabled, setDeselectDisabled] = useState<boolean>(false);
  const [isApproveSelectedDisabled, setApproveSelectedDisabled] =
    useState<boolean>(false);
  const [isDenySelectedDisabled, setDenySelectedDisabled] =
    useState<boolean>(false);
  const [selectedRequests, setSelectedRequests] = useState<any[]>([]);
  const { user } = useUser();
  const [requestSubmitting, setRequestSubmitting] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    fetchStatuses();
    fetchReasons();
    fetchSupportAffiliates();
    setIsLoading(false);
  }, []);

  const fetchStatuses = async () => {
    const result: any = await api.get('/support/RequestStatuses');
    setRequestStatuses(result.data);
  };

  const fetchReasons = async () => {
    const result: any = await api.get('/support/ContactReasons');
    setContactReasons(result.data);
  };

  const fetchSupportAffiliates = async () => {
    const result: any = await api.get(
      `/support/affiliate/${user.affiliateId}/Affiliates`
    );

    setSupportAffiliates(result.data);
  };

  const fetchRequests = async (props?: any) => {
    setIsLoading(true);

    try {
      const params = {
        offset: (props?.current - 1) * props?.pageSize || 0,
        limit: props?.pageSize || Pagination.pageSize,
        affiliateID: props?.affiliateID || undefined,
        rangeFrom: props?.rangeFrom || undefined,
        rangeTo: props?.rangeTo || undefined,
        requestStatusID: props?.statuses || undefined,
        requestTypeID: props?.requestTypeID || undefined,
      };

      const result: any = await api.get(`/support/Requests`, { params });

      setRequests(result.data.supportRequests);

      if (props?.requestTypeID !== undefined) {
        checkRequestsForBulkProcessing(
          result.data.supportRequests,
          props?.requestTypeID
        );
      }

      const p = result.data.pagination;
      const newPagination = {
        current: Math.floor(p.offset / p.limit) + 1,
        pageSize: p.limit,
        total: p.totalItems,
      };

      setPagination(newPagination);
    } finally {
      setIsLoading(false);
    }
  };

  const downloadRequests = async (props?: any) => {
    setIsLoading(true);

    try {
      let downloadLocation = `support/RequestsFile?offset=${
        props?.current || 1
      }&limit=${props?.pageSize || Pagination.pageSize}`;

      downloadLocation += props?.affiliateID
        ? `&affiliateID=${props?.affiliateID}`
        : ``;

      downloadLocation += props?.rangeFrom
        ? `&fromDate=${props?.rangeFrom}`
        : ``;

      downloadLocation += props?.toDate ? `&toDate=${props?.toDate}` : ``;

      downloadLocation += props?.statuses
        ? `&requestStatusID=${props?.statuses}`
        : ``;

      downloadLocation += props?.requestTypeID
        ? `&requestTypeID=${props?.requestTypeID}`
        : ``;

      downloadFile(downloadLocation);
    } finally {
      setIsLoading(false);
    }
  };

  const downloadCSV = async (downloadType: string) => {
    setIsLoading(true);

    try {
      let downloadLocation = `support/DownloadCSV?fileType=${downloadType}`;
      downloadFile(downloadLocation);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchRequestMessages = async (requestID: string) => {
    setIsLoading(true);

    const params = {
      supportRequestID: requestID,
    };

    const result: any = await api.get<RequestMessage[]>(
      `/support/RequestDetails`,
      { params }
    );

    setIsLoading(false);

    return result.data;
  };

  const closeRequest = async (requestID: string, affiliateID: string) => {
    setIsLoading(true);
    return await api
      .post('/support/Close', {
        supportRequestID: requestID,
        affiliateID: affiliateID,
        requestStatusID: 3,
      })
      .then((response) => {
        setIsLoading(false);
        return response.data;
      });
  };

  const reopenRequest = async (requestID: string, affiliateID: string) => {
    setIsLoading(true);
    return await api
      .post('/support/Reopen', {
        supportRequestID: requestID,
        affiliateID: affiliateID,
        requestStatusID: 4,
      })
      .then((response) => {
        setIsLoading(false);
        return response.data;
      });
  };

  const fetchAdditionalCapturedDetails = async (requestID: string) => {
    setIsLoading(true);

    const params = {
      supportRequestID: requestID,
    };

    const result: any = await api.get<RequestMessage[]>(
      `/support/GetRequestAdditionalDetails`,
      { params }
    );

    setIsLoading(false);

    return result.data;
  };

  const noLongerLoading = async () => {
    setIsLoading(false);
  };

  const setLoading = async () => {
    setIsLoading(true);
  };

  const checkRequestsForBulkProcessing = async (
    returnedRequests: SupportRequest[],
    requestTypeID: string
  ) => {
    let allRequests: SupportRequest[] = returnedRequests;

    setSelectAllDisabled(true);
    setDeselectDisabled(true);
    setApproveSelectedDisabled(true);
    setDenySelectedDisabled(true);

    contactReasons.forEach((reason) => {
      if (reason.contactReasonID.toString() == requestTypeID) {
        let statusesAllowed =
          reason.additionalScreenConfiguration?.screenConfiguration
            ?.acceptFunctions?.statusesAllowed === undefined
            ? '-1'
            : reason.additionalScreenConfiguration?.screenConfiguration
                ?.acceptFunctions?.statusesAllowed;
        let allStatuses = statusesAllowed.split(',');

        allRequests.forEach((request) => {
          let bulkAllowed = false;

          allStatuses.forEach((thisStatus) => {
            if (parseInt(thisStatus) === request.requestStatus) {
              bulkAllowed = true;
            }
          });

          request.bulkProcessingAllowed = bulkAllowed;

          if (request.bulkProcessingAllowed) {
            setSelectAllDisabled(false);
          }
        });

        setRequests(allRequests);
      }
    });
  };

  const selectAllRequests = async () => {
    let allRequests: SupportRequest[] = supportRequests;
    const newSelectedRequestsArr = selectedRequests;
    newSelectedRequestsArr.length = 0;

    allRequests.forEach((request) => {
      if (request.bulkProcessingAllowed) {
        request.bulkProcessing = true;
        newSelectedRequestsArr.push(request.supportRequestID);
      }
    });

    setSelectedRequests(newSelectedRequestsArr);
    setSelectAllDisabled(true);
    setDeselectDisabled(false);
    setApproveSelectedDisabled(false);
    setDenySelectedDisabled(false);
    setRequests(allRequests);
  };

  const deselectAllRequests = async () => {
    let allRequests: SupportRequest[] = supportRequests;
    const newSelectedRequestsArr = selectedRequests;
    newSelectedRequestsArr.length = 0;
    setSelectedRequests(newSelectedRequestsArr);

    allRequests.forEach((request) => {
      if (request.bulkProcessingAllowed) {
        request.bulkProcessing = false;
      }
    });

    setSelectAllDisabled(false);
    setDeselectDisabled(true);
    setApproveSelectedDisabled(true);
    setDenySelectedDisabled(true);
    setRequests(allRequests);
  };

  const requestSwitchHandler = (requestID: string) => {
    let allRequests: SupportRequest[] = supportRequests;
    const newSelectedRequestsArr = selectedRequests;

    if (selectedRequests.includes(requestID)) {
      // Get the index of the current request
      const indexOfCurrentRequest = selectedRequests.findIndex(
        (selectedRequests: any) => {
          return selectedRequests === requestID;
        }
      );

      allRequests.forEach((request) => {
        if (request.supportRequestID === requestID) {
          if (request.bulkProcessingAllowed) {
            request.bulkProcessing = false;
          }
        }
      });

      // If the user is unselecting the current product remove it from the list
      newSelectedRequestsArr.splice(indexOfCurrentRequest, 1);
      setSelectAllDisabled(false);
    } else {
      newSelectedRequestsArr.push(requestID);

      allRequests.forEach((request) => {
        if (request.supportRequestID === requestID) {
          if (request.bulkProcessingAllowed) {
            request.bulkProcessing = true;
          }
        }
      });
    }
    setSelectedRequests(newSelectedRequestsArr);

    setRequests(allRequests);

    if (selectedRequests.length > 0) {
      setDeselectDisabled(false);
      setApproveSelectedDisabled(false);
      setDenySelectedDisabled(false);
    }

    if (selectedRequests.length === 0) {
      setDeselectDisabled(true);
      setApproveSelectedDisabled(true);
      setDenySelectedDisabled(true);
    }
  };

  const updateQueryAdditionalData = async (
    item: SupportQueryAdditionalCapturedUpdate
  ) => {
    // api call
    setRequestSubmitting(true);
    return await api
      .post(`/support/UpdateRequestAdditionalDetails`, item)
      .then((response) => {
        setRequestSubmitting(false);
        return response.data;
      });
  };

  const processBulkUpdate = async (method: string, item: BulkRequestUpdate) => {
    // api call
    setRequestSubmitting(true);
    return await api.post(`/support/${method}`, item).then((response) => {
      setRequestSubmitting(false);
      return response.data;
    });
  };

  const noLongerSubmitting = async () => {
    setRequestSubmitting(false);
  };

  return {
    // Variables
    requestStatuses,
    contactReasons,
    supportAffiliates,
    requestsLoading,
    pagination,
    supportRequests,
    isSelectAllDisabled,
    isDeselectDisabled,
    isApproveSelectedDisabled,
    isDenySelectedDisabled,
    selectedRequests,
    requestSubmitting,
    //categories,
    loadStatuses: fetchStatuses,
    loadReasons: fetchReasons,
    loadAffiliates: fetchSupportAffiliates,
    loadRequests: fetchRequests,
    loadRequestMessages: fetchRequestMessages,
    fetchAdditionalCapturedDetails,
    closeRequest,
    reopenRequest,
    noLongerLoading,
    selectAllRequests,
    deselectAllRequests,
    requestSwitchHandler,
    updateQueryAdditionalData,
    downloadRequests,
    downloadCSV,
    setLoading,
    processBulkUpdate,
    noLongerSubmitting,
  };
};

export default useSupportRequests;
