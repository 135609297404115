import { useState } from 'react';
import useUser from 'hooks/User';
import api from 'libs/api';

export type BannerGroup = {
  bannerGroupID: number;
  bannerGroupName: string;
};

export type Banner = {
  alternativeText: string;
  bannerCodeSnippet: string;
  bannerGroups: BannerGroup[];
  bannerID: string;
  bannerImage: string;
  bannerImagePath: string;
  bannerName: string;
  dimensionTypeID: number;
  dimensionX: number;
  dimensionY: number;
  isActive: boolean;
  isHidden: boolean;
  isSynchronised: false;
  linkURL: string;
};

export type Pagination = {
  current: number;
  pageSize: number;
  total: number;
};

export interface IUseBanners {
  banner?: Banner;
  banners: Banner[];
  pagination: Pagination;
  isLoading: boolean;
  getBanner: (bannerId: string) => Promise<any>;
  storeBanner: (props: any) => Promise<any>;
  load: (props: any) => Promise<void>;
}

const useBanners = (): IUseBanners => {
  const { user } = useUser();
  const [banner, setBanner] = useState<Banner>();
  const [banners, setBanners] = useState<Banner[]>([]);
  const [pagination, setPagination] = useState<Pagination>({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [isLoading, setIsLoading] = useState(false);

  const fetch = async (props: any) => {
    setIsLoading(true);
    const result: any = await api.get(
      `/resources/affiliate/${user.affiliateId}/Banners`,
      {
        params: {
          IncludeHiddenBanners: props.includeHiddenBanners,
          IncludeInactiveBanners: props.includeInactiveBanners,
          Limit: props.limit,
          Offset: props.offset,
          BannerGroupID: props.bannerGroupID,
          DimensionTypeID: props.dimensionTypeID,
          BannerSearchText: props.bannerSearchText,
        },
      }
    );

    const p: Pagination = {
      current: result.data.pagination.offset / result.data.pagination.limit + 1,
      pageSize: result.data.pagination.limit,
      total: result.data.pagination.totalItems,
    };

    setBanners(result.data.banners);
    setPagination(p);
    setIsLoading(false);
  };

  const getBanner = async (bannerId: string) => {
    setIsLoading(true);
    const result: any = await api.get(
      `/resources/affiliate/${user.affiliateId}/Banner`,
      {
        params: {
          bannerId: bannerId,
        },
      }
    );

    setIsLoading(false);
    setBanner(result.data);
  };

  const storeBanner = async (banner: Banner) =>
    api.post(`/resources/StoreBanner`, {
      AlternativeText: banner.alternativeText,
      BannerID: banner.bannerID,
      BannerGroups: banner.bannerGroups,
      BannerImage: banner.bannerImage,
      BannerName: banner.bannerName,
      DimensionX: banner.dimensionX,
      DimensionY: banner.dimensionY,
      IsActive: banner.isActive,
      IsHidden: banner.isHidden,
      LinkURL: banner.linkURL,
    });

  return {
    // Variables
    banner,
    banners,
    pagination,
    isLoading,
    // Methods
    getBanner,
    storeBanner,
    load: fetch,
  };
};

export default useBanners;
