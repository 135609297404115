import api from 'libs/api';
import { useState } from 'react';
import Pagination from 'constants/pagination';

export enum AffiliateStatus {
  Active = 1,
  Closed = 2,
}

export type AffiliateListItem = {
  name: string;
  id: string;
  status: string;
  affilateId: string;
  company: string;
  email: string;
  groupName: string;
  billingEnabled: boolean;
};

const afPath = '/accounts/affiliates';

export interface IUseAffiliates {
  affiliates: AffiliateListItem[];
  pagination: any;
  isLoading: boolean;
  load: (params?: any) => Promise<void>;
  setStatus: (id: string, status: AffiliateStatus) => Promise<any>;
  setBilling: (id: string, billingEnabled: boolean) => Promise<any>;
}

const useAffiliate = (): IUseAffiliates => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [pagination, setPagination] = useState<any>(Pagination);
  const [affiliates, setAffiliates] = useState<AffiliateListItem[]>([]);

  const load = async (props?: any) => {
    setIsLoading(true);

    const params = {
      offset: props?.current || 1,
      limit: props?.pageSize || Pagination.pageSize,
      externalIdentifier: props?.affiliateId || undefined,
      status: props?.status,
      affiliateName: props?.affiliateName,
      affiliateEmail: props?.affiliateEmail,
      affiliateGroupId: props?.affiliateGroup,
      dataReview: props?.dataReview,
    };

    try {
      const { data: results }: any = await api.get(afPath, { params });

      const { data, limit, offset, totalItems } = results;

      setAffiliates(data);

      const newPagination = {
        current: offset,
        pageSize: limit,
        total: totalItems,
      };

      setPagination(newPagination);
    } finally {
      setIsLoading(false);
    }
  };

  const setStatus = async (id: string, status: AffiliateStatus) => {
    setIsLoading(false);

    try {
      return api
        .put(`/accounts/affiliate/${id}/status`, { status })
        .then((response) => {
          return response.data;
        });
    } finally {
      setIsLoading(true);
    }
  };

  const setBilling = async (id: string, billingEnabled: boolean) => {
    setIsLoading(true);

    try {
      return api
        .put(`/accounts/affiliate/${id}/billing`, { billingEnabled })
        .then((response) => {
          return response.data;
        });
    } finally {
      setIsLoading(false);
    }
  };

  // const get = () => api.get(`${afPath}/${id}`);
  // const getDetails = () => api.get(`${afPath}/${id}/details`);

  // implement required methods and api calls

  return {
    // Methods
    load,
    setStatus,
    setBilling,
    // get,
    // getDetails,
    // Variables
    affiliates,
    isLoading,
    pagination,
  };
};

export default useAffiliate;
