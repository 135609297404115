import { useTranslation } from 'react-i18next';
import {
  Button,
  Card,
  DatePicker,
  Dropdown,
  Menu,
  message,
  Modal,
  Row,
  Select,
  Space,
  Switch,
  Table,
  Tooltip,
  Upload,
} from 'components/antd';
import { Link } from 'react-router-dom';
import { Content, TitleRow } from 'components/shared';
import { MouseEvent, useCallback, useEffect, useState } from 'react';
import moment, { Moment } from 'moment';
import useSupportRequests, {
  RequestStatus,
  EnquiryType,
  SupportRequest,
  RequestMessage,
  SupportAffiliate,
  ContactResultType,
  AdditionalCaptured,
  AdditionalCaptureDetails,
  TQUploadStatus,
} from 'hooks/Support/requests';
import styled from 'styled-components';
import useUser from 'hooks/User';
import {
  Cancel,
  Check,
  DropDownArrow,
  EditNote,
  Payment,
  Reopened,
  Reply,
} from 'components/icons';
import { Permissions } from 'constants/permissions';
import ProcessRequestModal from './ProcessRequestModal';
import UpdateAdditionalCapturedDataModal from './UpdateAdditionalCapturedDataModal';
import UploadResultModal from './UploadResultModal';
import api from 'libs/api';

const CardRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  width: auto;
  gap: 1em;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1em;
`;

const Detail = styled.div`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ApprovalContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;
`;

const GreyBox = styled.div`
  background-color: #f2f2f2;
  padding: 10px 10px 15px 10px;
  min-width: 600px;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  align-items: center;
`;

type Props = {
  requestStatuses?: RequestStatus[];
  contactReasons?: EnquiryType[];
  supportAffiliates?: SupportAffiliate[];
};

class RequestMessageView {
  public supportRequestID: string;
  public messageSender: string;
  public messageReceiver: string;
  public messageContactName: string;
  public messageDate: string;
  public supportMessageID: string;
  public messageText: string;
  public constructor(message: RequestMessage) {
    this.supportRequestID = message.supportRequestID;
    this.messageSender = message.messageSender;
    this.messageReceiver = message.messageReceiver;
    this.messageContactName = message.messageContactName;
    this.messageDate = message.messageDate;
    this.supportMessageID = message.supportMessageID;
    this.messageText = message.messageText;
  }
}

const Page = ({
  requestStatuses = [],
  contactReasons = [],
  supportAffiliates = [],
}: Props) => {
  const [state, setState] = useState<any>({});
  const { t } = useTranslation('supportRequests');
  const { t: c } = useTranslation('common');
  const {
    supportRequests,
    requestsLoading,
    pagination,
    isSelectAllDisabled,
    isDeselectDisabled,
    isDenySelectedDisabled,
    isApproveSelectedDisabled,
    selectedRequests,
    loadRequests,
    loadRequestMessages,
    closeRequest,
    reopenRequest,
    noLongerLoading,
    selectAllRequests,
    deselectAllRequests,
    requestSwitchHandler,
    fetchAdditionalCapturedDetails,
    downloadRequests,
    downloadCSV,
    setLoading,
  } = useSupportRequests();
  const [startDate, setStartDate] = useState<Moment | null>(null);
  const [endDate, setEndDate] = useState<Moment | null>(null);
  const [requestMessages, setRequestMessages] = useState<{
    [id: string]: RequestMessageView[];
  }>({});
  const { hasPermission, user } = useUser();
  const [processAllAllowed, setProcessAllAllowed] = useState<boolean>(false);
  const [acceptBulkAllowed, setAcceptBulkAllowed] = useState<boolean>(false);
  const [denyBulkAllowed, setDenyBulkAllowed] = useState<boolean>(false);
  const [acceptBulkButtonText, setAcceptBulkButtonText] = useState<string>('');
  const [denyBulkButtonText, setDenyBulkButtonText] = useState<string>('');
  const [processModalVisible, setProcessModalVisible] = useState(false);
  const [processAPIMethod, setProcessAPIMethod] = useState<string>('');
  const [processResultCodes, setProcessResultCodes] = useState<
    ContactResultType[]
  >([]);
  const [processRequestIDs, setProcessRequestIDs] = useState<any[]>([]);
  const [processBulkAcceptResultCodes, setAcceptBulkResultCodes] = useState<
    ContactResultType[]
  >([]);
  const [processBulkDenyResultCodes, setDenyBulkResultCodes] = useState<
    ContactResultType[]
  >([]);
  const [processBulkAcceptAPIMethod, setAcceptBulkAPIMethod] =
    useState<string>('');
  const [processBulkDenyAPIMethod, setDenyBulkAPIMethod] = useState<string>('');
  const [
    updateAdditionalCapturedDataModalVisible,
    setUpdateAdditionalCapturedModalVisible,
  ] = useState(false);
  const [additionalCapturedDetail, setAdditionalCapturedDetail] =
    useState<any>();
  const [additionalCaptureOptions, setAdditionalCaptureOptions] =
    useState<AdditionalCaptureDetails>();
  const [amendingRequest, setAmendingRequest] = useState<SupportRequest>();
  const [uploadTQResults, setUploadTQResults] = useState<TQUploadStatus>();
  const [uploadResultModalVisible, setUploadResultModalVisible] =
    useState(false);
  const [uploadProcessType, setUploadProcessType] = useState<string>('');
  const [affiliateID, setSelectedAffiliate] = useState<string>('');
  const [selectedReasonType, setSelectedReasonType] = useState<string>('');
  const [selectedStatuses, setSelectedStatusCodes] = useState<string[]>([]);

  const hasAdminRights = hasPermission(Permissions['Support.Query.Admin']);

  const changeDate = (val: any) => {
    setStartDate(val?.[0]);
    setEndDate(val?.[1]);

    const sDate = val?.[0]?.utc().startOf('day');
    const eDate = val?.[1]?.utc().endOf('day');

    setState({
      ...state,
      rangeFrom: sDate?.toISOString(),
      rangeTo: eDate?.toISOString(),
    });

    loadRequests({
      ...state,
      rangeFrom: sDate?.toISOString(),
      rangeTo: eDate?.toISOString(),
      current: 1,
    }).catch(() => message.error(t('requestRetrievalError')));
  };

  const options = requestStatuses.map((c) => ({
    label: t(c.statusTypeName),
    value: c.statusTypeNumber?.toString(),
  }));

  useEffect(() => {
    if (supportAffiliates.length === 1 && affiliateID === '') {
      setAffiliateID(supportAffiliates[0].affiliateID);
    } else {
      setAffiliateID('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supportAffiliates]);

  const setAffiliateID = (selectedAffiliate: string) => {
    setSelectedAffiliate(selectedAffiliate);
    var actualValue = selectedAffiliate === '' ? undefined : selectedAffiliate;
    setState({ ...state, affiliateID: actualValue });

    loadRequests({
      ...state,
      affiliateID: actualValue,
      current: 1,
    }).catch(() => message.error(t('supportRetrievalError')));
  };

  const setSelectedRequestType = (selectedReasonType: string) => {
    var actualValue =
      selectedReasonType === '' ? undefined : selectedReasonType;
    setSelectedReasonType(selectedReasonType);
    setState({ ...state, requestTypeID: actualValue });

    loadRequests({
      ...state,
      requestTypeID: actualValue,
      current: 1,
    }).catch(() => message.error(t('supportRetrievalError')));
  };

  const setSelectedStatuses = (selectedStatuses: string[]) => {
    var actualValue =
      selectedStatuses.length > 0 ? selectedStatuses : undefined;
    setSelectedStatusCodes(selectedStatuses);
    setState({ ...state, statuses: actualValue });

    loadRequests({
      ...state,
      statuses: actualValue,
      current: 1,
    }).catch(() => message.error(t('supportRetrievalError')));
  };

  useEffect(() => {
    if (
      selectedReasonType !== '' &&
      selectedReasonType !== undefined &&
      hasAdminRights
    ) {
      contactReasons.forEach((reason) => {
        if (reason.contactReasonID.toString() == selectedReasonType) {
          if (
            reason.additionalScreenConfiguration?.screenConfiguration
              ?.acceptFunctions?.isBulkAllowed ||
            reason.additionalScreenConfiguration?.screenConfiguration
              ?.denyFunctions?.isBulkAllowed
          ) {
            setProcessAllAllowed(true);

            setAcceptBulkAllowed(
              reason.additionalScreenConfiguration?.screenConfiguration
                ?.acceptFunctions?.isBulkAllowed === undefined
                ? false
                : reason.additionalScreenConfiguration?.screenConfiguration
                    ?.acceptFunctions?.isBulkAllowed
            );
            setDenyBulkAllowed(
              reason.additionalScreenConfiguration?.screenConfiguration
                ?.denyFunctions?.isBulkAllowed === undefined
                ? false
                : reason.additionalScreenConfiguration?.screenConfiguration
                    ?.denyFunctions?.isBulkAllowed
            );
            setAcceptBulkButtonText(
              reason.additionalScreenConfiguration?.screenConfiguration
                ?.acceptFunctions?.bulkButtonText === undefined
                ? ''
                : reason.additionalScreenConfiguration?.screenConfiguration
                    ?.acceptFunctions?.bulkButtonText
            );
            setDenyBulkButtonText(
              reason.additionalScreenConfiguration?.screenConfiguration
                ?.denyFunctions?.bulkButtonText === undefined
                ? ''
                : reason.additionalScreenConfiguration?.screenConfiguration
                    ?.denyFunctions?.bulkButtonText
            );
            setAcceptBulkResultCodes(reason.acceptanceReasons!);
            setAcceptBulkAPIMethod(
              reason.additionalScreenConfiguration?.screenConfiguration
                ?.acceptFunctions?.apiMethod === undefined
                ? ''
                : reason.additionalScreenConfiguration?.screenConfiguration
                    ?.acceptFunctions?.apiMethod
            );
            setDenyBulkResultCodes(reason.denialReasons!);
            setDenyBulkAPIMethod(
              reason.additionalScreenConfiguration?.screenConfiguration
                ?.denyFunctions?.apiMethod === undefined
                ? ''
                : reason.additionalScreenConfiguration?.screenConfiguration
                    ?.denyFunctions?.apiMethod
            );
          } else {
            setProcessAllAllowed(false);
          }
        }
      });
    } else {
      setProcessAllAllowed(false);
    }

    //fetchNewData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedReasonType]);

  const fetchNewData = () => {
    loadRequests({
      ...state,
    }).catch(() => message.error(t('supportRetrievalError')));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    setState({ ...state, ...sorter, ...pagination });
    loadRequests({ ...state, ...sorter, ...pagination }).catch(() =>
      message.error(t('supportRetrievalError'))
    );
    deselectAllRequests();
  };

  const fetchRequestMessages = useCallback(async (requestID: string) => {
    const messages = await loadRequestMessages(requestID).catch(() => {
      message.error(t('supportRetrievalError'));
    });
    setRequestMessages((val) => {
      var obj = { ...val };
      obj[requestID] = messages.map(
        (message: RequestMessage) => new RequestMessageView(message)
      );
      return obj;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchRequestAdditionalCapturedDetail = useCallback(
    async (requestID: string) => {
      const capturedDetail = await fetchAdditionalCapturedDetails(
        requestID
      ).catch(() => {
        message.error(t('supportAdditionalCapturedRetrievalError'));
        noLongerLoading();
      });
      setAdditionalCapturedDetail(capturedDetail.additionalCapturedDetails);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    []
  );

  const getColumns = (
    t: Function,
    c: Function,
    contactReasons: EnquiryType[] = []
  ) =>
    [
      {
        title: t('bulkProcessing'),
        render: (af: any) => (
          <Switch
            onChange={() => {
              requestSwitchHandler(af.supportRequestID);
            }}
            defaultChecked={false}
            {...(af.bulkProcessing === undefined
              ? {}
              : af.bulkProcessing
              ? { checked: true }
              : {})}
            disabled={!af.bulkProcessingAllowed}
          />
        ),
        hidden: processAllAllowed ? false : true,
      },
      {
        title: t('affiliateName'),
        dataIndex: 'affiliateName',
        key: 'affiliateName',
        hidden: hasAdminRights ? false : true,
      },
      {
        title: t('requestType'),
        dataIndex: 'contactReasonID',
        key: 'contactReasonID',
        render: (item: number) => (
          <>
            {contactReasons.find((c: EnquiryType) => c.contactReasonID === item)
              ?.contactReasonName || item}
          </>
        ),
      },
      {
        title: t('contactName'),
        dataIndex: 'contactName',
        key: 'contactName',
      },
      {
        title: t('contactPhone'),
        dataIndex: 'contactPhone',
        key: 'contactPhone',
      },
      {
        title: t('emailAddress'),
        dataIndex: 'emailAddress',
        key: 'emailAddress',
      },
      {
        title: t('createdDate'),
        dataIndex: 'requestDate',
        key: 'requestDate',
        render: (date: Date) => c('date', { value: new Date(date) }),
      },
      {
        title: t('lastUpdatedDate'),
        dataIndex: 'lastUpdatedDate',
        key: 'lastUpdatedDate',
        render: (date: Date) => c('date', { value: new Date(date) }),
      },
      {
        title: t('status'),
        dataIndex: 'requestStatusName',
        key: 'requestStatusName',
        render: (item: string) => <>{t(item)}</>,
      },
      {
        title: 'Action',
        width: 100,
        key: 'actions',
        align: 'right',
        render: (af: any) => (
          <Space>
            {af.requestStatus === 1 && !hasAdminRights ? (
              <Tooltip title={t('addMessageTooltip')}>
                <Link
                  to={`/support/requests/${af.supportRequestID}/${af.affiliateID}/addmessage`}
                >
                  {
                    <Button
                      icon={
                        <Reply style={{ marginLeft: -2, marginBottom: -2 }} />
                      }
                    />
                  }
                </Link>
              </Tooltip>
            ) : af.requestStatus === 3 ? (
              <Button
                icon={<Reply style={{ marginLeft: -2, marginBottom: -2 }} />}
                disabled={true}
              />
            ) : (
              <Tooltip title={t('replyMessageTooltip')}>
                <Link
                  to={`/support/requests/${af.supportRequestID}/${af.affiliateID}/reply`}
                >
                  {
                    <Button
                      icon={
                        <Reply style={{ marginLeft: -2, marginBottom: -2 }} />
                      }
                    />
                  }
                </Link>
              </Tooltip>
            )}
            {af.requestStatus === 3 ? (
              <Tooltip title={t('reopenRequestTooltip')}>
                <Button
                  icon={
                    <Reopened style={{ marginLeft: -2, marginBottom: -2 }} />
                  }
                  onClick={() => reopen(af)}
                />
              </Tooltip>
            ) : (
              <Tooltip title={t('completeRequestTooltip')}>
                <Button
                  icon={<Check style={{ marginLeft: -2, marginBottom: -2 }} />}
                  onClick={() => completeRequest(af)}
                />
              </Tooltip>
            )}
            {allowAcceptanceButton(af) && hasAdminRights ? (
              <Tooltip title={t(acceptanceButtonText(af))}>
                <Button
                  icon={
                    <Payment style={{ marginLeft: -2, marginBottom: -2 }} />
                  }
                  onClick={() => acceptRequest(af)}
                />
              </Tooltip>
            ) : (
              <></>
            )}
            {allowDenialButton(af) && hasAdminRights ? (
              <Tooltip title={t(denialButtonText(af))}>
                <Button
                  icon={<Cancel style={{ marginLeft: -2, marginBottom: -2 }} />}
                  onClick={() => denyRequest(af)}
                />
              </Tooltip>
            ) : (
              <></>
            )}
            {allowEditDetailsButton(af) ? (
              <Tooltip title={t('editAdditionalCaptureDetails')}>
                <Button
                  icon={
                    <EditNote style={{ marginLeft: -2, marginBottom: -2 }} />
                  }
                  onClick={() => updateRequestAdditionalCaptureDetails(af)}
                />
              </Tooltip>
            ) : (
              <></>
            )}
          </Space>
        ),
      },
    ].filter((item) => !item.hidden);

  const allowEditDetailsButton = (record: SupportRequest) => {
    let returnValue = false;
    contactReasons.forEach((reason) => {
      if (reason.contactReasonID === record.contactReasonID) {
        if (reason.allowAdditionalUpdating) {
          let statusesAllowed =
            reason.additionalScreenConfiguration?.screenConfiguration
              ?.acceptFunctions?.statusesAllowed === undefined
              ? '-1'
              : reason.additionalScreenConfiguration?.screenConfiguration
                  ?.acceptFunctions?.statusesAllowed;
          let allStatuses = statusesAllowed.split(',');

          allStatuses.forEach((thisStatus) => {
            if (parseInt(thisStatus) === record.requestStatus) {
              returnValue = true;
            }
          });
        }
      }
    });
    return returnValue;
  };

  const allowAcceptanceButton = (record: SupportRequest) => {
    let returnValue = false;
    contactReasons.forEach((reason) => {
      if (reason.contactReasonID === record.contactReasonID) {
        if (reason.allowAcceptance) {
          let statusesAllowed =
            reason.additionalScreenConfiguration?.screenConfiguration
              ?.acceptFunctions?.statusesAllowed === undefined
              ? '-1'
              : reason.additionalScreenConfiguration?.screenConfiguration
                  ?.acceptFunctions?.statusesAllowed;
          let allStatuses = statusesAllowed.split(',');

          allStatuses.forEach((thisStatus) => {
            if (parseInt(thisStatus) === record.requestStatus) {
              returnValue = true;
            }
          });
        }
      }
    });
    return returnValue;
  };

  const acceptanceButtonText = (record: SupportRequest) => {
    let returnValue = '';
    contactReasons.forEach((reason) => {
      if (reason.contactReasonID === record.contactReasonID) {
        returnValue =
          reason.additionalScreenConfiguration?.screenConfiguration
            ?.acceptFunctions?.buttonText === undefined
            ? ''
            : reason.additionalScreenConfiguration?.screenConfiguration
                ?.acceptFunctions?.buttonText;
      }
    });
    return returnValue;
  };

  const allowDenialButton = (record: SupportRequest) => {
    let returnValue = false;
    contactReasons.forEach((reason) => {
      if (reason.contactReasonID === record.contactReasonID) {
        if (reason.allowDenial) {
          let statusesAllowed =
            reason.additionalScreenConfiguration?.screenConfiguration
              ?.denyFunctions?.statusesAllowed === undefined
              ? '-1'
              : reason.additionalScreenConfiguration?.screenConfiguration
                  ?.denyFunctions?.statusesAllowed;
          let allStatuses = statusesAllowed.split(',');

          allStatuses.forEach((thisStatus) => {
            if (parseInt(thisStatus) === record.requestStatus) {
              returnValue = true;
            }
          });
        }
      }
    });

    return returnValue;
  };

  const denialButtonText = (record: SupportRequest) => {
    let returnValue = '';
    contactReasons.forEach((reason) => {
      if (reason.contactReasonID === record.contactReasonID) {
        returnValue =
          reason.additionalScreenConfiguration?.screenConfiguration
            ?.denyFunctions?.buttonText === undefined
            ? ''
            : reason.additionalScreenConfiguration?.screenConfiguration
                ?.denyFunctions?.buttonText;
      }
    });
    return returnValue;
  };

  const acceptRequest = (record: SupportRequest) => {
    contactReasons.forEach((reason) => {
      if (reason.contactReasonID === record.contactReasonID) {
        let newRequestsArray: any[] = new Array();
        newRequestsArray.push(record.supportRequestID);
        setProcessRequestIDs(newRequestsArray);
        setProcessResultCodes(reason.acceptanceReasons!);
        setProcessAPIMethod(
          reason.additionalScreenConfiguration?.screenConfiguration
            ?.acceptFunctions?.apiMethod === undefined
            ? ''
            : reason.additionalScreenConfiguration?.screenConfiguration
                ?.acceptFunctions?.apiMethod
        );
      }
    });
    setProcessModalVisible(true);
  };

  const updateRequestAdditionalCaptureDetails = (record: SupportRequest) => {
    contactReasons.forEach((reason) => {
      if (reason.contactReasonID === record.contactReasonID) {
        setAdditionalCaptureOptions(reason.additionalCaptureDetails);
      }
    });

    setAmendingRequest(record);
    fetchRequestAdditionalCapturedDetail(record.supportRequestID);
    setUpdateAdditionalCapturedModalVisible(true);
  };

  const denyRequest = (record: SupportRequest) => {
    contactReasons.forEach((reason) => {
      if (reason.contactReasonID === record.contactReasonID) {
        let newRequestsArray: any[] = new Array();
        newRequestsArray.push(record.supportRequestID);
        setProcessRequestIDs(newRequestsArray);
        setProcessResultCodes(reason.denialReasons!);
        setProcessAPIMethod(
          reason.additionalScreenConfiguration?.screenConfiguration
            ?.denyFunctions?.apiMethod === undefined
            ? ''
            : reason.additionalScreenConfiguration?.screenConfiguration
                ?.denyFunctions?.apiMethod
        );
      }
    });
    setProcessModalVisible(true);
  };

  const completeRequest = (record: SupportRequest) => {
    Modal.confirm({
      title: t('closeRequestPrompt'),
      onOk: async () => {
        closeRequest(record.supportRequestID, record.affiliateID)
          .then(() => {
            message.success(t('request.complete.success'));
          })
          .catch((error) => {
            noLongerLoading();
            const errorMsg = error?.response?.data?.message;
            message.error(
              t('request.complete.error', {
                msg: errorMsg ? t(errorMsg) : undefined,
              })
            );
          })
          .finally(fetchNewData);
      },
      okText: t('promptYes'),
      cancelText: t('promptNo'),
    });
  };

  const approveSelectedRequests = () => {
    setProcessRequestIDs(selectedRequests);
    setProcessResultCodes(processBulkAcceptResultCodes!);
    setProcessAPIMethod(processBulkAcceptAPIMethod);
    setProcessModalVisible(true);
  };

  const denySelectedRequests = () => {
    setProcessRequestIDs(selectedRequests);
    setProcessResultCodes(processBulkDenyResultCodes!);
    setProcessAPIMethod(processBulkDenyAPIMethod);
    setProcessModalVisible(true);
  };

  const reopen = (record: SupportRequest) => {
    Modal.confirm({
      title: t('reopenRequestPrompt'),
      onOk: async () => {
        reopenRequest(record.supportRequestID, record.affiliateID)
          .then(() => {
            message.success(t('request.reopen.success'));
          })
          .catch((error) => {
            noLongerLoading();
            const errorMsg = error?.response?.data?.message;
            message.error(
              t('request.reopen.error', {
                msg: errorMsg ? t(errorMsg) : undefined,
              })
            );
          })
          .finally(fetchNewData);
      },
      okText: t('promptYes'),
      cancelText: t('promptNo'),
    });
  };

  const SupportMessages = (record: RequestMessageView) => {
    const columns = [
      {
        title: t('messageFrom'),
        dataIndex: 'messageSender',
        key: 'messageSender',
      },
      {
        title: t('messageTo'),
        dataIndex: 'messageReceiver',
        key: 'messageReceiver',
      },
      {
        title: t('contactName'),
        dataIndex: 'messageContactName',
        key: 'messageContactName',
      },
      {
        title: t('messageDate'),
        dataIndex: 'messageDate',
        key: 'messageDate',
        render: (date: Date) => c('date', { value: new Date(date) }),
      },
    ];

    const data = requestMessages[record.supportRequestID];

    return (
      <Table
        size="small"
        rowKey="supportMessageID"
        columns={columns}
        dataSource={data}
        pagination={false}
        expandable={{
          expandedRowRender: (record: RequestMessageView) => (
            <CardRow>
              <Column style={{ width: '100%' }}>
                <div>
                  <Detail
                    dangerouslySetInnerHTML={{ __html: record.messageText }}
                  />
                </div>
              </Column>
            </CardRow>
          ),
          rowExpandable: (record: RequestMessageView) =>
            record.messageText ? true : false,
        }}
      />
    );
  };

  const dropdownMenuItems = () => {
    return hasAdminRights ? (
      <Menu onClick={dropdownOptionClicked}>
        <Menu.Item key="tqResultCSVDownload">
          {t('downloadTQResultCSV')}
        </Menu.Item>
        <Upload
          showUploadList={false}
          customRequest={uploadTQResultsFile}
          beforeUpload={(file) => {
            const isCSV = file.type === 'text/csv';
            if (!isCSV) {
              message.error(t('fileNotCSV'));
            }
            return isCSV || Upload.LIST_IGNORE;
          }}
        >
          <Menu.Item key="tqResultCSVUpload">
            {t('uploadTQResultCSV')}
          </Menu.Item>
        </Upload>
      </Menu>
    ) : (
      <Menu onClick={dropdownOptionClicked}>
        <Upload
          showUploadList={false}
          customRequest={uploadTQs}
          beforeUpload={(file) => {
            const isCSV = file.type === 'text/csv';
            if (!isCSV) {
              message.error(t('fileNotCSV'));
            }
            return isCSV || Upload.LIST_IGNORE;
          }}
        >
          <Menu.Item key="tqCSVUpload">{t('uploadTQCSV')}</Menu.Item>
        </Upload>
      </Menu>
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  function dropdownOptionClicked(e: any) {
    switch (e.key) {
      case 'tqResultCSVDownload':
        downloadCSV('adminTQ');
        break;
    }
  }

  const uploadTQs = (options: any) => {
    let path = `/support/affiliate/${user.affiliateId}/uploadTQs`;
    setUploadProcessType('uploadTQs');
    uploadFile(options, path);
  };

  const uploadTQResultsFile = (options: any) => {
    let path = `/support/uploadTQResults`;
    setUploadProcessType('uploadTQResults');
    uploadFile(options, path);
  };

  const uploadFile = (options: any, path: string) => {
    const { file } = options;
    const formData = new FormData();
    formData.append('fileData', file);

    setLoading();

    api
      .post(path, formData)
      .then((response) => {
        const data = response.data;
        setUploadTQResults(data);
        noLongerLoading();
        message.success('The file has been uploaded and processed');
        setUploadResultModalVisible(true);
        resetListFromBulkProcess();
      })
      .catch(({ response }) => {
        noLongerLoading();
        message.error(`Error uploading file: ${response?.data?.message || ''}`);
      });

    return false;
  };

  function functionButtonClicked(e: any) {
    if (hasAdminRights) {
      const sDate = startDate?.utc().startOf('day');
      const eDate = endDate?.utc().endOf('day');

      var startingAffiliate = affiliateID === '' ? undefined : affiliateID;
      var startingReasonType =
        selectedReasonType === '' ? undefined : selectedReasonType;

      if (!hasAdminRights && startingAffiliate === undefined) {
        return;
      }

      downloadRequests({
        pagination,
        affiliateID: startingAffiliate,
        requestTypeID: startingReasonType,
        statuses: selectedStatuses.length > 0 ? selectedStatuses : undefined,
        rangeFrom: sDate?.toISOString(),
        rangeTo: eDate?.toISOString(),
      }).catch(() => message.error(t('supportExportError')));
    } else {
      downloadCSV('affiliateTQ');
    }
  }

  const resetListFromBulkProcess = () => {
    fetchNewData();
    deselectAllRequests();
    setProcessModalVisible(false);
  };

  return (
    <Content>
      <TitleRow>
        <h1>{t('SupportRequests')}</h1>
        <Space>
          <Dropdown.Button
            onClick={functionButtonClicked}
            overlay={dropdownMenuItems()}
            icon={<DropDownArrow style={{ marginTop: 5 }} />}
          >
            {hasAdminRights ? t('exportList') : t('downloadTQCSV')}
          </Dropdown.Button>
          <Link to={`/support/requests/create`}>
            <Button disabled={hasAdminRights ? true : false}>
              {t('newRequest')}
            </Button>
          </Link>
        </Space>
      </TitleRow>

      <Row justify="center" style={{ margin: '16px 0' }}>
        <Space>
          <DatePicker.RangePicker
            value={[startDate, endDate]}
            onChange={changeDate}
            disabledDate={(date) => date > moment()}
          />
          <Select
            mode="multiple"
            allowClear
            placeholder={t('selectStatus')}
            options={options as any[]}
            style={{ width: 200 }}
            onChange={(value) => {
              setSelectedStatuses(value);
            }}
          />
          <Select
            allowClear
            placeholder={t('selectReasonType')}
            options={contactReasons.map((reason) => ({
              label: reason.contactReasonName,
              value: reason.contactReasonID,
            }))}
            value={selectedReasonType !== '' ? selectedReasonType : undefined}
            style={{ width: 200 }}
            onChange={(value) => {
              setSelectedRequestType(value as string);
            }}
          />
          {hasAdminRights ? (
            <Select
              placeholder={t('affiliate')}
              allowClear
              disabled={!hasAdminRights}
              options={supportAffiliates.map((affiliate) => ({
                label: affiliate.affiliateName,
                value: affiliate.affiliateID.toString(),
              }))}
              value={affiliateID !== '' ? affiliateID : undefined}
              style={{ width: 200 }}
              onChange={(value) => {
                setAffiliateID(value as string);
              }}
            />
          ) : (
            <></>
          )}
        </Space>
      </Row>
      {processAllAllowed === true && (
        <ApprovalContainer>
          <GreyBox>
            <h3 style={{ marginBottom: '15px' }}>{t('requestApproval')}</h3>
            <div>
              <Button
                type="primary"
                disabled={isSelectAllDisabled}
                onClick={() => {
                  selectAllRequests();
                }}
              >
                {t('selectAll')}
              </Button>
              <Button
                style={{ marginLeft: '15px' }}
                type="primary"
                disabled={isDeselectDisabled}
                onClick={() => {
                  deselectAllRequests();
                }}
              >
                {t('deselectAll')}
              </Button>
              {acceptBulkAllowed === true && (
                <Button
                  style={{ marginLeft: '15px' }}
                  disabled={isApproveSelectedDisabled}
                  type="primary"
                  onClick={() => {
                    approveSelectedRequests();
                  }}
                >
                  {t(acceptBulkButtonText)}
                </Button>
              )}
              {denyBulkAllowed === true && (
                <Button
                  style={{ marginLeft: '15px' }}
                  disabled={isDenySelectedDisabled}
                  type="primary"
                  onClick={() => {
                    denySelectedRequests();
                  }}
                >
                  {t(denyBulkButtonText)}
                </Button>
              )}
            </div>
          </GreyBox>
        </ApprovalContainer>
      )}
      <UploadResultModal
        visible={uploadResultModalVisible}
        results={uploadTQResults}
        processType={uploadProcessType}
        onFinished={() => setUploadResultModalVisible(false)}
      />
      <ProcessRequestModal
        visible={processModalVisible}
        apiMethod={processAPIMethod}
        resultCodes={processResultCodes}
        requestIDs={processRequestIDs}
        contactName={user.name}
        onFinished={() => resetListFromBulkProcess()}
        onCancel={() => setProcessModalVisible(false)}
      />
      <UpdateAdditionalCapturedDataModal
        visible={updateAdditionalCapturedDataModalVisible}
        capturedDetails={additionalCapturedDetail}
        captureOptions={additionalCaptureOptions}
        supportRequest={amendingRequest}
        userIsAdmin={hasAdminRights}
        contactName={user.name}
        onFinished={() => {
          setAdditionalCapturedDetail('');
          setUpdateAdditionalCapturedModalVisible(false);
        }}
        onCancel={() => {
          setAdditionalCapturedDetail('');
          setUpdateAdditionalCapturedModalVisible(false);
        }}
      />
      <Card style={{ marginTop: '2em' }}>
        <Table
          rowKey="supportRequestID"
          size="small"
          loading={requestsLoading}
          columns={getColumns(t, c, contactReasons) as any}
          onChange={handleTableChange}
          pagination={pagination}
          dataSource={supportRequests}
          expandable={{
            expandedRowRender: SupportMessages,
            rowExpandable: (val: any) => true,
            onExpand: (expanded: boolean, supportRequest: SupportRequest) => {
              if (expanded && !requestMessages[supportRequest.supportRequestID])
                fetchRequestMessages(supportRequest.supportRequestID);
            },
          }}
        />
      </Card>
    </Content>
  );
};

export default Page;
