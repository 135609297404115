import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { ThemeSwitcherProvider } from 'react-css-theme-switcher';
import { CSSProp } from 'styled-components'; // eslint-disable-line @typescript-eslint/no-unused-vars
import moment from 'moment-timezone';
import 'moment/locale/en-gb';

import Compose from 'components/App/Compose';
import { UserProvider } from 'hooks/User/Context';
import { LayoutProvider, getThemeFromUrl } from 'hooks/Layout/Context';

import App from './components/App';

import './i18n';

import 'react-custom-scroll/dist/customScroll.css';
import themes from './constants/themes';

const themeName = getThemeFromUrl() || 'default';

const themeDictionary = Object.entries(themes).reduce((acc, cur) => {
  const [name, options] = cur;
  return { ...acc, [name]: options.css };
}, {});

moment.tz.setDefault('UTC');
moment.locale('en-gb');

// This should fix props in styled-components
declare global {
  namespace JSX {
    interface IntrinsicAttributes {
      css?: CSSProp;
    }
  }
}

ReactDOM.render(
  <ThemeSwitcherProvider
    insertionPoint={document.getElementById('inject-styles-here')}
    defaultTheme={themeName}
    themeMap={themeDictionary}
  >
    <Compose providers={[BrowserRouter, UserProvider, LayoutProvider]}>
      <App />
    </Compose>
  </ThemeSwitcherProvider>,

  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
