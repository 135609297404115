import api from 'libs/api';
import { useState } from 'react';
import Pagination from 'constants/pagination';
import useUser from 'hooks/User';

export type CommissionCodeListItem = {
  id?: number;
  code: string;
  commissionGroupId: number;
  name: string;
};

export type ProductGroup = {
  id: string;
  name: string;
};

export type CommissionIncrease = {
  name: string;
  amount: number;
  increaseDefaultCommission: boolean;
  effectiveFrom?: Date;
  effectiveTo?: Date;
  affiliates: string[];
};

export interface IUseCommissionCodes {
  commissionCodes: CommissionCodeListItem[];
  pagination: any;
  isLoading: boolean;
  productGroups: ProductGroup[];

  load: (params?: any, groups?: ProductGroup[]) => Promise<void>;
  fetchOne: (
    id: number,
    showArchived?: boolean
  ) => Promise<CommissionCodeListItem>;
  loadProductGroups: () => Promise<void>;
  add: (item: CommissionCodeListItem) => Promise<void>;
  update: (item: CommissionCodeListItem) => Promise<void>;
  saveCommissionIncrease: (
    commissionGroupId: number,
    item: CommissionIncrease
  ) => Promise<void>;
}

const path = '/sales/user';

const useCommitionCodes = (): IUseCommissionCodes => {
  const { user } = useUser();
  const { id: userId } = user;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [pagination, setPagination] = useState<any>(Pagination);
  const [productGroups, setProductGroups] = useState<ProductGroup[]>([]);
  const [commissionCodes, setCommissionCodes] = useState<
    CommissionCodeListItem[]
  >([]);

  const load = async (props?: any, groups: ProductGroup[] = []) => {
    setIsLoading(true);

    const params = {
      offset: props?.current || 1,
      limit: props?.pageSize || Pagination.pageSize,
      groupName: groups.find((x) => x.id === props.productGroup)?.name,
      code: props.code,
      name: props.name,
      affiliateId: props.affiliateId,
      archived: props.archived,
    };

    try {
      const { data: results }: any = await api.get(
        `${path}/${userId}/commissionCode`,
        { params }
      );

      const { data, limit, offset, totalItems } = results;
      setCommissionCodes(data);

      const newPagination = {
        current: offset,
        pageSize: limit,
        total: totalItems,
      };

      setPagination(newPagination);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchOne = (id: number, showArchived: boolean = false) => {
    setIsLoading(true);

    return api
      .get(`/sales/user/${userId}/commissionCode/${id}`, {
        params: { showArchived },
      })
      .then((response) => response.data)
      .finally(() => setIsLoading(false));
  };

  const addCode = async (item: CommissionCodeListItem) => {
    return api
      .post(`${path}/${userId}/commissionCode`, item)
      .then((response) => response.data);
  };

  const updateCode = async (item: CommissionCodeListItem) => {
    return api
      .patch(`${path}/${userId}/commissionCode/${item.id}`, item)
      .then((response) => response.data);
  };

  const loadProductGroups = async () => {
    const { data } = await api.get(`${path}/${userId}/commissionGroup`);

    setProductGroups(data);
  };

  const saveCommissionIncrease = async (
    commissionGroupId: number,
    item: CommissionIncrease
  ) => {
    return api
      .post(
        `${path}/${userId}/commissionGroup/${commissionGroupId}/commissionCode/commission`,
        item
      )
      .then((response) => response.data);
  };

  return {
    // Methods
    load,
    fetchOne,
    loadProductGroups,
    update: updateCode,
    add: addCode,
    saveCommissionIncrease,
    // Variables
    productGroups,
    commissionCodes,
    isLoading,
    pagination,
  };
};

export default useCommitionCodes;
