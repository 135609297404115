import { Routes, Route } from 'react-router-dom';

import Detail from './Detail';
import Index from './index2';

function Page() {
  return (
    <Routes>
      <Route path="archive" element={<Index isArchive={true} />} />
      <Route index element={<Index />} />

      <Route path=":id" element={<Detail />} />
    </Routes>
  );
}

export default Page;
