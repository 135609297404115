import { Navigate, Route, Routes } from 'react-router';
import { PrivatePage } from 'components/shared';
import useFAQCategoriesHook from 'hooks/Support/faqCategories';

import Categories from './Categories';
import FAQs from './FAQs';
import Edit from './Edit';

import { Permissions } from 'constants/permissions';

const Page = () => {
  const useFAQCategories = useFAQCategoriesHook();
  const { filterCategories } = useFAQCategories;

  return (
    <Routes>
      <Route
        path="/:id/edit"
        element={
          <PrivatePage
            requirement={Permissions['Content.FAQs.Admin']}
            children={<Edit categories={filterCategories} />}
          />
        }
      />
      <Route
        path="/create"
        element={
          <PrivatePage
            requirement={Permissions['Content.FAQs.Admin']}
            children={<Edit categories={filterCategories} isNew />}
          />
        }
      />
      <Route
        path="/faqs"
        element={
          <PrivatePage
            children={<FAQs />}
            requirement={Permissions['Content.FAQs.Admin']}
          />
        }
      />
      <Route
        path="/categories"
        element={
          <PrivatePage
            children={<Categories useFAQCategories={useFAQCategories} />}
            requirement={Permissions['Content.FAQCategories.Admin']}
          />
        }
      />
      <Route index element={<Navigate to="/faqs" />} />
    </Routes>
  );
};

export default Page;
