import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import {
  Button,
  Card,
  DatePicker,
  Input,
  message,
  Row,
  Select,
  Space,
  Table,
  Tooltip,
} from 'components/antd';
import { Content, TitleRow } from 'components/shared';
import { Link } from 'react-router-dom';
import moment, { Moment } from 'moment';
import useUser from 'hooks/User';
import usePayments, { PaymentStatus } from 'hooks/Financial/payments';
import usePaymentRequests, {
  PaymentRequestType,
} from 'hooks/Financial/paymentrequests';
import { Features } from 'constants/features';
import { Download, Edit, Search } from 'components/icons';
import { Permissions } from 'constants/permissions';
import usePurchaseOrders, { POReference } from 'hooks/Financial/purchaseorders';

const Page = () => {
  const { t } = useTranslation('financialAdminPaymentRequests');
  const { t: c } = useTranslation('common');
  const [startDate, setStartDate] = useState<Moment | null>(null);
  const [endDate, setEndDate] = useState<Moment | null>(null);
  const { getFeature, hasPermission } = useUser();
  const { paymentStatuses, paymentAffiliates } = usePayments();
  const {
    pagination,
    paymentRequests,
    requestsLoading,
    requestTypes,
    downloadInvoice,
    loadPaymentRequests,
    exportList,
  } = usePaymentRequests();
  const { purchaseOrderReferences } = usePurchaseOrders();
  const [state, setState] = useState<any>({});

  const hasPurchaseOrderFunctionality = getFeature(
    Features['Finance.Features.PurchaseOrders']
  ).enabled;
  const canEditPayment = hasPermission(
    Permissions['Finance.PaymentRequests.Edit']
  );

  const onDownload = (values: any) => {
    exportList(values);
  };

  const changeDate = (val: any) => {
    setStartDate(val?.[0]);
    setEndDate(val?.[1]);

    const sDate = val?.[0]?.utc().startOf('day');
    const eDate = val?.[1]?.utc().endOf('day');

    setState({
      ...state,
      rangeFrom: sDate?.toISOString(),
      rangeTo: eDate?.toISOString(),
    });

    loadPaymentRequests({
      ...state,
      rangeFrom: sDate?.toISOString(),
      rangeTo: eDate?.toISOString(),
      current: 1,
    }).catch(() => message.error(t('requestRetrievalError')));
  };

  const setSelectedStatus = (selectedStatus: string) => {
    var actualValue = selectedStatus === '' ? undefined : selectedStatus;
    setState({ ...state, status: actualValue });

    loadPaymentRequests({
      ...state,
      status: actualValue,
      current: 1,
    }).catch(() => message.error(t('requestRetrievalError')));
  };

  const setAffiliateID = (selectedAffiliate: string) => {
    var actualValue = selectedAffiliate === '' ? undefined : selectedAffiliate;
    setState({ ...state, affiliateID: actualValue });

    loadPaymentRequests({
      ...state,
      affiliateID: actualValue,
      current: 1,
    }).catch(() => message.error(t('requestRetrievalError')));
  };

  const setSelectedRequestType = (selectedRequestType: string) => {
    var actualValue =
      selectedRequestType === '' ? undefined : selectedRequestType;
    setState({ ...state, requestType: actualValue });

    loadPaymentRequests({
      ...state,
      requestType: actualValue,
      current: 1,
    }).catch(() => message.error(t('requestRetrievalError')));
  };

  const setPurchaseOrderID = (selectedPO: string) => {
    var actualValue = selectedPO === '' ? undefined : selectedPO;
    setState({ ...state, purchaseOrderID: actualValue });

    loadPaymentRequests({
      ...state,
      purchaseOrderID: actualValue,
      current: 1,
    }).catch(() => message.error(t('requestRetrievalError')));
  };

  const setSearchTerm = (searchTerm: string) => {
    var actualValue = searchTerm === '' ? undefined : searchTerm;
    setState({ ...state, searchTerm: actualValue });

    loadPaymentRequests({
      ...state,
      searchTerm: actualValue,
      current: 1,
    }).catch(() => message.error(t('requestRetrievalError')));
  };

  useEffect(() => {
    loadPaymentRequests({
      ...state,
    }).catch(() => message.error(t('requestRetrievalError')));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formatCurrency = (value: any) =>
    `${t('currency.symbol')}${formatNumber(value)}`;

  const formatNumber = (value: any) =>
    `${c('number', {
      value: value,
    })}`;

  const handleTableChange = (pagination: any) => {
    setState({ ...state, ...pagination });

    loadPaymentRequests({
      ...state,
      ...pagination,
    }).catch(() => message.error(t('requestRetrievalError')));
  };

  const getColumns = (t: Function, c: Function) =>
    [
      {
        title: t('paymentRequestIdentifier'),
        dataIndex: 'paymentRequestIdentifier',
        key: 'paymentRequestIdentifier',
      },
      {
        title: t('paymentRequestType'),
        dataIndex: 'paymentRequestType',
        key: 'paymentRequestType',
        render: (item: string) => (
          <>
            {t(
              requestTypes.find(
                (c: PaymentRequestType) => c.requestTypeValue === item
              )?.requestTypeName || item
            )}
          </>
        ),
      },
      {
        title: t('affiliateName'),
        dataIndex: 'affiliateName',
        key: 'affiliateName',
      },
      {
        title: t('affiliateIdentifier'),
        dataIndex: 'affiliateExternalIdentifier',
        key: 'affiliateExternalIdentifier',
      },
      {
        title: t('paymentAmount'),
        dataIndex: 'amount',
        key: 'amount',
        align: 'right',
        render: (item: number) => formatCurrency(item),
      },
      {
        title: t('paymentCreatedDate'),
        dataIndex: 'dateCreated',
        key: 'dateCreated',
        render: (date: Date) => c('date', { value: new Date(date) }),
      },
      {
        title: t('paymentNotes'),
        dataIndex: 'notes',
        key: 'notes',
      },
      {
        title: t('paymentStatus'),
        dataIndex: 'status',
        key: 'status',
        render: (item: number) => (
          <>
            {t(
              paymentStatuses.find(
                (c: PaymentStatus) => c.statusTypeNumber === item
              )?.statusTypeName || item
            )}
          </>
        ),
      },
      {
        title: t('paymentUpdatedDate'),
        dataIndex: 'lastModifiedDate',
        key: 'lastModifiedDate',
        render: (date: Date) => c('date', { value: new Date(date) }),
      },
      {
        title: t('purchaseOrderNumber'),
        dataIndex: 'purchaseOrderId',
        key: 'purchaseOrderId',
        render: (item: string) => (
          <>
            {purchaseOrderReferences.find((c: POReference) => c.id === item)
              ?.tenantPurchaseOrderReference || item}
          </>
        ),
        hidden: hasPurchaseOrderFunctionality ? false : true,
      },
      {
        title: t('orderReference'),
        dataIndex: 'orderReference',
        key: 'orderReference',
      },
      {
        title: 'Action',
        width: 100,
        key: 'actions',
        align: 'right',
        render: (af: any) => (
          <Space>
            <Tooltip title={t('editPaymentRequest')}>
              <Link to={`/admin/financial/paymentrequests/${af.id}/edit`}>
                {
                  <Button
                    disabled={!canEditPayment}
                    icon={<Edit style={{ marginLeft: -2, marginBottom: -2 }} />}
                  />
                }
              </Link>
            </Tooltip>
            <Tooltip title={t('downloadInvoice')}>
              <Button
                disabled={
                  af.invoiceReference === ''
                    ? true
                    : af.invoiceReference === undefined
                    ? true
                    : af.invoiceReference === null
                    ? true
                    : false
                }
                onClick={() => downloadInvoice(af.id, af.invoiceReference)}
                icon={<Download style={{ marginLeft: -2, marginBottom: -2 }} />}
              />
            </Tooltip>
          </Space>
        ),
      },
    ].filter((item) => !item.hidden);

  return (
    <Content>
      <TitleRow>
        <h1>{t('paymentRequestsTitle')}</h1>
        <Space>
          <Button onClick={() => onDownload({ ...state })}>
            {t('Export')}
          </Button>
        </Space>
      </TitleRow>

      <Row justify="center" style={{ margin: '16px 0' }}>
        <Space>
          <DatePicker.RangePicker
            value={[startDate, endDate]}
            onChange={changeDate}
            disabledDate={(date) => date > moment()}
          />
          <Select
            placeholder={t('selectRequestType')}
            allowClear
            options={requestTypes.map((type) => ({
              label: t(type.requestTypeName),
              value: type.requestTypeValue,
            }))}
            style={{ width: 200 }}
            onChange={(value) => {
              setSelectedRequestType(value as string);
            }}
          />
          <Select
            allowClear
            placeholder={t('selectStatus')}
            options={paymentStatuses.map((c) => ({
              label: t(c.statusTypeName),
              value: c.statusTypeNumber?.toString(),
            }))}
            style={{ width: 200 }}
            onChange={(value) => {
              setSelectedStatus(value as string);
            }}
          />
          <Select
            showSearch
            placeholder={t('selectAffiliate')}
            allowClear
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option!.label as unknown as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            options={paymentAffiliates.map((affiliate) => ({
              label:
                (affiliate.externalIdentifier == null
                  ? ''
                  : '(' + affiliate.externalIdentifier + ') - ') +
                affiliate.affiliateName,
              value: affiliate.id.toString(),
            }))}
            style={{ width: 200 }}
            onChange={(value) => {
              setAffiliateID(value as string);
            }}
          />
          {hasPurchaseOrderFunctionality && (
            <Select
              placeholder={t('selectPurchaseOrder')}
              allowClear
              options={purchaseOrderReferences.map((po) => ({
                label: po.tenantPurchaseOrderReference,
                value: po.id.toString(),
              }))}
              style={{ width: 200 }}
              onChange={(value) => {
                setPurchaseOrderID(value as string);
              }}
            />
          )}
          <Input.Search
            value={state.searchTerm}
            onSearch={(value: string) => setSearchTerm(value as string)}
            placeholder={t('searchTerm')}
            allowClear
            onChange={(e) => setState({ ...state, searchTerm: e.target.value })}
            enterButton={<Search style={{ marginTop: 5, fontSize: '1.2em' }} />}
          />
        </Space>
      </Row>

      <Card style={{ marginTop: '2em' }}>
        <Table
          rowKey="id"
          size="small"
          loading={requestsLoading}
          columns={getColumns(t, c) as any}
          pagination={pagination}
          dataSource={paymentRequests}
          onChange={handleTableChange}
        />
      </Card>
    </Content>
  );
};

export default Page;
