import styled from 'styled-components';
import Logo from 'components/Layout/TenantSwitch/TenantLogo';
import useUser from 'hooks/User';
import { Col, Row } from 'components/antd';

const Container = styled.div<{ availableTenants: number; width: number }>`
  width: ${(props) => `${props.width}px`};
  left: ${(props) => `calc(100% - ${props.width > 200 ? 200 : props.width}px)`};
  height: auto;
  padding: 10px 0px 10px 10px;
  background: #fbfbfb;
  border: 1px solid #aaaaaa;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50px;
  max-width: 200px;
`;

const Arrow = styled.div`
  position: relative;
  background: #fbfbfb;
  position: relative;
  top: 13px;

  &:after,
  &:before {
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: '';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  &:after {
    border-bottom-color: #fbfbfb;
    border-width: 6px;
    margin-left: -6px;
  }

  &:before {
    border-bottom: 1px solid #aaaaaa;
    border-width: 8px;
    margin-left: -8px;
  }
`;

const SwitchContainer = () => {
  const { user } = useUser();

  return (
    <>
      <Container
        availableTenants={user.availableTenants.length}
        width={user.availableTenants.length * 70}
      >
        <Row gutter={[8, 8]}>
          {user.availableTenants.map((availableTenant) => (
            <Col span={user.availableTenants.length < 3 ? 12 : 8}>
              <Logo
                key={availableTenant.id}
                tenantLogo={availableTenant.bannerUrl}
                tenantLink={availableTenant.domain + '/dashboard'}
                tenantName={availableTenant.name}
              />
            </Col>
          ))}
        </Row>
      </Container>
      <Arrow />
    </>
  );
};

export default SwitchContainer;
