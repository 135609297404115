import useLayout from 'hooks/Layout';
import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router';
import { Content } from 'components/shared';
import { Button, Card, message, Space } from 'components/antd';
import useAgreements from 'hooks/Agreements/agreements';
import useUser from 'hooks/User';
import { useTranslation } from 'react-i18next';

const Page = () => {
  const { t } = useTranslation('platformAgreement');
  const { setMinimized } = useLayout();
  const {
    loadLatestAgreement,
    agreementsLoading,
    selectedAgreement,
    acceptAgreement,
  } = useAgreements();
  const { user, approvePlatformAgreement } = useUser();
  const navigate = useNavigate();
  const { search } = useLocation();
  const q = new URLSearchParams(search);
  const returnUrl = q.get('returnUrl');

  useEffect(() => {
    loadLatestAgreement().catch((error) => {
      const errorMsg = error?.response?.data?.message;
      message.error(
        t(`agreement.load.error`, {
          msg: errorMsg ? t(errorMsg) : undefined,
        })
      );
    });
    // setSidebar(false);
    setMinimized(true);
    return () => {
      setMinimized(false);
      // setSidebar(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onAccept = () => {
    if (!selectedAgreement || !selectedAgreement.id) return;
    acceptAgreement(selectedAgreement.id)
      .then(() => {
        message.success(t('agreement.accept.success'));

        approvePlatformAgreement();
        navigate(returnUrl || '/');
      })
      .catch((error) => {
        const errorMsg = error?.response?.data?.message;
        message.error(
          t(`agreement.accept.error`, {
            msg: errorMsg ? t(errorMsg) : undefined,
          })
        );
      });
  };

  const onLater = () => {
    sessionStorage.setItem('acceptSelfBillingLater', 'true');

    navigate(returnUrl || '/');
  };

  return (
    <Content
      style={{
        textAlign: 'center',
        maxWidth: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <h1>{t('platformAgreement')}</h1>

      <Card
        style={{ width: '', minWidth: 500, maxWidth: 1900 }}
        loading={agreementsLoading}
      >
        <div
          style={{ padding: '2em', overflowY: 'auto', height: 700 }}
          dangerouslySetInnerHTML={{
            __html: selectedAgreement?.agreementDetails || '',
          }}
        />
        {user.hasNewSelfBillingAgreement && (
          <>
            <br />

            <Space>
              <Button onClick={onAccept}>Agree</Button>
              {!selectedAgreement?.enforceAgreement && (
                <Button onClick={onLater}>Later</Button>
              )}
            </Space>
          </>
        )}
      </Card>
    </Content>
  );
};

export default Page;
