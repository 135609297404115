import { CgFeed, CgArrowDownR, CgArrowUpR } from 'react-icons/cg';
import { GiHamburgerMenu } from 'react-icons/gi';
import { GrResources, GrSystem } from 'react-icons/gr';
import {
  HiOutlineDocumentReport,
  HiOutlineDocumentText,
  HiOutlineDocumentDownload,
} from 'react-icons/hi';

import { RiAccountBoxLine } from 'react-icons/ri';
import {
  AiOutlineQuestionCircle,
  AiOutlineSearch,
  AiOutlineInfoCircle,
} from 'react-icons/ai';
import {
  FaSatelliteDish,
  FaUserCircle,
  FaChartBar,
  FaChartLine,
  FaCreativeCommonsShare,
  FaRegMoneyBillAlt,
  FaCheckCircle,
  FaGripHorizontal,
} from 'react-icons/fa';
import {
  BiBookContent,
  BiExport,
  BiRightArrowAlt,
  BiSupport,
  BiUpload,
  BiLinkAlt,
} from 'react-icons/bi';
import { IoIosArrowBack, IoIosArrowDown, IoIosSwitch } from 'react-icons/io';
import {
  MdOutlineTableRows,
  MdGridView,
  MdOutlineCreate,
  MdOutlineDashboard,
  MdDownload,
  MdOutlineQuestionAnswer,
  MdOutlineSupervisorAccount,
  MdPriceChange,
  MdOutlinePriceChange,
  MdOutlineCancel,
  MdOutlinePayments,
  MdEditNote,
  MdOutlineArrowDropDown,
} from 'react-icons/md';
import { BsTagFill, BsFillExclamationCircleFill } from 'react-icons/bs';
import { ImCross, ImCheckmark, ImReply } from 'react-icons/im';
import {
  RiDeleteBin6Line,
  RiEdit2Line,
  RiEyeLine,
  RiFileCopy2Line,
  RiFilterLine,
  RiAdminLine,
  RiSave3Fill,
  RiLogoutBoxRLine,
} from 'react-icons/ri';
import { ImNewspaper } from 'react-icons/im';
import { GoIssueReopened, GoLinkExternal, GoPlus } from 'react-icons/go';

const Accounts = RiAccountBoxLine;
const Admin = RiAdminLine;
const ArrowBack = IoIosArrowBack;
const ArrowDown = IoIosArrowDown;
const BarChart = FaChartBar;
const Cancel = MdOutlineCancel;
const CardsIcon = MdGridView;
const Check = ImCheckmark;
const CheckCircle = FaCheckCircle;
const Communication = FaSatelliteDish;
const Content = BiBookContent;
const Copy = RiFileCopy2Line;
const Create = MdOutlineCreate;
const Creative = FaCreativeCommonsShare;
const Cross = ImCross;
const Dashboard = MdOutlineDashboard;
const Delete = RiDeleteBin6Line;
const DocumentDownload = HiOutlineDocumentDownload;
const DownArrow = CgArrowDownR;
const Download = MdDownload;
const DropDownArrow = MdOutlineArrowDropDown;
const Edit = RiEdit2Line;
const ExclamationCircle = BsFillExclamationCircleFill;
const Export = BiExport;
const ExternalLink = GoLinkExternal;
const Feed = CgFeed;
const Filter = RiFilterLine;
const Hamburger = GiHamburgerMenu;
const Invoices = HiOutlineDocumentText;
const LineChart = FaChartLine;
const LinkURL = BiLinkAlt;
const Logout = RiLogoutBoxRLine;
const Money = FaRegMoneyBillAlt;
const News = ImNewspaper;
const Payment = MdOutlinePayments;
const QuestionCircleOutline = AiOutlineQuestionCircle;
const InfoCircleOutline = AiOutlineInfoCircle;
const Reopened = GoIssueReopened;
const Reply = ImReply;
const Report = HiOutlineDocumentReport;
const Resource = GrResources;
const Right = BiRightArrowAlt;
const RowsIcon = MdOutlineTableRows;
const Save = RiSave3Fill;
const Search = AiOutlineSearch;
const Support = BiSupport;
const SwitchIcon = IoIosSwitch;
const System = GrSystem;
const Tag = BsTagFill;
const TenantGrid = FaGripHorizontal;
const UpArrow = CgArrowUpR;
const Upload = BiUpload;
const UserCircle = FaUserCircle;
const View = RiEyeLine;
const QA = MdOutlineQuestionAnswer;
const Plus = GoPlus;
const Supervised = MdOutlineSupervisorAccount;
const BillingOff = MdPriceChange;
const BillingOn = MdOutlinePriceChange;
const EditNote = MdEditNote;

export {
  Accounts,
  Admin,
  ArrowBack,
  ArrowDown,
  BarChart,
  BillingOff,
  BillingOn,
  Cancel,
  CardsIcon,
  Check,
  CheckCircle,
  Copy,
  Communication,
  Content,
  Create,
  Creative,
  Cross,
  Dashboard,
  Delete,
  DocumentDownload,
  DownArrow,
  Download,
  DropDownArrow,
  Edit,
  EditNote,
  ExclamationCircle,
  Export,
  ExternalLink,
  Feed,
  Filter,
  Hamburger,
  InfoCircleOutline,
  Invoices,
  LineChart,
  LinkURL,
  Logout,
  Money,
  News,
  Payment,
  QA,
  QuestionCircleOutline,
  Reopened,
  Reply,
  Report,
  Resource,
  Right,
  RowsIcon,
  Save,
  Search,
  Support,
  SwitchIcon,
  System,
  Tag,
  TenantGrid,
  UpArrow,
  Upload,
  UserCircle,
  View,
  Plus,
  Supervised,
};
