import {
  Button,
  Card,
  Col,
  Collapse,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Pagination,
  Popover,
  Row,
  Select,
  Space,
  Spin,
  Switch,
  Table,
} from 'components/antd';
import { Check, Cross, Export, Filter as FilterIcon } from 'components/icons';
import { Content, TitleRow } from 'components/shared';
import ReportChart from './ReportChart';
import PaginationDefaults from 'constants/pagination';
import {
  AppliedFilter,
  Filter,
  ReportRequest,
  DrilldownData,
  ReportOverrideFilter,
  HiddenColumnsRequest,
} from 'hooks/Reporting/reports';
import useReports from 'hooks/Reporting/reports';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams /*, useSearchParams*/ } from 'react-router-dom';
import styled from 'styled-components';
import { _ } from 'numeral';
import { REPORT_CHART_CONFIG } from 'libs/chart';
import useUser from 'hooks/User';

const SummaryName = styled.span`
  color: grey;
`;

const SummaryValue = styled.span`
  font-size: 2.5em;
`;

const COLUMN_TYPE_DATE_FORMAT: any = {
  Date: 'DD/MM/YYYY',
  DateTime: 'DD/MM/YYYY HH:mm',
};
const FILTER_IDS_IGNORED: string[] = ['From', 'To'];
const CHARACTERS_IN_YEAR: number = 4;

type ReportPagination = {
  Current: number;
  PageSize: number;
  Total: number;
};

interface ReportParams {
  reportID?: string;
  reportFilterReplacements: ReportOverrideFilter[];
}

const Report = ({ reportID, reportFilterReplacements }: ReportParams) => {
  const [form] = Form.useForm();
  const { id = reportID ? reportID : '' } = useParams();
  // const [searchParams, setSearchParams] = useSearchParams();
  const [tableReportRequest, setTableReportRequest] = useState<ReportRequest>({
    ReportID: id,
    Filters: [],
  });
  const [chartReportRequest, setChartReportRequest] = useState<ReportRequest>({
    ReportID: id,
    Filters: [],
  });
  const [reportName, setReportName] = useState<string>('');
  const [reportColumns, setReportColumns] = useState<any[]>([]);
  const [reportData, setReportData] = useState<any[]>([]);
  const [reportFilters, setReportFilters] = useState<Filter[]>([]);
  const [reportPagination, setReportPagination] = useState<ReportPagination>({
    Current: PaginationDefaults.current,
    PageSize: PaginationDefaults.pageSize,
    Total: PaginationDefaults.total,
  });
  const [hiddenColumns, setHiddenColumns] = useState<string[]>([]);
  const { t } = useTranslation('reporting');
  const { t: c } = useTranslation('common');
  const { RangePicker } = DatePicker;
  const { downloadCSV, load, isDownloading, isLoading, report, saveColumns } =
    useReports();
  const [selectedMetric, setSelectedMetric] = useState<any>('Currency');
  const [reportDrilldownColumns, setReportDrilldownColumns] = useState<any[]>(
    []
  );
  const [reportDrilldownData, setReportDrilldownData] = useState<any[]>([]);
  const hasConfig = id ? REPORT_CHART_CONFIG[id] : false;
  const [showCharts, setShowCharts] = useState<boolean>(hasConfig);
  const [hasDateRangeFilter, setHasDateRangeFilter] = useState<boolean>(true);
  const { user } = useUser();

  const dateRanges: any = {};
  dateRanges[t('this.week')] = [
    moment().startOf('week'),
    moment().endOf('week'),
  ];
  dateRanges[t('last.week')] = [
    moment().subtract(1, 'week').startOf('week'),
    moment().subtract(1, 'week').endOf('week'),
  ];
  dateRanges[t('this.month')] = [
    moment().startOf('month'),
    moment().endOf('month'),
  ];
  dateRanges[t('last.month')] = [
    moment().subtract(1, 'month').startOf('month'),
    moment().subtract(1, 'month').endOf('month'),
  ];
  dateRanges[t('this.year')] = [
    moment().startOf('year'),
    moment().endOf('year'),
  ];
  dateRanges[t('last.year')] = [
    moment().subtract(1, 'year').startOf('year'),
    moment().subtract(1, 'year').endOf('year'),
  ];

  const fetchData = (params: any) => {
    load(params).catch(() => {
      message.error(t('reporting.fetch.error'));
    });
  };

  const gatherAppliedFilters = (values: any): AppliedFilter[] => {
    // Remove daterange from filters
    const filters = Object.keys(values)
      .filter((k) => 'DateRange' !== k)
      .map((k) => ({ ID: k, Value: values[k] }));

    if (hasDateRangeFilter) {
      const fromFilter: AppliedFilter = {
        ID: 'From',
        Value: values['DateRange'][0]?.toISOString(),
      };
      const toFilter: AppliedFilter = {
        ID: 'To',
        Value: values['DateRange'][1]?.toISOString(),
      };

      // Add the correct ones
      filters.push(fromFilter);
      filters.push(toFilter);
    }

    return filters;
  };

  const formatCurrency = (value: any) =>
    `${t('currency.symbol')}${formatNumber(value)}`;

  const formatNumber = (value: any) =>
    `${c('number', {
      value: value,
    })}`;

  const formatPercentage = (value: any) => `${parseFloat(value).toFixed(2)}%`;

  const formatValue = (type: string, value: string) => {
    if (type === 'Currency') return formatCurrency(value);
    if (type === 'Percentage') return formatPercentage(value);
    if (type === 'Integer') return formatNumber(value);

    if (
      Object.keys(COLUMN_TYPE_DATE_FORMAT).includes(type) &&
      value.length > CHARACTERS_IN_YEAR &&
      moment.utc(value).isValid()
    ) {
      return moment.utc(value).format(COLUMN_TYPE_DATE_FORMAT[type]);
    }

    return value;
  };

  const buildInitialFilters = (): AppliedFilter[] => {
    if (reportFilterReplacements.length > 0) {
      let filters = Object.keys(reportFilterReplacements).map((k: any) => ({
        ID: reportFilterReplacements[k].filterName,
        Value: reportFilterReplacements[k].filterValue,
      }));

      return filters;
    }

    return [];
  };

  const resetReport = () => {
    const initialFilters = buildInitialFilters();

    form.resetFields();
    setReportPagination({
      Current: PaginationDefaults.current,
      PageSize: PaginationDefaults.pageSize,
      Total: PaginationDefaults.total,
    });
    setTableReportRequest({
      ...tableReportRequest,
      Filters: initialFilters,
      ReportID: id,
      Current: PaginationDefaults.current,
      PageSize: PaginationDefaults.pageSize,
    });
    setChartReportRequest({
      ...tableReportRequest,
      Filters: initialFilters,
      ReportID: id,
      Current: PaginationDefaults.current,
      PageSize: PaginationDefaults.pageSize,
    });
  };

  useEffect(() => {
    setHiddenColumns([]);
    resetReport();
    setSelectedMetric('Currency');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, reportFilterReplacements]);

  useEffect(() => {
    if (tableReportRequest.Current === undefined) return;

    fetchData({
      ...tableReportRequest,
      Current: reportPagination.Current,
      PageSize: reportPagination.PageSize,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableReportRequest]);

  useEffect(() => {
    if (!report) return;

    const dataArray = report.Body.map((row) => {
      var r: any = { key: report.Body.indexOf(row) };
      report.Header.Columns.forEach((column) => {
        if (column.IsParentKey) {
          // If the column is the Parent Key for a DrillDown report, we need to assume this is unique, and should be used as the key in the table
          r.key = formatValue(
            column.Type,
            row[report.Header.Columns.indexOf(column)]
          );
        }

        if (column.InternalUse) return;

        r[column.ID] = formatValue(
          column.Type,
          row[report.Header.Columns.indexOf(column)]
        );
      });
      return r;
    });

    const columnsArray = report.Header.Columns.filter(
      (column) => !column.InternalUse && !hiddenColumns.includes(column.ID)
    ).map((column) => ({
      dataIndex: column.ID,
      ellipsis: column.Type === 'String',
      title: column.Name,
      className:
        column.DisplayClass !== undefined ? column.DisplayClass : undefined,
      key: Math.floor(Math.random() * 10000) + 1,
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      sortOrder:
        report.Header.Sort.ColumnID === column.ID
          ? report.Header.Sort.Ascending
            ? 'ascend'
            : 'descend'
          : undefined,
    }));

    const buildReportFilters = () => {
      let originalFilters = report.Header.Filters;

      if (reportFilterReplacements.length > 0) {
        let filters = Object.keys(originalFilters).map((k: any) => ({
          ID: originalFilters[k].ID,
          Name: originalFilters[k].Name,
          Type: originalFilters[k].Type,
          Value: reportFilterReplacements.find(
            (f) => f.filterName === originalFilters[k].ID
          )
            ? reportFilterReplacements.find(
                (f) => f.filterName === originalFilters[k].ID
              )?.filterValue
            : originalFilters[k].Value,
          Values: originalFilters[k].Values,
        }));

        return filters;
      }

      return originalFilters;
    };

    setReportName(report.Header.ReportName);
    setReportFilters(buildReportFilters);
    setReportColumns(columnsArray);
    setReportData(dataArray);
    setReportPagination({
      Current: Math.floor(report.Header.Offset / report.Header.Limit) + 1,
      PageSize: report.Header.Limit,
      Total: report.Header.Total,
    });
    if (hiddenColumns.length == 0) {
      if (report.HiddenColumns && report.HiddenColumns !== undefined) {
        setHiddenColumns(report.HiddenColumns.split(','));
      }
    }

    setHasDateRangeFilter(
      report.Header.Filters.find((f) => f.ID === 'From') !== undefined &&
        report.Header.Filters.find((f) => f.ID === 'To') !== undefined
    );

    if (report.DrillDownReport.Columns != null) {
      const dataDrilldownArray = report.DrillDownReport.ReportData.map(
        (row) => {
          const thisDataRows = row.DataRows.map((drillDownRow) => {
            var r: any = {
              key: row.ParentKey + '_' + row.DataRows.indexOf(drillDownRow),
            };
            report.DrillDownReport.Columns.forEach((column) => {
              if (column.InternalUse) return;

              r[column.ID] = formatValue(
                column.Type,
                drillDownRow[report.DrillDownReport.Columns.indexOf(column)]
              );
            });
            return r;
          });

          var rd: DrilldownData = {
            ParentKey: row.ParentKey,
            DataRows: thisDataRows,
          };

          return rd;
        }
      );

      const columnsDrilldownArray = report.DrillDownReport.Columns.filter(
        (column) => !column.InternalUse
      ).map((column) =>
        column.Type === 'Boolean'
          ? {
              dataIndex: column.ID,
              ellipsis: false,
              title: column.Name,
              key: Math.floor(Math.random() * 10000) + 1,
              sorter: false,
              render: (track: boolean) => (track ? <Check /> : <Cross />),
            }
          : {
              dataIndex: column.ID,
              ellipsis: column.Type === 'String',
              title: column.Name,
              key: Math.floor(Math.random() * 10000) + 1,
              sorter: false,
            }
      );

      setReportDrilldownColumns(columnsDrilldownArray);
      setReportDrilldownData(dataDrilldownArray);
    } else {
      setReportDrilldownColumns([]);
      setReportDrilldownData([]);
    }

    form.setFieldsValue({
      DateRange: [
        moment(report.Header.Filters.find((f) => f.ID === 'From')?.Value),
        moment(report.Header.Filters.find((f) => f.ID === 'To')?.Value),
      ],
    });

    if (reportFilterReplacements.length > 0) {
      reportFilterReplacements.forEach((filterReplacement) => {
        if (filterReplacement.filterName === 'NewsArticleID') {
          form.setFieldsValue({
            NewsArticleID: filterReplacement.filterValue.toString(),
          });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report, hiddenColumns]);

  //
  // CHANGE HANDLERS
  //

  const onTableChanged = (pagination: any, filter: any, sorter: any) => {
    setTableReportRequest({
      ...tableReportRequest,
      Ascending: sorter.order === 'ascend',
      SortBy: sorter.field,
    });
  };

  function onPageChanged(current: number, pageSize?: number) {
    fetchData({
      ...tableReportRequest,
      Current: current,
      PageSize: pageSize || PaginationDefaults.pageSize,
    });
  }

  //
  // FILTER FACTORY
  //
  const getFilterComponents = (filters: Filter[]) => {
    return filters
      .filter((f) => FILTER_IDS_IGNORED.indexOf(f.ID) < 0)
      .map((filter) => (
        <Col key={filter.ID} span={8}>
          {getFilterComponent(filter)}
        </Col>
      ));
  };

  const getFilterComponent = (filter: Filter) => {
    const hasValues = filter.Values && filter.Values.length > 0;

    let component = null;
    if (hasValues)
      component = (
        <Select
          disabled={isLoading}
          allowClear={true}
          optionFilterProp="label"
          options={filter?.Values?.map((v) => ({
            label: v.Display,
            value: v.Value,
          }))}
          showSearch
        />
      );

    if (component == null)
      switch (filter.Type.toLowerCase()) {
        case 'integer':
          component = (
            <InputNumber
              disabled={isLoading}
              min={0}
              style={{ width: '100%' }}
            />
          );
          break;
        case 'string':
          component = <Input allowClear={true} disabled={isLoading} />;
          break;
      }

    return (
      <Form.Item
        label={filter.Name}
        labelCol={{ span: 10 }}
        labelAlign="right"
        name={filter.ID}
        wrapperCol={{ span: 14 }}
      >
        {component}
      </Form.Item>
    );
  };

  const onFormSubmit = async (values: any) => {
    try {
      const fields = await form.validateFields();
      const filters = gatherAppliedFilters(fields);

      setTableReportRequest({
        ...tableReportRequest,
        Filters: filters,
        Current: 1,
      });
      setChartReportRequest({
        ...chartReportRequest,
        Filters: filters,
        Current: 1,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const setMetricForReport = (metric: any) => {
    setSelectedMetric(metric);
  };

  const DrillDownReportView = (record: any) => {
    var drilldownReportData: any = reportDrilldownData.filter(
      (ag) => ag.ParentKey === record.key
    );

    if (drilldownReportData.length > 0) {
      drilldownReportData = drilldownReportData[0].DataRows;
    }

    return (
      <Table
        size="small"
        rowKey="key"
        columns={reportDrilldownColumns}
        dataSource={drilldownReportData}
        pagination={false}
      />
    );
  };

  const setAndRecordHiddenColumns = (columns: string[]) => {
    setHiddenColumns(columns);

    var columnCall: HiddenColumnsRequest = {
      UserID: user.id,
      Columns: columns.toString(),
    };

    saveColumns(columnCall, id);
  };

  return (
    <Content>
      <TitleRow>
        <h1>
          {t('reporting')} - {reportName}
        </h1>
        <Space>
          <Spin
            style={{ marginRight: '8px' }}
            size="small"
            spinning={isDownloading}
          />
          <Button
            disabled={isDownloading || isLoading}
            icon={<Export style={{ marginRight: '4px' }} />}
            onClick={() => {
              downloadCSV({
                ...tableReportRequest,
                Current: undefined,
                PageSize: undefined,
              });
            }}
          >
            {t('export.to.csv')}
          </Button>
        </Space>
      </TitleRow>
      <Row
        justify="center"
        style={{
          paddingTop: '2em',
        }}
      >
        <Col span={24}>
          <Form
            className="ant-advanced-search-form"
            component={false}
            form={form}
            onFinish={onFormSubmit}
          >
            <Row gutter={24}>
              {hasDateRangeFilter ? (
                <Col span={8}>
                  <Form.Item
                    label={c('dateRange')}
                    name="DateRange"
                    labelCol={{ span: 10 }}
                    labelAlign="right"
                    wrapperCol={{ span: 14 }}
                    rules={[{ required: true }]}
                  >
                    <RangePicker
                      disabled={isLoading}
                      ranges={dateRanges}
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                </Col>
              ) : (
                <></>
              )}
              {getFilterComponents(reportFilters)}
            </Row>
            <Row gutter={24} justify="end">
              <Form.Item>
                <Space>
                  <Button
                    disabled={isLoading}
                    style={{ marginRight: '1em' }}
                    onClick={() => {
                      resetReport();
                    }}
                    icon={
                      <Cross
                        style={{ marginRight: '4px', marginBottom: '-2px' }}
                      />
                    }
                  >
                    {c('reset')}
                  </Button>
                </Space>
                <Space>
                  <Button
                    disabled={isLoading}
                    style={{ marginRight: '1em' }}
                    htmlType="submit"
                    icon={
                      <FilterIcon
                        style={{ marginRight: '4px', marginBottom: '-2px' }}
                      />
                    }
                    onClick={onFormSubmit}
                  >
                    {c('filter')}
                  </Button>
                </Space>
              </Form.Item>
            </Row>
          </Form>
        </Col>
      </Row>

      {report !== undefined && (showCharts || report.Summaries.length > 0) ? (
        <Card style={{ marginTop: '0em' }}>
          <>
            <ReportChart
              reportRequest={chartReportRequest}
              setMetricForReport={setMetricForReport}
              selectedMetric={selectedMetric}
            />
            {report !== undefined && report.Summaries.length > 0 && (
              <Collapse defaultActiveKey={['1']}>
                <Collapse.Panel header={t('summary')} key="1">
                  <Row justify="center">
                    <Spin spinning={isLoading} />

                    {!isLoading &&
                      report.Summaries.map((s) => (
                        <Col key={s.Name}>
                          <Row justify="center">
                            <Col span={24} style={{ textAlign: 'center' }}>
                              <SummaryName>{s.Name}</SummaryName>
                            </Col>

                            <Col span={24} style={{ textAlign: 'center' }}>
                              <SummaryValue>
                                {formatValue(s.Type, s.Value)}
                              </SummaryValue>
                            </Col>
                          </Row>
                        </Col>
                      ))}
                  </Row>
                </Collapse.Panel>
              </Collapse>
            )}
          </>
        </Card>
      ) : (
        <></>
      )}
      <Card style={{ marginTop: '1em' }}>
        <Row justify="center">
          {report !== undefined && report.Header.RemovePagination ? (
            <>
              <Col span={21}></Col>
            </>
          ) : (
            <>
              <Col span={3}></Col>
              <Col span={18} style={{ textAlign: 'center' }}>
                <Pagination
                  disabled={isLoading}
                  onChange={onPageChanged}
                  current={
                    reportPagination.Current || PaginationDefaults.current
                  }
                  pageSize={
                    reportPagination.PageSize || PaginationDefaults.pageSize
                  }
                  total={reportPagination.Total || PaginationDefaults.total}
                />
              </Col>
            </>
          )}
          <Col span={3} style={{ textAlign: 'right' }}>
            <Popover
              content={
                <>
                  {report !== undefined &&
                    report?.Header.Columns.filter(
                      (column) => !column.InternalUse
                    ).map((column) => (
                      <Row
                        key={column.ID}
                        style={{ marginBottom: '4px', width: '250px' }}
                      >
                        <Col span={18}>{column.Name}</Col>
                        <Col span={6} style={{ textAlign: 'right' }}>
                          <Switch
                            checked={!hiddenColumns.includes(column.ID)}
                            onClick={(checked) => {
                              if (checked) {
                                setAndRecordHiddenColumns(
                                  hiddenColumns.filter(
                                    (cid) => cid !== column.ID
                                  )
                                );
                                return;
                              }

                              setAndRecordHiddenColumns([
                                ...hiddenColumns,
                                column.ID,
                              ]);
                            }}
                          />
                        </Col>
                      </Row>
                    ))}
                </>
              }
              title={t('show.hide.columns')}
              trigger="click"
            >
              <Button disabled={isLoading}>{t('columns')}</Button>
            </Popover>
          </Col>
        </Row>
        <Row justify="center" style={{ marginTop: '2em' }}>
          <Col span={24}>
            <Table
              columns={reportColumns as any}
              dataSource={reportData}
              onChange={onTableChanged}
              pagination={false}
              loading={isLoading}
              expandable={{
                expandedRowRender: DrillDownReportView,
                rowExpandable: (val: any) =>
                  reportDrilldownData.some((ag) => ag.ParentKey === val.key),
              }}
            />
          </Col>
        </Row>
        {report !== undefined && !report.Header.RemovePagination ? (
          <Row justify="center" style={{ marginTop: '2em' }}>
            <Col span={3}></Col>
            <Col span={18} style={{ textAlign: 'center' }}>
              <Pagination
                disabled={isLoading}
                onChange={onPageChanged}
                current={reportPagination.Current || PaginationDefaults.current}
                pageSize={
                  reportPagination.PageSize || PaginationDefaults.pageSize
                }
                total={reportPagination.Total || PaginationDefaults.total}
              />
            </Col>
            <Col span={3}></Col>
          </Row>
        ) : (
          <></>
        )}
      </Card>
    </Content>
  );
};

export default Report;
