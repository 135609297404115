import { useTranslation } from 'react-i18next';

import { Card, Form, Select, message, Row } from 'components/antd';
import { Content } from 'components/shared';

import CommissionTable from './components/CommissionTable';
import useFinancialCommissions from 'hooks/Commissions/financialCommissions';
import { useEffect, useState } from 'react';

const Page = () => {
  const { t } = useTranslation('commissions');
  const [group, setGroup] = useState<string>();

  const { load, loadGroups, commissions, commissionGroups, isLoading } =
    useFinancialCommissions();

  useEffect(() => {
    loadGroups().catch((err) => {
      message.error('Error loading commission groups');
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    load({ commissionGroupId: group }).catch((err) => {
      message.error('Error loading commissions');
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group]);

  return (
    <Content>
      <h1>{t('commissions')}</h1>

      <Row justify="center" style={{ paddingTop: '2em', paddingBottom: '2em' }}>
        <Form layout="inline">
          <Form.Item name="commissionGroupId">
            <Select
              allowClear
              style={{ width: 210 }}
              placeholder={t('comissionGroupPlaceholder')}
              options={commissionGroups.map((x) => ({
                label: x.name,
                value: x.id,
              }))}
              onChange={(value: string) => setGroup(value)}
            />
          </Form.Item>
        </Form>
      </Row>

      <Card>
        <CommissionTable
          isLoading={isLoading}
          data={commissions}
          groups={commissionGroups}
        />
      </Card>
    </Content>
  );
};

export default Page;
