import { PrivatePage } from 'components/shared';
import { Route, Routes } from 'react-router';
import Report from './Report';

import { Features } from 'constants/features';
import { Permissions } from 'constants/permissions';

const Page = () => {
  return (
    <Routes>
      <Route
        path="/:id"
        element={
          <PrivatePage
            feature={Features.Reporting}
            requirement={Permissions.Reporting}
          >
            <Report reportID="" reportFilterReplacements={[]} />
          </PrivatePage>
        }
      />
    </Routes>
  );
};

export default Page;
