import {
  Button,
  Card,
  Row,
  Form,
  Input,
  InputNumber,
  Switch,
  Select,
  Table,
  Space,
  message,
  Tooltip,
} from 'components/antd';
import { Cross, Edit, Save, Search } from 'components/icons';
import { TitleRow } from 'components/shared';
import { SearchProduct } from 'hooks/Product/productHighlighting';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  isConfigLoading: boolean;
  maxHighlightedProducts: number;
  searchingForProducts: boolean;
  highlightedProducts: any;
  pagination: any;
  searchedProducts: any;
  savingProducts: boolean;
  saveHighlighted: Function;
  fetchData: Function;
  fetchConfig: Function;
  searchForProducts: Function;
  saveSearchProductChanges: Function;
}

const AddHighlightedProducts = ({
  isConfigLoading,
  maxHighlightedProducts,
  searchingForProducts,
  highlightedProducts,
  pagination,
  searchedProducts,
  savingProducts,
  saveHighlighted,
  fetchData,
  fetchConfig,
  searchForProducts,
  saveSearchProductChanges,
}: Props) => {
  const { t } = useTranslation('productHighlighting');
  const { t: c } = useTranslation('common');

  const [search, setSearch] = useState<string>('');
  const [editingSearchKey, setEditingSearchKey] = useState<string>('');
  const [highlightedProductsToAdd, setHighlightedProductsToAdd] = useState(
    [] as any[]
  );
  const [searchColumn, setSearchColumn] = useState<string>('$name');
  const [searchType, setSearchType] = useState<string>('in');
  const [newProdForm] = Form.useForm();

  const { Option } = Select;

  const isEditingSearchProduct = (product: SearchProduct) =>
    product.externalId === editingSearchKey;

  const fetchNewData = () => {
    setEditingSearchKey('');
    highlightedProductsToAdd.splice(0, highlightedProductsToAdd.length);
    fetchData();
  };

  const resetSearchProducts = () => {
    setEditingSearchKey('');
    newProdForm.resetFields();
    setHighlightedProductsToAdd([]);
    searchForProducts(pagination, searchType, searchColumn, search);
  };

  const updateSearchProducts = (products: SearchProduct[]) => {
    saveSearchProductChanges(products);
    setEditingSearchKey('');
    newProdForm.resetFields();
  };

  useEffect(() => {
    fetchNewData();
    fetchConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSearchColumns = [
    {
      title: t('highlightProduct'),
      dataIndex: 'productHighlighted',
      key: 'productHighlighted',
      fixed: 'left',
      editable: true,
      inputType: 'switch',
      render: (value: boolean) => {
        return <Switch checked={value} disabled />;
      },
    },
    {
      title: t('productSKU'),
      dataIndex: 'externalId',
      key: 'productSKU',
      fixed: 'left',
      editable: false,
      inputType: 'none',
    },
    {
      title: t('productName'),
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
      editable: false,
      inputType: 'none',
    },
    {
      title: t('commissionValue'),
      dataIndex: 'commission',
      key: 'commission',
      fixed: 'left',
      editable: true,
      inputType: 'input',
    },
    {
      title: c('action'),
      dataIndex: '',
      align: 'right',
      key: '',
      width: 200,
      render: (af: SearchProduct, _: SearchProduct, index: number) => {
        const editable = isEditingSearchProduct(af);
        return editable ? (
          <Space>
            <Tooltip title={t('saveEdit')}>
              <Button
                icon={
                  <Save
                    style={{
                      marginLeft: -3,
                      marginBottom: -3,
                      fontSize: '1.1em',
                    }}
                  />
                }
                onClick={() => saveSearchedProduct(af)}
              />
            </Tooltip>
            <Tooltip title={t('cancelEdit')}>
              <Button
                onClick={cancelSearchedProduct}
                icon={
                  <Cross
                    style={{
                      marginLeft: -2,
                      marginBottom: -2,
                      fontSize: '0.9em',
                    }}
                  />
                }
              />
            </Tooltip>
          </Space>
        ) : (
          <Space>
            <Tooltip title={t('editSearched')}>
              <Button
                onClick={() => editSearchedProduct(af)}
                icon={<Edit style={{ marginLeft: -2, marginBottom: -2 }} />}
              />
            </Tooltip>
          </Space>
        );
      },
    },
  ];

  const mergedSearchColumns = getSearchColumns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (product: any) => ({
        product,
        inputType: col.inputType,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditingSearchProduct(product),
      }),
    };
  });

  const editSearchedProduct = (product: SearchProduct) => {
    newProdForm.setFieldsValue({ ...product });
    setEditingSearchKey(product.externalId);
  };

  const cancelSearchedProduct = () => {
    setEditingSearchKey('');
    newProdForm.resetFields();
  };

  const saveSearchedProduct = async (product: SearchProduct) => {
    // Validate field and prevent save if not correct
    try {
      await newProdForm.validateFields();
    } catch (err) {
      return;
    }

    // Assign const with old search results
    const oldSearchResults = searchedProducts;

    // Get the values for if the product has been highlighted and the commission value entered
    const editedFieldsValues = newProdForm.getFieldsValue();

    // Get the Id of the product being edited
    const currentProductId = product.externalId;

    // Get index of product being edited
    const indexOfCurrentProduct = searchedProducts.findIndex(
      (productsObj: any) => {
        return productsObj.externalId === currentProductId;
      }
    );

    // Update the commission value in the searched products using the index of the product
    // If it hasn't been updated it will be saved as the original
    // We have to update the data in this way for the table to recognise the update
    const editedSearchResults = [...oldSearchResults];
    editedSearchResults[indexOfCurrentProduct] = {
      ...editedSearchResults[indexOfCurrentProduct],
      commission: editedFieldsValues.commission,
      productHighlighted: editedFieldsValues.productHighlighted,
    };

    updateSearchProducts(editedSearchResults);

    // If Highlight Product has been selected and isnt already in the array add the product to the highlightedProductsToAdd array
    if (
      editedFieldsValues.productHighlighted === true &&
      !highlightedProductsToAdd.some((e: any) => e.id === product.id)
    ) {
      // Create object for product (without order as this is done when saving list)
      const newProductToHighlight = {
        id: product.id,
        commission: editedFieldsValues.commission,
      };

      const oldHighlightedProductsToAddArr = highlightedProductsToAdd;

      const newHighlightedProductsToAddArr: any = [
        ...oldHighlightedProductsToAddArr,
        newProductToHighlight,
      ];

      setHighlightedProductsToAdd(newHighlightedProductsToAddArr);
    } else if (
      editedFieldsValues.productHighlighted === false &&
      highlightedProductsToAdd.some((e: any) => e.id === product.id)
    ) {
      // If highlightedProductsToAddArr contains the current product id and is set as false - remove it
      // Get index of produc to remove
      const indexOfCurrentProduct = highlightedProductsToAdd.findIndex(
        (productsObj: any) => {
          return productsObj.id === product.id;
        }
      );

      // Remove product based on Id
      // Immutable removal using spread operator so the state change is recognised
      const newHighlightedProductsToAddArr = [
        ...highlightedProductsToAdd.slice(0, indexOfCurrentProduct),
        ...highlightedProductsToAdd.slice(
          indexOfCurrentProduct + 1,
          highlightedProductsToAdd.length
        ),
      ];

      // Update products array
      setHighlightedProductsToAdd(newHighlightedProductsToAddArr);
    }
  };

  const searchProducts = (searchString: string) => {
    // Removes any pending products when starting a new search
    setHighlightedProductsToAdd([]);

    // New search term so reset pagination page number back to 1
    pagination.current = 1;

    // Set the search value
    setSearch(searchString);

    searchForProducts(pagination, searchType, searchColumn, searchString);
  };

  const saveProductsToHighlighted = () => {
    // Get current number of highlighted products, increase by 1 as the order num starts at 1 not 0
    // Loop over current products to see if any products being added are already in the list
    for (let i = 0; i < highlightedProductsToAdd.length; i++) {
      if (
        highlightedProducts.some(
          (e: any) => e.id === highlightedProductsToAdd[i].id
        )
      ) {
        // Remove product based on Id
        const newHighlightedProductsToAddArr = highlightedProductsToAdd;

        newHighlightedProductsToAddArr.splice(i, 1);
        setHighlightedProductsToAdd(newHighlightedProductsToAddArr);
      }
    }

    // Loop through new products adding an order value based on the above
    // Get the amount of current of current highlighted products
    let orderCount = highlightedProducts.length + 1;
    highlightedProductsToAdd.forEach(function (product: any) {
      product.order = orderCount;
      orderCount++;
    });

    // Clean up current highlighed products (remove uncessary key/value pairs)
    const formattedHighlightedProducts = highlightedProducts;

    formattedHighlightedProducts.forEach(function (product: any) {
      delete product.name;
      delete product.externalId;
      delete product.attributes;
    });

    // Create a list of current and new highlighted products
    const mergedHighlightedProducts = [
      ...formattedHighlightedProducts,
      ...highlightedProductsToAdd,
    ];

    // Call the end point (POST) with updaterd list containg new products
    saveHighlighted(mergedHighlightedProducts)
      .then(() => {
        message.success(t('highlighting.save.success'));
        resetSearchProducts();
      })
      .catch((response: any) => {
        message.error(
          t('highlighting.save.error', {
            error: response?.data?.message || response?.data?.title,
          })
        );
      });
  };

  const paginationChangeHandler = (pagination: any) => {
    // Removes any pending products when moving between pages
    setHighlightedProductsToAdd([]);

    searchForProducts(pagination, searchType, searchColumn, search);
  };

  const EditableSearchCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    product,
    index,
    children,
    ...restProps
  }: any) => {
    const inputNode =
      inputType === 'switch' ? (
        <Switch />
      ) : (
        <InputNumber type="number" min={0.01} />
      );
    return (
      <td {...restProps}>
        {editing ? (
          inputType === 'switch' ? (
            <Form.Item
              name={dataIndex}
              style={{
                margin: 0,
              }}
              valuePropName={'checked'}
              initialValue={
                product.productHighlighted === undefined
                  ? false
                  : product.productHighlighted
              }
              rules={[
                {
                  required: true,
                },
              ]}
            >
              {inputNode}
            </Form.Item>
          ) : (
            <Form.Item
              name={dataIndex}
              style={{
                margin: 0,
              }}
              valuePropName={'value'}
              rules={[
                {
                  required: true,
                  message: t('invalidCommissionValueShort'),
                  pattern: new RegExp(
                    /^0*[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*\d*(\.\d{0,2})?$/
                  ),
                },
              ]}
            >
              {inputNode}
            </Form.Item>
          )
        ) : (
          children
        )}
      </td>
    );
  };

  return (
    <Card style={{ marginTop: '1.5em' }} loading={isConfigLoading}>
      <TitleRow>
        <h2>{t('addProductsForHighlighting')}</h2>
      </TitleRow>
      <Row justify="center" style={{ marginTop: '1em' }}>
        <p>
          {t('maxNum')}
          <b>{maxHighlightedProducts}</b>
        </p>
      </Row>

      <Row justify="center" style={{ marginTop: '1em' }}>
        <p>
          {t('currently')} <b>{highlightedProducts.length}</b>{' '}
          {t('highlightedLowerCase')}
        </p>
      </Row>

      <Row justify="center" style={{ marginTop: '1em' }}>
        {maxHighlightedProducts - highlightedProducts.length === 0 && (
          <p>
            <b>{t('maxReached')}</b>
          </p>
        )}
        {maxHighlightedProducts - highlightedProducts.length === 1 && (
          <p>
            <b>{maxHighlightedProducts - highlightedProducts.length}</b>
            {t('productCanBe')}
          </p>
        )}
        {maxHighlightedProducts - highlightedProducts.length > 1 && (
          <p>
            <b>{maxHighlightedProducts - highlightedProducts.length}</b>
            {t('productsCanBe')}
          </p>
        )}
      </Row>
      {maxHighlightedProducts - highlightedProducts.length > 0 && (
        <>
          <Row justify="center" style={{ marginTop: '1em' }}>
            <Form.Item
              name="search_field"
              label={t('searchType')}
              style={{ marginLeft: '1em' }}
            >
              <Select
                style={{ width: 200, marginRight: 15 }}
                onChange={(value: string) => value && setSearchType(value)}
                defaultValue="in"
              >
                <Option value="in">{t('exactMultiple')}</Option>
                <Option value="like">{t('like')}</Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="search_field"
              label={t('searchColumn')}
              style={{ marginLeft: '1em' }}
            >
              <Select
                style={{ width: 200, marginRight: 15 }}
                onChange={(value: string) => value && setSearchColumn(value)}
                defaultValue="$name"
              >
                <Option value="$name">Product Name</Option>
                <Option value="$externalId">Product SKU</Option>
              </Select>
            </Form.Item>
          </Row>
          <Row justify="center" style={{ marginTop: '1em' }}>
            <Form.Item name="search">
              <Input.Search
                style={{ maxWidth: 700, minWidth: 635 }}
                value={search}
                onSearch={(value: string) => searchProducts(value)}
                placeholder={t('searchString')}
                allowClear
                enterButton={
                  <Search style={{ marginTop: 5, fontSize: '1.2em' }} />
                }
              />
            </Form.Item>
          </Row>
          <Form form={newProdForm} layout="vertical">
            <Table
              style={{ marginTop: '2em' }}
              components={{
                body: {
                  cell: EditableSearchCell,
                },
              }}
              rowKey="productSKU"
              columns={mergedSearchColumns as any}
              dataSource={searchedProducts}
              loading={searchingForProducts}
              pagination={pagination}
              onChange={paginationChangeHandler}
            />
            <Form.Item style={{ textAlign: 'center' }}>
              <Space>
                {highlightedProductsToAdd.length === 0 ||
                highlightedProductsToAdd.length >
                  maxHighlightedProducts - highlightedProducts.length ? (
                  <Button
                    style={{ marginTop: '1.5em' }}
                    loading={savingProducts}
                    onClick={saveProductsToHighlighted}
                    type="primary"
                    disabled
                  >
                    {t('saveToHighlight')}
                  </Button>
                ) : (
                  <Button
                    style={{ marginTop: '1.5em' }}
                    loading={savingProducts}
                    onClick={saveProductsToHighlighted}
                    type="primary"
                  >
                    {t('saveToHighlight')}
                  </Button>
                )}
              </Space>
            </Form.Item>
          </Form>
        </>
      )}
    </Card>
  );
};

export default AddHighlightedProducts;
