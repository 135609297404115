import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import moment from 'moment';
import { Button, Card } from 'components/antd';
import TagList from 'components/shared/TagList';
import { News } from 'hooks/Resources/news';
import { Right } from 'components/icons';
import { Link } from 'react-router-dom';
import { useState } from 'react';

type Props = {
  items: News[];
};

const Title = styled.h3`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-width: 100%;
  max-height: 3em;
  min-height: 3em;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Content = styled.div`
  display: -webkit-box;
  /* -webkit-line-clamp: 3; */
  -webkit-box-orient: vertical;
  max-width: 100%;
  max-height: 5em;
  height: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 1em;
`;

const Image = styled.img`
  max-width: 100%;
  max-height: 90%;
  margin: 1em 0 0 0;
  width: 100%;
  height: 200px;
  min-height: 200px;
  object-fit: contain;
  border: calc(2 * var(--border-width-base)) solid var(--border-color-base);
`;

const Row = styled.div`
  display: flex;
  justify-content: baseline;
  margin-bottom: 1em;
`;

const FlexDiv = styled.div`
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2em;
  gap: 2em;

  .ant-card-body {
    height: 100%;
  }
`;

const RightAlign = styled.div`
  text-align: right;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const ArticleDate = styled.h4`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-width: 100%;
  font-size: 12px;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 1em;
`;

function CardView({ items = [] }: Props) {
  const { t } = useTranslation();
  const [errors, setErrors] = useState<any>({});
  return (
    <FlexDiv>
      {items.map((item) => (
        <Card key={item.articleID} style={{ width: 300 }}>
          <Column style={{ height: '100%' }}>
            <Title title={item.articleHeadline}>{item.articleHeadline}</Title>
            <Image
              alt={
                !errors[item.articleID] && item.articleHeadline
                  ? item.articleHeadline
                  : t('noImage')
              }
              src={
                !errors[item.articleID] && item.articleHeadline
                  ? item.articleImagePath
                  : '/assets/no-image.png'
              }
              onError={() => setErrors({ ...errors, [item.articleID]: true })}
            />
            <Row style={{ marginTop: '0.5em' }}>
              <TagList
                title={t('categories')}
                tagData={item.newsArticleCategories.map((cat) => ({
                  id: cat.newsCategoryID?.toString(),
                  label: cat.newsCategoryName,
                }))}
                tagMaxWidth={'100px'}
              />
            </Row>
            <Content
              dangerouslySetInnerHTML={{ __html: item.articleSubHeading }}
            />
            <RightAlign>
              <ArticleDate>
                {moment(item.createdDate).local().format('DD MMM yyyy HH:mm')}
              </ArticleDate>
              <Link to={`/news/${item.articleID}`}>
                <Button type="primary">
                  {t('readMore')}
                  <Right
                    style={{ fontSize: '1.3em', marginBottom: '-0.2em' }}
                  />
                </Button>
              </Link>
            </RightAlign>
          </Column>
        </Card>
      ))}
    </FlexDiv>
  );
}

export default CardView;
