// import useLayout from 'hooks/Layout';
// import { useTranslation } from 'react-i18next';
// import { useHistory } from "react-router";
import { useEffect } from 'react';
import { Route, Routes } from 'react-router';

import Commissions from './Commissions';
import Invoices from './Invoices';

function Page() {
  // const { t } = useTranslation();

  // const { setNav, setSidebar } = useLayout();
  // const history = useHistory();

  useEffect(() => {
    // setNav(false);
    // setSidebar(false);

    // const unblock = history.block((location, action) => {
    //   if (true) {
    //     return window.confirm("Navigate Back?");
    //   }

    //   return true;
    // });

    return () => {
      // setNav(true);
      // setSidebar(true);
      // unblock();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Routes>
      <Route path="/commissions" element={<Commissions />} />
      <Route path="/invoices" element={<Invoices />} />
    </Routes>
  );
}

export default Page;
