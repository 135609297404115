import { useContext } from 'react';

import { LayoutContext } from './Context';

const useLayout = () => {
  const state = useContext(LayoutContext);

  const {
    showSidebar,
    showNav,
    showLogoInNav,
    minimized,
    logoName,
    logoPath,
    setShowSidebar,
    setShowNav,
    setShowLogoInNav,
    setMinimized,
  } = state;

  const setSidebar = setShowSidebar;
  const setNav = setShowNav;
  const setLogoInNav = setShowLogoInNav;

  return {
    // Variables
    showSidebar,
    showNav,
    showLogoInNav,
    minimized,
    logoName,
    logoPath,
    // Methods
    setSidebar,
    setNav,
    setLogoInNav,
    setMinimized,
  };
};

export default useLayout;
