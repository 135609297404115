import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { Tabs, Card, Space, Button, Row } from 'components/antd';
import { Content, TitleRow } from 'components/shared';
import ViewType from 'constants/viewType';

import Latest from './Latest';
import Archive from './Archive';
import { CardsIcon, RowsIcon } from 'components/icons';

const { TabPane } = Tabs;

type Props = {
  isArchive?: boolean;
};

function Page({ isArchive = false }: Props) {
  const { t } = useTranslation('news');

  const location = useLocation();
  const navigate = useNavigate();

  const [current, setCurrent] = useState('');
  const [view, setView] = useState<ViewType>(ViewType.Card);

  useEffect(() => {
    if (location.pathname.toLowerCase().endsWith('archive')) {
      setCurrent('archive');
    } else {
      setCurrent('latestNews');
    }
  }, [location]);

  const onChange = (page: string) => {
    if (page === 'latestNews') navigate('/news');
    if (page === 'archive') navigate('/news/archive');
  };

  return (
    <Content>
      <TitleRow>
        <h1>{t('news')}</h1>
      </TitleRow>
      <Card bodyStyle={{ padding: 0 }}>
        <Tabs
          centered
          onChange={onChange}
          activeKey={current}
          tabBarStyle={{ marginBottom: 0 }}
        >
          <TabPane tab={t('latestNews')} key="latestNews">
            <Row justify="end" style={{ padding: '1em' }}>
              <Space>
                <div>{t('common:view')}:</div>
                <Button
                  value="row"
                  type={view === ViewType.Row ? 'primary' : 'default'}
                  onClick={() => setView(ViewType.Row)}
                  icon={<RowsIcon style={{ marginBottom: '-2px' }} />}
                  size="small"
                />

                <Button
                  value="card"
                  type={view === ViewType.Card ? 'primary' : 'default'}
                  onClick={() => setView(ViewType.Card)}
                  icon={<CardsIcon style={{ marginBottom: '-2px' }} />}
                  size="small"
                />
              </Space>
            </Row>
          </TabPane>
          <TabPane tab={t('archive')} key="archive" style={{ height: 0 }} />
        </Tabs>
      </Card>

      {!isArchive ? <Latest view={view} /> : <Archive />}
    </Content>
  );
}

export default Page;
