import { Form, Select, Button, FormInstance } from 'antd';
import { IRecipientGroup } from '../../../../../hooks/Communications/Models';
import type { IBulkEmailForm } from './IBulkEmailForm';

const RecipientGroupsSelect = (props: {
  label: string;
  recipientGroups: IRecipientGroup[];
  form: FormInstance<IBulkEmailForm>;
}) => {
  const clear = () => {
    props.form.setFieldsValue({
      affiliateGroups: [],
    });
  };

  const selectAll = () => {
    props.form.setFieldsValue({
      affiliateGroups: props.recipientGroups.map((rg) => rg.affiliateGroupID),
    });
  };

  return (
    <Form.Item
      label={props.label}
      rules={[{ required: true }]}
      name="affiliateGroups"
    >
      <Select
        mode="multiple"
        placeholder="Please select"
        allowClear
        dropdownRender={(menu) => (
          <>
            {menu}
            <div style={{ padding: '4px 6px', textAlign: 'center' }}>
              <Button style={{ marginRight: '4px' }} onClick={clear}>
                Clear
              </Button>
              <Button onClick={selectAll}>Select All</Button>
            </div>
          </>
        )}
      >
        {props.recipientGroups.map((grp) => (
          <Select.Option
            key={grp.affiliateGroupID}
            value={grp.affiliateGroupID}
          >
            {grp.affiliateGroupName}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default RecipientGroupsSelect;
