import styled from 'styled-components';

import { ExclamationCircle } from 'components/icons';

type Props = {
  oneLine?: boolean;
  children?: JSX.Element | JSX.Element[];
  showImage?: boolean;
  // All other props
  [x: string]: any;
};

const PaddedBox = styled.div`
  padding: 2em;
  display: flex;
  flex-direction: ${({ oneLine }: Props) => (oneLine ? 'row' : 'column')};
  ${({ oneLine }: Props) => oneLine && 'align-items: center'};
  background-color: var(--content-background);
  margin-bottom: 1em;
`;

const ImageContainer = styled.div`
  ${({ oneLine }: Props) =>
    !oneLine ? 'margin-right: 2em' : 'margin-bottom: 2em'};
`;

const InfoBox = ({ oneLine, children, showImage = true, ...props }: Props) => (
  <PaddedBox {...props} oneLine={oneLine}>
    {showImage && (
      <ImageContainer>
        <ExclamationCircle style={{ width: '3em', height: '3em' }} />
      </ImageContainer>
    )}

    {children}
  </PaddedBox>
);

export default InfoBox;
