import { PrivatePage } from 'components/shared';
import useBannerGroupsHook from 'hooks/Resources/bannerGroups';
import { Navigate, Route, Routes } from 'react-router';
import BannerForm from './BannerForm';
import List from './List';
import Groups from './Groups';

import { Permissions } from 'constants/permissions';

const Page = () => {
  const useBannerGroups = useBannerGroupsHook();

  return (
    <Routes>
      <Route
        path="/create"
        element={
          <PrivatePage
            requirement={Permissions['Resources.Banners.Admin']}
            component={BannerForm}
          />
        }
      />
      <Route
        path="/:id/edit"
        element={
          <PrivatePage
            requirement={Permissions['Resources.Banners.Admin']}
            component={BannerForm}
          />
        }
      />
      <Route
        path="/list"
        element={
          <PrivatePage
            requirement={Permissions['Resources.Banners.Basic']}
            component={List}
          />
        }
      />
      <Route
        path="/groups"
        element={
          <PrivatePage
            children={<Groups useBannerGroups={useBannerGroups} />}
            requirement={Permissions['Resources.BannerGroups.Admin']}
          />
        }
      />
      <Route index element={<Navigate to="list" />} />
    </Routes>
  );
};

export default Page;
