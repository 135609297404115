import useUser from './../../../../../hooks/User';
import { useState } from 'react';
import { Modal, Input, FormInstance } from 'antd';
import type { IBulkEmailForm } from './IBulkEmailForm';
import { QueueEmailRequest } from '../../Models/QueueEmailRequest';
import useCommunications from './../../../../../hooks/Communications/Emails';

const PreviewModal = (props: {
  form: FormInstance<IBulkEmailForm>;
  visible: boolean;
  onFinished: () => void;
  onCancel: () => void;
}) => {
  const { queueEmail, isAddingOrUpdatingEmail } = useCommunications();

  const { user } = useUser();
  const [email, setEmail] = useState(user.email);

  const previewEmail = async () => {
    const data = props.form.getFieldsValue();
    const request = new QueueEmailRequest(
      email,
      data.emailSubject,
      data.emailMessageHTML,
      data.emailFromAddress,
      data.emailFromName
    );
    await queueEmail(request);
    props.onFinished();
  };

  return (
    <Modal
      title="Preview Email"
      okText="Send"
      visible={props.visible}
      onCancel={props.onCancel}
      onOk={previewEmail}
      confirmLoading={isAddingOrUpdatingEmail}
    >
      <p> Enter the email address you want to send the preview email to:</p>
      <Input
        type="email"
        value={email}
        onChange={({ target }) => setEmail(target.value)}
      />
    </Modal>
  );
};

export default PreviewModal;
