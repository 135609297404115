import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button, Form, Input } from 'components/antd';
// import { InfoBox } from 'components/shared';
import { FormInstance } from 'antd';
import { AffiliateAddress } from 'hooks/Account/affiliateDetails';

const Columns = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 992px) {
    display: grid;
    grid-template-columns: 1fr 400px;
  }
`;

type Props = {
  showSubmitButton?: boolean;
  onFinish?: (values: any) => any;
  form?: FormInstance<any>;
  address?: AffiliateAddress;
  readonly?: boolean;
};

function Page({
  onFinish,
  showSubmitButton = false,
  form,
  address,
  readonly = false,
}: Props) {
  const { t } = useTranslation('wizard');
  const [newForm] = Form.useForm();
  form = form || newForm;
  const disabled = readonly;

  return (
    <Columns>
      <Form
        name="basic"
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 12 }}
        initialValues={address}
        autoComplete="off"
        onFinish={onFinish}
      >
        <Form.Item
          label={t('addr1')}
          name="addressLineOne"
          rules={[{ required: true }]}
        >
          <Input disabled={disabled} />
        </Form.Item>

        <Form.Item label={t('addr2')} name="addressLineTwo">
          <Input disabled={disabled} />
        </Form.Item>

        <Form.Item label={t('city')} name="city" rules={[{ required: true }]}>
          <Input disabled={disabled} />
        </Form.Item>

        <Form.Item
          label={t('county')}
          name="county"
          rules={[{ required: true }]}
        >
          <Input disabled={disabled} />
        </Form.Item>

        <Form.Item
          label={t('country')}
          name="country"
          rules={[{ required: true }]}
        >
          <Input disabled={disabled} />
        </Form.Item>

        <Form.Item
          label={t('postcode')}
          name="postCode"
          rules={[
            { required: true },
            ({ getFieldValue }) => ({
              validator(_, value) {
                const len: number = value.replaceAll(' ', '').length;

                if (len < 5) return Promise.reject(t('zipCodeTooShort'));
                if (len > 8) return Promise.reject(t('zipCodeTooLong'));
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input disabled={disabled} />
        </Form.Item>

        {showSubmitButton && (
          <Form.Item wrapperCol={{ offset: 8 }}>
            <Button type="primary" htmlType="submit" disabled={disabled}>
              Submit
            </Button>
          </Form.Item>
        )}
      </Form>

      {/* <InfoBox>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec vel sem
          at arcu consequat pretium sed et nibh. Maecenas accumsan ligula ac
          justo pulvinar, at feugiat enim molestie. Cras non laoreet diam.
          Maecenas ultricies non est et mollis. Duis et eros nisl. Phasellus
        </p>
      </InfoBox> */}
    </Columns>
  );
}

export default Page;
