import styled from 'styled-components';

const CenterBox = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  text-align: center;
  width: fit-content;
  margin-top: 8em;
  font-family: Helvetica, sans-serif;
`;

const Title = styled.h1`
  font-weight: bold;
  font-size: 2em;
  margin-bottom: 0;
  line-height: normal;
`;

const Subtitle = styled.h2`
  font-size: 1.5em;
  margin-bottom: 0;
  line-height: normal;
`;

const Center = styled.div`
  width: 100%;
`;

type Props = {
  message?: string;
  additionalText?: string;
  children?: any;
};

const Page = ({ message = '', additionalText = '', children }: Props) => {
  return (
    <Center>
      <CenterBox>
        {message && <Title>{message}</Title>}
        <Subtitle>{additionalText && additionalText}</Subtitle>
        {children}
      </CenterBox>
    </Center>
  );
};

Page.prototype = {};

export default Page;
