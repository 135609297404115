import useUser from 'hooks/User';
import api from 'libs/api';
import { useState } from 'react';
import { NewsCategory } from './newsCategories';
import Pagination from 'constants/pagination';
import moment from 'moment';

export type News = {
  articleID: string;
  articleHeadline: string;
  articleSubHeading: string;
  articleBody: string;
  articleImage?: string;
  articleImagePath?: string;
  createdDate?: string;
  lastUpdateDate?: string;
  forDashboard?: boolean;
  expiryDate?: string;
  newsArticleCategories: NewsCategory[];
};

export type NewsCategoryIDRequestType = {};

export type NewsFetchProps = {
  articleSearchText?: string;
  newsCategories?: { newsCategoryID: number }[];
  rangeFrom?: string;
  rangeTo?: string;
  dashboardOnly?: boolean;
  includeExpired?: boolean;
  itemsPerPage?: number;
  pageNumber: number;
};

const useNews = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [pagination, setPagination] = useState<any>(Pagination);
  const [news, setNews] = useState<News[]>([]);
  const [article, setArticle] = useState<News>();
  const { user } = useUser();

  const fetchArticle = async (id: string, isEditing: boolean) => {
    setIsLoading(true);
    try {
      const result: any = await api.get(
        `content/affiliate/${user.affiliateId}/Article`,
        { params: { articleId: id, editingArticle: isEditing } }
      );

      const article = result.data;

      if (!article) throw Error('Article not found');

      article.categories = article?.newsArticleCategories.map(
        (cat: any) => cat.newsCategoryID.toString() || []
      );
      article.expiryDate = article.expiryDate && moment(article.expiryDate);
      setArticle(article);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchNews = async (props?: any) => {
    setIsLoading(true);

    const params = {
      offset: (props?.current - 1) * props?.pageSize || 0,
      limit: props?.pageSize || Pagination.pageSize,
      articleSearchText: props?.articleSearchText || undefined,
      dashboardOnly: props?.dashboardOnly || undefined,
      includeExpired: props?.includeExpired || undefined,
      newsCategoryID: props?.categories || undefined,
      rangeFrom: props?.rangeFrom || undefined,
      rangeTo: props?.rangeTo || undefined,
    };

    const result: any = await api.get(
      `/content/affiliate/${user.affiliateId}/GetNewsArticles`,
      { params }
    );

    setNews(result.data.newsArticles);

    const p = result.data.pagination;
    const newPagination = {
      current: Math.floor(p.offset / p.limit) + 1,
      pageSize: p.limit,
      total: p.totalItems,
    };

    setPagination(newPagination);
    setIsLoading(false);
  };

  const storeNewsArticle = (item: News) =>
    api.post(`/content/StoreNewsArticle`, item);

  return {
    // Variables
    isLoading,
    news,
    article,
    pagination,
    // Methods
    load: fetchNews,
    loadArticle: fetchArticle,
    store: storeNewsArticle,
  };
};

export default useNews;
