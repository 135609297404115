import { useState } from 'react';
import api from 'libs/api';
import Pagination from 'constants/pagination';
import downloadFile from 'libs/downloadFile';

export type FeesRecord = {
  id: string;
  amount: number;
  notes?: string;
  dateCreated: string;
  lastModified?: string;
  createdBy: string;
  lastModifiedBy?: string;
  status: string;
  invoiceReference?: string;
  invoiceFilePath?: string;
  paymentRequestIdentifier: string;
  purchaseOrderId?: string;
};

export type NewFeesRecord = {
  amount: number;
  notes?: string;
};

export type FeesStatus = {
  statusTypeValue: string;
  statusTypeName: string;
};

export type UpdateRequest = {
  status: number;
};

export type UpdateRequestNotes = {
  notes?: string;
};

const useFees = () => {
  const [feesRecords, setFeesRecords] = useState<FeesRecord[]>([]);
  const [feesRecord, setFeesRecord] = useState<FeesRecord>();
  const [feesLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState<any>(Pagination);
  const [feesSubmitting, setIsSubmitting] = useState(false);
  const [uploadSubmitting, setIsUploading] = useState(false);
  const [feesStatuses, setStatuses] = useState<FeesStatus[]>([
    { statusTypeValue: 'NotInvoiced', statusTypeName: 'NotInvoiced' },
    { statusTypeValue: 'Invoiced', statusTypeName: 'Invoiced' },
    { statusTypeValue: 'PaidByTenant', statusTypeName: 'PaidByTenant' },
  ]);

  const fetchFees = async (props?: any) => {
    setIsLoading(true);

    try {
      const params = {
        offset: props?.current || 1,
        limit: props?.pageSize || Pagination.pageSize,
        fromDate: props?.rangeFrom || undefined,
        toDate: props?.rangeTo || undefined,
        status: props?.status || undefined,
      };

      const result: any = await api.get(`/finance/fees`, { params });

      setFeesRecords(result.data.data);

      const newPagination = {
        current: result.data.offset,
        pageSize: result.data.limit,
        total: result.data.totalItems,
      };

      setPagination(newPagination);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchFeesRecord = async (id: string) => {
    setIsLoading(true);
    try {
      const result: any = await api.get(`finance/fees/${id}`);

      setFeesRecord(result.data);
    } finally {
      setIsLoading(false);
    }
  };

  const createFeesRecord = async (item: NewFeesRecord) => {
    // api call
    setIsSubmitting(true);
    return await api.post(`/finance/fees`, item).then((response) => {
      setIsSubmitting(false);
      return response.data;
    });
  };

  const updateFeesRecord = async (id: string, item: NewFeesRecord) => {
    // api call
    setIsSubmitting(true);
    return await api.put(`/finance/fees/${id}`, item).then((response) => {
      setIsSubmitting(false);
      return response.data;
    });
  };

  const updateFeesStatus = async (id: string, item: UpdateRequest) => {
    setIsSubmitting(true);
    return await api
      .put(`/finance/fees/${id}/status`, item)
      .then((response) => {
        setIsSubmitting(false);
        return response.data;
      });
  };

  const updateFeesNotes = async (id: string, item: UpdateRequestNotes) => {
    setIsSubmitting(true);
    return await api.put(`/finance/fees/${id}/notes`, item).then((response) => {
      setIsSubmitting(false);
      return response.data;
    });
  };

  const noLongerSubmitting = async () => {
    setIsSubmitting(false);
  };

  const noLongerUploading = async () => {
    setIsUploading(false);
  };

  const uploadInvoiceFile = async (id: string, fileData: FormData) => {
    // api call
    setIsUploading(true);

    return await api
      .post(`/finance/fees/${id}/invoice`, fileData)
      .then((response) => {
        setIsUploading(false);
        return response.data;
      });
  };

  const downloadInvoiceFile = async (id: string, fileReference: string) => {
    return downloadFile(`/finance/fees/${id}/invoice`, fileReference, 'pdf');
  };

  const setStatusesForFeesStatus = async (status: string) => {
    switch (status) {
      case 'NotInvoiced':
        let returnStatusesNI: FeesStatus[] = [
          { statusTypeValue: 'NotInvoiced', statusTypeName: 'NotInvoiced' },
        ];
        setStatuses(returnStatusesNI);
        return;
      case 'Invoiced':
        let returnStatusesIN: FeesStatus[] = [
          { statusTypeValue: 'Invoiced', statusTypeName: 'Invoiced' },
          { statusTypeValue: 'PaidByTenant', statusTypeName: 'PaidByTenant' },
        ];
        setStatuses(returnStatusesIN);
        return;
      case 'PaidByTenant':
        let returnStatusesPT: FeesStatus[] = [
          { statusTypeValue: 'PaidByTenant', statusTypeName: 'PaidByTenant' },
        ];
        setStatuses(returnStatusesPT);
        return;
    }
  };

  return {
    // Variables
    feesLoading,
    pagination,
    feesRecords,
    feesSubmitting,
    feesStatuses,
    feesRecord,
    uploadSubmitting,
    //categories,
    loadFees: fetchFees,
    loadFeesRecord: fetchFeesRecord,
    newFees: createFeesRecord,
    updateFees: updateFeesRecord,
    updateFeesStatus,
    updateFeesNotes,
    noLongerSubmitting,
    uploadInvoice: uploadInvoiceFile,
    downloadInvoice: downloadInvoiceFile,
    setStatuses: setStatusesForFeesStatus,
    noLongerUploading,
  };
};

export default useFees;
