import { Logout } from 'components/icons';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import useLayout from 'hooks/Layout';

const Center = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  height: 100%;
  background: var(--primary-color);
`;

const Content = styled.div`
  display: flex;
  max-width: 1200px;
  justify-content: space-between;
  align-content: center;
  width: 100%;
`;

const UserPart = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 2em;
`;

const Menu = styled.div`
  height: 100%;
  width: 100%;

  color: var(--normal-color);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 2em;
  padding: 0 2em;
  font-weight: 700;
`;

const Topbar = () => {
  const { t } = useTranslation();
  const { logoName, logoPath } = useLayout();
  return (
    <Center>
      <Content>
        <Menu>
          <img
            src={`/assets/logo/${logoPath}`}
            alt={logoName}
            title={logoName}
            style={{
              marginLeft: '2em',
              filter: 'invert(100%)',
              height: '4em',
            }}
          />
          {/* <span>{t('faq')}</span>
        <span>{t('support')}</span> */}
        </Menu>
        <UserPart>
          <Link title={t('logout')} style={{ height: '1.5em' }} to="/logout">
            <Logout
              fill="white"
              style={{ fontSize: '1.5em', marginBottom: '-5px' }}
            />
          </Link>
        </UserPart>
      </Content>
    </Center>
  );
};

export default Topbar;
