import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Bar } from 'react-chartjs-2';

import moment from 'moment';
import { useEffect, useState } from 'react';
import { message, Row, Spin } from 'components/antd';
import { getComparisonReportData } from 'libs/chart';
import useReports from 'hooks/Reporting/reports';
import { useTranslation } from 'react-i18next';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

export const getOptions = (t: Function) => ({
  indexAxis: 'y',
  responsive: true,
  maintainAspectRatio: false,
  layout: {
    padding: {
      right: '80',
    },
  },
  plugins: {
    tooltip: {
      enabled: false,
    },
    datalabels: {
      align: 'end',
      anchor: 'end',
      color: 'black',
      font: function (context: any) {
        return { size: 14 };
      },
      formatter: function (_: any, context: any) {
        const { type, value } = context.dataset.dataExtra[context.dataIndex];
        switch (type) {
          case 'Integer':
            return t('number', { value });
          case 'Currency':
            return `£${t('number', { value })}`;
          default:
            return value;
        }
      },
    },
  },
  scales: {
    x: {
      type: 'linear',
      ticks: {
        // Include a symbol in the tick, before or after
        callback: function (value: any, index: any, values: any) {
          return `${(value * 100).toFixed(0)}%`;
        },
      },
    },
  },
});

const standardRequest = {
  ReportID: 'SalesTrafficClicks',
  Filters: [
    {
      ID: 'From',
      Value: moment().utc().startOf('month').toISOString(),
    },
    {
      ID: 'To',
      Value: moment().utc().endOf('day').toISOString(),
    },
    {
      ID: 'GroupBy',
      Value: 'Month',
    },
  ],
  Current: undefined,
  PageSize: undefined,
};

const monthBackRequest = {
  ReportID: 'SalesTrafficClicks',
  Filters: [
    {
      ID: 'From',
      Value: moment()
        .utc()
        .subtract(1, 'months')
        .startOf('month')
        .toISOString(),
    },
    {
      ID: 'To',
      Value: moment().utc().subtract(1, 'months').endOf('day').toISOString(),
    },
    {
      ID: 'GroupBy',
      Value: 'Month',
    },
  ],
  Current: undefined,
  PageSize: undefined,
};

const monthBackRequestFull = {
  ReportID: 'SalesTrafficClicks',
  Filters: [
    {
      ID: 'From',
      Value: moment()
        .utc()
        .subtract(1, 'months')
        .startOf('month')
        .toISOString(),
    },
    {
      ID: 'To',
      Value: moment().utc().subtract(1, 'months').endOf('month').toISOString(),
    },
    {
      ID: 'GroupBy',
      Value: 'Month',
    },
  ],
  Current: undefined,
  PageSize: undefined,
};

const yearBackRequestFull = {
  ReportID: 'SalesTrafficClicks',
  Filters: [
    {
      ID: 'From',
      Value: moment().utc().startOf('month').add(-1, 'year').toISOString(),
    },
    {
      ID: 'To',
      Value: moment().utc().endOf('month').add(-1, 'year').toISOString(),
    },
    {
      ID: 'GroupBy',
      Value: 'Month',
    },
  ],
  Current: undefined,
  PageSize: undefined,
};

const yearBackRequest = {
  ReportID: 'SalesTrafficClicks',
  Filters: [
    {
      ID: 'From',
      Value: moment().utc().startOf('month').add(-1, 'year').toISOString(),
    },
    {
      ID: 'To',
      Value: moment().utc().endOf('day').add(-1, 'year').toISOString(),
    },
    {
      ID: 'GroupBy',
      Value: 'Month',
    },
  ],
  Current: undefined,
  PageSize: undefined,
};

type Props = {
  type?: string;
};

const columns = ['Clicks', 'TotalSales', 'TotalCommission'];

const Component = ({ type = 'month' }: Props) => {
  const { t } = useTranslation();
  const [request] = useState<any>(standardRequest);
  const [compareRequest, setCompareRequest] = useState<any>(null);
  const [compareRequestFull, setCompareRequestFull] = useState<any>(null);
  const [data, setData] = useState<any>(null);

  const { isLoading, report, load } = useReports();
  const { isLoading: isLoadingB, report: reportB, load: loadB } = useReports();
  const {
    isLoading: isLoadingBFull,
    report: reportBFull,
    load: loadBFull,
  } = useReports();

  useEffect(() => {
    if (type === 'year') {
      setCompareRequest(yearBackRequest);
      setCompareRequestFull(yearBackRequestFull);
    } else {
      setCompareRequest(monthBackRequest);
      setCompareRequestFull(monthBackRequestFull);
    }
  }, [type]);

  useEffect(() => {
    if (!request) return;
    load(request).catch(() => message.error('Unable to download current data'));
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request]);

  useEffect(() => {
    if (!compareRequest) return;
    loadB(compareRequest).catch(() =>
      message.error('Unable to download previous period data')
    );
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [compareRequest]);

  useEffect(() => {
    if (!compareRequestFull) return;
    loadBFull(compareRequestFull).catch(() =>
      message.error('Unable to download previous period full month data')
    );
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [compareRequestFull]);

  useEffect(() => {
    if (!report || !reportB || !reportBFull) return;

    const range = ` (1-${moment().format('D')})`;
    const dsLabels: any = [
      moment().format('MMMM YYYY') + range,
      (type !== 'year'
        ? moment().add(-1, 'month').format('MMMM YYYY')
        : moment().add(-1, 'year').format('MMMM YYYY')) + range,
    ];

    const d = getComparisonReportData(
      report,
      reportB,
      reportBFull,
      dsLabels,
      columns
    );
    setData(d);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report, reportB, reportBFull]);

  return (
    <div style={{ height: 300 }}>
      <Row justify="center">
        <Spin spinning={isLoading || isLoadingB || isLoadingBFull} />
      </Row>
      {!(isLoading || isLoadingB || isLoadingBFull || !data) && (
        <Bar
          data={
            data || {
              labels: [],
              datasets: [],
            }
          }
          width={undefined}
          height={300}
          options={getOptions(t) as any}
        />
      )}
    </div>
  );
};

export default Component;
