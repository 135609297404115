import styled from 'styled-components';

const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  align-items: center;

  > * {
    margin-bottom: 0.5em;
  }
  /* h1,
  h2,
  h3 {
    margin-bottom: unset;
  } */
`;

export default TitleRow;
