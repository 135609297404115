import { Menu } from 'components/antd';
import useUser from 'hooks/User';

import { Permissions } from 'constants/permissions';
import { Features } from 'constants/features';

const { SubMenu } = Menu;

type Props = {
  permission?: Permissions;
  feature?: Features;
  features?: Features[];
  permissions?: Permissions[];
  // All other props
  [x: string]: any;
};

const MenuItemCheck = (props: Props) => {
  const { hasPermission, getFeature } = useUser();

  if (props.permission && !hasPermission(props.permission)) return null;

  if (props.feature && !getFeature(props.feature).show) return null;

  return <SubMenu {...(props as any)} />;
};

export default MenuItemCheck;
