import api from 'libs/api';
import { useEffect, useState } from 'react';
import {
  Pagination,
  IEmailTemplate,
  IRecipientGroup,
  IBulkEmail,
  IGetEmailTemplatesCall,
  TemplateType,
  IListBulkEmailResult,
  BulkEmailRequest,
  IEmailActivity,
  BulkEmailStatus,
} from './Models';

const useCommunications = () => {
  const [loadingEntityCount, setLoadingEntityCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [pagination, setPagination] = useState<Pagination>(
    new Pagination(1, 1, 25)
  );
  const [isAddingOrUpdatingEmail, setIsAddingOrUpdatingEmail] =
    useState<boolean>(false);
  const [templates, setTemplates] = useState<IEmailTemplate[]>([]);
  const [recipientGroups, setRecipientGroups] = useState<IRecipientGroup[]>([]);
  const [bulkEmails, setBulkEmails] = useState<IBulkEmail[]>([]);
  const [isCreatingUpdatingTemplate, setIsCreatingUpdatingTemplate] =
    useState<boolean>(false);

  useEffect(() => {
    setIsLoading(loadingEntityCount > 0);
  }, [loadingEntityCount]);

  const fetchEmailTemplates = async (
    emailTemplateType: TemplateType,
    paginationParam?: Pagination
  ) => {
    setLoadingEntityCount((prev) => prev + 1);
    const offsetPag = paginationParam?.toOffsetPagination();
    try {
      const result = await api.get<IGetEmailTemplatesCall>(
        'communications/getemailtemplates',
        {
          params: {
            templateTypeId: emailTemplateType,
            limit: offsetPag?.limit,
            offset: offsetPag?.offset,
          },
        }
      );
      if (paginationParam !== null) {
        const serverPagination = Pagination.fromOffsetPagination(
          result.data.pagination
        );
        setPagination(serverPagination);
      }
      setTemplates(result.data.emailTemplates);
    } finally {
      setLoadingEntityCount((prev) => prev - 1);
    }
  };

  const fetchRecipientGroups = async () => {
    setLoadingEntityCount((prev) => prev + 1);
    try {
      const result = await api.get<IRecipientGroup[]>(
        'communications/affiliategroups',
        {
          params: {},
        }
      );
      setRecipientGroups(result.data);
    } finally {
      setLoadingEntityCount((prev) => prev - 1);
    }
  };

  const queueEmail = async (email: any) => {
    setIsAddingOrUpdatingEmail(true);
    try {
      await api.post<IRecipientGroup[]>('communications/queueemail', email);
    } finally {
      setIsAddingOrUpdatingEmail(false);
    }
  };

  const fetchBulkEmails = async (
    paginationParam: Pagination,
    startDate?: string,
    endDate?: string
  ) => {
    setLoadingEntityCount((prev) => prev + 1);
    const offsetPag = paginationParam.toOffsetPagination();
    try {
      const result = await api.get<IListBulkEmailResult>(
        'communications/getbulkemails',
        {
          params: {
            rangeFrom: startDate,
            rangeTo: endDate,
            limit: offsetPag.limit,
            offset: offsetPag.offset,
          },
        }
      );
      setPagination(Pagination.fromOffsetPagination(result.data.pagination));
      setBulkEmails(result.data.bulkEmails);
    } finally {
      setLoadingEntityCount((prev) => prev - 1);
    }
  };

  const getBulkEmail = async (bulkEmailId: number): Promise<IBulkEmail> => {
    setLoadingEntityCount((prev) => prev + 1);
    try {
      const result = await api.get<IBulkEmail>('communications/bulkemail', {
        params: {
          bulkEmailId,
        },
      });
      return result.data;
    } finally {
      setLoadingEntityCount((prev) => prev - 1);
    }
  };

  const addOrUpdateEmail = async (email: BulkEmailRequest) => {
    setIsAddingOrUpdatingEmail(true);
    try {
      await api.post<IRecipientGroup[]>('communications/storebulkemail', email);
    } finally {
      setIsAddingOrUpdatingEmail(false);
    }
  };

  const getEmailActivity = async (emailId: string) => {
    var result = await api.get<IEmailActivity[]>(
      'communications/getemailactivityhistory',
      {
        params: {
          emailId,
        },
      }
    );
    return result.data;
  };

  const cancelBulkEmail = async (bulkEmailId: number) =>
    api.post<IEmailActivity[]>('communications/cancelbulkemail', {
      bulkEmailId: bulkEmailId,
    });

  const fetchTemplate = async (
    emailTemplateId: number,
    emailTemplateTypeId: number
  ): Promise<IEmailTemplate> => {
    try {
      setLoadingEntityCount((c) => c + 1);
      var result = await api.get<IEmailTemplate>(
        'communications/emailtemplate',
        {
          params: { emailTemplateId, emailTemplateTypeId },
        }
      );
      return result.data;
    } finally {
      setLoadingEntityCount((c) => c - 1);
    }
  };

  const createOrUpdateTemplate = async (template: IEmailTemplate) => {
    try {
      setIsCreatingUpdatingTemplate(true);
      await api.post('communications/storeemailtemplate', template);
    } finally {
      setIsCreatingUpdatingTemplate(false);
    }
  };

  return {
    // Variables
    isLoading,
    isAddingOrUpdatingEmail,
    pagination,
    templates,
    isCreatingUpdatingTemplate,
    recipientGroups,
    bulkEmails,
    // Methods
    setPagination,
    fetchTemplate,
    fetchBulkEmails,
    getBulkEmail,
    fetchEmailTemplates,
    fetchRecipientGroups,
    createOrUpdateTemplate,
    queueEmail,
    addOrUpdateEmail,
    getEmailActivity,
    cancelBulkEmail,
  };
};
export type { IEmailTemplate, IBulkEmail, IRecipientGroup };
export default useCommunications;
export { BulkEmailStatus, BulkEmailRequest, TemplateType, Pagination };
