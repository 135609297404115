import { Navigate, Route, Routes } from 'react-router';
import { PrivatePage } from 'components/shared';
import List from './List';
import { Permissions } from 'constants/permissions';

const Page = () => {
  return (
    <Routes>
      <Route
        path="list"
        element={
          <PrivatePage
            children={<List />}
            requirement={Permissions['Finance.BankingDashboard.Read']}
          />
        }
      />
      <Route index element={<Navigate to="list" />} />
    </Routes>
  );
};

export default Page;
