import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import useLayout from 'hooks/Layout';
import { useEffect } from 'react';
import { Content, InfoBox } from 'components/shared';
import { Card, message, Col, Row } from 'components/antd';
import useAgreements from 'hooks/Agreements/agreements';
import moment from 'moment';

const InfoOrderedList = styled.ol`
  padding-inline-start: 0px;
  padding-top: 12px;
  list-style-type: none;
`;

const Page = () => {
  const { setMinimized } = useLayout();
  const {
    loadLatestAffiliateAgreement,
    agreementsLoading,
    lastAcceptedAgreement,
  } = useAgreements();
  const { t } = useTranslation('supportPlatformAgreement');

  useEffect(() => {
    loadLatestAffiliateAgreement().catch((error) => {
      const errorMsg = error?.response?.data?.message;
      message.error(
        t(`agreement.load.error`, {
          msg: errorMsg ? t(errorMsg) : undefined,
        })
      );
    });
    //// setSidebar(false);
    //setMinimized(true);
    return () => {
      //setMinimized(false);
      //// setSidebar(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Content
      style={{
        maxWidth: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <h1>{t('platformAgreement')}</h1>

      <Card
        style={{
          width: '',
          minWidth: 500,
          maxWidth: 1900,
        }}
        loading={agreementsLoading}
      >
        <Row>
          <Col
            span={16}
            style={{
              alignItems: 'center',
              textAlign: 'center',
            }}
          >
            <div
              style={{ padding: '2em', overflowY: 'auto', height: 700 }}
              dangerouslySetInnerHTML={{
                __html: lastAcceptedAgreement?.agreementDetails || '',
              }}
            />
          </Col>
          <Col span={1}></Col>
          <Col span={7}>
            <InfoBox style={{ width: '100%', height: '100%' }}>
              <InfoOrderedList>
                <li>{t('platformAcceptedInfo')}</li>
                <li>&nbsp;</li>
                <li>
                  {t('platformAcceptedDate', {
                    msg: moment
                      .utc(lastAcceptedAgreement?.dateAccepted)
                      .format('DD MMM YYYY HH:mm'),
                  })}
                </li>
              </InfoOrderedList>
            </InfoBox>
          </Col>
        </Row>
      </Card>
    </Content>
  );
};

export default Page;
