import styled from 'styled-components';
import { FAQ } from 'hooks/Support/faqs';
import { Collapse } from 'antd';
import { Skeleton } from 'components/antd';

type Props = {
  isLoading: boolean;
  items: FAQ[];
};

const FlexDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2em;
  gap: 1em;
  flex-direction: column;
`;

const { Panel } = Collapse;

function CategoryFAQView({ items = [], isLoading }: Props) {
  return (
    <FlexDiv>
      {isLoading ? (
        <>
          <Skeleton title paragraph={{ rows: 1 }} />
          <Skeleton title paragraph={{ rows: 1 }} />
        </>
      ) : (
        <>
          {items.map((item) => (
            <Collapse key={item.faqid}>
              <Panel header={item.question} key={item.faqid}>
                <p dangerouslySetInnerHTML={{ __html: item.answer }} />
              </Panel>
            </Collapse>
          ))}
        </>
      )}
    </FlexDiv>
  );
}

export default CategoryFAQView;
