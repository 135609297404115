import styled from 'styled-components';

type Props = {
  value: number;
  [props: string]: any;
};

const CautionWrapper = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  min-height: 20px;
  border-left: 4px solid #00b900;
  border-right: 4px solid transparent;
`;

const CautionProgressOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: ${(props: Props) => props.value}%;
  background: #eeeeee;
`;

const CautionProgress = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(
    90deg,
    #00b900 0%,
    #ffc301 20%,
    #ff6e01 65%,
    #ff0101
  );
`;

const TextOverlay = styled.div`
  position: absolute;
  width: 100%;
  text-align: center;
`;

const Component = ({ value = 0, ...props }: Props) => {
  const hidePercentage: number = 100 - value;

  return (
    <CautionWrapper {...props}>
      <CautionProgress />
      <CautionProgressOverlay value={hidePercentage} />
      <TextOverlay>{value}%</TextOverlay>
    </CautionWrapper>
  );
};

export default Component;
