import { RcFile } from 'antd/lib/upload';
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  Row,
  Select,
  Switch,
} from 'components/antd';
import { Content, InfoBox, TitleRow } from 'components/shared';
import useBanners from 'hooks/Resources/banners';
import useBannerGroups from 'hooks/Resources/bannerGroups';
import useBannerImages from 'hooks/Resources/bannerImages';
import { ImageState, ImageData } from 'components/shared/ImageSelector';
import ImageSelector from 'components/shared/ImageSelector';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

type GroupOption = {
  label: string;
  value: string;
};

const defaults = {
  isActive: true,
  isHidden: false,
};

function BannerForm() {
  const [form] = Form.useForm();
  const { t } = useTranslation('banners');
  const { banner, isLoading, getBanner, storeBanner } = useBanners();
  const { load: loadGroups, groups } = useBannerGroups();
  const {
    load: loadImages,
    upload: uploadImage,
    images,
    isLoading: isLoadingImages,
  } = useBannerImages();
  const [convertedImages, setConvertedImages] = useState<ImageData[]>([]);
  const [groupOptions, setGroupOptions] = useState<GroupOption[]>([]);
  const [sizeFilter, setSizeFilter] = useState<string>();
  const navigate = useNavigate();
  const { id } = useParams();
  const type = id ? 'edit' : 'create';

  useEffect(() => {
    loadGroups(false);
    loadImages();

    if (id) getBanner(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setConvertedImages(mapImagesToImageData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [images]);

  useEffect(() => {
    setGroupOptions(mapGroupsToGroupOptions());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groups]);

  useEffect(() => {
    if (!banner) return;

    form.resetFields();
    form.setFieldsValue({
      alternativeText: banner?.alternativeText,
      bannerName: banner?.bannerName,
      linkURL: banner?.linkURL,
      isActive: banner?.isActive,
      isHidden: banner?.isHidden,
      groups: banner?.bannerGroups.map((bg) => {
        return bg.bannerGroupID.toString();
      }),
      image: {
        id: banner?.bannerImage,
        dimensionX: banner?.dimensionX,
        dimensionY: banner?.dimensionY,
      },
    });

    setSizeFilter(`${banner?.dimensionX}x${banner?.dimensionY}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [banner]);

  const onFinish = async (values: any) => {
    const image = convertedImages.find((image) => image.id === values.image.id);
    storeBanner({
      alternativeText: values.alternativeText,
      bannerID: id,
      bannerImage: image?.id,
      bannerName: values.bannerName,
      dimensionX: image?.dimensionX,
      dimensionY: image?.dimensionY,
      bannerGroups: values.groups.map((bannerGroupID: string) => ({
        bannerGroupID: Number.parseInt(bannerGroupID),
      })),
      isActive:
        values.isActive === undefined ? defaults.isActive : values.isActive,
      isHidden:
        values.isHidden === undefined ? defaults.isHidden : values.isHidden,
      linkURL: values.linkURL,
    }).then(
      () => {
        message.success(t(`${type}.success`));

        navigate(`/admin/banners/`);
      },
      () => {
        message.error(t(`${type}.error`));
      }
    );
  };

  function mapImagesToImageData(): ImageData[] {
    return images.map((image) => {
      return {
        id: image.fileName,
        source: image.fileLocation,
        dimensionX: image.dimensionX,
        dimensionY: image.dimensionY,
        state: ImageState.done,
        percentageUploaded: 50,
      };
    });
  }

  function mapGroupsToGroupOptions(): GroupOption[] {
    return groups.map((group) => {
      return {
        label: group.bannerGroupName,
        value: group.bannerGroupID.toString(),
      };
    });
  }

  const onImageSelectorUpload = (
    file: RcFile,
    onComplete: (id: string) => void
  ) => {
    const uploadedImage: ImageData = {
      id: file.uid,
      state: ImageState.uploading,
      percentageUploaded: 0,
    };

    const r: ImageData[] = mapImagesToImageData();
    r.unshift(uploadedImage);

    setConvertedImages(r);

    return uploadImage(file, (progressEvent) => {
      uploadedImage.percentageUploaded = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );

      const r: ImageData[] = mapImagesToImageData();
      r.unshift(uploadedImage);
      setConvertedImages(r);
    }).then(
      (result) => {
        uploadedImage.id = result.data.fileName;
        uploadedImage.source = result.data.fileLocation;
        uploadedImage.dimensionX = result.data.dimensionX;
        uploadedImage.dimensionY = result.data.dimensionY;
        uploadedImage.state = ImageState.done;
        uploadedImage.percentageUploaded = 100;

        r[0] = uploadedImage;
        setConvertedImages(r);

        form.setFieldsValue({
          image: uploadedImage,
        });

        onComplete(uploadedImage.id);
      },
      () => {
        uploadedImage.state = ImageState.error;
        uploadedImage.percentageUploaded = 100;

        r[0] = uploadedImage;
        setConvertedImages(r);
      }
    );
  };

  return (
    <Content>
      <TitleRow>
        <h1>{t(`title.${type}.banner`)}</h1>
      </TitleRow>
      <Card loading={isLoading}>
        <Row>
          <Col span={16}>
            <Form
              form={form}
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 12 }}
              onFinish={onFinish}
            >
              <Form.Item
                label={t('common:name')}
                name="bannerName"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={t('common:alternativeText')}
                name="alternativeText"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={t('linkURL')}
                name="linkURL"
                rules={[{ required: true, type: 'url' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={t('common:active')}
                name="isActive"
                style={{ textAlign: 'left' }}
                valuePropName="checked"
              >
                <Switch defaultChecked={defaults.isActive} />
              </Form.Item>
              <Form.Item
                label={t('common:hidden')}
                name="isHidden"
                style={{ textAlign: 'left' }}
                valuePropName="checked"
              >
                <Switch defaultChecked={defaults.isHidden} />
              </Form.Item>
              <Form.Item label={t('common:groups')} name="groups">
                <Select mode="tags" options={groupOptions} />
              </Form.Item>
              <Form.Item
                label={t('common:image')}
                name="image"
                rules={[{ required: true, message: t('error.image') }]}
              >
                <ImageSelector
                  height="350px"
                  loading={isLoadingImages}
                  images={convertedImages}
                  onUpload={onImageSelectorUpload}
                  sizeFilter={sizeFilter}
                />
              </Form.Item>
              <Form.Item wrapperCol={{ offset: 8 }}>
                <Button type="primary" htmlType="submit">
                  {t('common:submit')}
                </Button>
              </Form.Item>
            </Form>
          </Col>
          <Col span={8}>
            <InfoBox style={{ width: '100%', height: '100%' }}>
              <ul style={{ marginTop: '12px' }}>
                <li>
                  <b>{t('common:name')}</b> - {t('help.name')}
                </li>
                <li>
                  <b>{t('common:alternativeText')}</b>
                  {` - ${t('help.alternativeText')}`}
                </li>
                <li>
                  <b>{t('linkURL')}</b>
                  {` - ${t('help.linkURL')}`}
                </li>
                <li>
                  <b>{t('common:active')}</b>
                  {` - ${t('help.active')}`}
                </li>
                <li>
                  <b>{t('common:hidden')}</b>
                  {` - ${t('help.hidden')}`}
                </li>
                <li>
                  <b>{t('common:groups')}</b>
                  {` - ${t('help.groups')}`}
                </li>
                <li>
                  <b>{t('common:image')}</b>
                  {` - ${t('help.image')}`}
                </li>
              </ul>
            </InfoBox>
          </Col>
        </Row>
      </Card>
    </Content>
  );
}

export default BannerForm;
