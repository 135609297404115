import api from 'libs/api';
import { useState } from 'react';
import Pagination from 'constants/pagination';

export enum BankChangesApprovalStatus {
  Pending = 1,
  Approved = 2,
  Declined = 3,
}

export type BankChangesApproveListItem = {
  id: string;
  bankName: string;
  affiliateId: string;
  accountName: string;
  accountNumber: string;
  iban: string;
  swiftCode: string;
  sortCode: string;
  createdOn: Date;
  lastModified?: Date;
};

const changeRequestUrl = '/accounts';

export interface IUseInvoices {
  requests: BankChangesApproveListItem[];
  isLoading: boolean;
  pagination: any;
  load: (params?: any) => Promise<void>;
  approve: (id: string) => Promise<any>;
  decline: (id: string) => Promise<any>;
}

const useBankChangeRequestApprove = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState<any>(Pagination);
  const [requests, setRequests] = useState<BankChangesApproveListItem[]>([]);

  const fetch = async (props?: any) => {
    setIsLoading(true);
    const params = {
      offset: props?.current || 1,
      limit: props?.pageSize || Pagination.pageSize,
      fromDate: props?.fromDate?.startOf('day')?.toISOString() || undefined,
      toDate: props?.toDate?.endOf('day')?.toISOString() || undefined,
      status: props?.status || undefined,
    };
    try {
      const { data: results }: any = await api.get(
        `${changeRequestUrl}/BankAccountChangeRequests`,
        {
          params,
        }
      );
      const { data, limit, offset, totalItems } = results;

      setRequests(data);

      const newPagination = {
        current: offset,
        pageSize: limit,
        total: totalItems,
      };

      setPagination(newPagination);
    } finally {
      setIsLoading(false);
    }
  };

  const approve = (affiliateID: string, id: string) =>
    api
      .put(
        `${changeRequestUrl}/affiliate/${affiliateID}/changeRequests/${id}/approve`
      )
      .then((response) => response.data);

  const decline = (affiliateID: string, id: string) =>
    api
      .put(
        `${changeRequestUrl}/affiliate/${affiliateID}/changeRequests/${id}/decline`
      )
      .then((response) => response.data);

  return {
    // variables
    requests,
    isLoading,
    pagination,
    // methods
    load: fetch,
    approve,
    decline,
  };
};

export default useBankChangeRequestApprove;
