import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import {
  Card,
  Form,
  Table,
  Row,
  Select,
  Button,
  Col,
  message,
} from 'components/antd';
import { Content } from 'components/shared';
import useFeeds, {
  FeedFilter,
  FileFormat,
  SelectedFilter,
  NewUserFeed,
  NewFeedFilter,
} from 'hooks/Creatives/feeds';
import useUser from 'hooks/User';
import { Permissions } from 'constants/permissions';

const Page = () => {
  const { Option } = Select;
  const { t } = useTranslation('feedsBuild');
  const { t: c } = useTranslation('common');
  const [form] = Form.useForm();
  const [fileFormat, setFileFormat] = useState<number>(-1);
  const [filters, setFilters] = useState<any[]>([]);
  const { hasPermission } = useUser();

  const allowCreation = hasPermission(Permissions['Feeds.UserFeed.ReadWrite']);

  const {
    fileFormats,
    feedFilters,
    loadConfig,
    loadFeeds,
    createFeed,
    isLoading,
    userFeeds,
    updatedFeed,
  } = useFeeds();

  useEffect(() => {
    // Clear selected filters from previous render
    const newFiltersArr = filters;
    newFiltersArr.length = 0;

    Promise.all([loadConfig(), loadFeeds()]).catch(() => {
      message.error(t(`feeds.load.error`));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedFeed]);

  const columns = (fileFormats: FileFormat[] = []) => [
    {
      title: t('filters'),
      dataIndex: 'filterString',
      width: '25%',
    },
    {
      title: t('format'),
      dataIndex: 'type',
      render: (item: number) => (
        <>{fileFormats.find((c: FileFormat) => c.id === item)?.name || item}</>
      ),
    },
    {
      title: t('lastFetched'),
      dataIndex: 'lastFetchedAt',
      render: (date: Date) =>
        date ? c('date', { value: new Date(date) }) : t('neverBeenFetched'),
    },
    {
      title: t('url'),
      dataIndex: 'url',
      width: '45%',
      render: (item: string) => (
        <a href={item} target="_blank" rel="noreferrer">
          {item}
        </a>
      ),
    },
  ];

  const filterHandler = (filterID: string, value: string | null) => {
    let currentValue = value;

    // Convert null to the correct type
    if (currentValue === 'null') {
      currentValue = null;
    }
    const oldFilters = filters;

    if (!filters.some((e: any) => e.id === filterID)) {
      // If the current filter has not already been selected, add it to the filter list

      // Create new object for the current filter
      const newFilter = {
        id: filterID,
        value: currentValue,
      };

      // Create a new array with the new filter added
      const newFiltersArr: any = [...oldFilters, newFilter];

      // Set the filters
      setFilters(newFiltersArr);
      return;
    } else if (filters.some((e: any) => e.id === filterID)) {
      // If the current filter is already in the filter list, change its value to the updated one
      // Get index of filter
      const indexOfCurrentFilter = filters.findIndex((filter: any) => {
        return filter.id === filterID;
      });

      oldFilters[indexOfCurrentFilter].value = currentValue;

      const newFiltersArr: any = [...oldFilters];

      setFilters(newFiltersArr);
    }
  };

  return (
    <Content>
      <h1>{t('feeds')}</h1>
      <Card loading={isLoading}>
        <Form
          layout="inline"
          autoComplete="off"
          form={form}
          name="newFeed"
          onFinish={() => {
            createFeed(fileFormat, filters);
          }}
        >
          <Row
            justify="center"
            style={{
              paddingBottom: '0.5em',
              width: '100%',
            }}
          >
            <Col style={{ textAlign: 'left' }}>
              <Form.Item
                name="fileFormat"
                label={t('fileFormat')}
                rules={[{ required: true }]}
              >
                <Select
                  allowClear
                  disabled={!allowCreation}
                  placeholder={t('selectformat')}
                  value={fileFormat}
                  style={{ width: 200 }}
                  onChange={(value) => setFileFormat(Number(value))}
                >
                  {fileFormats.map((format) => (
                    <Option key={format.id}>{format.name}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col style={{ textAlign: 'left' }}>
              <Form.Item label={t('filters')}>
                {feedFilters.map((filter, i) => (
                  <Select
                    allowClear
                    key={filter.id}
                    disabled={!allowCreation}
                    placeholder={filter.name}
                    style={{
                      width: 300,
                      paddingBottom: '0.2em',
                      display: 'block',
                    }}
                    onChange={(value) => filterHandler(filter.id, value)}
                  >
                    {Object.entries(feedFilters[i].options).map(
                      ([key, value]: any) => (
                        <Option key={value}>{key}</Option>
                      )
                    )}
                  </Select>
                ))}
              </Form.Item>
            </Col>
          </Row>
          <Row justify="center" style={{ marginTop: '0.5em', width: '100%' }}>
            <Col style={{ textAlign: 'center' }}>
              <Button
                type="primary"
                htmlType="submit"
                disabled={!allowCreation}
              >
                {t('createFeed')}
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>
      <Card style={{ marginTop: '2em' }} loading={isLoading}>
        <Table
          loading={isLoading}
          bordered
          rowKey={'url'}
          dataSource={userFeeds}
          columns={columns(fileFormats)}
          pagination={false}
        />
      </Card>
    </Content>
  );
};

export default Page;
