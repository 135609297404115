import { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { TenantGrid } from 'components/icons';
import TenantSwitchContainer from 'components/Layout/TenantSwitch/Container';

const TenantGridIcon = styled(TenantGrid)`
  font-size: 1.5em;
  margin-top: 0px;

  &:hover {
    cursor: pointer;
  }
`;

const Index = () => {
  const ref = useRef();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Close menu if user clicks outside of it
  const handleClickOutside = (e: any) => {
    // @ts-ignore
    if (!ref.current.contains(e.target)) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    if (isMenuOpen) document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  });

  const ToggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    // @ts-ignore
    <div style={{ height: '1.5em' }} ref={ref}>
      <TenantGridIcon onClick={ToggleMenu} />
      {isMenuOpen && <TenantSwitchContainer />}
    </div>
  );
};

export default Index;
