import { Row } from 'components/antd';
import { TitleValue } from 'components/shared';
import { PromotionalMethod } from 'hooks/Account/affiliateDetails';
import { useTranslation } from 'react-i18next';

type Props = {
  details: any;
  methods: any[];
};

const Component = ({ details, methods }: Props) => {
  const { t } = useTranslation('wizard');

  if (!details) return <h2>No info</h2>;

  return (
    <>
      {/* Basic information */}
      <h2>{t('basicInfo')}</h2>
      <Row style={{ paddingLeft: '1em' }}>
        <TitleValue title={t('affiliateName')} value={details.affiliateName} />
        <TitleValue
          title={t('affiliateWebsite')}
          value={details.affiliateWebsite}
        />
        <TitleValue
          title={t('name')}
          value={`${t(details.title)} ${details.firstName} ${details.surname}`}
        />
        <TitleValue title={t('phone')} value={`${details.phoneNumber}`} />
        <TitleValue
          title={t('address')}
          value={[
            details.addressLineOne,
            details.addressLineTwo,
            details.city,
            details.county,
            details.country,
            details.postCode,
          ].join(', ')}
        />
      </Row>

      {/* Payments */}
      <h2 style={{ marginTop: '1em' }}>Payments</h2>
      <Row style={{ paddingLeft: '1em' }}>
        {details.vatRegistrationNumber && (
          <TitleValue title={t('vat')} value={details.vatRegistrationNumber} />
        )}
        <TitleValue title={t('bankName')} value={details.bankName} />
        <TitleValue title={t('accountName')} value={details.accountName} />
        <TitleValue title={t('accountNumber')} value={details.accountNumber} />
        {details.swiftCode && (
          <TitleValue title={t('swiftCode')} value={details.swiftCode} />
        )}
        {details.iban && (
          <TitleValue title={t('ibanCode')} value={details.iban} />
        )}
        {details.sortCode && (
          <TitleValue title={t('sortCode')} value={details.sortCode} />
        )}
      </Row>

      {/* Websites */}
      {details?.websites && (
        <h2 style={{ marginTop: '1em' }}>{t('websites')}</h2>
      )}

      <div style={{ paddingLeft: '1em' }}>
        {details?.websites?.map((w: any, i: number) => (
          <div key={i}>
            <Row>
              <TitleValue title={t('name')} value={w.websiteName} />
              <TitleValue title={t('url')} value={w.websiteUrl} />
              <TitleValue
                title={t('visitorCount')}
                value={w.uniqueMonthlyVisitors}
              />
              <TitleValue
                title={t('description')}
                value={w.websiteDescription}
              />
              <TitleValue
                title={t('promoMethods')}
                value={(w?.promotionalMethodIds || [])
                  .map((x: string) => methods.find((m) => m.id === x))
                  .map((x: PromotionalMethod) => x?.name || x)
                  .join(', ')}
              />
              <TitleValue
                title={t('socialNetworks')}
                value={(w?.socialNetworks || [])
                  .map((x: any) => x.accountHandle)
                  .join(', ')}
              />
            </Row>
            {i !== details.websites.length - 1 && <hr />}
          </div>
        ))}
      </div>
    </>
  );
};

export default Component;
