import { Card, Button, Table, Select, Form, Row, Col, message } from 'antd';
import { Content, TitleRow } from 'components/shared';
import { Link } from 'react-router-dom';
// import { useTranslation } from 'react-i18next';
import useCommunications from 'hooks/Communications/Emails';
import { useEffect, useState } from 'react';
import {
  IEmailTemplate,
  Pagination,
  TemplateType,
} from 'hooks/Communications/Models';
import styled from 'styled-components';
import { Cross, Edit, Check } from 'components/icons';

const DetailsRow = styled.div`
  margin-bottom: 5px;
`;

const EmailHtml = styled.div`
  margin-top: 5px;
  border: 1px dotted;
  padding: 10px;
`;

const TemplateDetails = (template: IEmailTemplate) => {
  return (
    <div>
      <DetailsRow>
        <strong>CC:</strong> {template.emailCCList ?? '-'}
      </DetailsRow>
      <DetailsRow>
        <strong>BCC:</strong> {template.emailBCCList ?? '-'}
      </DetailsRow>
      {/*Not sure about this one - bad idea in so many levels - temporary?*/}
      <strong>Email:</strong>
      <EmailHtml
        dangerouslySetInnerHTML={{ __html: template.emailMessageHTML }}
      ></EmailHtml>
    </div>
  );
};

const ListPage = () => {
  // const { t } = useTranslation('communicationsMaint');
  const {
    isLoading,
    templates,
    pagination,
    setPagination,
    fetchEmailTemplates,
  } = useCommunications();
  const [templateType, setTemplateType] = useState<TemplateType>(
    TemplateType.User
  );

  const templateColumns = [
    {
      dataIndex: 'templateName',
      title: 'Name',
      key: 'Name',
    },
    {
      dataIndex: 'emailSubject',
      title: 'Subject',
      key: 'Subject',
    },
    {
      title: 'From',
      key: 'from',
      render: (template: IEmailTemplate) => (
        <>
          {template.emailFromName} ({template.emailFromAddress})
        </>
      ),
    },
    {
      dataIndex: 'trackActivity',
      title: 'Track',
      key: 'Track',
      render: (track: boolean) => (track ? <Check /> : <Cross />),
    },
    {
      title: '',
      dataIndex: '',
      key: 'actions',
      width: 50,
      render: (template: IEmailTemplate) => (
        <Link
          to={`/admin/communications/emailtemplates/edit/${template.emailTemplateID}/${template.templateTypeID}`}
        >
          <Button>
            <Edit
              title="Edit"
              style={{
                marginLeft: -2,
                marginBottom: -2,
                fontSize: '0.9em',
              }}
            />
          </Button>
        </Link>
      ),
    },
  ];

  const refresh = (paginationParam?: Pagination) => {
    fetchEmailTemplates(templateType, paginationParam || pagination).catch(
      () => {
        message.error('Error while loading data');
      }
    );
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(refresh, [templateType]);

  const handleChange = (tablePagination: any) => {
    const newPaginationVal = new Pagination(
      tablePagination.current,
      pagination.total,
      tablePagination.pageSize
    );
    setPagination(newPaginationVal);
    refresh(newPaginationVal);
  };

  return (
    <Content>
      <TitleRow>
        <h1>Email Templates</h1>
        <Link to={`/admin/communications/emailtemplates/add`}>
          <Button disabled={templateType === TemplateType.System}>
            New Template
          </Button>
        </Link>
      </TitleRow>

      <Row justify="space-around">
        <Col span={4}>
          <Form.Item label="Type">
            <Select
              value={templateType}
              onChange={(t: TemplateType) => setTemplateType(t)}
            >
              <Select.Option
                key={TemplateType.System}
                value={TemplateType.System}
              >
                System
              </Select.Option>
              <Select.Option key={TemplateType.User} value={TemplateType.User}>
                User
              </Select.Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Card>
        <Table
          size="small"
          loading={isLoading}
          columns={templateColumns}
          dataSource={templates}
          pagination={pagination}
          onChange={handleChange}
          rowKey={(template: IEmailTemplate) => template.emailTemplateID}
          expandable={{ expandedRowRender: TemplateDetails }}
        />
      </Card>
    </Content>
  );
};

export default ListPage;
