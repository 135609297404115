import { Form, Modal, Input, Select, message } from 'antd';
import useSupportRequests, { ContactResultType } from 'hooks/Support/requests';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const ProcessRequestModal = (props: {
  visible: boolean;
  apiMethod: string;
  resultCodes: ContactResultType[];
  requestIDs: any[];
  contactName: string;
  onFinished: () => void;
  onCancel: () => void;
}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation('supportRequests');
  const { requestSubmitting, processBulkUpdate, noLongerSubmitting } =
    useSupportRequests();

  const submitRequestProcess = async () => {
    const results = await form.validateFields();
    results.supportRequestIDs = props.requestIDs;

    processBulkUpdate(props.apiMethod, results)
      .then(() => {
        message.success(t(`query.bulkupdate.success`));
        form.resetFields();
        props.onFinished();
      })
      .catch((error) => {
        const errorMsg = error?.response?.data?.message;
        message.error(
          t(`query.bulkupdate.error`, {
            msg: errorMsg ? t(errorMsg) : undefined,
          })
        );
        noLongerSubmitting();
      });
  };

  const cancelRequestProcess = async () => {
    form.resetFields();
    props.onCancel();
  };

  useEffect(() => {
    form.setFieldsValue({ contactName: props.contactName });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.visible]);

  return (
    <Modal
      title={t('processBulkRequests')}
      okText={t('okBulkRequests')}
      cancelText={t('cancelBulkRequests')}
      visible={props.visible}
      onCancel={cancelRequestProcess}
      onOk={submitRequestProcess}
      confirmLoading={requestSubmitting}
    >
      <Form
        name="basic"
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 12 }}
        autoComplete="off"
      >
        <p>{t('bulkProcessIntroText')}</p>
        <Form.Item
          label={t('contactName')}
          name="contactName"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="resultCode"
          label={t('resultCode')}
          rules={[{ required: true }]}
        >
          <Select
            placeholder={t('selectResultCode')}
            options={props.resultCodes.map((result) => ({
              label: result.resultTypeDescription,
              value: result.resultTypeID,
            }))}
          />
        </Form.Item>
        <Form.Item
          label={t('notes')}
          name="notes"
          rules={[{ required: false }]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ProcessRequestModal;
