import {
  Card,
  Button,
  DatePicker,
  Table,
  Tag,
  Modal,
  Space,
  Row,
  message,
} from 'antd';
import { Cross, Edit } from 'components/icons';
import { Content, TitleRow } from 'components/shared';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import moment, { Moment } from 'moment';
import useCommunications, {
  BulkEmailStatus,
} from 'hooks/Communications/Emails';
import { useNavigate } from 'react-router-dom';
import { Pagination } from 'hooks/Communications/Models';

const affiliateColumns = [
  {
    title: 'Affiliate ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    render: (email: AffiliateViewEmail) => email.address,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (status: ListStatus) => (
      <div>
        <Tag color={status.color}>{status.text}</Tag>
      </div>
    ),
  },
  {
    title: 'Date',
    dataIndex: 'deliveredDate',
    key: 'deliveredDate',
    render: (value: any) => (value ? value : ''),
  },
];

const emailActivityColumns = [
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: 'Date',
    dataIndex: 'occuredDate',
    key: 'occuredDate',
  },
  {
    title: 'Recipient',
    dataIndex: 'emailRecipient',
    key: 'emailRecipient',
  },
];

type ListStatus = {
  id: number;
  text: string;
  color: string;
};

class EmailActivityView {
  public occuredDate: string;
  public description: string;
  public emailRecipient: string;
  public constructor(
    description: string,
    occuredDate: string,
    emailRecipient: string
  ) {
    this.occuredDate = moment
      .utc(occuredDate)
      .local()
      .format('DD/MM/yyyy HH:mm');
    this.description = description[0].toUpperCase() + description.slice(1);
    this.emailRecipient = emailRecipient;
  }
}

class AffiliateViewEmail {
  public constructor(public address: string, public id: string) {}
}

class AffiliateView {
  static statusText: { [id: number]: ListStatus } = {
    1: { id: 1, text: 'Pending', color: 'gray' },
    2: { id: 2, text: 'Sending', color: 'blue' },
    3: { id: 3, text: 'Sent', color: 'green' },
    4: { id: 4, text: 'Failed', color: 'red' },
  };
  public status: { text: string; color: string };
  public key: string;
  public deliveredDate: string;
  public constructor(
    public id: string,
    public name: string,
    public email: AffiliateViewEmail,
    status: number,
    deliveredDate: string
  ) {
    this.key = id;
    this.deliveredDate =
      deliveredDate &&
      moment.utc(deliveredDate).local().format('DD/MM/yyyy HH:mm');
    this.status = AffiliateView.statusText[status];
  }
}

class AffiliateGroupView {
  public key: string;
  public constructor(
    public id: string,
    public name: string,
    public tracked: boolean,
    public affiliates: AffiliateView[]
  ) {
    this.key = id;
  }
}

class BulkEmailView {
  static statusText: { [id: number]: ListStatus } = {
    1: { id: 1, text: 'Draft', color: 'gray' },
    2: { id: 2, text: 'Sending', color: 'blue' },
    3: { id: 3, text: 'Sent', color: 'green' },
    4: { id: 4, text: 'Cancelled', color: 'orange' },
    5: { id: 5, text: 'Failed', color: 'red' },
    6: { id: 6, text: 'Partially Sent', color: 'yellow' },
  };
  public key: number;
  public created: string;
  public scheduled?: string;
  public status: ListStatus;
  public sendDateInThePast: boolean;
  public constructor(
    public id: number,
    public createdDate: string,
    public subject: string,
    public emailStatus: BulkEmailStatus,
    public affiliateGroups: AffiliateGroupView[],
    public sendDate?: string
  ) {
    this.key = id;
    var scheduledDate = moment.utc(sendDate).local();
    this.created = moment.utc(createdDate).local().format('DD/MM/yyyy HH:mm');
    if (createdDate !== sendDate)
      this.scheduled = scheduledDate.format('DD/MM/yyyy HH:mm');
    this.status = BulkEmailView.statusText[emailStatus];
    this.sendDateInThePast = scheduledDate < moment();
  }
}

const ListPage = () => {
  const { t } = useTranslation('communicationsMaint');
  const navigate = useNavigate();

  const {
    isLoading,
    bulkEmails,
    pagination,
    setPagination,
    fetchBulkEmails,
    getEmailActivity,
    cancelBulkEmail,
  } = useCommunications();
  const [startDate, setStartDate] = useState<Moment | null>(null);
  const [endDate, setEndDate] = useState<Moment | null>(null);

  const [emailActivities, setEmailActivities] = useState<{
    [id: string]: EmailActivityView[];
  }>({});

  const fetchEmailActivity = useCallback(async (emailId: string) => {
    const activities = await getEmailActivity(emailId);
    setEmailActivities((val) => {
      var obj = { ...val };
      obj[emailId] = activities.map(
        (activity) =>
          new EmailActivityView(
            activity.activityDesc,
            activity.activityDate,
            activity.emailRecipient
          )
      );
      return obj;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (tablePagination: any) => {
    const newPaginationVal = new Pagination(
      tablePagination.current,
      pagination.total,
      tablePagination.pageSize
    );
    setPagination(newPaginationVal);
    refresh(newPaginationVal);
  };

  const refresh = (paginationParam?: Pagination) => {
    const sDate = startDate?.utc().startOf('day');
    const eDate = endDate?.utc().endOf('day');
    fetchBulkEmails(
      paginationParam || pagination,
      sDate?.toISOString(),
      eDate?.toISOString()
    ).catch(() => {
      message.error('Error while loading data');
    });
  };

  const changeDate = (val: any) => {
    setStartDate(val?.[0]);
    setEndDate(val?.[1]);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(refresh, [startDate, endDate]);

  const cancelEmail = async (bulkEmailId: number) => {
    Modal.confirm({
      title: 'Are you sure you want to cancel this email?',
      onOk: async () => {
        await cancelBulkEmail(bulkEmailId);
        refresh();
      },
    });
  };

  const bulkEmailColumns = [
    { title: 'Subject', dataIndex: 'subject', key: 'subject' },
    { title: 'Created', dataIndex: 'created', key: 'created' },
    { title: 'Scheduled', dataIndex: 'scheduled', key: 'scheduled' },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status: ListStatus) => (
        <div>
          <Tag color={status.color}>{status.text}</Tag>
        </div>
      ),
    },
    {
      title: 'Action',
      width: 100,
      key: 'actions',
      align: 'right',
      render: (bulkEmail: BulkEmailView) => {
        const disabled =
          bulkEmail.sendDateInThePast ||
          bulkEmail.status.id !== BulkEmailStatus.Draft;
        return (
          <Space>
            <Link to={`/admin/communications/bulkemails/edit/${bulkEmail.id}`}>
              <Button
                title="Edit"
                onClick={() => navigate(``)}
                disabled={disabled}
              >
                <Edit
                  style={{
                    marginLeft: -2,
                    marginBottom: -2,
                    fontSize: '0.9em',
                  }}
                />
              </Button>
            </Link>
            <Button
              title="Delete"
              onClick={() => cancelEmail(bulkEmail.id)}
              disabled={disabled}
            >
              <Cross
                style={{
                  marginLeft: -2,
                  marginBottom: -2,
                  fontSize: '0.9em',
                }}
              />
            </Button>
          </Space>
        );
      },
    },
  ];

  const Affiliates = (affiliateGroup: AffiliateGroupView) => (
    <Table
      size="small"
      columns={affiliateColumns}
      pagination={false}
      dataSource={affiliateGroup.affiliates}
      expandable={{
        expandedRowRender: EmailActivity,
        rowExpandable: (val) => affiliateGroup.tracked,
        onExpand: (expanded: boolean, affiliate: AffiliateView) => {
          if (expanded && !emailActivities[affiliate.email.id])
            fetchEmailActivity(affiliate.email.id);
        },
      }}
    />
  );

  const EmailActivity = (affiliate: AffiliateView) => {
    const data = emailActivities[affiliate.email.id];

    return (
      <Table
        loading={emailActivities[affiliate.email.id] === undefined}
        size="small"
        rowKey={(record) => data.indexOf(record)}
        columns={emailActivityColumns}
        dataSource={data}
        pagination={false}
      />
    );
  };

  const AffiliateGroups = (record: BulkEmailView) => {
    const columns = [
      {
        title: 'Affiliate Group',
        dataIndex: 'name',
        key: 'name',
      },
    ];

    const affiliateGroupsWithAffiliates = record.affiliateGroups.filter(
      (ag) => ag.affiliates.length > 0
    );

    return (
      <Table
        size="small"
        rowKey="id"
        columns={columns}
        dataSource={affiliateGroupsWithAffiliates}
        pagination={false}
        expandable={{
          expandedRowRender: Affiliates,
          rowExpandable: (val) => val.affiliates.length > 0,
        }}
      />
    );
  };

  return (
    <Content>
      <TitleRow>
        <h1>{t('bulkemails')}</h1>
        <Link to={`/admin/communications/bulkemails/add`}>
          <Button>New Email</Button>
        </Link>
      </TitleRow>

      <Row justify="space-around">
        <DatePicker.RangePicker
          value={[startDate, endDate]}
          onChange={changeDate}
          disabledDate={(date) => date > moment()}
        />
      </Row>

      <Card style={{ marginTop: '2em' }}>
        <Table
          size="small"
          loading={isLoading}
          columns={bulkEmailColumns as any}
          onChange={handleChange}
          pagination={pagination}
          dataSource={bulkEmails.map(
            (be) =>
              new BulkEmailView(
                be.bulkEmailID,
                be.createdDate,
                be.emailSubject,
                be.emailStatus,
                be.affiliateGroups.map(
                  (ag) =>
                    new AffiliateGroupView(
                      ag.affiliateGroupID,
                      ag.affiliateGroupName,
                      be.trackActivity,
                      ag.affiliates.flatMap((a) =>
                        a.emails.map(
                          (e) =>
                            new AffiliateView(
                              a.affiliateID,
                              a.affiliateName,
                              new AffiliateViewEmail(
                                e.emailRecipients,
                                e.emailID
                              ),
                              e.sentStatus,
                              e.sentDate
                            )
                        )
                      )
                    )
                ),
                be.sendDate
              )
          )}
          expandable={{
            expandedRowRender: AffiliateGroups,
            rowExpandable: (val) =>
              val.affiliateGroups.some((ag) => ag.affiliates.length > 0),
          }}
        />
      </Card>
    </Content>
  );
};

export default ListPage;
