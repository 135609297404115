import { message } from 'antd';
import {
  Button,
  Card,
  DatePicker,
  Form,
  Row,
  Space,
  Table,
  Select,
  Modal,
  Popover,
} from 'components/antd';
import Confirmation from '../../../Apply/Components/Confirmation';
import Caution from './Caution';
import {
  Check,
  Cross /*, Search*/,
  InfoCircleOutline,
  Search,
} from 'components/icons';
import { Content, PopoverConfirmMessage, TitleValue } from 'components/shared';
import useAffiliateGroup, {
  AffiliateGroup,
} from 'hooks/Account/affiliateGroups';
import useAffiliateApprove, { AffiliateApprovalStatus } from 'hooks/approve';
import useAffiliateDetails, {
  PromotionalMethod,
} from 'hooks/Account/affiliateDetails';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

// this tricks the popover to close
const mouseClickEvents = ['mousedown', 'click', 'mouseup'];
function simulateMouseClick(element: any) {
  mouseClickEvents.forEach((mouseEventType) => {
    element.dispatchEvent(
      new MouseEvent(mouseEventType, {
        view: window,
        bubbles: true,
        cancelable: true,
        buttons: 1,
      })
    );
  });
}

const Page = () => {
  const { t } = useTranslation('admin.affiliates');
  const { t: c } = useTranslation('common');
  const [state, setState] = useState<any>({
    status: 1,
  });
  const [methods, setMethods] = useState<PromotionalMethod[]>([]);

  const {
    isLoading,
    load,
    affiliates,
    pagination,
    approve,
    decline,
    loadApplicationDetails,
  } = useAffiliateApprove();
  const { groups } = useAffiliateGroup();
  const [detail, setDetail] = useState<any>();
  const [detailLoadingId, setDetailLoadingId] = useState<string | null>();

  const { fetchPromotionalMethods } = useAffiliateDetails();

  const onConfirmApprove = (id: string, values: any) => {
    approve(id, values.groupId, values.reason)
      .then(() => {
        message.success('The application was approved');
        // this clicks outside of the popover and forces it to close
        var element = document.querySelector('.ant-table-wrapper');
        simulateMouseClick(element);
      })
      .catch(() => {
        message.error('There was an error approving this application');
      })
      .finally(() => {
        load(state);
      });
  };

  const onConfirmDecline = (id: string, values: any) => {
    decline(id, values.groupId)
      .then(() => {
        message.success('The application was declined');

        // this clicks outside of the popover and forces it to close
        var element = document.querySelector('.ant-table-wrapper');
        simulateMouseClick(element);
      })
      .catch(() => {
        message.error('There was an error declining this application');
      })
      .finally(() => {
        load(state);
      });
  };

  const getColumns = (t: Function, groups: AffiliateGroup[]) => [
    {
      title: t('name'),
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
    },
    {
      title: t('applicationDate'),
      dataIndex: 'applicationDate',
      key: 'applicationDate',
      render: (value: string) => value && c('date', { value: new Date(value) }),
      width: 200,
    },
    {
      title: t('company'),
      dataIndex: 'company',
      key: 'company',
    },
    {
      title: t('email'),
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: t('cautionlevel'),
      dataIndex: 'cautionlevel',
      key: 'cautionlevel',
      width: 110,
      render: (value: number) => <Caution value={value} />,
    },
    {
      title: t('action'),
      dataIndex: '',
      key: '',
      width: 130,
      align: 'right',
      render: (af: any) => (
        <Space>
          <Button
            loading={af.id === detailLoadingId}
            onClick={() => onLoadDetail(af.id)}
            icon={<Search style={{ marginLeft: -2, marginBottom: -2 }} />}
          />

          <PopoverConfirmMessage
            disabled={state.status !== AffiliateApprovalStatus.Pending}
            groups={groups}
            onFinish={(values) => onConfirmApprove(af.id, values)}
          >
            <Button
              disabled={state.status !== AffiliateApprovalStatus.Pending}
              icon={<Check style={{ marginLeft: -2, marginBottom: -2 }} />}
            />
          </PopoverConfirmMessage>

          <PopoverConfirmMessage
            onFinish={(values) => onConfirmDecline(af.id, values)}
            disabled={state.status !== AffiliateApprovalStatus.Pending}
          >
            <Button
              disabled={state.status !== AffiliateApprovalStatus.Pending}
              icon={
                <Cross
                  style={{
                    fontSize: '0.8em',
                    marginLeft: -1,
                    marginBottom: -1,
                  }}
                />
              }
            />
          </PopoverConfirmMessage>
        </Space>
      ),
    },
  ];

  const columns = getColumns(c, groups) as any;

  useEffect(() => {
    load(state).catch(() => {
      message.error('There was an error loading the applications');
    });
    fetchPromotionalMethods()
      .then(setMethods)
      .catch(() => {
        message.error(t('wizard:promoMethodsLoadingError'));
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    setState({ ...state, ...pagination });
    load({ ...state, ...pagination }).catch(() => {
      message.error('There was an error loading the applications');
    });
  };

  const onFinish = (values: any) => {
    setState({ ...state, ...values, current: 1 });
    load({ ...state, ...values, current: 1 }).catch(() => {
      message.error('There was an error loading the applications');
    });
  };

  const onLoadDetail = (id: string) => {
    setDetailLoadingId(id);

    loadApplicationDetails(id)
      .then((d: any) => {
        setDetail(d);
      })
      .catch(() => {
        message.error(t('affiliateApplicationDetail.error'));
      })
      .finally(() => {
        setDetailLoadingId(null);
      });
  };

  return (
    <Content>
      <h1>{t('affiliatesApprove')}</h1>

      <Row justify="center" style={{ paddingTop: '2em', paddingBottom: '2em' }}>
        <Form layout="inline" initialValues={state}>
          <Form.Item name="date">
            <DatePicker.RangePicker
              allowClear
              onChange={(results: any) =>
                onFinish({
                  ...state,
                  fromDate: results && results[0],
                  toDate: results && results[1],
                })
              }
            />
          </Form.Item>

          <Form.Item name="status">
            <Select
              style={{ minWidth: 140 }}
              onChange={(value) => onFinish({ ...state, status: value })}
            >
              <Option value={1}>Pending</Option>
              <Option value={2}>Approved</Option>
              <Option value={3}>Declined</Option>
            </Select>
          </Form.Item>
        </Form>
      </Row>

      <Card>
        <Table
          loading={isLoading}
          rowKey="id"
          columns={columns}
          onChange={handleTableChange}
          dataSource={affiliates}
          pagination={pagination}
        />
      </Card>

      <Modal
        closable={true}
        title={t('affiliateApplicationDetails') + ' ' + detail?.id}
        visible={detail}
        footer={null}
        onOk={() => setDetail(null)}
        onCancel={() => setDetail(null)}
        width={1200}
      >
        {/* Risk information */}
        <h2>{t('riskInformation')}</h2>
        <Row style={{ paddingLeft: '1em', paddingBottom: '1em' }}>
          <TitleValue
            title={t('cautionLevel')}
            value={<Caution value={detail?.riskScore?.totalScore} />}
          />
        </Row>
        <Row style={{ paddingLeft: '1em', paddingBottom: '1em' }}>
          <TitleValue
            title={t('declinedBankingDetailsCount')}
            value={detail?.riskScore?.declinedBankingDetailsCount}
          />
          <TitleValue
            title={t('sameApplicationIP')}
            value={
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {detail?.riskScore?.sameApplicationIP}
                {detail?.applicationIP && (
                  <>
                    <Popover content={detail?.applicationIP}>
                      <InfoCircleOutline
                        style={{
                          fontSize: '1.2em',
                          marginLeft: '0.2em',
                          cursor: 'pointer',
                        }}
                      />
                    </Popover>
                  </>
                )}
              </div>
            }
          />
          <TitleValue
            title={t('sameBankingDetailsCount')}
            value={detail?.riskScore?.sameBankingDetailsCount}
          />
        </Row>
        <Confirmation details={detail} methods={methods} />
      </Modal>
    </Content>
  );
};

export default Page;
