import {
  Button,
  Card,
  Form,
  Input,
  message,
  Select,
  Upload,
} from 'components/antd';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Content } from 'components/shared';
import usePaymentRequests, {
  UpdateRequest,
  UpdateRequestNotes,
} from 'hooks/Financial/paymentrequests';
import { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import useUser from 'hooks/User';
import { Features } from 'constants/features';
import { Upload as UploadIcon } from 'components/icons';
import usePurchaseOrders from 'hooks/Financial/purchaseorders';

const Columns = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 992px) {
    display: grid;
    grid-template-columns: 1fr 400px;
  }
`;

const Page = () => {
  const { t } = useTranslation('financialAdminPaymentRequests');
  const { t: c } = useTranslation('common');
  let { id } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { getFeature } = useUser();
  const {
    paymentRequest,
    requestsLoading,
    requestSubmitting,
    requestTypes,
    requestStatuses,
    uploadSubmitting,
    loadRequest,
    uploadInvoice,
    setStatuses,
    updatePaymentRequest,
    updatePaymentRequestNotes,
    noLongerSubmitting,
    noLongerUploading,
  } = usePaymentRequests();
  const { purchaseOrderReferences } = usePurchaseOrders();

  useEffect(() => {
    if (id) {
      loadRequest(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (paymentRequest !== undefined) {
      setStatuses(paymentRequest.status!);

      form.setFieldsValue({
        status: paymentRequest.status!,
        amount: formatNumber(paymentRequest.amount),
        lastModifiedDate: c('date', {
          value: new Date(paymentRequest.lastModifiedDate),
        }),
        dateCreated: c('date', { value: new Date(paymentRequest.dateCreated) }),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentRequest]);

  const formatNumber = (value: any) =>
    `${c('number', {
      value: parseFloat(value).toFixed(2),
    })}`;

  const hasPurchaseOrderFunctionality = getFeature(
    Features['Finance.Features.PurchaseOrders']
  ).enabled;

  const onFinish = async (values: any) => {
    const results = await form.validateFields();

    let updateRequest: UpdateRequest = {
      status:
        results.status === 'NotInvoiced'
          ? 1
          : results.status === 'Invoiced'
          ? 2
          : results.status === 'PaidByTenant'
          ? 3
          : results.status === 'Created'
          ? 5
          : results.status === 'PaidToClient'
          ? 6
          : 4,
      orderReference: results.orderReference,
      purchaseOrderId: hasPurchaseOrderFunctionality
        ? results.purchaseOrderId
        : undefined,
    };

    let updateNotesRequest: UpdateRequestNotes = {
      notes: results.notes,
    };

    updatePaymentRequestNotes(id!, updateNotesRequest)
      .then(() => {
        updatePaymentRequest(id!, updateRequest)
          .then(() => {
            message.success(t(`payment.edit.success`));
            navigate(`/admin/financial/paymentrequests/list`);
          })
          .catch((error) => {
            const errorMessages = Object.values(error?.response?.data?.errors);

            if (errorMessages.length >= 1) {
              errorMessages.flat().forEach((value) => {
                message.error(
                  t(`payment.edit.error`, {
                    msg: typeof value === 'string' ? t(value) : undefined,
                  })
                );
              });
            } else {
              const errorMsg = error?.response?.data?.message;

              message.error(
                t(`payment.edit.error`, {
                  msg: errorMsg ? t(errorMsg) : undefined,
                })
              );
            }
            noLongerSubmitting();
          });
      })
      .catch((error) => {
        const errorMessages = Object.values(error?.response?.data?.errors);

        if (errorMessages.length >= 1) {
          errorMessages.flat().forEach((value) => {
            message.error(
              t(`payment.edit.error`, {
                msg: typeof value === 'string' ? t(value) : undefined,
              })
            );
          });
        } else {
          const errorMsg = error?.response?.data?.message;

          message.error(
            t(`payment.edit.error`, {
              msg: errorMsg ? t(errorMsg) : undefined,
            })
          );
        }
        noLongerSubmitting();
      });
  };

  const onUpload = (options: any) => {
    const { file } = options;
    const formData = new FormData();
    formData.append('file', file);

    uploadInvoice(id!, formData)
      .then(() => {
        message.success(t(`invoice.upload.success`));
        loadRequest(id!);
      })
      .catch((error) => {
        const errorMsg = error?.response?.data;

        message.error(
          t(`invoice.upload.error`, {
            msg: errorMsg ? t(errorMsg) : undefined,
          })
        );
        noLongerUploading();
      });

    return false;
  };

  return (
    <Content>
      <h1>{t('updatePaymentRequest')}</h1>
      <Card loading={requestsLoading}>
        <Columns>
          <Form
            initialValues={paymentRequest}
            name="basic"
            form={form}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 12 }}
            autoComplete="off"
            onFinish={onFinish}
          >
            <Form.Item name="paymentRequestType" label={t('requestType')}>
              <Select
                placeholder={t('selectRequestType')}
                disabled={true}
                options={requestTypes.map((rt) => ({
                  label: t(rt.requestTypeName),
                  value: rt.requestTypeValue,
                }))}
              />
            </Form.Item>

            <Form.Item name="affiliateName" label={t('affiliateName')}>
              <Input disabled={true} />
            </Form.Item>

            <Form.Item
              name="affiliateExternalIdentifier"
              label={t('affiliateExternalIdentifier')}
            >
              <Input disabled={true} />
            </Form.Item>

            <Form.Item label={t('amount')} name="amount">
              <Input disabled={true} />
            </Form.Item>

            <Form.Item label={t('dateCreated')} name="dateCreated">
              <Input disabled={true} />
            </Form.Item>

            <Form.Item label={t('lastUpdatedDate')} name="lastModifiedDate">
              <Input disabled={true} />
            </Form.Item>

            <Form.Item label={t('fileReference')} name="fileReference">
              <Input disabled={true} />
            </Form.Item>

            <Form.Item label={t('orderReference')} name="orderReference">
              <Input
                disabled={
                  paymentRequest?.paymentRequestType === 'Commission'
                    ? true
                    : paymentRequest?.status === 'Created'
                    ? false
                    : paymentRequest?.status === 'NotInvoiced'
                    ? false
                    : true
                }
              />
            </Form.Item>

            {hasPurchaseOrderFunctionality && (
              <Form.Item name="purchaseOrderId" label={t('purchaseOrder')}>
                <Select
                  placeholder={t('selectPurchaseOrder')}
                  options={purchaseOrderReferences.map((po) => ({
                    label: po.tenantPurchaseOrderReference,
                    value: po.id.toString(),
                  }))}
                />
              </Form.Item>
            )}

            <Form.Item label={t('notes')} name="notes">
              <Input />
            </Form.Item>

            <Form.Item
              label={t('status')}
              name="status"
              rules={[{ required: true }]}
            >
              <Select
                placeholder={t('selectStatus')}
                options={requestStatuses.map((rs) => ({
                  label: t(rs.statusTypeName),
                  value: rs.statusTypeValue,
                }))}
              />
            </Form.Item>

            {paymentRequest?.status! === 'NotInvoiced' && (
              <Form.Item wrapperCol={{ offset: 8 }}>
                <Upload
                  showUploadList={false}
                  customRequest={onUpload}
                  accept=".pdf"
                >
                  <Button
                    loading={uploadSubmitting}
                    icon={
                      <UploadIcon
                        style={{
                          fontSize: '1.2em',
                          marginBottom: -3,
                          marginLeft: -3,
                          marginRight: 3,
                        }}
                      />
                    }
                  >
                    {t('uploadButton')}
                  </Button>
                </Upload>
              </Form.Item>
            )}

            <Form.Item wrapperCol={{ offset: 8 }}>
              <Button
                type="primary"
                htmlType="submit"
                loading={requestSubmitting}
              >
                {c('submit')}
              </Button>
            </Form.Item>
          </Form>
        </Columns>
      </Card>
    </Content>
  );
};

export default Page;
