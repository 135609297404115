import { Select, Button } from 'antd';
import { useState } from 'react';
import { IEmailTemplate } from '../../../../../hooks/Communications/Models';

const TemplateSelect = (props: {
  label: string;
  templates: IEmailTemplate[];
  onSelect?: (templateId?: number) => void;
}) => {
  const [selectedId, setSelectedId] = useState<number | undefined>(undefined);

  return (
    <div className="ant-row ant-form-item">
      <div className="ant-col ant-form-item-label">
        <label>Template</label>
      </div>
      <div className="ant-col ant-form-item-control">
        <Select
          allowClear
          value={selectedId}
          onChange={(templateId: number) => {
            setSelectedId(templateId);
            props.onSelect && props.onSelect(templateId);
          }}
          dropdownRender={(menu) => (
            <>
              {menu}
              <div style={{ padding: '4px 6px', textAlign: 'center' }}>
                <Button
                  style={{ marginRight: '4px' }}
                  onClick={() => setSelectedId(undefined)}
                >
                  Clear
                </Button>
              </div>
            </>
          )}
        >
          {props.templates.map((t: IEmailTemplate) => (
            <Select.Option key={t.emailTemplateID} value={t.emailTemplateID}>
              {t.templateName}
            </Select.Option>
          ))}
        </Select>
      </div>
    </div>
  );
};

export default TemplateSelect;
