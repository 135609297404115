import {
  Button,
  Card,
  Form,
  Input,
  Select,
  Switch,
  message,
} from 'components/antd';
import { Content } from 'components/shared';
import Editor from 'components/shared/Editor';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import { FAQCategoryCompact } from 'hooks/Support/faqCategories';
import useFAQs from 'hooks/Support/faqs';

type Props = {
  isNew?: boolean;
  categories?: FAQCategoryCompact[];
};

const Page = ({ isNew, categories = [] }: Props) => {
  const { t } = useTranslation('supportFAQs');
  const [form] = Form.useForm();
  let { id } = useParams();
  const navigate = useNavigate();

  const { loadFAQ, faqsLoading, faq, store } = useFAQs();
  useEffect(() => {
    if (!isNew && id) {
      loadFAQ(parseInt(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFinish = async (values: any) => {
    const results = await form.validateFields();
    const catIds = results.categories;
    delete results.categories;

    results.faqCategories = catIds.map((id: any) => ({
      FAQCategoryID: id,
    }));

    if (!isNew) results.faqID = id;
    const localePath = isNew ? 'add' : 'edit';

    store(results)
      .then(() => {
        message.success(t(`faq.${localePath}.success`));
        navigate(`/admin/faq/faqs`);
      })
      .catch((error) => {
        const errorMsg = error?.response?.data?.message;
        message.error(
          t(`faq.${localePath}.error`, {
            msg: errorMsg ? t(errorMsg) : undefined,
          })
        );
      });
  };

  const options = categories.map((c) => ({
    label: c.faqCategoryName,
    value: c.faqCategoryID?.toString(),
  }));

  return (
    <Content>
      <h1>{t(isNew ? 'faqAdminCreate' : 'faqAdminEdit')}</h1>
      <Card loading={faqsLoading}>
        <Form
          initialValues={faq}
          name="basic"
          form={form}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 12 }}
          autoComplete="off"
          onFinish={onFinish}
        >
          <Form.Item
            label={t('question')}
            name="question"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="categories"
            label={t('categories')}
            rules={[{ required: true }]}
          >
            <Select
              mode="multiple"
              allowClear
              placeholder={t('selectCategory')}
              options={options as any[]}
            />
          </Form.Item>

          <Form.Item
            label={t('isActive')}
            name="isActive"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>

          <Form.Item
            label={t('answer')}
            name="answer"
            style={{ height: 'auto' }}
            rules={[{ required: true }]}
          >
            <Editor hideTracking={true} />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8 }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </Content>
  );
};

export default Page;
