import api from 'libs/api';
import { useState } from 'react';

const useProductProducts = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [productStatusChange, setProductStatusChange] = useState<
    string | undefined
  >(undefined);
  const [localStorageColumns, setLocalStorageColumns] = useState<any>(
    // @ts-ignore
    JSON.parse(localStorage.getItem('productFieldNames'))
  );
  const [doProductsRequireApproval, setDoProductsRequireApproval] =
    useState<any>(
      // @ts-ignore
      JSON.parse(localStorage.getItem('doProductsRequireApproval'))
    );
  const fetchFields = async () => {
    setIsLoading(true);
    try {
      if (localStorageColumns && doProductsRequireApproval)
        return localStorageColumns;
      const result: any = await api.get(`/products/config/public`);
      localStorage.setItem(
        'productFieldNames',
        JSON.stringify(result.data.fieldNames)
      );
      localStorage.setItem(
        'doProductsRequireApproval',
        JSON.stringify(result.data.doProductsRequireApproval)
      );
      setLocalStorageColumns(result.data.fieldNames);
      setDoProductsRequireApproval(result.data.doProductsRequireApproval);

      return result.data.fieldNames;
    } finally {
      setIsLoading(false);
    }
  };

  const fetchProduct = async (productId: number) => {
    setIsLoading(true);
    try {
      const result: any = await api.get(`/products/product/${productId}`);
      return result.data;
    } finally {
      setIsLoading(false);
    }
  };

  const approveProduct = async (id: number) => {
    setIsLoading(true);
    try {
      const bodyObj = { productIds: [id] };

      const result: any = await api.put(`/products/product/approve`, bodyObj);
      setProductStatusChange('approved');
      return result;
    } catch (err) {
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  const enableProduct = async (id: number) => {
    setIsLoading(true);
    try {
      const result: any = await api.put(`/products/product/${id}/enable`);
      setProductStatusChange('enabled');
      return result;
    } catch (err) {
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  const disableProduct = async (id: number) => {
    setIsLoading(true);
    try {
      const result: any = await api.put(`/products/product/${id}/disable`);
      setProductStatusChange('disabled');
      return result;
    } catch (err) {
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  const updateProduct = async (id: number, attributes: any) => {
    const nameIndex = Object.keys(attributes).indexOf('$name');
    let updatedObj = {
      name: Object.values(attributes)[nameIndex],
    };

    // Removed enabled key/value from data
    delete attributes.enabled;
    delete attributes.$name;
    delete attributes.$externalId;

    // Add the data object
    // @ts-ignore
    updatedObj = { ...updatedObj, attributes };

    setIsLoading(true);
    try {
      const result: any = await api.put(`/products/product/${id}`, updatedObj);
      return result;
      //Go back to table
    } catch (err) {
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    // Variables
    isLoading,
    productStatusChange,
    doProductsRequireApproval,
    // Methods
    loadFields: fetchFields,
    loadProduct: fetchProduct,
    approveProduct: approveProduct,
    updateProduct: updateProduct,
    enableProduct: enableProduct,
    disableProduct: disableProduct,
  };
};

export default useProductProducts;
