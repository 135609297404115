import { message } from 'components/antd';
import { Features } from 'constants/features';
import { Permissions } from 'constants/permissions';
import api, {
  addImpersionationUserId,
  removeImpersionationUserId,
} from 'libs/api';
import { User } from 'oidc-client';
import { useContext } from 'react';
import { tenantId as envTenantId } from 'constants/env';

import { IUser, IUserProvider, UserContext } from './Context';
import moment from 'moment-timezone';

type Feature = {
  id: string;
  visible: boolean;
  enabled: boolean;
};

type Tenant = {
  id: string;
  bannerUrl: string;
  name: string;
  domain: string;
};

const useUser = () => {
  const context: IUserProvider = useContext(UserContext);

  const { user, setUser } = context;

  const getFeature = (feature: Features) => {
    if (!user) return { show: false, enabled: false };
    return user.features[feature] || { show: false, enabled: false };
  };
  const isFeatureEnabled = (feature: Features) => {
    const result = user?.features[feature];
    return result && result.enabled;
  };
  const setFeature = (feature: string, show: boolean, enabled: boolean) => {
    if (!user) return;
    user.features[feature] = { show, enabled };
    setUser({ ...user });
  };

  const hasPermission = (permission: Permissions): boolean => {
    return !!(
      user &&
      user.permissions &&
      user.permissions[permission] !== undefined &&
      user.permissions[permission]
    );
  };
  const addPermission = (permission: string) => {
    user.permissions[permission] = true;
    setUser({ ...user });
  };
  const removePermission = (permission: string) => {
    user.permissions[permission] = false;
    setUser({ ...user });
  };

  const convertPermissions = (perms: any[]) =>
    perms.reduce((prev: { [key: string]: boolean }, curr: string) => {
      prev[curr] = true;
      return prev;
    }, {});

  const convertFeatures = (feats: any[]) =>
    feats.reduce(
      (
        prev: { [key: string]: { show: boolean; enabled: boolean } },
        curr: Feature
      ) => {
        prev[curr.id] = { show: curr.visible, enabled: curr.enabled };
        return prev;
      },
      {}
    );

  const resetUser = () => {
    setUser(null);
  };

  const approveAgreement = () => {
    setUser({ ...user, hasNewAgreement: false });
    return;
  };

  const approvePlatformAgreement = () => {
    setUser({ ...user, hasNewSelfBillingAgreement: false });
    return;
  };

  const approveDataReview = () => {
    setUser({ ...user, enforceDataReview: false });
    return;
  };

  // Here we determin which tenant will be used for the UI and for all of the api calls
  const getCurrentTenant = (tenantList: any[]) => {
    const currentHost: string = window.location.host.toLocaleLowerCase();
    // if we are on localhost default to envTenantId
    // if we have the tenants id in the list
    if (
      currentHost.includes('localhost') &&
      envTenantId &&
      tenantList.length > 0 &&
      tenantList.find((x: Tenant) => x.id === envTenantId)
    ) {
      return envTenantId;
    }

    const currentTenant = tenantList.find(
      (x: Tenant) => x.domain.toLowerCase() === currentHost
    );

    return currentTenant?.id;
  };

  const fetchUserInfo = async (returnUrl?: string): Promise<IUser | null> => {
    let u: User | null = null;
    try {
      u = await context.getUser(resetUser)();
    } catch (err) {
      console.error(err);
    }

    if (!u) return null;

    const userId = u.profile.sub;

    let config = {
      headers: {
        Authorization: 'Bearer ' + u.access_token,
      },
    };

    let permissions: any = {},
      features: any = {},
      affiliateId: string = '',
      email: string = '',
      fullName: string = '',
      hasNewAgreement: boolean = false,
      enforceAgreement: boolean = true,
      hasNewSelfBillingAgreement: boolean = false,
      enforceSelfBillingAgreement: boolean = true,
      enforceDataReview: boolean = false,
      reviewRequiredDate: string = '',
      tenantId: string = '',
      tenants: Tenant[] = [];

    try {
      const tenantResult = await api.get(
        `/system/user/${userId}/tenant`,
        config
      );
      if (!Array.isArray(tenantResult.data))
        throw new Error('Invalid tenant data');
      tenants = tenantResult.data || [];
      tenantId = getCurrentTenant(tenants);
      if (tenantId) {
        const [permsResult, featuresResult, affiliateResult] =
          await Promise.all(
            [
              api.get(
                `/system/tenant/${tenantId}/user/${userId}/permission`,
                config
              ),
              api.get(`/system/tenant/${tenantId}/feature`, config),
              api.get(`/accounts/user/${userId}/affiliate`),
            ].map((p) => p.then((result) => result.data))
          );

        permissions = convertPermissions(permsResult);
        features = convertFeatures(featuresResult);
        affiliateId = affiliateResult.id;
        email = affiliateResult.email;
        fullName = `${affiliateResult.firstName} ${affiliateResult.surname}`;
        hasNewAgreement =
          parseInt(affiliateResult.latestTermsVersionAccepted) <
          parseInt(affiliateResult.systemLatestTerms);
        enforceAgreement = affiliateResult.enforceLatestTerms;
        hasNewSelfBillingAgreement =
          parseInt(affiliateResult.latestSelfBillingAccepted) <
          parseInt(affiliateResult.systemLatestSelfBilling);
        enforceSelfBillingAgreement = affiliateResult.enforceLatestSelfBilling;
        enforceDataReview =
          moment.utc(affiliateResult.nextReviewDate) < moment.utc();
        reviewRequiredDate = affiliateResult.reviewRequiredDate;
      }
    } catch (error) {
      console.error(error);

      message.error('Error downloading user data. You will be logged out');
      setTimeout(() => {
        context.logout();
      }, 1000);
      return null;
    }
    const newUser: IUser = {
      id: userId,
      affiliateId: affiliateId,
      tenantId,
      name: fullName,
      email: email,
      permissions,
      features,
      hasNewAgreement,
      enforceAgreement,
      hasNewSelfBillingAgreement,
      enforceSelfBillingAgreement,
      enforceDataReview,
      reviewRequiredDate,
      returnUrl: returnUrl || '/',
      availableTenants: tenants,
    };
    setUser(newUser);
    return newUser;
  };

  const impersonateAffiliate = async (
    affiliateId?: string,
    affiliate?: any,
    tenantId?: string
  ) => {
    if (!affiliateId) {
      context.impersonate(null);
      sessionStorage.removeItem('impersonationUser');
      removeImpersionationUserId();
      return;
    }
    sessionStorage.setItem('impersonationUser', JSON.stringify(affiliate));
    const userId = affiliate.userId;
    if (!userId) throw new Error('NO_IMPERSONATION_USERID');

    const newUser = { ...user };

    // LOAD AFFILIATE INFO AND PERMISSIONS
    const permissions = await api
      .get(
        `/system/tenant/${tenantId || user.tenantId}/user/${userId}/permission`
      )
      .then((result) => result.data);

    newUser.name = affiliate?.name || 'New User';
    newUser.affiliateId = affiliate?.id || 'newID';
    newUser.email = affiliate?.email || 'newemail@blablabla.com';
    newUser.id = userId;
    newUser.permissions = convertPermissions(permissions || {});

    // Add the impersonation token to all the api calls from this point on
    addImpersionationUserId(userId);
    context.impersonate(newUser);
  };

  return {
    // Variables
    hasPermission,
    getFeature,
    isFeatureEnabled,
    // Test Methods - remove this when not testing how it works
    addPermission,
    removePermission,
    setFeature,
    permissions: user?.permissions || [],
    features: user?.features || [],
    isImpersonating: context.isImpersonated,
    user,
    // Methods
    // New Remove the above
    signinRedirectCallback: context.signinRedirectCallback,
    logout: context.logout,
    signoutRedirectCallback: context.signoutRedirectCallback,
    signinSilentCallback: context.signinSilentCallback,
    isAuthenticated: () => context.isAuthenticated(),
    signinRedirect: context.signinRedirect,
    getUser: context.getUser(resetUser),
    fetchUserInfo,
    approveAgreement,
    impersonateAffiliate,
    approvePlatformAgreement,
    approveDataReview,
  };
};

export default useUser;
