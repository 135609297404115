import {
  Report,
  Admin,
  News,
  Dashboard,
  Creative,
  Money,
  Support,
} from 'components/icons';

import { Menu } from 'components/antd';
import { MenuItemCheck, SubMenuCheck } from 'components/shared';

import { useTranslation } from 'react-i18next';
import useLayout from 'hooks/Layout';
import useReports from 'hooks/Reporting/reports';
import { useLocation } from 'react-router';
import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

import { Permissions } from 'constants/permissions';
import { Features } from 'constants/features';
import useUser from 'hooks/User';

const { SubMenu } = Menu;

const Sidebar = () => {
  const { t } = useTranslation();
  const { showSidebar, minimized, logoName, logoPath } = useLayout();
  const { reports, loadReports } = useReports();
  const { pathname } = useLocation();
  const [selectedKeys, setSelectedKeys] = useState([
    '/adminGroup',
    'adminAffiliates',
  ]);
  const [openKeys, setOpenKeys] = useState(selectedKeys);

  const { user, getFeature, isFeatureEnabled, hasPermission } = useUser();

  useEffect(() => {
    loadReports();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.id]);

  useEffect(() => {
    const parts = pathname.split('/').filter((i) => i);
    let part = parts[0];
    let i = 1;
    let result = [part];
    while (i < parts.length) {
      part += `.${parts[i]}`;
      result.push(part);
      i++;
    }

    setSelectedKeys(result);
    // maybe check if we need to do this below?
    if (!minimized) setOpenKeys([...openKeys, ...result]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const onOpenChange = (keys: string[]) => {
    setOpenKeys(keys);
  };

  const handleClick = (e: any) => {
    setSelectedKeys(e.keyPath);
    setOpenKeys([...openKeys, ...e.keyPath]);
  };

  // Display top sellers if the user has the correct features and permissions enabled
  const displayTopSellers =
    (isFeatureEnabled(Features['Reporting.Report.TopSellers']) ||
      getFeature(Features['Reporting.Report.TopSellers']).enabled) &&
    getFeature(Features['Reporting.Report.TopSellers']).show &&
    hasPermission(Permissions['Reporting.Report.TopSellers']) &&
    (hasPermission(Permissions['Products.Highlighted.Read']) ||
      hasPermission(Permissions['Products.Highlighted.ReadWrite']));

  // Display the Product Highlighting Admin page if the user has the correct features and permissions enabled
  const displayHighlightProductsAdmin =
    (isFeatureEnabled(Features['Reporting.Report.TopSellers']) ||
      getFeature(Features['Reporting.Report.TopSellers']).enabled) &&
    getFeature(Features['Reporting.Report.TopSellers']).show &&
    hasPermission(Permissions['Reporting.Report.TopSellers']) &&
    hasPermission(Permissions['Products.Highlighted.ReadWrite']);

  if (!showSidebar) return <></>;
  return (
    <aside style={{ textAlign: 'center' }}>
      <img
        src={`/assets/logo/${logoPath}`}
        alt={logoName}
        title={logoName}
        style={{
          margin: minimized ? 4 : 16,
          transition: 'all 0.5s ease',
          maxWidth: '8em',
          width: minimized ? 'calc(100% - 16px)' : '100%',
        }}
      />

      <nav>
        <Menu
          onClick={handleClick}
          mode={minimized ? 'vertical' : 'inline'}
          inlineCollapsed={false}
          selectedKeys={selectedKeys}
          openKeys={openKeys}
          onOpenChange={onOpenChange}
        >
          <Menu.Item key="dashboard" icon={<Dashboard />}>
            <NavLink to={'/dashboard'}>{t('dashboard')}</NavLink>
          </Menu.Item>
          <MenuItemCheck
            key="news"
            icon={<News />}
            feature={Features['Content.News']}
          >
            <NavLink to={'/news'}>{t('news')}</NavLink>
          </MenuItemCheck>
          <SubMenuCheck
            key="reporting"
            icon={<Report color="unset" />}
            title={t('reporting')}
            feature={Features.Reporting}
          >
            {(reports || []).map((report) => (
              <Menu.Item
                key={`reporting.${report.ID}`}
                disabled={!report.Enabled}
              >
                <NavLink to={`/reporting/${report.ID}`}>{report.Name}</NavLink>
              </Menu.Item>
            ))}
            {displayTopSellers && (
              <Menu.Item key="topsellers">
                <NavLink to={'/topsellers'}>{t('topsellers')}</NavLink>
              </Menu.Item>
            )}
          </SubMenuCheck>
          <SubMenu key="creatives" icon={<Creative />} title={t('creatives')}>
            <MenuItemCheck
              key="creatives.banners"
              feature={Features['Resources.Banners']}
            >
              <NavLink to={'/creatives/banners'}>{t('banners')}</NavLink>
            </MenuItemCheck>
            <Menu.Item key="creatives.feeds">
              <NavLink to={'/creatives/feeds'}>{t('feeds')}</NavLink>
            </Menu.Item>
            <MenuItemCheck
              key="creatives.links"
              feature={Features['Resources.Links']}
            >
              <NavLink to={'/creatives/links'}>{t('links')}</NavLink>
            </MenuItemCheck>
          </SubMenu>
          <SubMenu key="financials" icon={<Money />} title={t('financials')}>
            <Menu.Item key="financials.commissions">
              <NavLink to={'/financials/commissions'}>
                {t('commissions')}
              </NavLink>
            </Menu.Item>
            <Menu.Item key="financials.invoices">
              <NavLink to={'/financials/invoices'}>{t('invoices')}</NavLink>
            </Menu.Item>
          </SubMenu>
          <SubMenu key="support" icon={<Support />} title={t('support')}>
            <Menu.Item key="support.requests">
              <NavLink to={'/support/requests'}>{t('supportrequests')}</NavLink>
            </Menu.Item>

            <MenuItemCheck
              key="support.faqs"
              feature={Features['Content.FAQs']}
            >
              <NavLink to={'/support/faqs'}>{t('faqs')}</NavLink>
            </MenuItemCheck>
            <Menu.Item key="support.platformagreement">
              <NavLink to={'/support/platformagreement'}>
                {t('supportPlatformAgreement')}
              </NavLink>
            </Menu.Item>
            <Menu.Item key="support.terms">
              <NavLink to={'/support/termsandconditions'}>
                {t('supportTerms')}
              </NavLink>
            </Menu.Item>
          </SubMenu>

          <Menu.Divider />

          <SubMenuCheck
            key="admin"
            icon={<Admin />}
            permission={Permissions['UI.CanViewAdminSection']}
            title={t('admin')}
          >
            <SubMenu key="admin.affiliates" title={t('affiliates')}>
              <Menu.Item key="admin.affiliates.list">
                <NavLink to={'/admin/affiliates/list'}>
                  {t('affiliates')}
                </NavLink>
              </Menu.Item>
              <Menu.Item key="admin.affiliates.approve">
                <NavLink to={'/admin/affiliates/approve'}>
                  {t('applications')}
                </NavLink>
              </Menu.Item>
              <Menu.Item key="admin.affiliates.bank">
                <NavLink to={'/admin/affiliates/bank'}>
                  {t('paymentChangeRequests')}
                </NavLink>
              </Menu.Item>
              <Menu.Item key="admin.affiliates.groups">
                <NavLink to={'/admin/affiliates/groups'}>{t('groups')}</NavLink>
              </Menu.Item>
            </SubMenu>
            <SubMenu key="admin.banners" title={t('banners')}>
              <Menu.Item key="admin.banners.list">
                <NavLink to={'/admin/banners/list'}>{t('banners')}</NavLink>
              </Menu.Item>
              <Menu.Item key="admin.banners.groups">
                <NavLink to={'/admin/banners/groups'}>
                  {t('bannergroups')}
                </NavLink>
              </Menu.Item>
            </SubMenu>
            <SubMenu key="admin.communications" title={t('communications')}>
              <Menu.Item key="admin.communications.bulkemails">
                <NavLink to={'/admin/communications/bulkemails'}>
                  {t('bulkemails')}
                </NavLink>
              </Menu.Item>
              <Menu.Item key="admin.communications.emailtemplates">
                <NavLink to={'/admin/communications/emailtemplates'}>
                  {t('emailtemplates')}
                </NavLink>
              </Menu.Item>
            </SubMenu>
            <SubMenu key="admin.faq" title={t('faq')}>
              <Menu.Item key="admin.faq.faqs">
                <NavLink to={'/admin/faq/faqs'}>{t('faqs')}</NavLink>
              </Menu.Item>
              <Menu.Item key="admin.faq.categories">
                <NavLink to={'/admin/faq/categories'}>
                  {t('categories')}
                </NavLink>
              </Menu.Item>
            </SubMenu>
            <SubMenu key="admin.financial" title={t('financials')}>
              <MenuItemCheck
                key="admin.financial.bankingdashboard"
                feature={Features['Finance.Features.BankingDashboard']}
              >
                <NavLink to={'/admin/financial/bankingdashboard'}>
                  {t('bankingDashboard')}
                </NavLink>
              </MenuItemCheck>
              <MenuItemCheck
                key="admin.financial.purchaseorders"
                feature={Features['Finance.Features.PurchaseOrders']}
              >
                <NavLink to={'/admin/financial/purchaseorders'}>
                  {t('purchaseOrders')}
                </NavLink>
              </MenuItemCheck>
              <Menu.Item key="admin.financial.paymentrequests">
                <NavLink to={'/admin/financial/paymentrequests'}>
                  {t('paymentRequests')}
                </NavLink>
              </Menu.Item>
              <Menu.Item key="admin.financial.bonuspayments">
                <NavLink to={'/admin/financial/bonuspayments'}>
                  {t('bonusPayments')}
                </NavLink>
              </Menu.Item>
              <Menu.Item key="admin.financial.commissionpayments">
                <NavLink to={'/admin/financial/commissionpayments'}>
                  {t('commissionPayments')}
                </NavLink>
              </Menu.Item>
              <Menu.Item key="admin.financial.tenancypayments">
                <NavLink to={'/admin/financial/tenancypayments'}>
                  {t('tenancyPayments')}
                </NavLink>
              </Menu.Item>
              <MenuItemCheck
                key="admin.financial.creditnotes"
                feature={Features['Finance.Features.PurchaseOrders']}
              >
                <NavLink to={'/admin/financial/creditnotes'}>
                  {t('creditNotePayments')}
                </NavLink>
              </MenuItemCheck>
              <MenuItemCheck
                key="admin.financial.fees"
                feature={Features['Finance.Features.Fees']}
              >
                <NavLink to={'/admin/financial/fees'}>{t('fees')}</NavLink>
              </MenuItemCheck>
              <Menu.Item key="admin.billing">
                <NavLink to={'/admin/billing'}>{t('billingCycles')}</NavLink>
              </Menu.Item>
            </SubMenu>
            <SubMenu key="admin.news" title={t('news')}>
              <Menu.Item key="admin.news.list">
                <NavLink to={'/admin/news/list'}>{t('news')}</NavLink>
              </Menu.Item>
              <Menu.Item key="admin.news.categories">
                <NavLink to={'/admin/news/categories'}>
                  {t('categories')}
                </NavLink>
              </Menu.Item>
            </SubMenu>
            <SubMenu key="admin.product" title={t('product')}>
              <Menu.Item key="admin.product.management">
                <NavLink to={'/admin/products'}>{t('products')}</NavLink>
              </Menu.Item>
              {displayHighlightProductsAdmin && (
                <Menu.Item key="admin.product.highlighting">
                  <NavLink to={'/admin/product/highlighting'}>
                    {t('producthighlighting')}
                  </NavLink>
                </Menu.Item>
              )}
            </SubMenu>
            <SubMenu key="admin.others" title={t('others')}>
              <Menu.Item key="admin.commissions">
                <NavLink to={'/admin/commissions'}>{t('commissions')}</NavLink>
              </Menu.Item>
              <Menu.Item key="admin.commissionIncrease">
                <NavLink to={'/admin/commissionIncrease'}>
                  {t('commissionIncrease')}
                </NavLink>
              </Menu.Item>
              <Menu.Item key="admin.salesStatusChanges">
                <NavLink to={'/admin/salesStatusChanges'}>
                  {t('statusChanges')}
                </NavLink>
              </Menu.Item>
              <Menu.Item key="admin.agreement">
                <NavLink to={'/admin/agreement'}>{t('aggreement')}</NavLink>
              </Menu.Item>
              <Menu.Item key="admin.platformagreement">
                <NavLink to={'/admin/platformagreement'}>
                  {t('platformagreement')}
                </NavLink>
              </Menu.Item>
            </SubMenu>
          </SubMenuCheck>

          {/* <Menu.Divider /> */}
          {/*
          <Menu.Item key="logout" icon={<Logout color="unset" />}>
            <NavLink to={'/logout'}>{t('logout')}</NavLink>
          </Menu.Item> */}
        </Menu>
      </nav>
    </aside>
  );
};

export default Sidebar;
