import {
  Button,
  Form,
  Input,
  InputNumber,
  Switch,
  Select,
} from 'components/antd';
import { CommissionCompact, CommissionTier } from 'hooks/Commissions';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  item: any;
  onFinish: (item: CommissionCompact, tier?: CommissionTier) => void;
};

const Component = ({ item, onFinish }: Props) => {
  const [form] = Form.useForm();
  const { t: c } = useTranslation('common');
  const { t } = useTranslation('commissions');

  const [setup, setSetup] = useState(false);
  const [localItem, setLocalItem] = useState();

  const onChange = (value: boolean) => {
    setSetup(value);
  };

  useEffect(() => {
    if (!item) return;
    const tier = item?.tier || {};
    setLocalItem({ ...tier, ...item });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  useEffect(() => {
    setSetup(item?.isTieredCommission);
    form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localItem]);

  const onLocalFinish = (values: any) => {
    const item: CommissionCompact = {
      id: values.id,
      name: values.name,
      amount: values.amount,
    };

    const tier: CommissionTier = {
      backdate: values.backdate,
      period: values.period,
      increaseAmount: values.increaseAmount,
      threshold: values.threshold,
    };

    onFinish(item, setup ? tier : undefined);
  };

  return (
    <Form
      initialValues={localItem || { backdate: true }}
      name="basic"
      form={form}
      labelCol={{ span: 10 }}
      wrapperCol={{ span: 10 }}
      autoComplete="off"
      onFinish={onLocalFinish}
    >
      <Form.Item label={c('id')} name="id" style={{ display: 'none' }}>
        <Input readOnly />
      </Form.Item>
      <Form.Item label={c('name')} name="name">
        <Input placeholder={t('comissionNamePlaceholder')} />
      </Form.Item>
      <Form.Item label={c('amount')} name="amount">
        <InputNumber placeholder={t('comissionAmountPlaceholder')} />
      </Form.Item>
      <Form.Item
        label={c('setupCommissionTier')}
        name="isTieredCommission"
        valuePropName="checked"
      >
        <Switch onChange={onChange} />
      </Form.Item>

      {setup && (
        <>
          <Form.Item label={c('period')} name="period">
            <Select placeholder={t('periodPlaceholder')}>
              <Select.Option value="Week">Week</Select.Option>
              <Select.Option value="Month">Month</Select.Option>
              <Select.Option value="YearQuarter">YearQuarter</Select.Option>
              <Select.Option value="Year">Year</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label={c('threshold')} name="threshold">
            <InputNumber />
          </Form.Item>
          <Form.Item
            valuePropName="checked"
            label={c('backdate')}
            name="backdate"
          >
            <Switch />
          </Form.Item>
          <Form.Item label={c('increaseAmount')} name="increaseAmount">
            <InputNumber />
          </Form.Item>
        </>
      )}

      <Form.Item wrapperCol={{ offset: 10 }}>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default Component;
