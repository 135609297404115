// import useLayout from 'hooks/Layout';
// import { useTranslation } from 'react-i18next';
// import { useHistory } from "react-router";
import { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router';
import { PrivatePage } from 'components/shared';

import Agreement from './Agreement';
import Affiliates from './Affiliates';
import News from './News';
import FAQ from './FAQ';
import Products from './Product/ProductManagement';
import Banners from './Banners';
import Communications from './Communications';
import Devices from './Devices';
import Commissions from './Commissions';
import CommissionIncrease from './CommissionIncrease';
import SalesStatusChanges from './StatusChanges';
import BillingCycles from './Billing';
import Product from './Product';
import PlatformAgreement from './PlatformAgreement';
import Financial from './Financial';

import { Features } from 'constants/features';
import { Permissions } from 'constants/permissions';

function Page() {
  useEffect(() => {
    // const unblock = history.block((location, action) => {
    //   if (true) {
    //     return window.confirm("Navigate Back?");
    //   }

    //   return true;
    // });

    return () => {
      // unblock();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Routes>
      <Route
        path="affiliates/*"
        element={<PrivatePage component={Affiliates} />}
      />

      <Route
        path="agreement/*"
        element={<PrivatePage component={Agreement} />}
      />
      <Route
        path="platformagreement/*"
        element={<PrivatePage component={PlatformAgreement} />}
      />
      <Route path="commissionIncrease/*" element={<CommissionIncrease />} />
      <Route path="commissions/*" element={<Commissions />} />

      <Route
        path="faq/*"
        element={
          <PrivatePage feature={Features['Content.FAQs']} component={FAQ} />
        }
      />
      <Route
        path="news/*"
        element={
          <PrivatePage component={News} feature={Features['Content.News']} />
        }
      />

      <Route
        path="product/*"
        element={
          <PrivatePage feature={Features['Content.FAQs']} component={Product} />
        }
      />

      <Route path="products/*" element={<Products />} />
      <Route path="devices/*" element={<Devices />} />

      <Route
        path="commissions/*"
        element={
          <PrivatePage
            component={Commissions}
            requirement={Permissions['Sales.Commission.Admin.ReadWrite']}
          />
        }
      />

      <Route path="billing/*" element={<BillingCycles />} />

      <Route
        path="salesStatusChanges/*"
        element={
          <PrivatePage
            component={SalesStatusChanges}
            requirement={Permissions['Sales.SalesStatus.Admin.ReadWrite']}
          />
        }
      />

      <Route
        path="banners/*"
        element={
          <PrivatePage
            component={Banners}
            feature={Features['Resources.Banners']}
          />
        }
      />

      <Route
        path="communications/*"
        element={
          <PrivatePage
            component={Communications}
            feature={Features['Communications.Emails']}
          />
        }
      />

      <Route
        path="financial/*"
        element={<PrivatePage component={Financial} />}
      />

      <Route index element={<Navigate to="affiliates" />} />
    </Routes>
  );
}

export default Page;
