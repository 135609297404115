import { useEffect, useState } from 'react';

import { Route, Routes, useLocation } from 'react-router-dom';
import { useThemeSwitcher } from 'react-css-theme-switcher';
import { ConfigProvider } from 'antd';
import { getAntdLocale } from 'i18n';
import { useTranslation } from 'react-i18next';
import Playground from 'pages/Playground';
import Product from 'pages/Admin/Product/ProductManagement/Product';
import Reporting from 'pages/Reporting';
import TopSellers from 'pages/Reporting/TopSellers';
import Accounts from 'pages/Accounts';
import Communication from 'pages/Communication';
import Support from 'pages/Support';
import Admin from 'pages/Admin';
import Apply from 'pages/Apply';
import News from 'pages/News';
import Login from 'components/auth/Login';
import Logout from 'components/auth/Logout';
import LogoutCallback from 'components/auth/LogoutCallback';
import Dashboard from 'pages/Dashboard';
import Creatives from 'pages/Creatives';
import Financials from 'pages/Financials';
import Agreement from 'pages/Agreement';
import PlatformAgreement from 'pages/PlatformAgreement';
import DataReview from 'pages/DataReview';

import { Error, InfoPage, PrivatePage, Navigate } from 'components/shared';
import Layout from 'components/Layout';
import Sidebar from 'components/Layout/Sidebar';
import Navbar from 'components/Layout/Navbar';
import Topbar from 'components/Layout/Topbar';
import WizardTopbar from 'components/Layout/WizardTopbar';

import Callback from 'components/auth/Callback';
import useUser from 'hooks/User';

import { Permissions } from 'constants/permissions';
import { Features } from 'constants/features';

function App() {
  const { status } = useThemeSwitcher();
  const { i18n, t } = useTranslation();
  const imporsonateUser = sessionStorage.getItem('impersonationUser');

  const [isThemeLoaded, setIsThemeLoaded] = useState<boolean>(false);
  const [locale, setLocale] = useState(getAntdLocale());

  const location = useLocation();

  const {
    user,
    isAuthenticated,
    fetchUserInfo,
    impersonateAffiliate,
    isImpersonating,
  } = useUser();
  const isAuth = isAuthenticated();

  useEffect(() => {
    if (['loaded'].includes(status)) {
      setIsThemeLoaded(true);
    }
  }, [status]);

  useEffect(() => {
    if (window.location.pathname.includes('/login/callback')) return;
    if (isAuth && !user) {
      fetchUserInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth, user]);

  useEffect(() => {
    if (!user || !isAuth || isImpersonating) return;
    if (imporsonateUser) {
      const impUser: any = JSON.parse(imporsonateUser);
      impersonateAffiliate(impUser?.id, impUser, user.tenantId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth, user, isImpersonating]);

  useEffect(() => {
    const l = getAntdLocale();
    if (locale !== l) {
      setLocale(l);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  if (isAuth)
    if (status === 'loading' && !isThemeLoaded) {
      return (
        <InfoPage
          message={t('downloadingStyles')}
          additionalText={t('pleaseWaitDotDotDot')}
        />
      );
    }

  if (user && imporsonateUser && !isImpersonating) {
    return (
      <InfoPage
        message={t('impersonatingUser')}
        additionalText={t('impersonatingUserDetails')}
      />
    );
  }

  const acceptLater = sessionStorage.getItem('acceptLater') === 'true';
  const shouldRedirectToAgreement =
    user?.hasNewAgreement &&
    (user?.enforceAgreement || (!user?.enforceAgreement && !acceptLater));

  const acceptSelfBillingLater =
    sessionStorage.getItem('acceptSelfBillingLater') === 'true';
  const shouldRedirectToSelfBilling =
    user?.hasNewSelfBillingAgreement &&
    (user?.enforceSelfBillingAgreement ||
      (!user?.enforceSelfBillingAgreement && !acceptSelfBillingLater)) &&
    !shouldRedirectToAgreement;

  const acceptDataReviewLater =
    sessionStorage.getItem('acceptDataReviewLater') === 'true';
  const shouldRedirectToDataReview =
    user?.enforceDataReview &&
    !acceptDataReviewLater &&
    !shouldRedirectToAgreement &&
    !shouldRedirectToSelfBilling;

  return (
    <ConfigProvider locale={locale}>
      <Routes>
        <Route path="/login/callback" element={<Callback />} />
        <Route path="/logout/callback" element={<LogoutCallback />} />
        <Route path="/login" element={<Login />} />
        <Route path="/logout" element={<Logout />} />

        <Route
          path="/apply"
          element={
            <PrivatePage onlyNoTenant>
              <Layout topbar={<WizardTopbar />} higherTopbar>
                <Apply />
              </Layout>
            </PrivatePage>
          }
        />

        <Route
          element={
            <PrivatePage>
              <Layout
                sidebar={<Sidebar />}
                header={<Navbar />}
                topbar={<Topbar />}
              />
            </PrivatePage>
          }
        >
          <Route path="/agreement" element={<Agreement />} />
          <Route path="/platformagreement" element={<PlatformAgreement />} />
          <Route path="/datareview" element={<DataReview />} />
          {shouldRedirectToAgreement ||
          shouldRedirectToSelfBilling ||
          shouldRedirectToDataReview ? (
            shouldRedirectToAgreement ? (
              <Route
                path="*"
                element={
                  <Navigate
                    replace
                    to="/agreement"
                    search={`?returnUrl=${location.pathname}`}
                    state={{ from: location }}
                  />
                }
              />
            ) : shouldRedirectToSelfBilling ? (
              <Route
                path="*"
                element={
                  <Navigate
                    replace
                    to="/platformagreement"
                    search={`?returnUrl=${location.pathname}`}
                    state={{ from: location }}
                  />
                }
              />
            ) : (
              <Route
                path="*"
                element={
                  <Navigate
                    replace
                    to="/datareview"
                    search={`?returnUrl=${location.pathname}`}
                    state={{ from: location }}
                  />
                }
              />
            )
          ) : (
            <>
              <Route path="/playground" element={<Playground />} />
              <Route index element={<Navigate to="/dashboard" />} />
              <Route
                path="/news/*"
                element={
                  <PrivatePage
                    requirement={Permissions['Content.News.Basic']}
                    feature={Features['Content.News']}
                    component={News}
                  />
                }
              />
              <Route
                path="/admin/products/product/:productId"
                element={
                  <PrivatePage
                    requirement={Permissions['Products.ReadWrite']}
                    component={Product}
                  />
                }
              />
              <Route
                path="/reporting/*"
                element={
                  <PrivatePage
                    feature={Features.Reporting}
                    requirement={Permissions.Reporting}
                    component={Reporting}
                  />
                }
              />
              <Route
                path="/topsellers"
                element={
                  <PrivatePage
                    requirement={Permissions['Products.Highlighted.Read']}
                    feature={Features['Reporting.Report.TopSellers']}
                    component={TopSellers}
                  />
                }
              />
              <Route path="/creatives/*" element={<Creatives />} />
              <Route path="/financials/*" element={<Financials />} />
              <Route path="/support/*" element={<Support />} />
              <Route path="/accounts/*" element={<Accounts />} />
              <Route path="/communication/*" element={<Communication />} />

              <Route
                path="/admin/*"
                element={
                  <PrivatePage
                    requirement={Permissions['UI.CanViewAdminSection']}
                    component={Admin}
                  />
                }
              />

              <Route path="/dashboard" element={<Dashboard />} />
            </>
          )}
          <Route path="/*" element={<Error code={404} />} />
        </Route>

        <Route index element={<Navigate to="/dashboard" />} />
      </Routes>
    </ConfigProvider>
  );
}

export default App;
