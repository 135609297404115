import { Button } from 'components/antd';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const ProductCardContainer = styled.div`
  position: absolute;
  height: 300px;
  width: 1220px;
  display: flex;
  justify-content: space-between;
`;

const ProductCard = styled.div`
  padding: 15px !important;
  border-radius: 5px;
  width: 220px;
  height: 190px;
  background-color: #fff;
  text-align: center;
  transition: all 0.2s ease 0s;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  user-select: none;

  h1 {
    font-weight: 600;
    font-size: 15px;
  }

  h2 {
    margin-top: 30px;
    font-size: 14px;
  }

  &:hover,
  &:focus {
    transform: translateY(-0.4em);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    transition: all 0.2s ease 0s;
    cursor: default;
  }

  &:nth-child(1) {
    margin-top: -40px;
  }
  &:nth-child(2) {
    margin-top: -74px;
  }
  &:nth-child(3) {
    margin-top: -142px;
  }
  &:nth-child(4) {
    margin-top: -108px;
  }
  &:nth-child(5) {
    margin-top: -6px;
  }
`;

const ButtonContainer = styled.div`
  margin-top: 22px;
`;

interface Props {
  topProductsData: any;
  hoveredProductHandler: Function;
  generateLinkHandler: Function;
  exitHoveredProductHandler: Function;
}

const Page = ({
  topProductsData,
  hoveredProductHandler,
  generateLinkHandler,
  exitHoveredProductHandler,
}: Props) => {
  const { t } = useTranslation('reporting');

  const truncateProductTitle = (productTitle: string) => {
    let truncatedProductTitle = productTitle;
    if (productTitle.length > 45) {
      truncatedProductTitle = truncatedProductTitle.slice(0, 45) + '...';
    }
    return truncatedProductTitle;
  };

  return (
    <>
      <ProductCardContainer>
        {topProductsData.map(
          (
            product: {
              productSKU: string;
              productName: string;
              commission: number;
            },
            index: any
          ) => {
            return (
              <ProductCard
                key={product.productSKU}
                onMouseOver={() => hoveredProductHandler(product.productName)}
                //@ts-ignore
                onMouseLeave={exitHoveredProductHandler}
              >
                <h1 style={{ minHeight: 50 }}>
                  {truncateProductTitle(product.productName)}
                </h1>
                <h2>
                  £{product.commission} {t('commission')}*
                </h2>
                <ButtonContainer>
                  <Button
                    type="primary"
                    onClick={() => {
                      generateLinkHandler(product.productSKU);
                    }}
                  >
                    {t('copyURL')}
                  </Button>
                </ButtonContainer>
              </ProductCard>
            );
          }
        )}
      </ProductCardContainer>
    </>
  );
};

export default Page;
