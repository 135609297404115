import {
  Card,
  Form,
  Row,
  Table,
  Select,
  Space,
  Button,
  message,
} from 'components/antd';
import { Content, TitleRow } from 'components/shared';
import useBilling from 'hooks/billing';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

const currentDate = new Date();
const currentMonth = currentDate.getMonth() + 1;
const currentYear = currentDate.getFullYear();
let years: number[] = [];
for (let i = 2013; i <= currentYear; i++) years = [i, ...years];

const getColumns = (t: Function) => [
  {
    title: t('triggerDate'),
    width: 130,
    dataIndex: 'triggerDate',
    key: 'triggerDate',
    fixed: 'left',
    render: (date: Date) => t('date', { value: new Date(date) }),
  },
  {
    title: t('invoiceCount'),
    width: 130,
    dataIndex: 'invoiceCount',
    key: 'invoiceCount',
    fixed: 'left',
  },
];

const Page = () => {
  // const { t } = useTranslation('billing');
  const { t: c } = useTranslation('common');
  const [state, setState] = useState<any>({
    month: currentMonth,
    year: currentYear,
  });
  const { isLoading, list, load, exportPDF } = useBilling();

  useEffect(() => {
    load(state).catch(() => {
      message.error('Unable to download billing data');
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDownload = (values: any) => {
    exportPDF(values);
  };

  const onFinish = (values: any) => {
    setState({ ...state, ...values });
    load({ ...state, ...values }).catch(() => {
      message.error('Unable to download billing data');
    });
  };

  return (
    <Content>
      <TitleRow>
        <h1>{c('billingCycles')}</h1>
        <Space>
          <Button onClick={() => onDownload({ ...state })}>Export</Button>
        </Space>
      </TitleRow>

      <Row justify="center" style={{ paddingTop: '2em', paddingBottom: '2em' }}>
        <Form layout="inline" initialValues={state}>
          <Form.Item name="month">
            <Select
              style={{ minWidth: 140 }}
              onChange={(value) => onFinish({ ...state, month: value })}
            >
              <Option value={1}>January</Option>
              <Option value={2}>February</Option>
              <Option value={3}>March</Option>
              <Option value={4}>April</Option>
              <Option value={5}>May</Option>
              <Option value={6}>June</Option>
              <Option value={7}>July</Option>
              <Option value={8}>August</Option>
              <Option value={9}>September</Option>
              <Option value={10}>October</Option>
              <Option value={11}>November</Option>
              <Option value={12}>December</Option>
            </Select>
          </Form.Item>
          <Form.Item name="year">
            <Select
              style={{ minWidth: 100 }}
              onChange={(value) => onFinish({ ...state, year: value })}
              options={years.map((y) => ({
                label: y,
                value: y,
              }))}
            />
          </Form.Item>
        </Form>
      </Row>

      <Card>
        <Table
          loading={isLoading}
          columns={getColumns(c)}
          rowKey="id"
          dataSource={list}
        />
      </Card>
    </Content>
  );
};

export default Page;
