import { Button, Image, message, Modal, Space, Table } from 'components/antd';
import { Copy, Edit, View } from 'components/icons';
import TagList from 'components/shared/TagList';
import { Banner } from 'hooks/Resources/banners';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const ContainerDiv = styled.div`
  width: 100%;
`;

const getColumns = (
  t: Function,
  copyCode: Function,
  showModal: Function,
  isAdmin: boolean
) => [
  {
    title: t('common:dimensions'),
    dataIndex: '',
    key: '',
    fixed: 'left',
    render: (banner: Banner) => `${banner.dimensionX}x${banner.dimensionY}`,
  },
  {
    title: t('common:name'),
    dataIndex: 'bannerName',
    key: 'bannerName',
    fixed: 'left',
  },
  {
    title: t('common:alternativeText'),
    dataIndex: 'alternativeText',
    key: 'alternativeText',
    fixed: 'left',
  },
  {
    title: t('bannerGroups'),
    dataIndex: '',
    key: '',
    render: (banner: Banner) => (
      <TagList
        title={t('common:groups')}
        tagData={banner.bannerGroups.map((bg) => ({
          id: bg.bannerGroupID.toString(),
          label: bg.bannerGroupName,
        }))}
      />
    ),
  },
  {
    title: t('common:action'),
    dataIndex: '',
    key: '',
    align: 'center',
    render: (banner: Banner) => {
      return (
        <div>
          <Space>
            <Button
              title={t('common:preview')}
              style={{ margin: '0 8px 0 0' }}
              onClick={() => {
                showModal(banner.bannerImagePath);
              }}
              icon={
                <View
                  style={{
                    marginLeft: -3,
                    marginBottom: -3,
                  }}
                />
              }
            ></Button>
          </Space>
          <Space>
            <Button
              title={t('copy.code')}
              htmlType="submit"
              style={{ margin: '0 8px 0 0' }}
              onClick={() => {
                copyCode(banner.bannerCodeSnippet);
              }}
              icon={
                <Copy
                  style={{
                    marginLeft: -3,
                    marginBottom: -3,
                  }}
                />
              }
            ></Button>
          </Space>
          {isAdmin && (
            <Space>
              <Link to={`/admin/banners/${banner.bannerID}/edit`}>
                <Button
                  title={t('common:edit')}
                  icon={
                    <Edit
                      style={{
                        marginLeft: -2,
                        marginBottom: -2,
                      }}
                    />
                  }
                ></Button>
              </Link>
            </Space>
          )}
        </div>
      );
    },
  },
];

type Props = {
  banners: Banner[];
  isAdmin: boolean;
};

function TableView({ banners = [], isAdmin = false }: Props) {
  const { t } = useTranslation('banners');
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [modalImageUrl, setModalImageUrl] = useState<string>();

  function copyCode(code: string) {
    navigator.clipboard.writeText(code);
    message.success(t(`code.copied`));
  }

  const showModal = (imageUrl: string) => {
    setModalImageUrl(imageUrl);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <ContainerDiv>
      <Table
        rowKey="bannerID"
        columns={getColumns(t, copyCode, showModal, isAdmin) as any}
        dataSource={banners}
        pagination={false}
        style={{ width: '100%' }}
      />

      <Modal
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{ hidden: true }}
        cancelButtonProps={{ hidden: true }}
        cancelText={t('common:close')}
        bodyStyle={{ textAlign: 'center' }}
      >
        <Image src={modalImageUrl} />
      </Modal>
    </ContainerDiv>
  );
}

export default TableView;
