import JoditEditor from 'jodit-react';

import { useMemo, useRef } from 'react';

type Props = {
  onChange?: any;
  value?: any;
  defaultValue?: any;
  hideTracking?: any;
  readOnly?: boolean;
};

const Component = ({
  onChange = (val: string) => {},
  value = '',
  readOnly = false,
  hideTracking,
}: Props) => {
  const editor = useRef(null);

  // https://xdsoft.net/jodit/pro/docs/how-to/add-custom-button.md#drop-down-list
  // in the future for a dropdown button

  const trackingButton = hideTracking
    ? []
    : [
        '|',
        {
          name: 'Tracking Link',
          tooltip: 'Tracking Link',
          exec: (editor: any) => {
            editor.s.insertHTML(
              `{tracking_url}${editor.s.html}{/tracking_url}`
            );
          },
        },
      ];

  const config = {
    readonly: readOnly,
    toolbarAdaptive: false,
    height: 400,
    toolbarButtonSize: 'small',
    buttons: [
      'font',
      'fontsize',
      'bold',
      'italic',
      'underline',
      'strikethrough',
      '|',
      'link',
      'image',
      '|',
      'ul',
      'ol',
      'indent',
      'outdent',
      'left',
      'center',
      'right',
      'justify',
      'paragraph',
      'classSpan',
      'brush',
      'superscript',
      'subscript',
      'selectall',
      ...trackingButton,
    ],
  };

  const change = (value: any) => {
    onChange(value || '');
  };

  return useMemo(
    () => (
      <JoditEditor
        ref={editor}
        value={value || ''}
        config={config}
        onBlur={change}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
};
export default Component;
