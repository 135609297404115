import { Navigate, Route, Routes } from 'react-router';
import { PrivatePage } from 'components/shared';
import List from './List';
import Edit from './Edit';

const Page = () => {
  return (
    <Routes>
      <Route path="/:id/edit" element={<PrivatePage children={<Edit />} />} />
      <Route path="create" element={<Edit isNew />} />
      <Route path="list" element={<List />} />
      <Route index element={<Navigate to="list" />} />
    </Routes>
  );
};

export default Page;
