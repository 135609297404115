import { Right } from 'components/icons';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useNews, { News } from 'hooks/Resources/news';
import moment from 'moment';
import styled from 'styled-components';

import { Button, Card, Form, Pagination, Row, Select } from 'components/antd';
import { Link } from 'react-router-dom';

const HeadlineSection = styled.div`
  width: 75%;
`;

const Page = () => {
  const { t } = useTranslation('news');
  const { isLoading, load, news, pagination } = useNews();
  const [year, setYear] = useState<number>(2021);

  useEffect(() => {
    requestData(year, pagination.current, pagination.pageSize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const requestData = (year: number, current: number, pageSize?: number) => {
    setYear(year);
    const rangeFrom = moment([year, 0, 1]);
    const rangeTo = moment(rangeFrom).endOf('year');
    load({
      current,
      pageSize,
      rangeFrom: rangeFrom.toISOString(),
      rangeTo: rangeTo.toISOString(),
    });
  };

  const onChange = (current: number, pageSize?: number) =>
    requestData(year, current, pageSize);

  return (
    <>
      <Row justify="center" style={{ paddingTop: '2em' }}>
        <Form layout="inline">
          <Form.Item name="category" initialValue={[2021]}>
            <Select
              placeholder={t('year')}
              options={[2021, 2020].map((item) => ({
                label: item,
                value: item,
              }))}
              style={{ width: 200 }}
              onChange={(value) =>
                requestData(
                  value as number,
                  pagination.current,
                  pagination.pageSize
                )
              }
            />
          </Form.Item>
        </Form>
      </Row>

      <Card style={{ marginTop: '2em' }} loading={isLoading}>
        <Row justify="center" style={{ marginBottom: '1em' }}>
          <Pagination
            onChange={onChange}
            pageSize={pagination.pageSize}
            current={pagination.current}
            total={pagination.total}
          />
        </Row>

        {news.map((item: News) => (
          <Row
            key={item.articleID}
            style={{
              padding: '0.4em',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <HeadlineSection>{item.articleHeadline}</HeadlineSection>
            <span>
              {moment(item.createdDate).local().format('DD MMM yyyy HH:mm')}
            </span>
            <Link to={`/news/${item.articleID}`}>
              <Button type="primary">
                {t('common:readMore')}
                <Right style={{ fontSize: '1.3em', marginBottom: '-0.2em' }} />
              </Button>
            </Link>
          </Row>
        ))}

        <Row justify="center" style={{ marginTop: '1em' }}>
          <Pagination
            onChange={onChange}
            pageSize={pagination.pageSize}
            current={pagination.current}
            total={pagination.total}
          />
        </Row>
      </Card>
    </>
  );
};

export default Page;
