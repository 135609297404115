import { Button, Card, Col, Image, Row, message, Space } from 'components/antd';
import TagList from 'components/shared/TagList';
import { Copy, Create } from 'components/icons';
import { Banner } from 'hooks/Resources/banners';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

type Props = {
  banner: Banner;
  isAdmin: boolean;
};

const FlexDiv = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const TitlePara = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const ImageContainer = styled.div`
  background: #d9d9d9;
  display: flex;
  flex: auto;
  flex-flow: column;
  justify-content: center;
  height: 150px;
  overflow: hidden;
  padding: 4px;
  position: relative;
`;

function CardView(props: Props) {
  const { t } = useTranslation('banners');
  let banner: Banner = props.banner;

  function CopyCode() {
    navigator.clipboard.writeText(banner.bannerCodeSnippet);
    message.success(t(`code.copied`));
  }

  return (
    <Card
      key={banner.bannerID}
      style={{
        float: 'left',
        textAlign: 'center',
        width: '250px',
      }}
      bodyStyle={{ height: '100%' }}
    >
      <FlexDiv>
        <TitlePara title={banner.bannerName}>{banner.bannerName}</TitlePara>
        <ImageContainer>
          <Image
            src={banner.bannerImagePath}
            alt={banner.alternativeText}
            style={{
              border: 'solid 1px #d9d9d9',
              height: 'auto',
              margin: 'auto',
              maxHeight: '142px',
              maxWidth: '142px',
              width: 'auto',
            }}
          />
        </ImageContainer>
        <Row justify="start" style={{ marginTop: '8px' }}>
          <TagList
            title={t('common:groups')}
            tagData={banner.bannerGroups.map((bg) => ({
              id: bg.bannerGroupID.toString(),
              label: bg.bannerGroupName,
            }))}
          />
        </Row>
        <Row justify="center" style={{ marginTop: '1em' }}>
          <Col span={12} style={{ textAlign: 'left' }}>
            <p style={{ margin: '0', height: '32px', lineHeight: '32px' }}>
              {banner.dimensionX}x{banner.dimensionY}
            </p>
          </Col>
          <Col span={12} style={{ textAlign: 'right' }}>
            {props.isAdmin && (
              <Space style={{ marginLeft: '8px' }}>
                <Link to={`/admin/banners/${banner.bannerID}/edit`}>
                  <Button
                    title={t('common:edit')}
                    icon={
                      <Create
                        style={{
                          marginLeft: -2,
                          marginBottom: -2,
                        }}
                      />
                    }
                  ></Button>
                </Link>
              </Space>
            )}

            <Space style={{ marginLeft: '8px' }}>
              <Button
                title={t('copy.code')}
                htmlType="submit"
                onClick={CopyCode}
                icon={
                  <Copy
                    style={{
                      marginLeft: -3,
                      marginBottom: -3,
                    }}
                  />
                }
              ></Button>
            </Space>
          </Col>
        </Row>
      </FlexDiv>
    </Card>
  );
}

export default CardView;
