import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';

import { LOGINAS_COLOR } from 'constants/index';
import { Cross, Logout, UserCircle /*, ArrowDown*/ } from 'components/icons';
import { Space, Button } from 'components/antd';
import { useTranslation } from 'react-i18next';
import useUser from 'hooks/User';
import useLayout from 'hooks/Layout';
import TenantSwitch from 'components/Layout/TenantSwitch';
import LoggedInAs from 'components/Layout/LoggedInAs';

const Menu = styled.div`
  height: 100%;
  width: 100%;
  color: white;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: space-between;
  align-content: center;
  padding: 0 2em;
  font-weight: 700;
`;

const Right = styled.div`
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1em;
`;

const Center = styled.div`
  text-align: center;
`;

const Topbar = () => {
  const { t } = useTranslation();
  const { logoName } = useLayout();
  const { user, isImpersonating, impersonateAffiliate } = useUser();
  const navigate = useNavigate();
  return (
    <Menu style={{ backgroundColor: isImpersonating ? LOGINAS_COLOR : '' }}>
      <span>{logoName} | Affiliate Manager </span>
      <Center>
        {isImpersonating ? (
          <div style={{ display: 'inline-flex' }}>
            <LoggedInAs affiliateName={user?.name} />
            <Button
              onClick={() =>
                impersonateAffiliate().then(() => {
                  navigate('/admin/affiliates/list');
                })
              }
              style={{ marginLeft: '0.5em' }}
              icon={<Cross style={{ marginBottom: -1 }} />}
              size="small"
              shape="circle"
            />
          </div>
        ) : (
          <span></span>
        )}
      </Center>
      <Right>
        <Space>
          {user.availableTenants.length > 1 && (
            <div title={t('tenant')}>
              <TenantSwitch />
            </div>
          )}
          <Link to="/accounts/me" style={{ height: '1.5em' }}>
            <UserCircle
              title={user?.name}
              style={{ fontSize: '1.5em', fill: 'white', marginBottom: '-5px' }}
            />
          </Link>
          <Link title={t('logout')} style={{ height: '1.5em' }} to="/logout">
            <Logout
              fill="white"
              style={{ fontSize: '1.5em', marginBottom: '-5px' }}
            />
          </Link>
        </Space>
      </Right>
    </Menu>
  );
};

export default Topbar;
