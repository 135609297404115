import { Route, Routes } from 'react-router';

import AffiliateDetail from '../Admin/Affiliates/Edit';
import useAffiliateGroups from 'hooks/Account/affiliateGroups';

function Page() {
  const { groups } = useAffiliateGroups();

  return (
    <Routes>
      <Route path="me/*" element={<AffiliateDetail groups={groups} />} />
    </Routes>
  );
}

export default Page;
