import { useTranslation } from 'react-i18next';
import useLayout from 'hooks/Layout';
import useUser from 'hooks/User';

import { Button, Input } from 'components/antd';
import { Content } from 'components/shared';
import styled from 'styled-components';
import { useState } from 'react';
import { useThemeSwitcher } from 'react-css-theme-switcher';
import i18n, { getFormat, setFormat } from 'i18n';

const Columns = styled.div`
  display: flex;
  justify-content: stretch;
`;

function Page() {
  const { t } = useTranslation();
  const {
    permissions,
    features,
    addPermission,
    removePermission,
    getFeature,
    setFeature,
  } = useUser();
  const { currentTheme, switcher } = useThemeSwitcher();
  const setTheme = (t: string) => switcher({ theme: t });

  const { setSidebar, setNav, showSidebar, showNav } = useLayout();
  const [pInputValue, setPInputValue] = useState('');
  const [fInputValue, setFInputValue] = useState('');
  return (
    <Content>
      <h1>{t('Playground')}</h1>
      <>
        <div>{t('hi', { name: 'Adam' })}</div>
        <div>{t('date', { value: new Date() })}</div>
        <div>{t('number', { value: 42069.666 })}</div>
        <br />
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '0.5em',
            padding: 8,
          }}
        >
          {t('themes')}:
          <Button
            type={currentTheme === 'default' ? 'primary' : 'default'}
            onClick={() => setTheme('default')}
          >
            {t('default')}
          </Button>
          <Button
            type={currentTheme === 'therange' ? 'primary' : 'default'}
            onClick={() => setTheme('therange')}
          >
            {t('therange')}
          </Button>
          <Button
            type={currentTheme === 'three' ? 'primary' : 'default'}
            onClick={() => setTheme('three')}
          >
            {t('three')}
          </Button>
          {t('format')}:
          <Button
            type={getFormat() === 'en-gb' ? 'primary' : 'default'}
            onClick={() => setFormat('en-gb')}
          >
            {t('en')}
          </Button>
          <Button
            type={getFormat() === 'cs' ? 'primary' : 'default'}
            onClick={() => setFormat('cs')}
          >
            {t('cz')}
          </Button>
          {t('language')}:
          <Button
            type={i18n.language === 'en' ? 'primary' : 'default'}
            onClick={() => i18n.changeLanguage('en')}
          >
            {t('en')}
          </Button>
          <Button
            type={i18n.language === 'cs' ? 'primary' : 'default'}
            onClick={() => i18n.changeLanguage('cs')}
          >
            {t('cz')}
          </Button>
        </div>
        <br />

        <div>
          {/* <DatePicker />
        <Custom />
        <br />
        <Link to="/apply">Take me to tenant application wizard</Link>
        <br />
        <br />
        */}
          <div>
            <Button
              onClick={() => {
                setSidebar(!showSidebar);
              }}
            >
              Toggle Sidebar
            </Button>
            <Button onClick={() => setNav(!showNav)}>Toggle Navbar</Button>
          </div>
          <br />
          <Columns>
            <div style={{ flex: 1 }}>
              <Input
                style={{ maxWidth: 200 }}
                onChange={(e) => setPInputValue(e.target.value)}
              />
              <Button onClick={() => addPermission(pInputValue)}>
                Add Permission
              </Button>
              <Button onClick={() => removePermission(pInputValue)}>
                Remove Permission
              </Button>
            </div>

            <div style={{ flex: 1 }}>
              <Input
                style={{ maxWidth: 200 }}
                onChange={(e) => setFInputValue(e.target.value)}
              />
              <Button
                onClick={() =>
                  setFeature(
                    fInputValue,
                    !getFeature(fInputValue as any)?.show,
                    getFeature(fInputValue as any)?.enabled
                  )
                }
              >
                Toggle Show
              </Button>
              <Button
                onClick={() =>
                  setFeature(
                    fInputValue,
                    getFeature(fInputValue as any)?.show,
                    !getFeature(fInputValue as any)?.enabled
                  )
                }
              >
                Toggle Enabled
              </Button>
            </div>
          </Columns>

          <Columns>
            <div style={{ flex: 1 }}>
              Permissions:
              <pre>{JSON.stringify(permissions, null, 2)}</pre>
            </div>
            <div style={{ flex: 1 }}>
              Features:
              <pre>{JSON.stringify(features, null, 2)}</pre>
            </div>
          </Columns>
        </div>
      </>
    </Content>
  );
}

export default Page;
