import { useEffect, useState } from 'react';
import api from 'libs/api';
import Pagination from 'constants/pagination';

export type PurchaseOrder = {
  id: string;
  value: number;
  spendRemaining: number;
  tenantPurchaseOrderReference: string;
  dateCreated: string;
  description: string;
  associatedPaymentRequests?: AssociatedPaymentRequest[];
};

export type AssociatedPaymentRequest = {
  id: string;
  amount: number;
  dateCreated: string;
  status: number;
  discriminator: string;
  notes?: string;
  paymentRequestIdentifier?: string;
};

export type RemainingPercentage = {
  percentageValue: number;
  percentageLabel: string;
};

export type NewPurchaseOrder = {
  amount: number;
  tenantPurchaseOrderReference: string;
  description?: string;
};

export type POReference = {
  id: string;
  tenantPurchaseOrderReference: string;
};

const usePurchaseOrders = () => {
  const [purchaseOrders, setPurchaseOrders] = useState<PurchaseOrder[]>([]);
  const [purchaseOrdersLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState<any>(Pagination);
  const [purchaseOrderSubmitting, setIsSubmitting] = useState(false);
  const [editingPurchaseOrder, setPurchaseOrder] = useState<PurchaseOrder>();
  const [purchaseOrderReferences, setPurchaseOrderReferences] = useState<
    POReference[]
  >([]);
  const [remainingPercentages, setRemainingPercentages] = useState<
    RemainingPercentage[]
  >([
    { percentageValue: 10, percentageLabel: '10%' },
    { percentageValue: 25, percentageLabel: '25%' },
    { percentageValue: 50, percentageLabel: '50%' },
  ]);

  useEffect(() => {
    setIsLoading(true);
    fetchPurchaseOrderReferences();
    setIsLoading(false);
  }, []);

  const fetchPurchaseOrderReferences = async () => {
    const result: any = await api.get(`/finance/purchaseorderreferences`);

    setPurchaseOrderReferences(result.data);
  };

  const fetchPurchaseOrders = async (props?: any) => {
    setIsLoading(true);

    try {
      const params = {
        offset: props?.current || 1,
        limit: props?.pageSize || Pagination.pageSize,
        fromDate: props?.rangeFrom || undefined,
        toDate: props?.rangeTo || undefined,
        tenantPurchaseOrderReference:
          props?.purchaseOrderReference || undefined,
        percentRemaining: props?.percentRemaining || undefined,
      };

      const result: any = await api.get(`/finance/purchaseorders`, { params });

      setPurchaseOrders(result.data.data);

      const newPagination = {
        current: result.data.offset,
        pageSize: result.data.limit,
        total: result.data.totalItems,
      };

      setPagination(newPagination);
    } finally {
      setIsLoading(false);
    }
  };

  const createPurchaseOrder = async (item: NewPurchaseOrder) => {
    // api call
    setIsSubmitting(true);
    return await api.post(`/finance/purchaseorders`, item).then((response) => {
      setIsSubmitting(false);
      return response.data;
    });
  };

  const noLongerSubmitting = async () => {
    setIsSubmitting(false);
  };

  return {
    // Variables
    purchaseOrdersLoading,
    pagination,
    purchaseOrders,
    remainingPercentages,
    purchaseOrderSubmitting,
    editingPurchaseOrder,
    purchaseOrderReferences,
    //categories,
    loadPurchaseOrders: fetchPurchaseOrders,
    createPurchaseOrder,
    noLongerSubmitting,
  };
};

export default usePurchaseOrders;
