import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import {
  Button,
  Card,
  DatePicker,
  message,
  Row,
  Select,
  Space,
  Table,
  Tooltip,
} from 'components/antd';
import { Content, TitleRow } from 'components/shared';
import { Link } from 'react-router-dom';
import moment, { Moment } from 'moment';
import useUser from 'hooks/User';
import usePayments, {
  PaymentStatus,
  PaymentAffiliate,
} from 'hooks/Financial/payments';
import { Features } from 'constants/features';
import { Edit } from 'components/icons';
import { Permissions } from 'constants/permissions';
import usePurchaseOrders, { POReference } from 'hooks/Financial/purchaseorders';

const Page = () => {
  const { t } = useTranslation('financialAdminPayments');
  const { t: c } = useTranslation('common');
  const [startDate, setStartDate] = useState<Moment | null>(null);
  const [endDate, setEndDate] = useState<Moment | null>(null);
  const { getFeature, hasPermission } = useUser();
  const {
    paymentStatuses,
    paymentAffiliates,
    paymentsLoading,
    pagination,
    paymentRequests,
    loadTenancyRequests,
  } = usePayments();
  const { purchaseOrderReferences } = usePurchaseOrders();
  const [state, setState] = useState<any>({});

  const hasPurchaseOrderFunctionality = getFeature(
    Features['Finance.Features.PurchaseOrders']
  ).enabled;
  const canCreatePayment = hasPermission(
    Permissions['Finance.Tenancies.Create']
  );

  const changeDate = (val: any) => {
    setStartDate(val?.[0]);
    setEndDate(val?.[1]);

    const sDate = val?.[0]?.utc().startOf('day');
    const eDate = val?.[1]?.utc().endOf('day');

    setState({
      ...state,
      rangeFrom: sDate?.toISOString(),
      rangeTo: eDate?.toISOString(),
    });

    loadTenancyRequests({
      ...state,
      rangeFrom: sDate?.toISOString(),
      rangeTo: eDate?.toISOString(),
      current: 1,
    }).catch(() => message.error(t('requestRetrievalError')));
  };

  const setSelectedStatus = (selectedStatus: string) => {
    var actualValue = selectedStatus === '' ? undefined : selectedStatus;
    setState({ ...state, status: actualValue });

    loadTenancyRequests({
      ...state,
      status: actualValue,
      current: 1,
    }).catch(() => message.error(t('requestRetrievalError')));
  };

  const setAffiliateID = (selectedAffiliate: string) => {
    var actualValue = selectedAffiliate === '' ? undefined : selectedAffiliate;
    setState({ ...state, affiliateID: actualValue });

    loadTenancyRequests({
      ...state,
      affiliateID: actualValue,
      current: 1,
    }).catch(() => message.error(t('requestRetrievalError')));
  };

  useEffect(() => {
    loadTenancyRequests({
      ...state,
    }).catch(() => message.error(t('requestRetrievalError')));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formatCurrency = (value: any) =>
    `${t('currency.symbol')}${formatNumber(value)}`;

  const formatNumber = (value: any) =>
    `${c('number', {
      value: value,
    })}`;

  const handleTableChange = (pagination: any) => {
    setState({ ...state, ...pagination });

    loadTenancyRequests({
      ...state,
      ...pagination,
    }).catch(() => message.error(t('requestRetrievalError')));
  };

  const getColumns = (t: Function, c: Function) =>
    [
      {
        title: t('paymentRequestIdentifier'),
        dataIndex: 'paymentRequestIdentifier',
        key: 'paymentRequestIdentifier',
      },
      {
        title: t('affiliateName'),
        dataIndex: 'affiliateName',
        key: 'affiliateName',
      },
      {
        title: t('affiliateIdentifier'),
        dataIndex: 'affiliateExternalIdentifier',
        key: 'affiliateExternalIdentifier',
      },
      {
        title: t('paymentAmount'),
        dataIndex: 'amount',
        key: 'amount',
        align: 'right',
        render: (item: number) => formatCurrency(item),
      },
      {
        title: t('paymentCreatedDate'),
        dataIndex: 'dateCreated',
        key: 'dateCreated',
        render: (date: Date) => c('date', { value: new Date(date) }),
      },
      {
        title: t('paymentStatus'),
        dataIndex: 'status',
        key: 'status',
        render: (item: number) => (
          <>
            {t(
              paymentStatuses.find(
                (c: PaymentStatus) => c.statusTypeNumber === item
              )?.statusTypeName || item
            )}
          </>
        ),
      },
      {
        title: t('paymentNotes'),
        dataIndex: 'notes',
        key: 'notes',
      },
      {
        title: t('paymentUpdatedDate'),
        dataIndex: 'lastModifiedDate',
        key: 'lastModifiedDate',
        render: (date: Date) => c('date', { value: new Date(date) }),
      },
      {
        title: t('purchaseOrderNumber'),
        dataIndex: 'purchaseOrderId',
        key: 'purchaseOrderId',
        render: (item: string) => (
          <>
            {purchaseOrderReferences.find((c: POReference) => c.id === item)
              ?.tenantPurchaseOrderReference || item}
          </>
        ),
        hidden: hasPurchaseOrderFunctionality ? false : true,
      },
      {
        title: t('orderReference'),
        dataIndex: 'orderReference',
        key: 'orderReference',
      },
      {
        title: 'Action',
        width: 100,
        key: 'actions',
        align: 'right',
        render: (af: any) => (
          <Space>
            <Tooltip title={t('editPaymentRequest')}>
              <Link to={`/admin/financial/tenancypayments/${af.id}/edit`}>
                {
                  <Button
                    disabled={!canCreatePayment}
                    icon={<Edit style={{ marginLeft: -2, marginBottom: -2 }} />}
                  />
                }
              </Link>
            </Tooltip>
          </Space>
        ),
      },
    ].filter((item) => !item.hidden);

  return (
    <Content>
      <TitleRow>
        <h1>{t('tenancyTitle')}</h1>
        <Link to={`/admin/financial/tenancypayments/create`}>
          <Button disabled={!canCreatePayment}>{t('newPayment')}</Button>
        </Link>
      </TitleRow>

      <Row justify="center" style={{ margin: '16px 0' }}>
        <Space>
          <DatePicker.RangePicker
            value={[startDate, endDate]}
            onChange={changeDate}
            disabledDate={(date) => date > moment()}
          />
          <Select
            allowClear
            placeholder={t('selectStatus')}
            options={paymentStatuses.map((c) => ({
              label: t(c.statusTypeName),
              value: c.statusTypeNumber?.toString(),
            }))}
            style={{ width: 200 }}
            onChange={(value) => {
              setSelectedStatus(value as string);
            }}
          />
          <Select
            showSearch
            placeholder={t('selectAffiliate')}
            allowClear
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option!.label as unknown as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            options={paymentAffiliates.map((affiliate) => ({
              label:
                (affiliate.externalIdentifier == null
                  ? ''
                  : '(' + affiliate.externalIdentifier + ') - ') +
                affiliate.affiliateName,
              value: affiliate.id.toString(),
            }))}
            style={{ width: 200 }}
            onChange={(value) => {
              setAffiliateID(value as string);
            }}
          />
        </Space>
      </Row>

      <Card style={{ marginTop: '2em' }}>
        <Table
          rowKey="id"
          size="small"
          loading={paymentsLoading}
          columns={getColumns(t, c) as any}
          pagination={pagination}
          dataSource={paymentRequests}
          onChange={handleTableChange}
        />
      </Card>
    </Content>
  );
};

export default Page;
