import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Card, message, Skeleton } from 'components/antd';
import { Content } from 'components/shared';

import Wizard from './Wizard';
import Decline from './Decline';
import Pending from './Pending';
import useApplication, { ApplicationStatus } from 'hooks/Apply';
import { Navigate } from 'react-router-dom';

const Spaced = styled.div`
  margin-bottom: 1em;
  margin-top: 1em;
  width: 100%;
`;

const reasons = [
  'Profile incomplete',
  'No URL given',
  'Dead URL/page not found',
  'Site does not compliment the advertiser brand',
  'Advertiser does not work with this type of affiliate',
];

function Page() {
  const { t } = useTranslation('wizard');
  const { load, status, isLoading, apply } = useApplication();
  const [error, setError] = useState(false);

  useEffect(() => {
    load().catch(() => {
      setError(true);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (details: any) => {
    return apply(details)
      .then(() => {
        message.success(t('submit_success'));
        window.location.href = '/';
      })
      .catch(({ response }) => {
        message.error(
          t('submit_error', { error: response?.data?.title || 'unknown' })
        );
        console.error(response?.data);
        const { errors } = response?.data;
        if (errors) {
          message.error(
            t('submit_fields_error', {
              errorText: Object.entries(errors)
                .map(([k, v]) => `${k}(${v})`)
                .join(', '),
            })
          );
        }
      });
  };

  if (error)
    return (
      <Content maxWidth={1200} position="center">
        <Spaced>
          <h1>{t('signupWizard')}</h1>
        </Spaced>
        <Spaced>
          <Card>Error page</Card>
        </Spaced>
      </Content>
    );

  return (
    <Content maxWidth={1200} position="center">
      <Spaced>
        <h1>{t('signupWizard')}</h1>
      </Spaced>
      <Spaced>
        <Card>
          {isLoading && <Skeleton />}
          {!isLoading && (
            <>
              {status === ApplicationStatus.Approved && (
                <div>
                  {/* Might need to do a full reload */}
                  <Navigate to="/" />
                </div>
              )}
              {status === ApplicationStatus.Wizard && (
                <Wizard onSubmit={onSubmit} />
              )}
              {status === ApplicationStatus.Declined && (
                <Decline
                  reason={reasons[Math.floor(Math.random() * reasons.length)]}
                />
              )}
              {status === ApplicationStatus.Pending && <Pending />}
            </>
          )}
        </Card>
      </Spaced>
    </Content>
  );
}

export default Page;
