import React, { useEffect, useState } from 'react';
import themes from 'constants/themes';
import { useThemeSwitcher } from 'react-css-theme-switcher';

interface ILayoutContext {
  showSidebar: boolean;
  showNav: boolean;
  showLogoInNav: boolean;
  minimized: boolean;
  logoPath: string;
  logoName: string;
  setShowSidebar: (value: boolean) => void;
  setShowNav: (value: boolean) => void;
  setShowLogoInNav: (value: boolean) => void;
  setMinimized: (value: boolean) => void;
}

const LayoutContext = React.createContext<ILayoutContext>({} as ILayoutContext);

type Props = {
  children: JSX.Element | JSX.Element[];
};

const themeNamesList = Object.keys(themes);
export const getThemeFromUrl = () => {
  const url = window.location.host;
  const parts = url.split('//');
  const part: string = (parts[1] || parts[0])?.split('.')[0] || 'default';
  if (part.includes('-staging')) {
    const stagingpart: string = part.split('-staging')[0];
    if (themeNamesList.includes(stagingpart)) return stagingpart.toLowerCase();
  }

  if (!themeNamesList.includes(part)) return 'default';
  return part.toLowerCase();
};

const LayoutProvider = ({ children }: Props) => {
  const [showSidebar, setShowSidebar] = useState(true);
  const [showNav, setShowNav] = useState(false);
  const [showLogoInNav, setShowLogoInNav] = useState(false);
  const [minimized, setMinimized] = useState(false);
  const [logoName, setLogoName] = useState('Summit Media');
  const [logoPath, setLogoPath] = useState('summit.svg');
  const { currentTheme } = useThemeSwitcher();

  useEffect(() => {
    const part = currentTheme || getThemeFromUrl() || 'default';

    const logo = themes[part].logo;
    const name = themes[part].name;

    setLogoName(name);
    setLogoPath(logo);

    // compare with themes,
    // select logo
  }, [currentTheme]);

  const context = {
    showSidebar,
    showNav,
    showLogoInNav,
    minimized,
    logoName,
    logoPath,
    setShowSidebar,
    setShowNav,
    setShowLogoInNav,
    setMinimized,
  };

  return (
    <LayoutContext.Provider value={context}>{children}</LayoutContext.Provider>
  );
};

export { LayoutContext, LayoutProvider };
