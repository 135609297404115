// import useUser from 'hooks/User';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  message,
  Form,
  Input,
  Row,
  Col,
  Card,
  Button,
  Popconfirm,
} from 'components/antd';
import styled from 'styled-components';
import { Content } from 'components/shared';
import useProductProducts from 'hooks/Product/productsProduct';
import { CheckCircle } from 'components/icons';

const ProductSettings = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  border-bottom: #000;
  width: 80%;
  margin-left: 10%;
  padding-bottom: 14px;
  border-bottom: 1px solid #ccc;
  margin-bottom: 35px;
  margin-top: -35px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const ApprovedContainer = styled.div`
  width: 70%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ApprovedTitle = styled.h1`
  font-size: 1.8em;
`;

const StyledCheckCircle = styled(CheckCircle)`
  color: #18a558;
  font-size: 2.2em;
  margin-right: 10px;
  margin-top: -15px;
`;

const UnapprovedContainer = styled.div`
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #eee;
  padding: 20px;
  margin-bottom: 18px;
`;

const EnabledContainer = styled.div`
  width: 80%;
  margin-left: 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 36px;
  border-bottom: 1px solid #ccc;
  margin-bottom: 50px;
  font-size: 1.5em;
`;

function Page() {
  const { productId }: any = useParams();

  const { t } = useTranslation('products');

  const [productKeys, setProductKeys] = useState<any>();
  const [productData, setProductData] = useState<any>();
  const [productApproved, setProductApproved] = useState<boolean>();
  const [productEnabled, setProductEnabled] = useState<boolean>();

  const {
    isLoading,
    productStatusChange,
    doProductsRequireApproval,
    approveProduct,
    updateProduct,
    loadFields,
    loadProduct,
    enableProduct,
    disableProduct,
  } = useProductProducts();

  const fetchKeys = () => {
    loadFields()
      .then((res) => {
        setProductKeys(res);
      })
      .catch(() => {
        message.error(t('productsData.fetch.error'));
      });
  };

  const fetchData = () => {
    loadProduct(productId)
      .then((res) => {
        const nameAttribute = { $name: res.name };
        const skuAttribute = { $externalId: res.externalId };
        const productAttributes = {
          ...res.attributes,
          ...nameAttribute,
          ...skuAttribute,
        };

        setProductData(productAttributes);
        setProductApproved(res.approved);
        setProductEnabled(res.enabled);
      })
      .catch(() => {
        message.error(t('productsData.fetch.error'));
      });
  };

  const approveProductHandler = () => {
    approveProduct(productId)
      .then(() => {
        message.success(t('productApproved'));
      })
      .catch(() => {
        message.error(t('productApproval.fetch.error'));
      });
  };

  const enableProductHandler = () => {
    enableProduct(productId)
      .then(() => {
        message.success(t('productWasEnabled'));
      })
      .catch(() => {
        message.error(t('productEnableDisable.fetch.error'));
      });
  };

  const disableProductHandler = () => {
    disableProduct(productId)
      .then(() => {
        message.success(t('productWasDisabled'));
      })
      .catch(() => {
        message.error(t('productEnableDisable.fetch.error'));
      });
  };

  const saveProductHandler = (productObj: object) => {
    updateProduct(productId, productObj)
      .then(() => {
        message.success(t('productUpdated'));
      })
      .catch(() => {
        message.error(t('productUpdate.fetch.error'));
      });
  };

  useEffect(() => {
    fetchKeys();
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productStatusChange, productApproved]);

  const [form] = Form.useForm();

  // This generates the fields based on values in productKeys
  // for each entry the product has it generates a <Form.Item>
  // with an <Input /> element
  const getFields = () =>
    Object.keys(productKeys)
      .map((key) => {
        return (
          <Col span={12} key={key}>
            <Form.Item
              name={key}
              label={productKeys[key]}
              initialValue={productData[key]}
            >
              {/* Disables the SKU / externalId field as this is not editable */}
              <Input {...(key === '$externalId' ? { disabled: true } : {})} />
            </Form.Item>
          </Col>
        );
      })
      .filter((x) => x);

  return (
    <Content>
      <h1>{t('productDetails')}</h1>
      <Card
        bodyStyle={{ paddingLeft: 125, paddingRight: 125 }}
        loading={isLoading}
      >
        <Row justify="center" style={{ paddingTop: '2em' }}>
          <Form
            form={form}
            name="advanced_search"
            className="ant-advanced-search-form"
            style={{
              padding: '5em',
              background: '#f9f9f9',
            }}
            onFinish={(values) => {
              saveProductHandler(values);
            }}
          >
            <Row>
              {doProductsRequireApproval && (
                <ProductSettings>
                  {productApproved ? (
                    <ApprovedContainer>
                      <StyledCheckCircle />
                      <ApprovedTitle>{t('approved')}</ApprovedTitle>
                    </ApprovedContainer>
                  ) : (
                    <UnapprovedContainer>
                      <p>{t('notApproved')}</p>
                      <Button type="primary" onClick={approveProductHandler}>
                        {' '}
                        {t('approve')}
                      </Button>
                    </UnapprovedContainer>
                  )}
                </ProductSettings>
              )}
            </Row>
            <Row>
              <EnabledContainer>
                {productEnabled ? (
                  <>
                    <p>{t('productIsEnabled')}</p>
                    <Popconfirm
                      placement="bottom"
                      title={`${t('areYouSureDisable')}`}
                      onConfirm={() => {
                        disableProductHandler();
                      }}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button type="primary">{t('disableProduct')}</Button>
                    </Popconfirm>
                  </>
                ) : (
                  <>
                    <p>{t('productIsDisabled')}</p>

                    <Popconfirm
                      placement="bottom"
                      title={`${t('areYouSureEnable')}`}
                      onConfirm={() => {
                        enableProductHandler();
                      }}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button type="primary">{t('enableProduct')}</Button>
                    </Popconfirm>
                  </>
                )}
              </EnabledContainer>
            </Row>

            <Row gutter={24}>
              {productKeys && productData ? (
                <>{getFields()}</>
              ) : (
                t('waitingForData')
              )}
            </Row>
            <Row>
              <Col
                span={24}
                style={{
                  textAlign: 'center',
                }}
              >
                <ButtonContainer>
                  <Link to={`/admin/products`}>
                    <Button type="primary" style={{ marginRight: '2em' }}>
                      {t('cancel')}
                    </Button>
                  </Link>
                  <Button type="primary" htmlType="submit">
                    {t('save')}
                  </Button>
                </ButtonContainer>
              </Col>
            </Row>
          </Form>
        </Row>
      </Card>
    </Content>
  );
}

export default Page;
