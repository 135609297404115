import api from 'libs/api';
import { useState } from 'react';

export type AffiliateDetail = {
  affiliateName: string;
  title: string;
  firstName: string;
  surname: string;
  phonenumber: number;
  companyWebsite: string;
};

export type AffiliateAddress = {
  addressLineOne: string;
  addressLineTwo: string;
  city: string;
  county: string;
  country: string;
  postCode: number;
};

export type AffiliateWebsite = {
  id: string;
  websiteName: string;
  uniqueMonthlyVisitors: number;
  websiteDescription: string;
  promotionalMethods: PromotionalMethod[];
  promotionalMethodIds?: string[];
  socialNetworks: SocialNetworkHandle[];
};

export type AffiliateBankUk = {
  bankAccountId: string;
  changeRequestId: string;
  accountName: string;
  accountNumber: string;
  bankName: string;
  sortCode: string;
};

export type AffiliateBankNonUk = {
  bankAccountId: string;
  changeRequestId: string;
  accountName: string;
  accountNumber: string;
  bankName: string;
  iban: string;
  swiftCode: string;
};

export type PromotionalMethod = {
  id: string;
  name: string;
};

export type SocialNetworkType = {
  id: number;
  name: string;
};

export type SocialNetworkHandle = {
  id: number;
  accountHandle: string;
};

export type AcceptedDataReview = {
  bankAccountId: string;
  selfBillingAgreementId: string;
};

export type AffiliateBank = AffiliateBankUk | AffiliateBankNonUk;

const afPath = '/accounts/affiliate';
const pMPath = '/accounts/WebsitePromotionalMethods';
const snPath = '/accounts/SocialNetworks';

export interface IUseAffiliates {
  detail?: AffiliateDetail;
  address?: AffiliateAddress;
  bank?: AffiliateBank;
  websites?: AffiliateWebsite[];
  vat?: string;
  group?: string;
  promotionalMethods: PromotionalMethod[];
  socialNetworks: SocialNetworkType[];
  isLoading: boolean;
  isClosed: boolean;
  load: (params?: any) => Promise<void>;
  acceptDataReview: (data: any) => Promise<any>;
  saveDetails: (data: any) => Promise<any>;
  saveAddress: (data: any) => Promise<any>;
  saveVat: (data: any) => Promise<any>;
  saveGroup: (data: any) => Promise<any>;
  saveBank: (data: any) => Promise<any>;
  saveWebsite: (values: AffiliateWebsite) => Promise<any>;
  deleteWebsite: (id: string) => Promise<any>;
  loadNetworks: () => Promise<void>;
  fetchPromotionalMethods: () => Promise<PromotionalMethod[]>;
}

const useAffiliateDetails = (id?: string): IUseAffiliates => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isClosed, setIsClosed] = useState<boolean>(false);
  const [detail, setDetail] = useState<AffiliateDetail>();
  const [address, setAddress] = useState<AffiliateAddress>();
  const [websites, setWebsites] = useState<AffiliateWebsite[]>([]);
  const [methods, setMethods] = useState<PromotionalMethod[]>([]);
  const [group, setGroup] = useState<string>();
  const [bank, setBank] = useState<AffiliateBank>();
  const [vat, setVat] = useState<string>();
  //const [socialNetworks, setSocialNetworks] = useState<SocialNetworkType[]>([]);
  const [socialNetworks, setSocialNetworks] = useState<SocialNetworkType[]>([
    { id: 1, name: 'Facebook' },
    { id: 2, name: 'YouTube' },
    { id: 3, name: 'Instagram' },
    { id: 4, name: 'TikTok' },
    { id: 5, name: 'Twitter' },
    { id: 6, name: 'Pinterest' },
    { id: 7, name: 'Snapchat' },
  ]);

  const loadNetworks = async () => {
    setIsLoading(true);

    try {
      const result: any = await api.get(snPath);

      setSocialNetworks(result.data);
    } finally {
      setIsLoading(false);
    }
  };

  const load = async (affiliateId?: string) => {
    setIsLoading(true);

    try {
      const { data }: any = await api.get(`${afPath}/${affiliateId || id}`);

      if (data.websites)
        data.websites = data.websites.map((x: AffiliateWebsite) => ({
          ...x,
          promotionalMethodIds: x.promotionalMethods.map((p) => p.id),
        }));

      setDetail({ ...data });
      setAddress(data.address);
      setBank(data.bankAccount);
      setVat(data.vatRegistrationNumber);
      setWebsites(data.websites);
      setGroup(data?.affiliateGroup?.id);
      setIsClosed(data.affiliateStatus !== 1);

      setMethods(await fetchPromotionalMethods());
    } finally {
      setIsLoading(false);
    }
  };

  const fetchPromotionalMethods = async () => {
    const { data } = await api.get(pMPath);
    return data;
  };

  const acceptDataReview = (values: AcceptedDataReview) =>
    api.post(`${afPath}/${id}/datareview`, values);

  const saveDetails = (values: AffiliateDetail) =>
    api.put(`${afPath}/${id}`, values);

  const saveAddress = async (values: AffiliateAddress) =>
    api.put(`${afPath}/${id}/address`, values);

  const saveVat = async (values: string) =>
    api.put(`${afPath}/${id}/vatregistrationnumber`, values);

  const saveGroup = async (values: string) =>
    api.put(`${afPath}/${id}/affiliategroup`, values);

  const saveBank = async (values: AffiliateBank) => {
    const result: any = { ...values };
    delete result.changeRequested;
    result.sortCode = result.sortCode && result.sortCode.replace(/-/g, '');
    return api.post(`${afPath}/${id}/BankAccountChangeRequest`, result);
  };

  const saveWebsite = (values: AffiliateWebsite) => {
    const w = { ...values };
    // const ids = w.promotionalMethodIds;
    // delete w.promotionalMethodIds;
    // (w as any).promotionalMethods = ids;
    return w.id
      ? api.put(`${afPath}/${id}/website/${w.id}`, w)
      : api.post(`${afPath}/${id}/website`, w);
  };

  const deleteWebsite = (websiteId: string) =>
    api.delete(`${afPath}/${id}/website/${websiteId}`);
  return {
    // Methods
    deleteWebsite,
    load,
    saveAddress,
    saveBank,
    saveDetails,
    saveVat,
    saveWebsite,
    saveGroup,
    loadNetworks,
    fetchPromotionalMethods,
    acceptDataReview,
    // Variables
    isClosed,
    address,
    bank,
    detail,
    group,
    isLoading,
    promotionalMethods: methods,
    vat,
    websites,
    socialNetworks,
  };
};

export default useAffiliateDetails;
