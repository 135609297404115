export enum TemplateType {
  System = 1,
  User = 2,
}

export interface IEmailTemplate {
  emailTemplateID: number;
  templateTypeID: number;
  templateName: string;
  emailSubject: string;
  emailMessageHTML: string;
  emailFromName: string;
  emailFromAddress: string;
  emailCCList?: string;
  emailBCCList?: string;
  trackActivity: boolean;
}

export interface IGetEmailTemplatesCall {
  pagination: IOffsetPagination;
  emailTemplates: IEmailTemplate[];
}

export interface IRecipientGroup {
  affiliateGroupID: string;
  affiliateGroupName: string;
}

export enum BulkEmailStatus {
  Draft = 1,
  Sending = 2,
  Sent = 3,
  Cancelled = 4,
  Failed = 5,
  PartiallySent = 6,
}

export interface IBulkEmailAffiliateEmail {
  emailID: string;
  emailRecipients: string;
  sentStatus: number;
  sentDate: string;
}

export interface IBulkEmailAffiliate {
  affiliateID: string;
  affiliateName: string;
  emails: IBulkEmailAffiliateEmail[];
}

export interface IBulkEmailAffiliateGroup {
  affiliateGroupID: string;
  affiliateGroupName: string;
  affiliates: IBulkEmailAffiliate[];
}

export interface IBulkEmail {
  sendDate?: string;
  bulkEmailID: number;
  emailSubject: string;
  createdDate: string;
  emailFromName: string;
  emailFromAddress: string;
  emailCCList?: string;
  emailBCCList?: string;
  emailStatus: BulkEmailStatus;
  emailMessageHTML: string;
  trackActivity: boolean;
  affiliateGroups: IBulkEmailAffiliateGroup[];
}

export interface IOffsetPagination {
  offset: number;
  limit: number;
  totalItems: number;
}

export class Pagination {
  public hideOnSinglePage: boolean = true;
  constructor(
    public current: number,
    public total: number,
    public pageSize: number
  ) {}

  static fromOffsetPagination({
    offset,
    limit,
    totalItems,
  }: IOffsetPagination) {
    const currentPage = offset === 0 ? 1 : Math.ceil(offset / limit) + 1;
    return new Pagination(currentPage, totalItems, limit);
  }

  public toOffsetPagination(): IOffsetPagination {
    return {
      offset: (this.current - 1) * this.pageSize,
      limit: this.pageSize,
      totalItems: this.total,
    };
  }
}

export interface IListBulkEmailResult {
  pagination: IOffsetPagination;
  bulkEmails: IBulkEmail[];
}

export class BulkEmailRequestAffiliateGroup {
  public constructor(public affiliateGroupId: string) {}
}

export class BulkEmailRequest {
  public affiliateGroups: BulkEmailRequestAffiliateGroup[];
  public bulkEmailID?: number;
  public emailCCList?: string;
  public emailBCCList?: string;
  public sendDate?: string;

  public constructor(
    public emailSubject: string,
    public emailMessageHTML: string,
    public emailFromName: string,
    public emailFromAddress: string,
    public trackActivity: boolean,
    affiliateGroupsIds: string[]
  ) {
    this.affiliateGroups = affiliateGroupsIds.map(
      (id) => new BulkEmailRequestAffiliateGroup(id)
    );
  }
}

export interface IEmailActivity {
  activityDate: string;
  activityDesc: string;
  activityDetails: string;
  emailID: string;
  emailRecipient: string;
}
