import { Button } from 'components/antd';
import { InfoPage } from 'components/shared';
import useUser from 'hooks/User';
import { Navigate, useLocation } from 'react-router';

export const Page = () => {
  const { isAuthenticated, signinRedirect } = useUser();
  const { search } = useLocation();
  const q = new URLSearchParams(search);
  let returnUrl = q.get('returnUrl');

  if (returnUrl && returnUrl.toLocaleLowerCase().includes('logout'))
    returnUrl = null;

  if (isAuthenticated()) {
    return <Navigate to={returnUrl || '/'} />;
  }

  signinRedirect(returnUrl);
  return (
    <div style={{ textAlign: 'center' }}>
      <InfoPage
        message="Logging in"
        additionalText="Please wait you are being redirected"
      />
      <br />
      <Button onClick={() => signinRedirect(returnUrl)} size="large">
        In case that doesnt work
      </Button>
    </div>
  );
};

export default Page;
