import { Popover, Tag } from 'components/antd';

const DEFAULT_TAG_COLOUR = 'black';
const DEFAULT_TAG_COUNT = 2;
const DEFAULT_TAG_STYLE = {
  maxWidth: '90px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

export type TagData = {
  id?: string;
  label: string;
};

type Props = {
  title: string;
  tagCount?: number;
  tagData: TagData[];
  tagMaxWidth?: string;
};

function TagList(props: Props) {
  const tagCount = props.tagCount ?? DEFAULT_TAG_COUNT;
  if (props.tagMaxWidth) DEFAULT_TAG_STYLE.maxWidth = props.tagMaxWidth;

  const groupPopoverContent = (
    <div>
      {props.tagData.map((t) => (
        <Tag key={t.id} color={DEFAULT_TAG_COLOUR}>
          {t.label}
        </Tag>
      ))}
    </div>
  );

  return (
    <div>
      {props.tagData.slice(0, tagCount).map((tag) => (
        <Tag
          key={tag.id}
          color={DEFAULT_TAG_COLOUR}
          style={DEFAULT_TAG_STYLE}
          title={tag.label}
        >
          {tag.label}
        </Tag>
      ))}
      {props.tagData.length > DEFAULT_TAG_COUNT && (
        <Popover
          content={groupPopoverContent}
          title={props.title}
          trigger="hover"
        >
          <Tag style={DEFAULT_TAG_STYLE}>...</Tag>
        </Popover>
      )}
    </div>
  );
}

export default TagList;
