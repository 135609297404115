import useUser from 'hooks/User';
import api from 'libs/api';
import { useState } from 'react';

export type CommissionMapping = {
  id: number;
  effectiveFrom?: Date;
  effectiveTo?: Date;
  affiliates: string[];
  products: string[];
  allProducts: boolean;
  allAffiliates: boolean;
};

export interface IUseCommissionMappings {
  mappings: CommissionMapping[];
  isLoading: boolean;
  load: (commissionId: string) => Promise<void>;
  add: (
    commissionId: string,
    item: CommissionMapping
  ) => Promise<CommissionMapping>;
  update: (
    commissionId: string,
    item: CommissionMapping
  ) => Promise<CommissionMapping>;
}

const path = '/sales/user';

const useCommissionMappings = (
  commissionCode?: string
): IUseCommissionMappings => {
  const { user } = useUser();
  const { id: userId } = user;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [mappings, setMappings] = useState<CommissionMapping[]>([]);

  const load = async (commissionId: string) => {
    setIsLoading(true);

    const { data: results }: any = await api.get(
      `${path}/${userId}/commissionCode/${commissionCode}/commission/${commissionId}/commissionMap`
    );

    setMappings(results);

    setIsLoading(false);
  };

  const add = (commissionId: string, item: CommissionMapping) => {
    // TODO only use date values and add startOf/endOf date
    item.effectiveFrom =
      item.effectiveFrom && (item.effectiveFrom as any).startOf('day');
    item.effectiveTo =
      item.effectiveTo && (item.effectiveTo as any).endOf('day');

    return api
      .post(
        `${path}/${userId}/commissionCode/${commissionCode}/commission/${commissionId}/commissionMap`,
        item
      )
      .then((result) => result.data);
  };

  const update = (commissionId: string, item: CommissionMapping) => {
    const updatedItem = { ...item };
    delete updatedItem.effectiveFrom;

    updatedItem.effectiveTo =
      updatedItem.effectiveTo && (updatedItem.effectiveTo as any).endOf('day');

    return api
      .patch(
        `${path}/${userId}/commissionCode/${commissionCode}/commission/${commissionId}/commissionMap/${item.id}`,
        updatedItem
      )
      .then((result) => result.data);
  };

  return {
    // Methods
    load,
    add,
    update,
    // Variables
    mappings,
    isLoading,
  };
};

export default useCommissionMappings;
