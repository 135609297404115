import api from 'libs/api';
import { message } from 'components/antd';
import { useState } from 'react';
import useUser from 'hooks/User';
import { useTranslation } from 'react-i18next';

export type TopSellingProductModel = {
  productSKU: string;
  commissionGroup: number;
  commissionCode: string;
};

const useTopProducts = () => {
  const { user } = useUser();
  const { id: userId } = user;
  const { t } = useTranslation('reporting');

  const [isTopProductsLoading, setIsTopProductsLoading] =
    useState<boolean>(false);
  const [topProductsData, setTopProductsData] = useState([] as any[]);

  const fetchTopProducts = async () => {
    setIsTopProductsLoading(true);
    try {
      const result: any = await api.get(`/reporting/report/TopSellers`);

      const initialTopProductsData = result.data.Body;
      const topSellerColumns = result.data.Header.Columns;

      // Get position of ProductSKU
      const productSKUIndex = topSellerColumns.findIndex((columnObj: any) => {
        return columnObj['ID'] === 'ProductSKU';
      });

      // Get position of Product Name
      const productNameIndex = topSellerColumns.findIndex((columnObj: any) => {
        return columnObj['ID'] === 'ProductName';
      });

      // Get position of Order/Sale Sequence
      const productOrderIndex = topSellerColumns.findIndex((columnObj: any) => {
        return columnObj['ID'] === 'SaleSequence';
      });

      // Get position of CommissionCode Sequence
      const commissionCodeIndex = topSellerColumns.findIndex(
        (columnObj: any) => {
          return columnObj['ID'] === 'CommissionCode';
        }
      );

      // Get position of CommissionGroup Sequence
      const commissionGroupIndex = topSellerColumns.findIndex(
        (columnObj: any) => {
          return columnObj['ID'] === 'CommissionGroup';
        }
      );

      // Get ProductSKU and Product Name in object
      const topProductsArr = [];
      for (let i = 0; i < 5; i++) {
        let temporaryObj = {
          productSKU: initialTopProductsData[i][productSKUIndex],
          productName: initialTopProductsData[i][productNameIndex],
          productOrder: initialTopProductsData[i][productOrderIndex],
          commissionCode: initialTopProductsData[i][commissionCodeIndex],
          commissionGroup: initialTopProductsData[i][commissionGroupIndex],
        };
        topProductsArr.push(temporaryObj);
      }

      // Send to product commission handler to get the commission values
      productCommissionHandler(topProductsArr);
    } catch (err) {
      throw err;
    }
  };

  const productCommissionHandler = async (topProductsArr: any) => {
    const commissionValues = new Array<TopSellingProductModel>();
    // Add a default commission value for products with no commission
    for (let i = 0; i < topProductsArr.length; i++) {
      // Set the commission value to -, this will stay the same if no commission value is found
      topProductsArr[i].commission = '-';
      // Add the product to the commissionValues array
      if (
        topProductsArr[i].productSKU != '' &&
        topProductsArr[i].commissionGroup != '' &&
        topProductsArr[i].commissionCode != ''
      ) {
        let newProductCommissionValue: TopSellingProductModel = {
          productSKU: topProductsArr[i].productSKU,
          commissionCode: topProductsArr[i].commissionCode,
          commissionGroup: topProductsArr[i].commissionGroup,
        };
        commissionValues.push(newProductCommissionValue);
      }
    }

    // Look up commission values
    const params = {
      Products: commissionValues.length > 0 ? commissionValues : undefined,
    };

    const commissionsResult: any = await api
      .post(`/sales/user/${userId}/topSellerCommissions`, params)
      .then(({ data }) => {
        const returnedCommissions = data;
        returnedCommissions.forEach((productCommission: any) => {
          topProductsArr.forEach((product: any) => {
            if (productCommission.productSKU === product.productSKU) {
              // Set product commission, using 2 decimal places
              product.commission =
                productCommission.commissionValue.toLocaleString('en-UK', {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                });
            }
          });
        });

        // Order top products in the order they sit on the podium
        // Get the indexes of the products
        const indexForPodium1 = topProductsArr.findIndex((productsObj: any) => {
          return productsObj.productOrder === 4;
        });

        const indexForPodium2 = topProductsArr.findIndex((productsObj: any) => {
          return productsObj.productOrder === 3;
        });

        const indexForPodium3 = topProductsArr.findIndex((productsObj: any) => {
          return productsObj.productOrder === 1;
        });

        const indexForPodium4 = topProductsArr.findIndex((productsObj: any) => {
          return productsObj.productOrder === 2;
        });

        const indexForPodium5 = topProductsArr.findIndex((productsObj: any) => {
          return productsObj.productOrder === 5;
        });

        const sortedTopProductsArr = [
          topProductsArr[indexForPodium1],
          topProductsArr[indexForPodium2],
          topProductsArr[indexForPodium3],
          topProductsArr[indexForPodium4],
          topProductsArr[indexForPodium5],
        ];

        setTopProductsData(sortedTopProductsArr);
        setIsTopProductsLoading(false);
      })
      .catch((err) => {
        throw err;
      });
  };

  const fetchProductsTrackingLink = async (productSKU: string) => {
    // When a user clicks on the copy URL, a call needs to be made to the Resources Internal service to return the tracking link, it will not be pre-calculated.
    // This probably needs tweeking once it is accesible to other tenants
    let params: object = {
      LinkURL: productSKU,
    };

    const commissionsResult: any = await api
      .post(`/resources/affiliate/${user.affiliateId}/GenerateProductLink`, {
        AffiliateID: `${user.affiliateId}`,
        LinkURL: productSKU,
      })
      .then(({ data }) => {
        const productURL = data.generatedLinkURL;
        message.success(t('urlHasBeenCopied'));
        navigator.clipboard.writeText(productURL);
      })
      .catch((err) => {
        message.error(t('urlCopyError'));
        throw err;
      });
  };

  return {
    // Variables
    isTopProductsLoading,
    topProductsData,
    // Methods
    fetchTopProducts: fetchTopProducts,
    generateLinkHandler: fetchProductsTrackingLink,
  };
};

export default useTopProducts;
