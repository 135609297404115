import useUser from 'hooks/User';
import { useEffect, useState } from 'react';
import api from 'libs/api';

export type SupportMessage = {
  supportRequestID: string;
  affiliateID: string;
  contactName: string;
  supportMessage: string;
  userIsAdmin: boolean;
};

const useSupportMessages = () => {
  const [supportMessage, setSupportMessage] = useState<SupportMessage>();
  const [messagesLoading, setIsLoading] = useState(false);
  const [messagesSubmitting, setIsSubmitting] = useState(false);
  const { user } = useUser();

  const setStartMessage = async (
    requestID: string,
    affiliateID: string,
    isAdmin: boolean
  ) => {
    setIsLoading(true);

    let newMessage: SupportMessage = {
      contactName: user.name,
      supportRequestID: requestID,
      affiliateID: affiliateID,
      supportMessage: '',
      userIsAdmin: isAdmin,
    };
    setSupportMessage(newMessage);
  };

  const noLongerLoading = async () => {
    setIsLoading(false);
  };

  const submitMessage = (item: SupportMessage) => {
    // api call
    setIsSubmitting(true);
    return api
      .post('/support/Message', item)
      .then((response) => {
        return response.data;
      })
      .finally(() => setIsSubmitting(false));
  };

  return {
    // Variables
    messagesLoading,
    messagesSubmitting,
    supportMessage,
    //categories,
    prepareMessage: setStartMessage,
    noLongerLoading,
    submitMessage,
    //resequence,
  };
};

export default useSupportMessages;
