import { useState } from 'react';
import api from 'libs/api';
import { RcFile } from 'antd/lib/upload';

export type NewsImage = {
  fileName: string;
  fileLocation: string;
};

export interface IUseNewsImages {
  images: NewsImage[];
  isLoading: boolean;
  load: () => Promise<void>;
  upload: (
    file: any,
    onProgress?: (progressEvent: any) => void
  ) => PromiseLike<any>;
}

const useNewsImages = (): IUseNewsImages => {
  const [images, setImages] = useState<NewsImage[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const load = async () => {
    setIsLoading(true);

    const result: any = await api.get('/content/GetImages');

    setImages(result.data);
    setIsLoading(false);
  };

  const upload = async (
    file: RcFile,
    onProgress?: (progressEvent: any) => void
  ): Promise<any> => {
    const formData = new FormData();
    formData.append('Files[]', file);

    return api.post('/content/UploadImage', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: onProgress,
    });
  };

  return {
    // Variables
    images,
    isLoading,
    // Methods
    load,
    upload,
  };
};

export default useNewsImages;
