import useLayout from 'hooks/Layout';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router';
import { Content, InfoBox } from 'components/shared';
import { Button, Card, message, Space, Col, Row } from 'components/antd';
import useAgreements from 'hooks/Agreements/agreements';
import useUser from 'hooks/User';
import PaymentsForm from 'pages/Apply/PaymentsForm';
import VatForm from 'pages/Apply/VatForm';
import useAffiliateDetails, {
  AcceptedDataReview,
} from 'hooks/Account/affiliateDetails';
import moment from 'moment';

const Page = () => {
  const { t } = useTranslation('dataReview');
  const { setMinimized } = useLayout();
  const {
    loadLatestAffiliateAgreement,
    agreementsLoading,
    lastAcceptedAgreement,
  } = useAgreements();
  const { user, approveDataReview } = useUser();
  const navigate = useNavigate();
  const { search } = useLocation();
  const q = new URLSearchParams(search);
  const returnUrl = q.get('returnUrl');
  const affiliateId = user.affiliateId;
  const { load, bank, isLoading, vat, acceptDataReview } =
    useAffiliateDetails(affiliateId);

  useEffect(() => {
    load().catch((error) => {
      const errorMsg = error?.response?.data?.message;
      message.error(
        t(`review.loadaffiliate.error`, {
          msg: errorMsg ? t(errorMsg) : undefined,
        })
      );
    });
    loadLatestAffiliateAgreement().catch((error) => {
      const errorMsg = error?.response?.data?.message;
      message.error(
        t(`review.loadagreement.error`, {
          msg: errorMsg ? t(errorMsg) : undefined,
        })
      );
    });
    // setSidebar(false);
    setMinimized(true);
    return () => {
      setMinimized(false);
      // setSidebar(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onAccept = () => {
    if (!lastAcceptedAgreement || !lastAcceptedAgreement.agreementID) return;
    if (!bank?.bankAccountId) return;

    let dataReview: AcceptedDataReview = {
      bankAccountId: bank.bankAccountId,
      selfBillingAgreementId: lastAcceptedAgreement.agreementID,
    };

    acceptDataReview(dataReview)
      .then(() => {
        message.success(t('review.commit.success'));

        approveDataReview();
        navigate(returnUrl || '/');
      })
      .catch((error) => {
        const errorMsg = error?.response?.data?.message;
        message.error(
          t(`review.commit.error`, {
            msg: errorMsg ? t(errorMsg) : undefined,
          })
        );
      });
  };

  const onLater = () => {
    sessionStorage.setItem('acceptDataReviewLater', 'true');

    navigate(returnUrl || '/');
  };

  return (
    <Content
      style={{
        maxWidth: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <h1>{t('dataReview')}</h1>

      <Row>
        <Col span={20}>
          <InfoBox style={{ width: '100%', height: '100%' }}>
            {t('dataReviewIntro', {
              msg: moment.utc(user?.reviewRequiredDate).format('DD MMM YYYY'),
            })}
          </InfoBox>
        </Col>
      </Row>
      <Card
        style={{
          width: '',
          minWidth: 500,
          maxWidth: 1900,
        }}
        loading={agreementsLoading || isLoading}
      >
        <Row>
          <Col
            span={10}
            style={{
              alignItems: 'center',
              textAlign: 'center',
            }}
          >
            <div
              style={{ padding: '2em', overflowY: 'auto', height: 600 }}
              dangerouslySetInnerHTML={{
                __html: lastAcceptedAgreement?.agreementDetails || '',
              }}
            />
          </Col>
          <Col span={4}></Col>
          <Col span={10}>
            <VatForm
              vat={vat}
              showSubmitButton={false}
              readonly={true}
              dataReviewDisplay={true}
            />
            <PaymentsForm
              waitingForApproval={!!bank?.changeRequestId}
              bank={bank}
              showSubmitButton={false}
              readonly={true}
              dataReviewDisplay={true}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Space>
              <Button onClick={onAccept}>{t('confirm')}</Button>
              <Button onClick={onLater}>{t('later')}</Button>
            </Space>
          </Col>
        </Row>
      </Card>
    </Content>
  );
};

export default Page;
