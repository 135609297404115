/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Card,
  Form,
  Input,
  message,
  Row,
  Space,
  Switch,
  Table,
} from 'components/antd';
import { Create, Cross, Edit, Save, SwitchIcon } from 'components/icons';
import { Content, TitleRow } from 'components/shared';
import {
  IUseNewsCategories,
  NewsCategory,
} from 'hooks/Resources/newsCategories';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  useCategories: IUseNewsCategories;
};

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}: any) => {
  const inputNode = inputType === 'switch' ? <Switch /> : <Input />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          valuePropName={inputType === 'switch' ? 'checked' : 'value'}
          initialValue={inputType === 'switch' ? false : ''}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const Page = ({ useCategories }: Props) => {
  const { t } = useTranslation('news');
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState<any>('');
  const [includeDeactivated, setIncludeDeactivated] = useState(false);
  const [data, setData] = useState<NewsCategory[]>([]);

  useEffect(() => {
    useCategories.load(includeDeactivated);
  }, [includeDeactivated]);

  useEffect(() => {
    setData(useCategories.categories);
  }, [useCategories.categories]);

  const isEditing = (record: NewsCategory) =>
    record.newsCategoryID === editingKey;

  const edit = (record: NewsCategory) => {
    form.setFieldsValue({
      ...record,
    });
    setEditingKey(record.newsCategoryID);
  };

  // const remove = (record: NewsCategory) => {
  //   if (!record || record.newsCategoryID === undefined) return;
  //   useCategories
  //     .remove(record.newsCategoryID)
  //     .then(() => {
  //       alert('yaay');
  //     })
  //     .catch(() => alert('nay'));
  // };

  const cancel = () => {
    setEditingKey('');
    form.resetFields();
    useCategories.load(includeDeactivated);
  };

  const toggle = (record: NewsCategory) => {
    if (!record.newsCategoryID) return;
    useCategories
      .activate(record.newsCategoryID, !record.isActive)
      .then(() => {
        message.success(t('newsCategory.activationToggle.success'));
        useCategories.load(includeDeactivated);
      })
      .catch((error) => {
        const errorMsg = error?.response?.data?.message;
        message.error(
          t('newsCategory.activationToggle.error', {
            msg: errorMsg ? t(errorMsg) : undefined,
          })
        );
      });
  };

  const save = async (key: any) => {
    let row;
    try {
      row = await form.validateFields();
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
      return;
    }

    const newData = [...data];
    const index =
      key > -1
        ? newData.findIndex((item: any) => key === item.newsCategoryID)
        : -1;
    const isEdit = index > -1;
    const localePath = `newsCategory.${isEdit ? 'edit' : 'save'}`;

    (isEdit
      ? useCategories.edit({ ...newData[index], ...row })
      : useCategories.add(row)
    )
      .then(() => {
        message.success(t(`${localePath}.success`));
      })
      .catch((error) => {
        const errorMsg = error?.response?.data?.message;
        message.error(
          t(`${localePath}.error`, {
            msg: errorMsg ? t(errorMsg) : undefined,
          })
        );
      })
      .finally(() => {
        form.resetFields();
        setEditingKey('');
        useCategories.load(includeDeactivated);
      });
  };

  const columns = [
    {
      title: t('common:isActive'),
      dataIndex: 'isActive',
      width: '10%',
      editable: true,
      render: (isActive: boolean) => <Switch disabled checked={isActive} />,
    },
    {
      title: t('common:name'),
      dataIndex: 'newsCategoryName',
      width: '55%',
      editable: true,
    },
    {
      title: t('articleCount'),
      dataIndex: 'totalNewsArticlesCount',
    },
    {
      title: t('common:action'),
      align: 'right' as 'right',
      dataIndex: 'action',
      width: 200,
      render: (_: any, record: any) => {
        const editable = isEditing(record);
        return editable ? (
          <Space>
            <Button
              icon={
                <Save
                  style={{
                    marginLeft: -3,
                    marginBottom: -3,
                    fontSize: '1.1em',
                  }}
                />
              }
              onClick={() => save(record.newsCategoryID)}
            />

            <Button
              onClick={cancel}
              icon={
                <Cross
                  style={{
                    marginLeft: -2,
                    marginBottom: -2,
                    fontSize: '0.9em',
                  }}
                />
              }
            />
          </Space>
        ) : (
          <Space>
            <Button
              disabled={editingKey !== ''}
              onClick={() => edit(record)}
              icon={
                <Edit
                  style={{
                    marginLeft: -2,
                    marginBottom: -2,
                  }}
                />
              }
            />
            <Button
              disabled={editingKey !== ''}
              onClick={() => toggle(record)}
              icon={
                <SwitchIcon
                  style={{
                    marginLeft: -2,
                    marginBottom: -2,
                  }}
                />
              }
            />

            {/* <Button
              disabled={editingKey !== '' || record.count}
              onClick={() => remove(record)}
              icon={
                <Delete
                  style={{
                    marginLeft: -2,
                    marginBottom: -2,
                  }}
                />
              }
            /> */}
          </Space>
        );
      },
    },
  ];
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record: any) => ({
        record,
        inputType: col.dataIndex === 'isActive' ? 'switch' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const addNew = () => {
    setData([
      {
        isActive: true,
        newsCategoryID: -1,
        newsCategoryName: '',
        totalNewsArticlesCount: 0,
      },
      ...data,
    ]);
    setEditingKey(-1);
  };

  const onChange = (values: any) => {
    setIncludeDeactivated(values.includeDeactivated);
  };

  return (
    <Content>
      <TitleRow>
        <h1>{t('newsCategories')}</h1>
        <Button
          disabled={editingKey !== ''}
          icon={
            <Create
              style={{ marginLeft: -2, marginBottom: -2, fontSize: '1.2em' }}
            />
          }
          onClick={addNew}
        >
          {t('common:add')}
        </Button>
      </TitleRow>

      <Row justify="center" style={{ paddingTop: '2em', paddingBottom: '2em' }}>
        <Form layout="inline" onValuesChange={onChange}>
          <Form.Item
            name="includeDeactivated"
            valuePropName="checked"
            initialValue={false}
            label={t('includeDeactivated')}
          >
            <Switch />
          </Form.Item>
        </Form>
      </Row>

      <Card>
        <Form form={form} component={false}>
          <Table
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            loading={useCategories.isLoading}
            rowKey="newsCategoryID"
            bordered
            dataSource={data}
            columns={mergedColumns}
            rowClassName="editable-row"
            pagination={false}
          />
        </Form>
      </Card>
    </Content>
  );
};

export default Page;
