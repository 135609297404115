import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TitleRow } from 'components/shared';
import {
  Button,
  Card,
  Form,
  InputNumber,
  Table,
  Space,
  message,
  Tooltip,
} from 'components/antd';
import { Cross, Edit, Save, UpArrow, DownArrow } from 'components/icons';
import { HighlightedProduct } from 'hooks/Product/productHighlighting';

interface Props {
  highlightedProducts: any;
  isHighlightedProductsLoading: boolean;
  saveHighlighted: Function;
  fetchData: Function;
}

const HighlightedProducts = ({
  highlightedProducts,
  isHighlightedProductsLoading,
  saveHighlighted,
  fetchData,
}: Props) => {
  const { t } = useTranslation('productHighlighting');
  const { t: c } = useTranslation('common');

  const [editingKey, setEditingKey] = useState<string>('');

  const getColumns = [
    {
      title: t('productSKU'),
      dataIndex: 'externalId',
      key: 'productSKU',
      fixed: 'left',
      editable: false,
      inputType: 'none',
    },
    {
      title: t('productName'),
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
      editable: false,
      inputType: 'none',
    },
    {
      title: t('commissionValue'),
      dataIndex: 'commission',
      key: 'commission',
      fixed: 'left',
      editable: true,
      inputType: 'input',
    },
    {
      title: c('action'),
      dataIndex: '',
      align: 'right',
      key: '',
      width: 200,
      render: (
        af: HighlightedProduct,
        _: HighlightedProduct,
        index: number
      ) => {
        const editable = isEditing(af);
        return editable ? (
          <Space>
            <Tooltip title={t('saveEdit')}>
              <Button
                icon={
                  <Save
                    style={{
                      marginLeft: -3,
                      marginBottom: -3,
                      fontSize: '1.1em',
                    }}
                  />
                }
                onClick={() => saveHighlightedChange(af)}
              />
            </Tooltip>
            <Tooltip title={t('cancelEdit')}>
              <Button
                onClick={cancelHighlighted}
                icon={
                  <Cross
                    style={{
                      marginLeft: -2,
                      marginBottom: -2,
                      fontSize: '0.9em',
                    }}
                  />
                }
              />
            </Tooltip>
          </Space>
        ) : (
          <Space>
            <Tooltip title={t('moveUpSequence')}>
              <Button
                disabled={
                  index === 0 ||
                  index > highlightedProducts.length - 1 ||
                  editingKey !== ''
                }
                onClick={() => moveUpHandler(af)}
                icon={
                  <UpArrow
                    style={{
                      marginLeft: -2,
                      marginBottom: -2,
                    }}
                  />
                }
              />
            </Tooltip>
            <Tooltip title={t('moveDownSequence')}>
              <Button
                disabled={
                  index < 0 ||
                  index >= highlightedProducts.length - 1 ||
                  editingKey !== ''
                }
                onClick={() => moveDownHandler(af)}
                icon={
                  <DownArrow
                    style={{
                      marginLeft: -2,
                      marginBottom: -2,
                    }}
                  />
                }
              />
            </Tooltip>
            <Tooltip title={t('editHighlighted')}>
              <Button
                onClick={() => editHighlighted(af)}
                icon={<Edit style={{ marginLeft: -2, marginBottom: -2 }} />}
              />
            </Tooltip>
            <Tooltip title={t('removeHighlighted')}>
              <Button
                disabled={editingKey !== ''}
                onClick={() => removeHighlighted(af)}
                icon={
                  <Cross
                    style={{
                      marginLeft: -2,
                      marginBottom: -2,
                      fontSize: '0.9em',
                    }}
                  />
                }
              />
            </Tooltip>
          </Space>
        );
      },
    },
  ];

  const mergedColumns = getColumns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (product: any) => ({
        product,
        inputType: col.inputType,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(product),
      }),
    };
  });

  const [form] = Form.useForm();

  const fetchNewData = () => {
    setEditingKey('');
    form.resetFields();
    fetchData();
  };

  const isEditing = (product: HighlightedProduct) =>
    product.externalId === editingKey;

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    product,
    index,
    children,
    ...restProps
  }: any) => {
    let inputNode = <InputNumber type="number" min={0.01} />;
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            rules={[
              {
                required: true,
                message: t('invalidCommissionValueShort'),
                pattern: new RegExp(
                  /^0*[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*\d*(\.\d{0,2})?$/
                ),
              },
            ]}
            style={{
              margin: 0,
            }}
            valuePropName={inputType === 'switch' ? 'checked' : 'value'}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  const editHighlighted = (product: HighlightedProduct) => {
    form.setFieldsValue({ ...product });
    setEditingKey(product.externalId);
  };

  const saveHighlightedChange = async (product: HighlightedProduct) => {
    // Validate field and prevent save if not correct
    try {
      await form.validateFields();
    } catch (err) {
      return;
    }

    // Get the Product SKU of the product being edited
    // Product SKU is externalId
    const currentProductSKU = product.externalId;

    // Get the index of the product being edited
    const indexOfCurrentProduct = highlightedProducts.findIndex(
      (productsObj: any) => {
        return productsObj.externalId === currentProductSKU;
      }
    );

    // Get the value of the field
    const fields = form.getFieldsValue();

    // Get the commission value and assign it
    const newCommission = fields.commission;

    // Update the object within the Highlighted Product Array
    highlightedProducts[indexOfCurrentProduct].commission = newCommission;

    // Save the changes
    saveHighlightedChanges(highlightedProducts);
  };

  const cancelHighlighted = () => {
    setEditingKey('');
    form.resetFields();
  };

  const removeHighlighted = (product: HighlightedProduct) => {
    // Get the current Product SKU
    const currentProductSKU = product.externalId;

    // Get the index of the current product
    // External ID is the Product SKU
    const indexOfCurrentProduct = highlightedProducts.findIndex(
      (productsObj: any) => {
        return productsObj.externalId === currentProductSKU;
      }
    );

    // Remove the current product from the highlightedProducts Array
    highlightedProducts.splice(indexOfCurrentProduct, 1);

    // Move each product below this one up 1 in order
    for (let i = indexOfCurrentProduct; i < highlightedProducts.length; i++) {
      highlightedProducts[i].order = highlightedProducts[i].order - 1;
    }

    // Save the changes
    saveHighlightedChanges(highlightedProducts);
  };

  const moveUpHandler = (product: HighlightedProduct) => {
    const currentOrderNumber = product.order;

    // Get the index of the current product
    const indexOfCurrentProduct = highlightedProducts.findIndex(
      (productsObj: any) => {
        return productsObj.order === currentOrderNumber;
      }
    );

    // Get the index of the product above the current product
    const indexOfAboveProduct = highlightedProducts.findIndex(
      (productsObj: any) => {
        return productsObj.order === currentOrderNumber - 1;
      }
    );

    // Change the order number of the current product and the product above it
    highlightedProducts[indexOfCurrentProduct].order--;
    highlightedProducts[indexOfAboveProduct].order++;

    saveHighlightedChanges(highlightedProducts);
  };

  const moveDownHandler = (product: HighlightedProduct) => {
    const currentOrderNumber = product.order;

    // Get the index of the current product
    const indexOfCurrentProduct = highlightedProducts.findIndex(
      (productsObj: any) => {
        return productsObj.order === currentOrderNumber;
      }
    );

    // Get the index of the product below the current product
    const indexOfAboveProduct = highlightedProducts.findIndex(
      (productsObj: any) => {
        return productsObj.order === currentOrderNumber + 1;
      }
    );

    // Change the order number of the current product and the product below it
    highlightedProducts[indexOfCurrentProduct].order++;
    highlightedProducts[indexOfAboveProduct].order--;

    saveHighlightedChanges(highlightedProducts);
  };

  const saveHighlightedChanges = (products: HighlightedProduct[]) => {
    saveHighlighted(products)
      .then(() => {
        message.success(t('highlighting.save.success'));
        fetchNewData();
        setEditingKey('');
        form.resetFields();
      })
      .catch((response: any) => {
        message.error(
          t('highlighting.save.error', {
            error: response?.data?.message || response?.data?.title,
          })
        );
      });
  };

  useEffect(() => {
    fetchNewData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card>
      <TitleRow>
        <h2>{t('highlightedProducts')}</h2>
      </TitleRow>
      <Form form={form} component={false}>
        <Table
          style={{ marginTop: '1.5em', marginBottom: '1em' }}
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          rowKey="productSKU"
          columns={mergedColumns as any}
          dataSource={highlightedProducts}
          loading={isHighlightedProductsLoading}
          pagination={false}
        />
      </Form>
    </Card>
  );
};

export default HighlightedProducts;
