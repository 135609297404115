import axios from 'axios';

import { apiUrl } from 'constants/env';
import { AxiosError, AxiosRequestConfig } from 'axios';

const defaultHeader = { 'Content-Type': 'application/json' };
let bearerToken: string | undefined;
let impersonationUserId: string | undefined;
let isSetup = false;

const addAuthHeader = (config: AxiosRequestConfig) => {
  if (!config.headers) config.headers = {};

  if (bearerToken) {
    //console.log('Bearer ', bearerToken);
    config.headers['Authorization'] = `Bearer ${bearerToken}`;
  }

  return config;
};

// Check if we have impersonation user in the sessionStorage (tab specific)
const addImpersonationHeader = (config: AxiosRequestConfig) => {
  if (!config.headers) config.headers = {};

  if (impersonationUserId) {
    config.headers['X-IMPERSONATION-TARGET'] = impersonationUserId;
  }

  return config;
};

const clearAuthToken = (logoutUser?: Function) => (error: AxiosError) => {
  if (error.response && error.response.status === 401) {
    removeAuthHeader();
    if (logoutUser) logoutUser();
  }
  throw error;
};

export const isAxiosSetup = () => isSetup;

// logoutUser is a function that is called when
// we want to logout the user with no params
type SetupProps = {
  onSignout: Function | undefined;
  token: string;
};
export const setupAxios = ({ onSignout, token }: SetupProps) => {
  if (!token) return;

  bearerToken = token;

  api.interceptors.request.use(addAuthHeader);
  api.interceptors.request.use(addImpersonationHeader);

  // redirect if 401
  api.interceptors.response.use(
    (response) => response,
    clearAuthToken(onSignout)
  );

  isSetup = true;
};

export const removeAuthHeader = () => {
  bearerToken = undefined;
};

export const addImpersionationUserId = (id: string) => {
  impersonationUserId = id;
};

export const removeImpersionationUserId = () => {
  impersonationUserId = undefined;
};

const urlFormatParam = (key: string, value: string) =>
  `${encodeURIComponent(key)}=${encodeURIComponent(value)}&`;

const api = axios.create({
  baseURL: window.location.host.toLocaleLowerCase().includes('localhost')
    ? apiUrl
    : `https://${window.location.host}/api`,
  timeout: 30000,
  headers: defaultHeader,
  paramsSerializer: (params) => {
    // This changes the way we serialize arrays and does
    // item=1,item=2 instead of item[]=1,item[]=2
    const keys = Object.keys(params);
    let options = '';

    keys.forEach((key) => {
      const isParamTypeObject = typeof params[key] === 'object';
      const isParamTypeArray = isParamTypeObject && params[key].length >= 0;

      if (!isParamTypeObject) {
        const value = params[key];
        if (value !== undefined) options += urlFormatParam(key, params[key]);
      }

      if (isParamTypeObject && isParamTypeArray) {
        params[key].forEach((element: string) => {
          if (element !== undefined) options += urlFormatParam(key, element);
        });
      }
    });

    return options ? options.slice(0, -1) : options;
  },
});

/**
 * Add this as the catch step to api calls
 * to propagate the server validation errors
 * to the current form
 * @param {*} form form
 * @param {*} t translation
 * @returns sends the rejected promise forward
 */
// const validateFormAndTranslation =
//   (form, t, mappings = {}) =>
//   (error) => {
//     const errors = error?.response?.data?.errors;
//     if (errors) {
//       form.setFields(
//         Object.entries(errors).map(([k, v]) => ({
//           name: mappings[k] || k,
//           errors: v.map(t),
//         }))
//       );
//     }
//     return Promise.reject(error);
//   };

// export const validateForm = validateFormAndTranslation;

export default api;
