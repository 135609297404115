import { Report } from 'hooks/Reporting/reports';
import moment from 'moment';

export const REPORT_CHART_CONFIG: any = {
  SalesTrafficClicks: {
    DateColumnID: 'Date',
    GroupByFilterID: 'GroupBy',
    GroupByFilterValue: 'Day',
    DefaultMetric: 'Currency',
    Metrics: [
      {
        value: 'Currency',
        label: 'Values',
      },
      {
        value: 'Integer',
        label: 'Counts',
      },
    ],
  },
};

export function getColour(index: number, alternateColors?: boolean) {
  const color = [
    '#FFC300',
    '#FF5733',
    '#C70039',
    '#900C3F',
    '#581845',
    '#7585FF',
    '#DAF7A6',
    '#0000FF',
    '#33FF00',
    '#FCD02C',
  ][index];
  return alternateColors ? `${color}88` : color;
}

const formatYAxis = (data: string, columnType: string) => {
  if (columnType === 'Date' && moment.utc(data).isValid()) {
    return moment.utc(data).format('DD/MM/YYYY');
  }

  return data;
};

const getXAxisTick = (columnType: string, t: Function) => {
  if (columnType === 'Currency') return t('currency.symbol');

  return '';
};

type ReportData = {
  dataSource: any[];
  labels: any[];
  chartDataTick: string;
};

export const getReportData = (
  report: Report,
  metric: string | string[],
  t: Function,
  alternate?: boolean
): ReportData => {
  const headerCol = report.Header.Columns;
  const reportId = report.Header.ReportID;
  const yAxisColumnID: string = REPORT_CHART_CONFIG[reportId].DateColumnID;
  const yAxisColumn = headerCol.find(({ ID }) => ID === yAxisColumnID);

  if (!yAxisColumn)
    return {
      dataSource: [],
      labels: [],
      chartDataTick: '',
    };

  const yAxisDataIndex = headerCol.indexOf(yAxisColumn);
  const labelData = report.Body.map((row) => row[yAxisDataIndex]).map((v) =>
    formatYAxis(v, yAxisColumn.Type)
  );

  // get headers
  const selectedHeaders = Array.isArray(metric)
    ? metric
    : headerCol.filter(({ Type }) => Type === metric).map((x) => x.ID);

  const selectedHeaderNames = headerCol
    .filter(({ Type, ID }) =>
      Array.isArray(metric) ? metric.includes(ID) : Type === metric
    )
    .map((x) => x.Name);

  if (!Array.isArray(metric)) {
    REPORT_CHART_CONFIG[report.Header.ReportID].xAxisColumnType = metric;
  }

  // populate data from body - use only the selectedHeaders and first column is the label (not aggregated yet)
  const reportDataTable = report.structuredBody.map((x: any) => {
    const label = x[yAxisColumnID];
    const data = selectedHeaders.map((h) => x[h]);
    return [label, data];
  });

  // aggregate values
  const aggrReportData = reportDataTable.reduce((acc: any, cur: any) => {
    const [label, data] = cur;

    if (acc[label]) {
      //agregate data
      const a = acc[label];
      for (let i = 0; i < a.length; ++i) a[i] = a[i] + data[i];
    } else {
      acc[label] = data;
    }
    return acc;
  }, {});

  // prepare legend data
  const legendData: any = {};
  selectedHeaders.forEach((h) => (legendData[h] = []));

  Object.entries(aggrReportData).forEach((entry) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [label, data] = entry;

    (data as number[]).forEach((value: number, index: number) => {
      legendData[selectedHeaders[index]].push(value);
    });
    //split arrays into legend buckets by its index/name
  });

  // prepare a legend for each header
  const legend = selectedHeaders.map((name: string, i: number) => ({
    label: alternate
      ? `${selectedHeaderNames[i]} (Comparison)`
      : selectedHeaderNames[i],
    backgroundColor: getColour(i, alternate),
    borderColor: getColour(i, alternate),
    data: legendData[name] || [],
  }));

  return {
    dataSource: legend,
    labels: labelData,
    chartDataTick: getXAxisTick(
      REPORT_CHART_CONFIG[reportId].xAxisColumnType,
      t
    ),
  };
};

// function LightenDarkenColor(col: any, amt: any) {
//   var usePound = false;
//   if (col[0] === '#') {
//     col = col.slice(1);
//     usePound = true;
//   }

//   var num = parseInt(col, 16);

//   var r = (num >> 16) + amt;

//   if (r > 255) r = 255;
//   else if (r < 0) r = 0;

//   var b = ((num >> 8) & 0x00ff) + amt;

//   if (b > 255) b = 255;
//   else if (b < 0) b = 0;

//   var g = (num & 0x0000ff) + amt;

//   if (g > 255) g = 255;
//   else if (g < 0) g = 0;

//   return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
// }

// also you could decide this based on const { currentTheme } = useThemeSwitcher();
// and put some values into src/constants/themes.ts
// i have created lightened primary colors from the primary
// const threeColors = ['#fb7f6d', '#fcbfc6'];

export const getComparisonReportData = (
  reportA: any,
  reportB: any,
  reportBFull: any,
  datasetLabels: string[],
  columnIds?: string[]
) => {
  const color1 = window
    .getComputedStyle(document.documentElement)
    .getPropertyValue('--graph-color-1');

  const color2 = window
    .getComputedStyle(document.documentElement)
    .getPropertyValue('--graph-color-3');

  const colors = [color1, color2];

  const headerCol = reportA.Header.Columns;
  let columns = headerCol
    .map((h: any, i: number) => ({ id: h.ID, title: h.Name, index: i }))
    .filter((h: any) => (columnIds ? columnIds.includes(h.id) : true));

  let columnIndexes: number[] = columns.map((x: { index: number }) => x.index);

  if (reportA.Body.length === 0) {
    const monthName = moment().startOf('month').format('MMMM');
    reportA.Body = [[monthName, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]];
  }
  if (reportB.Body.length === 0) {
    const monthName = moment().startOf('month').add(-1, 'month').format('MMMM');
    reportB.Body = [[monthName, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]];
  }
  if (reportBFull.Body.length === 0) {
    const monthName = moment().startOf('month').add(-1, 'month').format('MMMM');
    reportBFull.Body = [[monthName, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]];
  }

  let result = [reportA, reportB].map((r, index: number) => ({
    label: datasetLabels[index],
    dataExtra: columnIndexes.map((i) => ({
      value: r.Body[0][i],
      type: headerCol[i].Type,
    })),
    data: columnIndexes.map((i) => {
      const currentValue = r.Body[0][i];
      const fullMonthValue = reportBFull.Body[0][i];
      if (!currentValue || !fullMonthValue) return currentValue;
      return r.Body[0][i] / reportBFull.Body[0][i];
    }),
    backgroundColor: colors[index],
    // borderWidth: 2,
    // borderColor: LightenDarkenColor(getColour(index), -60),
  }));

  return {
    labels: columns.map((x: any) => x.title),
    datasets: result,
  };
};
