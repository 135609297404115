import api from 'libs/api';
import { useState } from 'react';

export enum ApplicationStatus {
  Pending = 1,
  Approved = 2,
  Declined = 3,
  Wizard = 4,
}

const applicationPath = '/accounts/affiliateapplication';
const applicationStatusPath = '/accounts/user/affiliateapplicationstatus';

export interface IUseApplication {
  status: ApplicationStatus;
  isLoading: boolean;
  load: (params?: any) => Promise<void>;
  apply: (application: any) => Promise<void>;
}

const useApplication = (): IUseApplication => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [status, setStatus] = useState<ApplicationStatus>(
    ApplicationStatus.Wizard
  );

  const load = async (props?: any) => {
    setIsLoading(true);

    try {
      const { data: results }: any = await api.get(applicationStatusPath);

      const { status } = results;

      // if status is null, that means the user has no application
      setStatus(status || ApplicationStatus.Wizard);
    } finally {
      setIsLoading(false);
    }
  };

  const apply = (application: any) =>
    api.post(applicationPath, application).then((result) => result.data);

  // implement required methods and api calls

  return {
    // Methods
    load,
    apply,
    // Variables
    isLoading,
    status,
  };
};

export default useApplication;
