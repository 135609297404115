type Props = {
  reason: string;
};

function Page({ reason }: Props) {
  return (
    <div>
      <h2>Application Declined</h2>
      <p>Unfortunately, you were not accepted on to the Affiliate program.</p>

      {/* <p>
        The reason you were not accepted: <strong>{reason}</strong>
      </p> */}

      <p>
        If you have any questions regarding our decision, please contact{' '}
        <strong>Insert here</strong>
      </p>
    </div>
  );
}

export default Page;
