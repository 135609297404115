import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  Card,
  Form,
  Input,
  Row,
  Space,
  Select,
  message,
  Popconfirm,
} from 'components/antd';
import { Content } from 'components/shared';

import { Table, Button } from 'components/antd';
import {
  BillingOff,
  BillingOn,
  Edit,
  Search,
  Supervised,
  SwitchIcon,
} from 'components/icons';
import useAffiliate, {
  AffiliateListItem,
  AffiliateStatus,
} from 'hooks/Account/affiliate';
import { AffiliateGroup } from 'hooks/Account/affiliateGroups';
import { useEffect, useState } from 'react';
import useUser from 'hooks/User';

const getColumns = (
  t: Function,
  setStatus: Function,
  setBilling: Function,
  impersonateAffiliate: Function,
  navigate: Function
) => [
  {
    title: t('status'),
    dataIndex: 'status',
    key: 'status',
    fixed: 'left',
    width: 100,
  },
  {
    title: t('name'),
    dataIndex: 'name',
    key: 'name',
    fixed: 'left',
  },
  {
    title: t('email'),
    dataIndex: 'email',
    key: 'email',
    width: 150,
  },
  {
    title: t('affiliateId'),
    width: '30%',
    dataIndex: 'externalIdentifier',
    key: 'affiliateId',
  },
  {
    title: t('company'),
    dataIndex: 'company',
    key: 'company',
  },

  {
    title: t('group'),
    dataIndex: 'groupName',
    key: 'groupName',
  },
  {
    title: t('action'),
    key: 'action',
    width: 100,

    align: 'right',
    render: (af: AffiliateListItem) => (
      <Space>
        <Popconfirm
          title="Are you sure you want to login as this user?"
          onConfirm={() => {
            impersonateAffiliate(af.id, af)
              .then(() => {
                message.success(`Logged in as ${af.name}`);
                navigate('/dashboard');
              })
              .catch(() => {
                message.error(`Error logging in as ${af.name}`);
              });
          }}
          okText="Yes"
          cancelText="Cancel"
        >
          <Button
            disabled={af.status !== 'Active'}
            title={t('signInAs')}
            icon={
              <Supervised
                style={{ marginLeft: -4, marginBottom: -4, fontSize: '1.2em' }}
              />
            }
          />
        </Popconfirm>

        <Link to={`/admin/affiliates/${af.id}/edit`} title={t('edit')}>
          <Button
            icon={<Edit style={{ marginLeft: -2, marginBottom: -2 }} />}
          />
        </Link>
        <Popconfirm
          title="Are you sure you want to toggle the active status of this affiliate?"
          onConfirm={() => {
            setStatus(
              af.id,
              af.status === 'Active'
                ? AffiliateStatus.Closed
                : AffiliateStatus.Active
            );
          }}
          okText="Yes"
          cancelText="Cancel"
        >
          <Button
            title={t('admin.affiliates:toggleStatus')}
            icon={
              <SwitchIcon
                style={{ fontSize: '0.8em', marginLeft: -1, marginBottom: -1 }}
              />
            }
          />
        </Popconfirm>
        {af.billingEnabled ? (
          <Popconfirm
            title="Are you sure you want to disable billing for this affiliate?"
            onConfirm={() => {
              setBilling(af.id, false);
            }}
            okText="Yes"
            cancelText="Cancel"
          >
            <Button
              title={t('admin.affiliates:toggleBillingDisabled')}
              icon={
                <BillingOff
                  style={{
                    marginLeft: -4,
                    marginBottom: -4,
                    fontSize: '1.2em',
                  }}
                />
              }
            />
          </Popconfirm>
        ) : (
          <Popconfirm
            title="Are you sure you want to enable billing for this affiliate?"
            onConfirm={() => {
              setBilling(af.id, true);
            }}
            okText="Yes"
            cancelText="Cancel"
          >
            <Button
              title={t('admin.affiliates:toggleBillingEnabled')}
              icon={
                <BillingOn
                  style={{
                    marginLeft: -4,
                    marginBottom: -4,
                    fontSize: '1.2em',
                  }}
                />
              }
            />
          </Popconfirm>
        )}
      </Space>
    ),
  },
];

type Props = {
  groups: AffiliateGroup[];
};

const Page = ({ groups }: Props) => {
  const { t } = useTranslation('admin.affiliates');
  const { t: c } = useTranslation('common');
  const { affiliates, pagination, isLoading, load, setStatus, setBilling } =
    useAffiliate();
  const [state, setState] = useState<any>({});
  const navigate = useNavigate();

  const { impersonateAffiliate } = useUser();

  useEffect(() => {
    load(state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    setState({ ...state, ...pagination });
    load({ ...state, ...pagination });
  };

  const onFinish = (values: any) => {
    setState({ ...state, ...values, current: 1 });
    load({ ...state, ...values, current: 1 });
  };

  const setStatusAndReload = (id: string, status: AffiliateStatus) => {
    setStatus(id, status)
      .then(() => {
        message.success('The status was successfully changed');
      })
      .catch(() => {
        message.error('There was an error changing the status');
      })
      .finally(() => {
        load(state);
      });
  };

  const setBillingAndReload = (id: string, billingEnabled: boolean) => {
    setBilling(id, billingEnabled)
      .then(() => {
        message.success('The billing status was successfully changed');
      })
      .catch(() => {
        message.error('There was an error changing the billing status');
      })
      .finally(() => {
        load(state);
      });
  };

  const columns = getColumns(
    c,
    setStatusAndReload,
    setBillingAndReload,
    impersonateAffiliate,
    navigate
  ) as any;
  return (
    <Content>
      <h1>{t('affiliatesList')}</h1>

      <Row justify="center" style={{ paddingTop: '2em', paddingBottom: '2em' }}>
        <Form layout="inline">
          <Form.Item name="status">
            <Select
              placeholder={c('status')}
              allowClear
              options={['active', 'closed'].map((item) => ({
                label: c(item),
                value: item,
              }))}
              style={{ width: 200 }}
              onChange={(value) => onFinish({ ...state, status: value })}
            />
          </Form.Item>

          <Form.Item name="affiliateGroup">
            <Select
              placeholder={c('affiliateGroup')}
              allowClear
              options={groups.map((item) => ({
                label: item.name,
                value: item.id || '',
              }))}
              style={{ width: 200 }}
              onChange={(value) =>
                onFinish({ ...state, affiliateGroup: value })
              }
            />
          </Form.Item>

          <Form.Item name="affilateName">
            <Input.Search
              value={state.affiliateName}
              onSearch={(value: string) =>
                onFinish({ ...state, affiliateName: value })
              }
              placeholder={c('affiliateName')}
              allowClear
              onChange={(e) =>
                setState({ ...state, affiliateName: e.target.value })
              }
              enterButton={
                <Search style={{ marginTop: 5, fontSize: '1.2em' }} />
              }
            />
          </Form.Item>

          <Form.Item name="affilateEmail">
            <Input.Search
              value={state.affiliateEmail}
              allowClear
              onSearch={(value: string) =>
                onFinish({ ...state, affiliateEmail: value })
              }
              onChange={(e) =>
                setState({ ...state, affiliateEmail: e.target.value })
              }
              placeholder={c('affiliateEmail')}
              enterButton={
                <Search style={{ marginTop: 5, fontSize: '1.2em' }} />
              }
            />
          </Form.Item>

          <Form.Item name="affilateId">
            <Input.Search
              value={state.affiliateId}
              allowClear
              onSearch={(value: string) =>
                onFinish({ ...state, affiliateId: value })
              }
              onChange={(e) =>
                setState({ ...state, affiliateId: e.target.value })
              }
              placeholder={c('affiliateId')}
              enterButton={
                <Search style={{ marginTop: 5, fontSize: '1.2em' }} />
              }
            />
          </Form.Item>

          <Form.Item name="dataReview">
            <Select
              placeholder={c('datareviewstatus')}
              allowClear
              options={['indate', 'expiring', 'expired'].map((item) => ({
                label: c(item),
                value: item,
              }))}
              style={{ width: 200 }}
              onChange={(value) => onFinish({ ...state, dataReview: value })}
            />
          </Form.Item>
        </Form>
      </Row>

      <Card>
        <Table
          loading={isLoading}
          rowKey="id"
          columns={columns}
          dataSource={affiliates}
          onChange={handleTableChange}
          pagination={pagination}
        />
      </Card>
    </Content>
  );
};

export default Page;
