export const isValidUrl = (param: string) => {
  let url: URL;
  try {
    url = new URL(param);
  } catch (_) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
};

export const isAlphaNumeric = (str: string) =>
  str === '' || !!str.match(/^[a-zA-Z0-9]+$/);
