import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import {
  Button,
  Card,
  Form,
  Input,
  message,
  Switch,
  Table,
  Select,
  Row,
  Col,
} from 'components/antd';
import { useVT } from 'virtualizedtableforantd4';

import { Content, TitleRow } from 'components/shared';
import { Create, Edit } from 'components/icons';

import useCommitionCodes, {
  CommissionCodeListItem,
  ProductGroup,
} from 'hooks/Commissions/code';
import useComission, {
  CommissionCompact,
  CommissionTier,
  TierActionStatus,
} from 'hooks/Commissions';
import useCommissionMappings from 'hooks/Commissions/mapping';

import CommissionDetail from './components/CommissionDetail';
import MappingTable from './components/MappingTable';

const StyledTable = styled(Table)`
  .selected-row td {
    background-color: lightgray;
  }

  .selected-row:hover td {
    background-color: gray !important;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 1em;
`;

const getColumns = (t: Function, id: number) => [
  {
    title: t('name'),
    dataIndex: 'name',
    key: 'name',
    width: 120,
    fixed: 'left',
    // sorter: (a: any, b: any) => a.name.localeCompare(b.name),
    // sortDirections: ['ascend', 'descend'],
  },
  {
    title: t('standardCommissions'),
    dataIndex: 'amount',
    render: (value: string) => `£ ${t('number', { value: Number(value) })}`,
    key: 'amount',
  },
  {
    title: t('isTieredCommission'),
    dataIndex: 'isTieredCommission',
    key: 'isTieredCommission',
    render: (value: boolean) => <Switch disabled checked={Boolean(value)} />,
  },
  {
    title: t('totalMappings'),
    dataIndex: 'totalMappings',
    key: 'totalMappings',
    render: (value: string) => value,
  },
  {
    title: t('action'),
    dataIndex: '',
    align: 'right',
    fixed: 'right',
    width: 60,
    key: '',
    render: (c: CommissionCodeListItem) => (
      <Link to={`/admin/commissions/${id}/edit?commission=${c.id}`}>
        <Button icon={<Edit style={{ marginLeft: -2, marginBottom: -2 }} />} />
      </Link>
    ),
  },
];

type Props = {
  isEdit?: boolean;
  groups: ProductGroup[];
};

const Page = ({ isEdit, groups }: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation('commissions');
  const { t: c } = useTranslation('common');
  const { search, pathname } = useLocation();
  let { id } = useParams();
  const [vt] = useVT(() => ({ scroll: { y: 600 } }), []);
  const {
    isLoading: isLoadingForm,
    fetchOne,
    add,
    update,
  } = useCommitionCodes();
  const {
    isLoading: isLoadingTable,
    isLoadingOne: isLoadingCommission,
    fetchOne: fetchOneCommission,
    commissions,
    load,
    add: addCommission,
    update: updateCommission,
    affiliates,
    loadAffiliates,
  } = useComission(Number(id));

  const useMapping = useCommissionMappings(id);

  const { isLoading: isLoadingMapping, load: loadMappings } = useMapping;

  const [item, setItem] = useState<CommissionCodeListItem>(null as any);
  const [selectedCommission, setSelectedCommission] = useState<any>(null);
  const [commission, setCommission] = useState<any>(null);
  const [showArchived, setShowArchived] = useState<boolean>(false);

  const [form] = Form.useForm();
  const idNum: number = Number(id);

  useEffect(() => {
    const q = new URLSearchParams(search);
    const com = q.get('commission');
    setSelectedCommission(com);
    if (!com || com === 'new') {
      setCommission({});
    } else {
      if (commissions.length <= 0) return;
      const loadTiered =
        commissions.find((x) => x.id === Number(com))?.isTieredCommission ||
        false;
      fetchOneCommission(Number(com), loadTiered)
        .then((c) => {
          setCommission(c);
        })
        .catch(() => {
          message.error('Unable to load commission details');
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, commissions]);

  useEffect(() => {
    if (isEdit) {
      fetchOne(idNum, showArchived)
        .then((result) => {
          setItem(result);
        })
        .catch((err) => {
          message.error(t('commissionCode.fetch.error'));
        });
      load().catch(() => {
        message.error(t('commission.load.error'));
      });
    } else {
      setItem({} as any);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, isEdit, showArchived]);

  useEffect(() => {
    if (commission && commission.id) {
      // commissionCode"id, commissionId
      loadMappings(commission.id).catch(() => {
        message.error(t('mapping.load.error'));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commission]);

  useEffect(() => {
    loadAffiliates().catch(() => {
      message.error('Error downloading affiliate list');
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => form.resetFields(), [item]);

  const rowClassFn = ({ id }: any) => {
    return Number(selectedCommission) === id ? 'selected-row' : '';
  };

  const onFinish = async (values: CommissionCodeListItem) => {
    (isEdit ? update(values) : add(values))
      .then((data: any) => {
        message.success(
          t(
            isEdit
              ? 'commissionCode.update.success'
              : 'commissionCode.add.success'
          )
        );
        if (!isEdit)
          navigate(`/admin/commissions/${data.id}/edit`, { replace: true });
      })
      .catch(({ response }) => {
        message.error(
          t(
            isEdit ? 'commissionCode.update.error' : 'commissionCode.add.error',
            { error: response?.data?.message }
          )
        );
      });
  };

  const onFinishCommission = (
    item: CommissionCompact,
    tier?: CommissionTier
  ) => {
    const isNewCommission = selectedCommission === 'new';

    // take a look if the commission wasTiered before the change, if its new it should default to false
    const wasTiered =
      commissions.find((x) => x.id === item.id)?.isTieredCommission || false;

    // take a look if the new commission is tiered
    const isTiered = !!tier;

    // need to look at previous state.. if tiered was there... based on that i have to say to the
    // update endpoint if it should delete/add/update tiered section
    let status = TierActionStatus.None;
    if (!isNewCommission) {
      if (!wasTiered && isTiered) status = TierActionStatus.Add;
      else if (wasTiered && !isTiered) status = TierActionStatus.Remove;
      else if (wasTiered && isTiered) status = TierActionStatus.Update;
    }

    // add always adds a tiered section if there is any :)
    return (
      isNewCommission
        ? addCommission(item, tier)
        : updateCommission(item, status, tier)
    )
      .then((data) => {
        message.success(
          t(isEdit ? 'commission.update.success' : 'commission.add.success')
        );
        if (isNewCommission) {
          navigate(`/admin/commissions/${id}/edit?commission=${data.id}`, {
            replace: true,
          });
        }
      })
      .catch(() => {
        message.error(
          t(isEdit ? 'commission.update.error' : 'commission.add.error')
        );
      })
      .finally(() => {
        load().catch(() => {
          message.error(t('commission.load.error'));
        });
      });
  };

  return (
    <Content>
      <h1>
        {t(isEdit ? 'commissionCode' : 'newCommissionCode', {
          code: item?.code || '',
        })}
      </h1>

      <Row gutter={[32, 32]} style={{ marginTop: '2em' }}>
        <Col xs={24} xl={10} xxl={10}>
          <h2>{t('comissionCodeDetails')}</h2>
          <Card loading={isLoadingForm}>
            <Form
              initialValues={item}
              name="basic"
              form={form}
              labelCol={{
                xs: { span: 8 },
                xl: { span: 10 },
                xxl: { span: 10 },
              }}
              wrapperCol={{
                xs: { span: 8 },
                xl: { span: 12 },
                xxl: { span: 10 },
              }}
              autoComplete="off"
              onFinish={onFinish}
            >
              <Form.Item name="id" style={{ display: 'none' }}>
                <Input />
              </Form.Item>
              <Form.Item
                label={c('code')}
                name="code"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input
                  title="This value can't be changed"
                  placeholder={t('comissionCodePlaceholder')}
                  disabled={isEdit}
                />
              </Form.Item>
              <Form.Item
                label={c('name')}
                name="name"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input placeholder={t('comissionCodeNamePlaceholder')} />
              </Form.Item>

              <Form.Item
                label={c('productGroup')}
                name="commissionGroupId"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  placeholder={t('productGroupPlaceholder')}
                  options={groups.map((x) => ({
                    label: x.name,
                    value: x.id,
                  }))}
                />
              </Form.Item>
              <Form.Item
                label={c('archived')}
                name="archived"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
              <Form.Item
                wrapperCol={{
                  xs: { offset: 8 },
                  xl: { offset: 10 },
                  xxl: { offset: 10 },
                }}
              >
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>

        {isEdit && (
          <Col xs={24} xl={14} xxl={14}>
            <TitleRow>
              <h2>{t('commissions')}</h2>
              <Header>
                <div>
                  <label>{c('showArchived')}: </label>
                  <Switch
                    checked={showArchived}
                    onChange={() => setShowArchived(!showArchived)}
                  />
                </div>

                <Link to={`${pathname}?commission=new`}>
                  <Button
                    icon={
                      <Create
                        style={{
                          marginLeft: -2,
                          marginBottom: -2,
                          fontSize: '1.2em',
                        }}
                      />
                    }
                  >
                    {t('common:add')}
                  </Button>
                </Link>
              </Header>
            </TitleRow>
            <Card loading={isLoadingForm}>
              <StyledTable
                rowKey="id"
                loading={isLoadingTable}
                columns={getColumns(c, idNum) as any}
                pagination={false}
                rowClassName={rowClassFn}
                dataSource={commissions}
                style={{ width: '100%' }}
                components={vt}
                scroll={{ y: 600 }}
              ></StyledTable>
            </Card>
          </Col>
        )}
      </Row>

      {selectedCommission && isEdit && (
        <Row gutter={[32, 32]} style={{ marginTop: '2em' }}>
          <Col xs={24} xl={10} xxl={10}>
            <TitleRow>
              <h2>{t('commissionDetail')}</h2>
              <Link to={pathname}>
                <Button>{t('common:close')}</Button>
              </Link>
            </TitleRow>
            <Card loading={isLoadingCommission}>
              <CommissionDetail
                item={commission}
                onFinish={onFinishCommission}
              />
            </Card>
          </Col>
          {selectedCommission !== 'new' && (
            <MappingTable
              affiliates={affiliates}
              isLoadingCommission={isLoadingCommission || isLoadingMapping}
              loading={isLoadingMapping}
              commissionId={commission?.id}
              useMapping={useMapping}
            />
          )}
        </Row>
      )}
    </Content>
  );
};

export default Page;
