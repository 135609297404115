import { message } from 'antd';
import { MessageApi } from 'antd/lib/message';

export interface Messages extends MessageApi {
  [key: string]: any;
}

// Overriding the default duration from 3 to 5 (documentation says its 1.5s)
const fn =
  (messageType: string) =>
  (text: string, duration: number = 3, ...args: any[]) =>
    (message as Messages)[messageType](text, duration, ...args);

const types = {
  success: fn('success'),
  info: fn('info'),
  warning: fn('warning'),
  error: fn('error'),
  loading: fn('loading'),
};

export default types;
