import { useEffect } from 'react';
import { Route, Routes } from 'react-router';
import { PrivatePage } from 'components/shared';

import Query from './Query';
import Requests from './Requests';
import FAQs from './FAQs';
import PlatformAgreement from './PlatformAgreement';
import Terms from './TermsAndConditions';

import { Permissions } from 'constants/permissions';
import { Features } from 'constants/features';

function Page() {
  useEffect(() => {
    return () => {
      // setNav(true);
      // setSidebar(true);
      // unblock();
    };
  }, []);

  return (
    <Routes>
      <Route path="query/*" element={<Query />} />
      <Route path="requests/*" element={<Requests />} />
      <Route
        path="faqs"
        element={
          <PrivatePage
            component={FAQs}
            feature={Features['Content.FAQs']}
            requirement={Permissions['Content.FAQs.Basic']}
          />
        }
      />
      <Route path="platformagreement/*" element={<PlatformAgreement />} />
      <Route path="termsandconditions/*" element={<Terms />} />
      <Route path="/">Support main page</Route>
    </Routes>
  );
}

export default Page;
