import { Button, Modal } from 'components/antd';
import Report from './Report';

export type Properties = {
  visible: boolean;
  reportID: string;
  handleClose: any;
  reportFilterReplacements: [];
};

const ReportModal = ({
  visible,
  handleClose,
  reportID,
  reportFilterReplacements,
}: Properties) => {
  return (
    <>
      <Modal
        visible={visible}
        onOk={handleClose}
        onCancel={handleClose}
        width={1000}
        footer={[
          <Button key="close" onClick={handleClose}>
            Close
          </Button>,
        ]}
      >
        <Report
          reportID={reportID}
          reportFilterReplacements={reportFilterReplacements}
        ></Report>
      </Modal>
    </>
  );
};
export default ReportModal;
