import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Card,
  Form,
  Input,
  InputNumber,
  message,
  Space,
  Table,
} from 'components/antd';
import { Create, Cross, Edit, Save } from 'components/icons';
import { Content, TitleRow } from 'components/shared';
import {
  AffiliateGroup,
  IUseAffiliateGroup,
} from 'hooks/Account/affiliateGroups';

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}: any) => {
  const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

type Props = {
  useGroups: IUseAffiliateGroup;
};

const Page = ({ useGroups }: Props) => {
  const { t } = useTranslation('admin.affiliates');
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState<any>('');
  const [data, setData] = useState<any[]>([]);

  useEffect(() => {
    setData(useGroups.groups);
  }, [useGroups.groups]);

  const isEditing = (record: any) => record.id === editingKey;

  const edit = (record: AffiliateGroup) => {
    form.setFieldsValue({
      ...record,
      name: record.name,
      description: record.description,
    });
    setEditingKey(record.id);
  };

  const cancel = () => {
    form.resetFields();
    setEditingKey('');
    useGroups.load();
  };

  const save = async (key: any) => {
    let row;
    try {
      row = await form.validateFields();
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
      return;
    }

    const isEdit = key === 'new' ? false : true;
    const localePath = `affiliateGroups.${isEdit ? 'edit' : 'add'}`;

    (isEdit ? useGroups.edit({ id: key, ...row }) : useGroups.add(row))
      .then(() => {
        message.success(t(`${localePath}.success`));
      })
      .catch((error) => {
        const errorMsg = error?.response?.data?.message;
        message.error(
          t(`${localePath}.error`, {
            msg: errorMsg ? t(errorMsg) : undefined,
          })
        );
      })
      .finally(() => {
        form.resetFields();
        setEditingKey('');
        useGroups.load();
      });
  };

  const columns = [
    {
      title: t('common:name'),
      dataIndex: 'name',
      width: '40%',
      editable: true,
    },
    {
      title: t('common:description'),
      width: '40%',
      dataIndex: 'description',
      editable: true,
    },
    {
      title: t('common:action'),
      align: 'right' as 'right',
      dataIndex: 'action',
      width: 100,
      render: (_: any, record: any) => {
        const editable = isEditing(record);
        return editable ? (
          <Space>
            <Button
              onClick={() => save(record.id)}
              title={t('common:save')}
              icon={
                <Save
                  style={{
                    marginLeft: -3,
                    marginBottom: -3,
                    fontSize: '1.1em',
                  }}
                />
              }
            />

            <Button
              onClick={cancel}
              title={t('common:cancel')}
              icon={
                <Cross
                  style={{
                    marginLeft: -2,
                    marginBottom: -2,
                    fontSize: '0.9em',
                  }}
                />
              }
            />
          </Space>
        ) : (
          <Button
            disabled={editingKey !== ''}
            title={t('common:edit')}
            onClick={() => edit(record)}
            icon={
              <Edit
                style={{
                  marginLeft: -2,
                  marginBottom: -2,
                }}
              />
            }
          />
        );
      },
    },
  ];
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record: any) => ({
        record,
        inputType: 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const addNew = () => {
    setData([
      {
        id: 'new',
        name: '',
        description: '',
      },
      ...data,
    ]);
    setEditingKey('new');
  };

  return (
    <Content>
      <TitleRow>
        <h1>{t('affiliateGroups')} </h1>
        <Button
          disabled={editingKey !== ''}
          onClick={addNew}
          icon={
            <Create
              style={{ marginLeft: -2, marginBottom: -2, fontSize: '1.2em' }}
            />
          }
        >
          {t('common:add')}
        </Button>
      </TitleRow>
      <Card>
        <Form form={form} component={false}>
          <Table
            pagination={false}
            loading={useGroups.isLoading}
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            rowKey="id"
            bordered
            dataSource={data}
            columns={mergedColumns}
            rowClassName="editable-row"
          />
        </Form>
      </Card>
    </Content>
  );
};

export default Page;
