import { FormInstance } from 'antd';

import { Form, Checkbox, Input } from 'components/antd';
import { useTranslation } from 'react-i18next';

type Props = {
  form?: FormInstance;
  agreementDetails: any;
  agreementId?: string;
};

function Page({ form, agreementDetails = '', agreementId }: Props) {
  const { t } = useTranslation();

  return (
    <>
      <div
        style={{ padding: '2em 0', overflowY: 'auto', height: 300 }}
        dangerouslySetInnerHTML={{ __html: agreementDetails }}
      />
      <Form
        name="basic"
        form={form}
        autoComplete="off"
        initialValues={{
          selfBillingAgreementId: agreementId,
        }}
        onValuesChange={(values) => {}}
      >
        <Form.Item name="selfBillingAgreementId" hidden>
          <Input type="hidden" />
        </Form.Item>
        <Form.Item
          name="selfBillingAgreementIdAccepted"
          valuePropName="checked"
          wrapperCol={{
            offset: 0,
            span: 16,
          }}
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                return value
                  ? Promise.resolve()
                  : Promise.reject(
                      new Error(t('platformAgreementConfirmationRequired'))
                    );
              },
            }),
          ]}
        >
          <Checkbox>{t('confirmPlatformAgreement')}</Checkbox>
        </Form.Item>
      </Form>
    </>
  );
}

export default Page;
