import { Content, TitleRow } from 'components/shared';
import { useTranslation } from 'react-i18next';
import HighlightedProducts from './HighlightedProducts';
import AddHighlightedProducts from './AddHighlightedProducts';
import useProductHighlighting from 'hooks/Product/productHighlighting';

const Page = () => {
  const { t } = useTranslation('productHighlighting');

  const {
    isConfigLoading,
    maxHighlightedProducts,
    searchingForProducts,
    pagination,
    searchedProducts,
    savingProducts,
    highlightedProducts,
    isHighlightedProductsLoading,
    saveHighlighted,
    fetchData,
    fetchConfig,
    searchForProducts,
    saveSearchProductChanges,
  } = useProductHighlighting();

  return (
    <Content>
      <TitleRow>
        <h1>{t('highlighting')}</h1>
      </TitleRow>
      <HighlightedProducts
        highlightedProducts={highlightedProducts}
        isHighlightedProductsLoading={isHighlightedProductsLoading}
        saveHighlighted={saveHighlighted}
        fetchData={fetchData}
      />
      <AddHighlightedProducts
        isConfigLoading={isConfigLoading}
        maxHighlightedProducts={maxHighlightedProducts}
        searchingForProducts={searchingForProducts}
        highlightedProducts={highlightedProducts}
        pagination={pagination}
        searchedProducts={searchedProducts}
        savingProducts={savingProducts}
        saveHighlighted={saveHighlighted}
        fetchData={fetchData}
        fetchConfig={fetchConfig}
        searchForProducts={searchForProducts}
        saveSearchProductChanges={saveSearchProductChanges}
      />
    </Content>
  );
};

export default Page;
