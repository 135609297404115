import { useEffect } from 'react';
import { Form, Modal, Input, DatePicker, message } from 'antd';
import {
  AdditionalCaptureDetails,
  SupportRequest,
} from 'hooks/Support/requests';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import useSupportRequests from 'hooks/Support/requests';

const UpdateAdditionalCapturedDataModal = (props: {
  visible: boolean;
  capturedDetails?: any;
  captureOptions?: AdditionalCaptureDetails;
  supportRequest?: SupportRequest;
  userIsAdmin: boolean;
  contactName: string;
  onFinished: () => void;
  onCancel: () => void;
}) => {
  const { requestSubmitting, updateQueryAdditionalData, noLongerSubmitting } =
    useSupportRequests();

  const submitRequestProcess = async () => {
    const results = await form.validateFields();

    results.supportRequestID = props.supportRequest!.supportRequestID;
    results.affiliateID = props.supportRequest!.affiliateID;
    results.userIsAdmin = props.userIsAdmin;
    var additionalCapturedDetails: any = {};

    if (props.captureOptions !== undefined) {
      props.captureOptions!.captureDetails?.forEach((capture) => {
        additionalCapturedDetails[capture.attributeName] = form.getFieldValue(
          capture.attributeName
        );
      });
      let jsonData = JSON.stringify(additionalCapturedDetails);
      results.additionalCapturedDetails = jsonData;
    }

    updateQueryAdditionalData(results)
      .then(() => {
        message.success(t(`query.updateAdditional.success`));
        form.resetFields();
        props.onFinished();
      })
      .catch((error) => {
        const errorMsg = error?.response?.data?.message;
        message.error(
          t(`query.updateAdditional.error`, {
            msg: errorMsg ? t(errorMsg) : undefined,
          })
        );
        noLongerSubmitting();
      });
  };

  const cancelRequestProcess = async () => {
    form.resetFields();
    props.onCancel();
  };

  const [form] = Form.useForm();
  const { t } = useTranslation('supportQuery');

  useEffect(() => {
    form.setFieldsValue({ contactName: props.contactName });
    if (props.capturedDetails !== undefined && props.capturedDetails !== '') {
      var parsedCaptureDetails = JSON.parse(props.capturedDetails);

      console.log(parsedCaptureDetails);

      var capturedData: any = {};

      Object.keys(parsedCaptureDetails).map((thisObject) => {
        if (thisObject !== 'transactionDate') {
          capturedData[thisObject] = parsedCaptureDetails[thisObject];
        } else {
          capturedData[thisObject] = moment(parsedCaptureDetails[thisObject]);
        }
      });
      form.setFieldsValue(capturedData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.capturedDetails]);

  return (
    <Modal
      title={t('processAdditionalCaptureUpdate')}
      okText={t('okChangeAdditionalCapturedUpdate')}
      cancelText={t('cancelAdditionalCapturedUpdate')}
      visible={props.visible}
      onCancel={cancelRequestProcess}
      onOk={submitRequestProcess}
      confirmLoading={requestSubmitting}
    >
      <Form
        name="basic"
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 12 }}
        autoComplete="off"
      >
        <Form.Item
          label={t('contactName')}
          name="contactName"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        {props.captureOptions !== undefined ? (
          props.captureOptions.captureDetails!.map((element, index) => {
            switch (element.dataType) {
              case 'datetime':
                return (
                  <Form.Item
                    label={t(element.attributeName)}
                    name={element.attributeName}
                    key={element.attributeName}
                    rules={[{ required: element.required }]}
                  >
                    <DatePicker
                      allowClear={false}
                      format="DD-MM-yyyy"
                      placeholder={t(element.attributeName)}
                      style={{ marginRight: '5px' }}
                      disabledDate={(date) => date > moment()}
                    />
                  </Form.Item>
                );
              case 'decimal':
                return (
                  <Form.Item
                    label={t(element.attributeName)}
                    name={element.attributeName}
                    key={element.attributeName}
                    rules={[
                      {
                        required: element.required,
                        message: t('invalidDecimalValue'),
                        pattern: new RegExp(
                          /^\s*(\d+(\.\d{1,2})?|\.\d{1,2})\s*$/
                        ),
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                );
              default:
                return (
                  <Form.Item
                    label={t(element.attributeName)}
                    name={element.attributeName}
                    key={element.attributeName}
                    rules={[{ required: element.required }]}
                  >
                    <Input />
                  </Form.Item>
                );
            }
          })
        ) : (
          <></>
        )}
      </Form>
    </Modal>
  );
};

export default UpdateAdditionalCapturedDataModal;
