// import localeValues from 'antd/es/locale/en_GB';
import {
  Button,
  Card,
  Form,
  Input,
  Row,
  Space,
  Switch,
  Table,
  Tooltip,
} from 'components/antd';
import {
  Create,
  DocumentDownload,
  Edit,
  Report,
  Search,
} from 'components/icons';
import { Content, TitleRow } from 'components/shared';
import { Permissions } from 'constants/permissions';
import TagList from 'components/shared/TagList';
import useNews, { News } from 'hooks/Resources/news';
import { NewsCategoryCompact } from 'hooks/Resources/newsCategories';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import ReportModal from 'pages/Reporting/ReportModal';
import {
  ReportOverrideFilter,
  ReportRequest,
  AppliedFilter,
} from 'hooks/Reporting/reports';
import moment from 'moment-timezone';
import useReports from 'hooks/Reporting/reports';
import useUser from 'hooks/User';

type Props = {
  categories: NewsCategoryCompact[];
};

const Title = styled.h3`
  font-weight: bold;
  max-width: 100%;
  width: 100%;
  max-height: 3em;
  font-size: 1.5em;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Image = styled.img`
  max-width: 100%;
  max-height: 90%;
  width: 100%;

  min-width: 250px;
  max-width: 250px;
  height: 200px;
  object-fit: contain;

  border: calc(2 * var(--border-width-base)) solid var(--border-color-base);
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1em;
`;

const CardRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  width: auto;
  gap: 1em;
`;

const Detail = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Page = ({ categories }: Props) => {
  const [state, setState] = useState<any>({});

  const { t } = useTranslation('news');
  const { t: c } = useTranslation('common');
  const [errors, setErrors] = useState<any>({});

  const { news, load, pagination, isLoading } = useNews();
  const { downloadCSV } = useReports();
  const { hasPermission } = useUser();

  const getColumns = (
    t: Function,
    c: Function,
    categories: NewsCategoryCompact[] = []
  ) => [
    {
      title: t('articleHeadline'),
      dataIndex: 'articleHeadline',
      key: 'articleHeadline',
      fixed: 'left',
    },
    {
      title: c('createdDate'),
      dataIndex: 'createdDate',
      key: 'createdDate',
      render: (date: Date) => c('date', { value: new Date(date) }),
    },
    {
      title: c('lastUpdated'),
      dataIndex: 'lastUpdateDate',
      key: 'lastUpdateDate',
      render: (date: Date) => c('date', { value: new Date(date) }),
    },
    {
      title: c('expires'),
      dataIndex: 'expiryDate',
      key: 'expiryDate',
      render: (date: Date) => date && new Date(date).toLocaleDateString(),
    },
    {
      title: c('categories'),
      dataIndex: 'newsArticleCategories',
      key: 'newsArticleCategories',
      render: (item: NewsCategoryCompact[]) => (
        <TagList
          title={c('categories')}
          tagData={item.map((cat) => ({
            id: cat.newsCategoryID?.toString(),
            label:
              categories.find(
                (c: NewsCategoryCompact) =>
                  c.newsCategoryID === cat.newsCategoryID
              )?.newsCategoryName || cat.newsCategoryName,
          }))}
          tagMaxWidth={'100px'}
        />
      ),
    },
    {
      title: c('action'),
      dataIndex: '',
      align: 'right',
      key: '',
      render: (af: any) => (
        <Space>
          <Tooltip title={t('editArticleTooltip')}>
            <Link to={`/admin/news/${af.articleID}/edit`}>
              <Button
                icon={<Edit style={{ marginLeft: -2, marginBottom: -2 }} />}
              />
            </Link>
          </Tooltip>
          {hasPermission(Permissions['Accounts.Affiliate.Administer']) && (
            <Tooltip title={t('viewStatisticsReportTooltip')}>
              <Button
                onClick={() => showReportModal(af.articleID, af.createdDate)}
                icon={<Report style={{ marginLeft: -2, marginBottom: -2 }} />}
              />
            </Tooltip>
          )}
          {hasPermission(Permissions['Accounts.Affiliate.Administer']) && (
            <Tooltip title={t('downloadStatisticsReportTooltip')}>
              <Button
                onClick={() => downloadReport(af.articleID, af.createdDate)}
                icon={
                  <DocumentDownload
                    style={{ marginLeft: -2, marginBottom: -2 }}
                  />
                }
              />
            </Tooltip>
          )}
          {/* <Button
            icon={<Delete style={{ marginLeft: -2, marginBottom: -2 }} />}
          /> */}
        </Space>
      ),
    },
  ];

  useEffect(() => {
    load(pagination);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    setState({ ...state, ...sorter, ...pagination });
    load({ ...state, ...sorter, ...pagination });
  };

  const onFinish = (values: any) => {
    setState({ ...state, ...values });
    // we need to reset the offset because filter can change the number of items
    load({ ...state, ...values, current: 1 });
  };

  const showReportModal = (articleID: string, createdDate: Date) => {
    let reportFilterReplacements: ReportOverrideFilter[] = [
      { filterName: 'NewsArticleID', filterValue: articleID },
      {
        filterName: 'From',
        filterValue: moment(createdDate).startOf('day').toDate().toISOString(),
      },
      {
        filterName: 'To',
        filterValue: moment(createdDate).startOf('day').toDate().toISOString(),
      },
    ];

    setState({
      showModal: true,
      articleID: articleID,
      filterReplacements: reportFilterReplacements,
    });
  };

  const downloadReport = (articleID: string, createdDate: Date) => {
    let reportFilters: AppliedFilter[] = [
      { ID: 'NewsArticleID', Value: articleID },
      {
        ID: 'From',
        Value: moment(createdDate).startOf('day').toDate().toISOString(),
      },
      {
        ID: 'To',
        Value: moment(createdDate).startOf('day').toDate().toISOString(),
      },
    ];

    let reportRequest: ReportRequest = {
      ReportID: 'NewsStatistics',
      Current: undefined,
      PageSize: undefined,
      Filters: reportFilters,
    };

    downloadCSV(reportRequest);
  };

  const hideReportModal = () => {
    setState({
      showModal: false,
    });
  };

  return (
    <Content>
      <TitleRow>
        <h1>{t('newsAdminList')}</h1>
        <Link to={`/admin/news/create`}>
          <Button
            icon={
              <Create
                style={{ marginLeft: -2, marginBottom: -2, fontSize: '1.2em' }}
              />
            }
          >
            {t('common:add')}
          </Button>
        </Link>
      </TitleRow>

      <ReportModal
        visible={state.showModal}
        handleClose={hideReportModal}
        reportID="NewsStatistics"
        reportFilterReplacements={state.filterReplacements}
      />

      <Row justify="center" style={{ paddingTop: '2em', paddingBottom: '2em' }}>
        <Form layout="inline">
          <Form.Item name="articleSearchText">
            <Input.Search
              allowClear
              value={state.articleSearchText}
              onSearch={(value) =>
                onFinish({ ...state, articleSearchText: value })
              }
              placeholder={t('articleSearchText')}
              enterButton={
                <Search style={{ marginTop: 5, fontSize: '1.2em' }} />
              }
            />
          </Form.Item>
          <Form.Item
            name="dashboardOnly"
            valuePropName="checked"
            initialValue={false}
            label={t('dashboardOnly')}
          >
            <Switch
              onChange={(value) => onFinish({ ...state, dashboardOnly: value })}
            />
          </Form.Item>
          <Form.Item
            name="includeExpired"
            valuePropName="checked"
            initialValue={false}
            label={t('includeExpired')}
          >
            <Switch
              onChange={(value) =>
                onFinish({ ...state, includeExpired: value })
              }
            />
          </Form.Item>
        </Form>
      </Row>

      <Card>
        <Table
          rowKey="articleID"
          columns={getColumns(t, c, categories) as any}
          dataSource={news}
          onChange={handleTableChange}
          loading={isLoading}
          pagination={pagination}
          expandable={{
            expandedRowRender: (record: News) => (
              <CardRow>
                <div style={{ padding: '1em' }}>
                  <Image
                    alt={
                      !errors[record.articleID] && record.articleHeadline
                        ? record.articleHeadline
                        : t('noImage')
                    }
                    src={
                      !errors[record.articleID] && record.articleHeadline
                        ? record.articleImagePath
                        : '/assets/no-image.png'
                    }
                    onError={() =>
                      setErrors({ ...errors, [record.articleID]: true })
                    }
                  />
                </div>
                <Column style={{ width: '100%' }}>
                  <div>
                    <Title>{record.articleHeadline}</Title>

                    <Detail
                      dangerouslySetInnerHTML={{
                        __html: record.articleSubHeading,
                      }}
                    />
                  </div>
                </Column>
              </CardRow>
            ),
            rowExpandable: (record: News) =>
              record.articleSubHeading ? true : false,
          }}
        />
      </Card>
    </Content>
  );
};

export default Page;
