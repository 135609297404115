import { useTranslation } from 'react-i18next';
import { Row, Spin } from 'components/antd';
import styled, { CSSProperties } from 'styled-components';
import { Line, Bar } from 'react-chartjs-2';

// resolves issue described https://github.com/sgratzl/chartjs-chart-wordcloud/issues/4
import { Chart as Ch, registerables } from 'chart.js';
Ch.register(...registerables);

export enum ChartTypes {
  Bar = 'bar',
  Line = 'line',
}

const Container = styled.div`
  height: 400px;
`;

interface Props {
  chartType: string;
  chartLabels: string[];
  dataSource: any[];
  dataTick: string;
  loading: boolean;
  style?: CSSProperties;
}

const Chart = ({
  chartType,
  chartLabels,
  dataSource,
  dataTick,
  loading,
  style,
}: Props) => {
  const { t } = useTranslation();

  const chartSwitch = (chartType: string) => {
    switch (chartType) {
      case ChartTypes.Line:
        return <Line data={data} options={chartOptions} />;
      case ChartTypes.Bar:
      default:
        return <Bar data={data} options={chartOptions} />;
    }
  };

  const data = {
    labels: chartLabels,
    datasets: dataSource,
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,

    plugins: {
      legend: {
        display: true,
      },
      datalabels: {
        // display labels for this specific dataset
        display: false,
      },
    },
    tooltips: {
      enabled: false,
    },
    scales: {
      y: {
        ticks: {
          // Include a symbol in the tick, before or after
          callback: function (value: any, index: any, values: any) {
            if (dataTick === '£') {
              return dataTick + t('number', { value: value });
            } else if (dataTick === '%') {
              return value + dataTick;
            } else {
              return value;
            }
          },
        },
      },
    },
  };

  return (
    <Container style={style}>
      <Row justify="center">
        <Spin spinning={loading} />
      </Row>
      {!loading && chartSwitch(chartType)}
    </Container>
  );
};

export default Chart;
