import { Navigate, Route, Routes } from 'react-router';
import useSupportHook from 'hooks/Support/helpdesk';
import Create from './Create';

const Page = () => {
  const useSupport = useSupportHook();
  const { enquiryTypes } = useSupport;

  return (
    <Routes>
      <Route path="create" element={<Create enquiryTypes={enquiryTypes} />} />
      <Route index element={<Navigate to="create" />} />
    </Routes>
  );
};

export default Page;
