import { Navigate, Route, Routes } from 'react-router';
import EmailTemplateAdd from './add';
import EmailTemplateEdit from './edit';
import EmailTemplatesList from './list';

const Page = () => {
  return (
    <Routes>
      <Route path="/list" element={<EmailTemplatesList />} />
      <Route path="/add" element={<EmailTemplateAdd />} />
      <Route
        path="/edit/:emailTemplateId/:emailTemplateTypeId"
        element={<EmailTemplateEdit />}
      />
      <Route index element={<Navigate to="list" />} />
    </Routes>
  );
};

export default Page;
