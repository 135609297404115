import { Switch, Table } from 'components/antd';
import { CommissionGroupItem } from 'hooks/Commissions/financialCommissions';
import { useTranslation } from 'react-i18next';

const getColumns = (t: Function, groups: CommissionGroupItem[]) => [
  {
    title: t('groupName'),
    dataIndex: 'commissionGroupId',
    key: 'commissionGroupId',
    fixed: 'left',
    width: 200,
    render: (value: string) => groups.find((x) => x.id === value)?.name,
  },
  {
    title: t('code'),
    dataIndex: 'code',
    key: 'code',
    width: 100,
  },
  {
    title: t('description'),
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: t('amount'),
    dataIndex: 'amount',
    key: 'amount',
    width: 65,
    render: (value: number) => '£' + t('common:number', { value }),
  },
  {
    title: t('effectiveFrom'),
    dataIndex: 'effectiveFrom',
    key: 'effectiveFrom',
    width: 150,
    render: (date: Date) => date && t('date', { value: new Date(date) }),
  },
  {
    title: t('isTieredCommission'),
    dataIndex: 'tiers',
    key: 'tiers',
    width: 140,
    render: (value: boolean) => <Switch disabled checked={Boolean(value)} />,
  },
];

type Props = {
  groups: CommissionGroupItem[];
  data: any[];
  isLoading?: boolean;
};

const Component = ({ data, groups, isLoading }: Props) => {
  const { t } = useTranslation();

  return (
    <Table
      rowKey="id"
      loading={isLoading}
      columns={getColumns(t, groups) as any}
      dataSource={data.map((x, i) => ({ id: i, ...x }))}
      // this allows the pagination to work when all data is local but still
      // maintains the default table settings
      pagination={{ current: undefined, pageSize: undefined, total: undefined }}
    />
  );
};

export default Component;
