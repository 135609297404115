import { InfoPage } from 'components/shared';
import useUser from 'hooks/User';
import { useTranslation } from 'react-i18next';

const LogoutCallback = () => {
  const { t } = useTranslation();
  const { signoutRedirectCallback } = useUser();

  signoutRedirectCallback();
  return (
    <InfoPage
      message={t('loggingIn')}
      additionalText={t('pleaseWaitDotDotDot')}
    />
  );
};

export default LogoutCallback;
