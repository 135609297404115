import { useEffect } from 'react';
import styled from 'styled-components';
import { Card } from 'components/antd';
import { FAQCategoryCompact } from 'hooks/Support/faqCategories';

type Props = {
  isLoading: boolean;
  items: FAQCategoryCompact[];
  loadFAQsForCategory: any;
  selectedCategory: number;
};

const Content = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  max-width: 100%;
  max-height: 5em;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: auto;
  margin-top: auto;
  text-align: center;
`;

const FlexDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2em;
  gap: 2em;

  .selectedCard {
    background: var(--special-color);
  }
  .categoryCard {
    border-radius: 10px;
    font-weight: bold;
    font-size: 1.3em;
  }
`;

function CategoryView({
  isLoading,
  items = [],
  loadFAQsForCategory,
  selectedCategory,
}: Props) {
  useEffect(() => {
    if (items.length > 0) {
      loadFAQsForCategory('phonyEvent', items[0].faqCategoryID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FlexDiv>
      {items.map((item) => (
        <Card
          hoverable={!isLoading}
          key={item.faqCategoryID}
          className={
            selectedCategory === item.faqCategoryID
              ? 'selectedCard categoryCard'
              : 'categoryCard'
          }
          style={{ width: '100%' }}
          onClick={(e: any) => {
            if (isLoading) {
              return;
            } else {
              loadFAQsForCategory(e, item.faqCategoryID);
            }
          }}
        >
          <Content>{item.faqCategoryName}</Content>
        </Card>
      ))}
    </FlexDiv>
  );
}

export default CategoryView;
