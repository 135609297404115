// Layout - Constants
export const LAYOUT_PADDING = '32px';

// Layout - SideBar
export const SIDEBAR_WIDTH = '16em';
export const MINIMIZED_SIDEBAR_WIDTH = '4em';

// Layout - TopBar
export const NAVBAR_HEIGHT = '120px';

// Impersonation Color
export const LOGINAS_COLOR = '#8b0000';
