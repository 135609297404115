import { useEffect, useState } from 'react';
import api from 'libs/api';

export type FAQCategory = {
  isActive: boolean;
  faqCategoryID?: number;
  faqCategoryName: string;
  sequenceNumber: number;
  totalFAQCount?: number;
  totalFAQActiveCount?: number;
  totalFAQInactiveCount?: number;
};

export type FAQCategoryCompact = {
  isActive: boolean;
  faqCategoryID?: number;
  faqCategoryName: string;
  sequenceNumber: number;
};

export type FAQCategoryResequence = {
  faqCategoryID: number;
  sequenceNumber: number;
  sequenceChange: number;
};

export interface IUseFAQCategories {
  filterCategories: FAQCategoryCompact[];
  categories: FAQCategory[];
  categoriesLoading: boolean;
  load: (includeDeactivated: boolean) => Promise<void>;
  add: (category: FAQCategory) => Promise<void>;
  edit: (category: FAQCategory) => Promise<void>;
  remove: (faqCategoryID: number) => Promise<void>;
  activate: (id: number, value: boolean) => Promise<any>;
  resequence: (category: FAQCategory, change: number) => Promise<any>;
  noLongerLoading: () => Promise<any>;
  pageIsLoading: () => Promise<any>;
  loadFilter: () => Promise<void>;
}

const useFAQCategories = (): IUseFAQCategories => {
  const [categories, setCategories] = useState<FAQCategory[]>([]);
  const [filterCategories, setFilterCategories] = useState<
    FAQCategoryCompact[]
  >([]);
  const [categoriesLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(true);
    fetchFilterCategories();
    setIsLoading(false);
  }, []);

  const noLongerLoading = async () => {
    setIsLoading(false);
  };

  const pageIsLoading = async () => {
    setIsLoading(true);
  };

  const fetchCategories = async (includeDeactivated: boolean) => {
    setIsLoading(true);
    const result: any = await api.get('/content/FAQCategories', {
      params: { includeDeactivated },
    });

    setCategories(result.data.faqCategories);

    setIsLoading(false);
  };

  const fetchFilterCategories = async () => {
    setIsLoading(true);
    const result: any = await api.get('/content/FilterFAQCategories');

    setFilterCategories(result.data);
    setIsLoading(false);
  };

  const add = (category: FAQCategoryCompact) => {
    setIsLoading(true);
    return api.post('/content/StoreFAQCategory', category).then((response) => {
      //fetchFilterCategories();
      setIsLoading(false);
      return response.data;
    });
  };

  const remove = async (faqCategoryID: number) => {
    const item = categories.find((c) => c.faqCategoryID === faqCategoryID);
    if (!item) throw new Error();

    // api call

    const index = categories.indexOf(item);
    categories.splice(index, 1);

    setCategories([...categories]);
  };

  const edit = async (category: FAQCategoryCompact) => {
    let item = categories.find(
      (c) => c.faqCategoryID === category.faqCategoryID
    );
    if (!item) throw new Error();

    item.faqCategoryName = category.faqCategoryName;
    item.isActive = category.isActive;
    item.sequenceNumber = category.sequenceNumber;

    // api call
    setIsLoading(true);
    return api.post('/content/StoreFAQCategory', item).then((response) => {
      fetchFilterCategories();
      setIsLoading(false);
      return response.data;
    });
  };

  const resequence = async (category: FAQCategory, change: number) => {
    let item = categories.find(
      (c) => c.faqCategoryID === category.faqCategoryID
    );
    if (!item) throw new Error();

    const newSequence = {
      faqCategoryID: category.faqCategoryID,
      sequenceNumber: category.sequenceNumber + change,
      sequenceChange: change,
    };

    // api call
    setIsLoading(true);
    return api
      .post('/content/FAQCategoryResequence', newSequence)
      .then((response) => {
        fetchFilterCategories();
        setIsLoading(false);
        return response.data;
      });
  };

  const activate = (id: number, value: boolean) => {
    setIsLoading(true);
    return api
      .post('/content/FAQCategoryActivation', {
        faqCategoryID: id,
        isActive: value,
      })
      .then((response) => {
        fetchFilterCategories();
        setIsLoading(false);
        return response.data;
      });
  };

  return {
    // Variables
    filterCategories,
    categories,
    categoriesLoading,
    // Methods
    load: fetchCategories,
    add,
    remove,
    edit,
    activate,
    noLongerLoading,
    pageIsLoading,
    loadFilter: fetchFilterCategories,
    resequence,
  };
};

export default useFAQCategories;
