import {
  Button,
  Card,
  Row,
  message,
  Space,
  Table,
  Tooltip,
} from 'components/antd';
import { Content, TitleRow } from 'components/shared';
import useTopProducts from 'hooks/Reporting/topProducts';
import useHighlightedProducts from 'hooks/Reporting/highlightedProducts';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import PodiumContainer from './PodiumContainer';
import ProductCardContainer from './ProductCardContainer';
import { LinkURL } from 'components/icons';

const Container = styled.div`
  height: 500px;
  padding-top: 200px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const HoveredProductTitle = styled.h3`
  width: auto;
  text-align: center;
  font-size: 18px;
  min-height: 40px;
  font-weight: 600;
`;

const CommissionsDisclaimer = styled.p`
  width: 100%;
  text-align: center;
  font-style: italic;
  font-size: 15px;
`;

const Page = () => {
  const {
    generateLinkHandler,
    fetchTopProducts,
    topProductsData,
    isTopProductsLoading,
  } = useTopProducts();
  const {
    fetchHighlightedProducts,
    highlightedProducts,
    isHighlightedProductsLoading,
  } = useHighlightedProducts();
  const { t } = useTranslation('reporting');

  const [hoveredProduct, setHoveredProduct] = useState<string>('');

  const fetchTopProductsData = () => {
    fetchTopProducts()
      .then((res) => {})
      .catch(() => {
        message.error(t('reporting.fetch.error'));
      });
  };

  const hoveredProductHandler = (productTitle: string) => {
    setHoveredProduct(productTitle);
  };

  const exitHoveredProductHandler = () => {
    setHoveredProduct('');
  };

  useEffect(() => {
    fetchTopProductsData();
    fetchHighlightedProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const highlightColumns = [
    {
      title: t('highlightedSequenceNumber'),
      dataIndex: 'order',
      key: 'order',
      fixed: 'left',
      editable: false,
      inputType: 'none',
    },
    {
      title: t('productName'),
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
      editable: false,
      inputType: 'none',
    },
    {
      title: t('commissionValue'),
      dataIndex: 'commission',
      key: 'commission',
      fixed: 'left',
      editable: false,
      inputType: 'none',
    },
    {
      title: t('trackingURL'),
      width: 150,
      key: 'trackingURL',
      align: 'center',
      render: (af: any) => (
        <Space>
          <Tooltip title={t('getTrackingURLToolTip')}>
            <Button
              onClick={() => {
                generateLinkHandler(af.externalId);
              }}
              icon={<LinkURL style={{ marginLeft: -2, marginBottom: -2 }} />}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  return (
    <Content>
      <TitleRow>
        <h1>{t('topSellers')}</h1>
      </TitleRow>
      <Card loading={isTopProductsLoading} style={{ marginTop: '1em' }}>
        <h2>{t('topProducts')}</h2>
        <HoveredProductTitle>{hoveredProduct}</HoveredProductTitle>
        <Container>
          <ProductCardContainer
            topProductsData={topProductsData}
            generateLinkHandler={generateLinkHandler}
            hoveredProductHandler={hoveredProductHandler}
            exitHoveredProductHandler={exitHoveredProductHandler}
          />
          <PodiumContainer />
        </Container>
        <Row>
          <CommissionsDisclaimer>
            {t('commissionDiscalimer')}
          </CommissionsDisclaimer>
        </Row>
      </Card>
      <Card style={{ marginTop: '2em' }}>
        <h2>{t('highlightedProducts')}</h2>
        <Table
          style={{ marginTop: '2em' }}
          rowKey="productSKU"
          columns={highlightColumns}
          dataSource={highlightedProducts}
          pagination={false}
          loading={isHighlightedProductsLoading}
        />
      </Card>
    </Content>
  );
};

export default Page;
