import { Navigate, Route, Routes, useLocation } from 'react-router';

import useAffiliateGroups from 'hooks/Account/affiliateGroups';

import List from './List';
import BankList from './Bank';
import Edit from './Edit';
import Approve from './Approve';
import Groups from './Groups';
import { PrivatePage } from 'components/shared';

import { Permissions } from 'constants/permissions';

const Page = () => {
  let { pathname } = useLocation();
  const useGroups = useAffiliateGroups();

  // new router fix, i dont know how to do this better

  return (
    <Routes>
      <Route
        path="/:id/edit/*"
        element={
          <PrivatePage
            requirement={Permissions['Accounts.Affiliate.Administer']}
            children={<Edit originPath={pathname} groups={useGroups.groups} />}
          />
        }
      />
      <Route
        path="/:id/view/*"
        element={
          <PrivatePage
            requirement={Permissions['Accounts.Affiliate.Administer']}
            children={
              <Edit originPath={pathname} readonly groups={useGroups.groups} />
            }
          />
        }
      />
      <Route
        path="/bank"
        element={
          <PrivatePage
            requirement={Permissions['Accounts.Affiliate.Administer']}
            component={BankList}
          />
        }
      />
      <Route
        path="/approve"
        element={
          <PrivatePage
            requirement={Permissions['Accounts.Affiliate.Administer']}
            component={Approve}
          />
        }
      />
      <Route
        path="/groups"
        element={
          <PrivatePage
            requirement={Permissions['Accounts.Tenant.Read']}
            children={<Groups useGroups={useGroups} />}
          />
        }
      />
      <Route
        path="/list"
        element={
          <PrivatePage
            requirement={Permissions['Accounts.Affiliate.Administer']}
            children={<List groups={useGroups.groups} />}
          />
        }
      />
      <Route index element={<Navigate to="list" />} />
    </Routes>
  );
};

export default Page;
