import styled from 'styled-components';

const StyledRibbon = styled.div`
  width: 100%;
  background: #f9f9f9;
  display: flex;
  justify-content: center;
  align-items: center;
  // Position absolute is needed to avoid the Card component's padding
  // An alternative way would be to add the padding to Row component instead of Card
  position: absolute;
`;

interface Props {
  children: any;
}

const Ribbon = ({ children }: Props) => {
  return <StyledRibbon>{children}</StyledRibbon>;
};

export default Ribbon;
