import {
  UserManager,
  WebStorageStateStore,
  Log,
  UserManagerSettings,
} from 'oidc-client';

// import { settings } from 'constants/identityServer';
// import { publicUrl } from 'constants/env';
import { isAxiosSetup, setupAxios, removeAuthHeader } from './api';

export default class AuthService {
  UserManager;

  constructor(public settings: UserManagerSettings) {
    this.UserManager = new UserManager({
      ...settings,
      userStore: new WebStorageStateStore({ store: window.localStorage }),
    });
    // Logger
    Log.logger = console;
    Log.level = Log.DEBUG;

    this.UserManager.events.addUserLoaded((user) => {
      if (window.location.href.indexOf('signin-oidc') !== -1) {
        this.navigateToScreen();
      }
    });
    this.UserManager.events.addSilentRenewError((e) => {
      console.log('silent renew error', e.message);
    });

    this.UserManager.events.addAccessTokenExpired(() => {
      console.log('token expired');
      this.signinSilent();
    });

    this.UserManager.events.addUserSignedIn(() => {
      alert('user signed in');
    });
  }

  signinRedirectCallback = () => {
    return this.UserManager.signinRedirectCallback();
  };

  getUser = (resetUser: Function) => async (setUser?: Function) => {
    const user = await this.UserManager.getUser();
    if (!user) return null;

    if (!isAxiosSetup()) {
      setupAxios({
        onSignout: () => {
          // This is called when 401 comes as a response
          // we should delete all user cached data, maybe log the user out
          // redirect to login page?
          removeAuthHeader();
          this.unauthenticate();
          resetUser && resetUser(); // this forces the UI to reevaulate
          this.logout();
        },
        token: user.access_token,
      });

      // This code tests 401 response of the UI
      // setTimeout(() => {
      //   alert('now trying to kill all stuff');
      //   removeAuthHeader();
      //   this.unauthenticate();
      //   resetUser && resetUser(); // this forces the UI to reevaulate
      // }, 60000);
    }
    return user;
  };

  parseJwt = (token: string) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  };

  signinRedirect = (returnUrl?: string | null) => {
    localStorage.setItem('redirectUri', window.location.pathname);
    const state = returnUrl ? { returnUrl } : undefined;
    this.UserManager.signinRedirect({ state });
  };

  navigateToScreen = () => {
    window.location.replace('/en/dashboard');
  };

  isAuthenticated = () => {
    const oidcStorage = JSON.parse(
      localStorage.getItem(
        `oidc.user:${this.settings.authority}:${this.settings.client_id}`
      ) || '{}'
    );

    return !!oidcStorage && !!oidcStorage.access_token;
  };

  unauthenticate = () => {
    localStorage.removeItem(
      `oidc.user:${this.settings.authority}:${this.settings.client_id}`
    );
    this.UserManager.removeUser();
  };

  signinSilent = () => {
    this.UserManager.signinSilent()
      .then((user) => {
        console.log('signed in', user);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  signinSilentCallback = () => {
    return this.UserManager.signinSilentCallback();
  };

  createSigninRequest = () => {
    return this.UserManager.createSigninRequest();
  };

  logout = () => {
    this.UserManager.signoutRedirect({
      id_token_hint: localStorage.getItem('id_token'),
    });
    this.UserManager.clearStaleState();
  };

  signoutRedirectCallback = () => {
    this.UserManager.clearStaleState();
    this.UserManager.signoutRedirectCallback().then(() => {
      localStorage.clear();
      sessionStorage?.clear();
      window.location.replace(window.location.host);
    });
  };
}
