import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useCommitionCodes, {
  CommissionCodeListItem,
  ProductGroup,
} from 'hooks/Commissions/code';

import { Content, TitleRow } from 'components/shared';
import {
  Button,
  Card,
  Form,
  Row,
  Space,
  Table,
  Select,
  Input,
  Switch,
  message,
} from 'components/antd';

import { Link } from 'react-router-dom';
import { Create, Edit, Search } from 'components/icons';

const getColumns = (t: Function, groups: ProductGroup[]) => [
  // {
  //   title: t('id'),
  //   dataIndex: 'id',
  //   key: 'id',
  //   fixed: 'left',
  // },
  {
    title: t('code'),
    dataIndex: 'code',
    key: 'code',
    fixed: 'left',
  },
  {
    title: t('productGroup'),
    dataIndex: 'commissionGroupId',
    key: 'commissionGroupId',
    render: (id: string) => id && t(groups.find((x) => x.id === id)?.name),
  },
  {
    title: t('name'),
    dataIndex: 'name',
    key: 'name',
    // render: (date: Date) => date && new Date(date).toLocaleDateString(),
  },
  {
    title: t('action'),
    dataIndex: '',
    align: 'right',
    key: '',
    render: (c: CommissionCodeListItem) => (
      <Space>
        <Link to={`/admin/commissions/${c.id}/edit`}>
          <Button
            icon={<Edit style={{ marginLeft: -2, marginBottom: -2 }} />}
          />
        </Link>
      </Space>
    ),
  },
];

type Props = {
  groups: ProductGroup[];
};

const Page = ({ groups }: Props) => {
  const [state, setState] = useState<any>({
    archived: false,
  });
  const { t } = useTranslation('commissions');
  const { t: c } = useTranslation('common');

  const { commissionCodes, isLoading, load, pagination } = useCommitionCodes();

  const loadData = (a: any, b: any) => {
    load(a, groups).catch(() => {
      message.error('There was an error loading commissions');
    });
  };

  useEffect(() => {
    loadData({ ...state, ...pagination }, groups);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    setState({ ...state, ...sorter, ...pagination });
    loadData({ ...state, ...sorter, ...pagination }, groups);
  };

  const onFinish = (values: any) => {
    setState({ ...state, ...values });
    // we need to reset the offset because filter can change the number of items
    loadData({ ...state, ...values, current: 1 }, groups);
  };

  const onSetState = (values: any) => {
    setState({ ...state, ...values });
  };

  return (
    <Content>
      <TitleRow>
        <h1>{t('commissionCodes')}</h1>
        <Link to={`/admin/commissions/add`}>
          <Button
            icon={
              <Create
                style={{ marginLeft: -2, marginBottom: -2, fontSize: '1.2em' }}
              />
            }
          >
            {t('common:add')}
          </Button>
        </Link>
      </TitleRow>

      <Row justify="center" style={{ paddingTop: '2em', paddingBottom: '2em' }}>
        <Form layout="inline" onValuesChange={onSetState}>
          <Form.Item name="productGroup" valuePropName="checked">
            <Select
              placeholder={c('productGroup')}
              allowClear
              options={groups.map((item) => ({
                label: t(item.name),
                value: item.id,
              }))}
              style={{ width: 200 }}
              onChange={(value) => onFinish({ ...state, productGroup: value })}
            />
          </Form.Item>

          <Form.Item name="code">
            <Input.Search
              value={state.affiliateId}
              onSearch={(value: string) => onFinish({ ...state, code: value })}
              placeholder={c('code')}
              enterButton={
                <Search style={{ marginTop: 5, fontSize: '1.2em' }} />
              }
            />
          </Form.Item>

          <Form.Item name="name">
            <Input.Search
              value={state.affiliateId}
              onSearch={(value: string) => onFinish({ ...state, name: value })}
              placeholder={c('name')}
              enterButton={
                <Search style={{ marginTop: 5, fontSize: '1.2em' }} />
              }
            />
          </Form.Item>

          <Form.Item name="affiliateId">
            <Input.Search
              value={state.affiliateId}
              onSearch={(value: string) =>
                onFinish({ ...state, affiliateId: value })
              }
              placeholder={c('affiliateId')}
              enterButton={
                <Search style={{ marginTop: 5, fontSize: '1.2em' }} />
              }
            />
          </Form.Item>

          <Form.Item name="archived" label={c('showArchived')}>
            <Switch
              checked={state.archived}
              onChange={(value: boolean) =>
                onFinish({ ...state, archived: value })
              }
            />
          </Form.Item>
        </Form>
      </Row>

      <Card>
        <Table
          rowKey="id"
          columns={getColumns(c, groups) as any}
          dataSource={commissionCodes}
          onChange={handleTableChange}
          pagination={pagination}
          loading={isLoading}
        />
      </Card>
    </Content>
  );
};

export default Page;
