import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const CenterBox = styled.div`
  margin: auto;
  text-align: center;
  width: fit-content;
  margin-top: 2em;
`;

const Title = styled.h1`
  font-weight: bold;
  font-size: 2em;
  margin-bottom: 0;
  line-height: normal;
`;

const Subtitle = styled.h2`
  font-size: 1em;
  margin-bottom: 0;
  margin-top: 0.5em;
  line-height: normal;
`;

const Center = styled.div`
  width: 100%;
`;

const ErrorFallback = () => {
  // const { t } = useTranslation('common');

  return (
    <Center>
      <CenterBox>
        <Title>Error Loading</Title>
        <Subtitle>There was an error loading this page</Subtitle>
      </CenterBox>
    </Center>
  );
};

export default ErrorFallback;
