import { InfoPage } from 'components/shared';
import useUser from 'hooks/User';
import { useTranslation } from 'react-i18next';

export const Logout = () => {
  const { logout } = useUser();
  const { t } = useTranslation();
  logout();
  return (
    <InfoPage
      message={t('loggingOut')}
      additionalText={t('pleaseWaitDotDotDot')}
    />
  );
};

export default Logout;
