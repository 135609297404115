import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const errorCodes: {
  [key: number]: string;
} = {
  404: 'pageNotFound',
  403: 'unauthorized',
};

const CenterBox = styled.div`
  margin: auto;
  text-align: center;
  width: fit-content;
  margin-top: 4em;
`;

const Title = styled.h1`
  font-weight: bold;
  font-size: 10em;
  margin-bottom: 0;
  line-height: normal;
`;

const Subtitle = styled.h2`
  font-size: 3em;
  margin-bottom: 0;
  line-height: normal;
`;

const Center = styled.div`
  width: 100%;
`;

type Props = {
  code?: number;
  message?: string;
};

const Error = ({ code = 404, message }: Props) => {
  const { t } = useTranslation('common');

  return (
    <Center>
      <CenterBox>
        {code && <Title>{code}</Title>}
        <Subtitle>{message ? message : t(errorCodes[code])}</Subtitle>
      </CenterBox>
    </Center>
  );
};

Error.prototype = {};

export default Error;
