import { Navigate, Route, Routes } from 'react-router';
import { PrivatePage } from 'components/shared';

import List from './List';
import Create from './Create';

import { Permissions } from 'constants/permissions';

const Page = () => {
  return (
    <Routes>
      <Route
        path="/:id/create"
        element={
          <PrivatePage
            requirement={Permissions['Accounts.TermsAndConditions.Write']}
            children={<Create isNew={false} />}
          />
        }
      />
      <Route
        path="/create"
        element={
          <PrivatePage
            requirement={Permissions['Accounts.TermsAndConditions.Write']}
            children={<Create isNew />}
          />
        }
      />
      <Route
        path="/list"
        element={
          <PrivatePage
            requirement={Permissions['Accounts.TermsAndConditions.ReadAll']}
            component={List}
          />
        }
      />
      <Route index element={<Navigate to="list" />} />
    </Routes>
  );
};

export default Page;
