import { Button, Card, Form, Input, Select, message } from 'components/antd';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Content } from 'components/shared';
import usePurchaseOrders from 'hooks/Financial/purchaseorders';
import { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import useUser from 'hooks/User';
import { Features } from 'constants/features';

const Columns = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 992px) {
    display: grid;
    grid-template-columns: 1fr 400px;
  }
`;

type Props = {
  isNew?: boolean;
};

const Page = ({ isNew }: Props) => {
  const { t } = useTranslation('financialAdminPurchaseOrders');
  const { t: c } = useTranslation('common');
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const {
    purchaseOrderSubmitting,
    purchaseOrdersLoading,
    editingPurchaseOrder,
    createPurchaseOrder,
    noLongerSubmitting,
  } = usePurchaseOrders();

  const onFinish = async (values: any) => {
    const results = await form.validateFields();

    createPurchaseOrder(results)
      .then(() => {
        message.success(t(`purchaseorder.add.success`));
        navigate(`/admin/financial/purchaseorders/list`);
      })
      .catch((error) => {
        const errorMessages = Object.values(error?.response?.data?.errors);

        if (errorMessages.length >= 1) {
          errorMessages.flat().forEach((value) => {
            message.error(
              t(`purchaseorder.add.error`, {
                msg: typeof value === 'string' ? t(value) : undefined,
              })
            );
          });
        } else {
          const errorMsg = error?.response?.data?.message;

          message.error(
            t(`purchaseorder.add.error`, {
              msg: errorMsg ? t(errorMsg) : undefined,
            })
          );
        }
        noLongerSubmitting();
      });
  };

  return (
    <Content>
      <h1>{t('newPurchaseOrderTitle')}</h1>
      <Card loading={purchaseOrdersLoading}>
        <Columns>
          <Form
            initialValues={editingPurchaseOrder}
            name="basic"
            form={form}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 12 }}
            autoComplete="off"
            onFinish={onFinish}
          >
            <Form.Item
              label={t('value')}
              name="value"
              rules={[
                {
                  required: true,
                  message: t('invalidValue'),
                  pattern: new RegExp(/^\s*-?(\d+(\.\d{1,2})?|\.\d{1,2})\s*$/),
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={t('tenantPurchaseOrderReference')}
              name="tenantPurchaseOrderReference"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={t('description')}
              name="description"
              rules={[{ required: false }]}
            >
              <Input />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8 }}>
              <Button
                type="primary"
                htmlType="submit"
                loading={purchaseOrderSubmitting}
              >
                {c('submit')}
              </Button>
            </Form.Item>
          </Form>
        </Columns>
      </Card>
    </Content>
  );
};

export default Page;
