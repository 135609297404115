import { useTranslation } from 'react-i18next';
import {
  Button,
  Card,
  DatePicker,
  Input,
  message,
  Row,
  Select,
  Space,
  Table,
} from 'components/antd';
import { Link } from 'react-router-dom';
import { Content, TitleRow } from 'components/shared';
import moment, { Moment } from 'moment';
import usePurchaseOrders, {
  PurchaseOrder,
} from 'hooks/Financial/purchaseorders';
import { useEffect, useState } from 'react';
import { Search } from 'components/icons';
import usePayments, { PaymentStatus } from 'hooks/Financial/payments';
import usePaymentRequests, {
  PaymentRequestType,
} from 'hooks/Financial/paymentrequests';
import { Permissions } from 'constants/permissions';
import useUser from 'hooks/User';

const Page = () => {
  const { t } = useTranslation('financialAdminPurchaseOrders');
  const { t: c } = useTranslation('common');
  const [startDate, setStartDate] = useState<Moment | null>(null);
  const [endDate, setEndDate] = useState<Moment | null>(null);
  const [state, setState] = useState<any>({});
  const { paymentStatuses } = usePayments();
  const { requestTypes } = usePaymentRequests();
  const { hasPermission } = useUser();
  const {
    pagination,
    purchaseOrders,
    purchaseOrdersLoading,
    remainingPercentages,
    loadPurchaseOrders,
  } = usePurchaseOrders();

  const canCreatePO = hasPermission(
    Permissions['Finance.PurchaseOrders.Create']
  );

  const changeDate = (val: any) => {
    setStartDate(val?.[0]);
    setEndDate(val?.[1]);

    const sDate = val?.[0]?.utc().startOf('day');
    const eDate = val?.[1]?.utc().endOf('day');

    setState({
      ...state,
      rangeFrom: sDate?.toISOString(),
      rangeTo: eDate?.toISOString(),
    });

    loadPurchaseOrders({
      ...state,
      rangeFrom: sDate?.toISOString(),
      rangeTo: eDate?.toISOString(),
      current: 1,
    }).catch(() => message.error(t('requestRetrievalError')));
  };

  const setPurchaseOrderRef = (selectedPO: string) => {
    var actualValue = selectedPO === '' ? undefined : selectedPO;
    setState({ ...state, purchaseOrderReference: actualValue });

    loadPurchaseOrders({
      ...state,
      purchaseOrderReference: actualValue,
      current: 1,
    }).catch(() => message.error(t('requestRetrievalError')));
  };

  const setPercentRemaining = (selectedPercentage: string) => {
    var actualValue =
      selectedPercentage === '' ? undefined : selectedPercentage;
    setState({ ...state, percentRemaining: actualValue });

    loadPurchaseOrders({
      ...state,
      percentRemaining: actualValue,
      current: 1,
    }).catch(() => message.error(t('requestRetrievalError')));
  };

  const formatCurrency = (value: any) =>
    `${t('currency.symbol')}${formatNumber(value)}`;

  const formatNumber = (value: any) =>
    `${c('number', {
      value: value,
    })}`;

  const onFinish = (values: any) => {
    setState({ ...state, ...values, current: 1 });

    loadPurchaseOrders({
      ...state,
      ...values,
      current: 1,
    }).catch(() => message.error(t('requestRetrievalError')));
  };

  useEffect(() => {
    loadPurchaseOrders({
      ...state,
    }).catch(() => message.error(t('requestRetrievalError')));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTableChange = (pagination: any) => {
    setState({ ...state, ...pagination });

    loadPurchaseOrders({
      ...state,
      ...pagination,
    }).catch(() => message.error(t('requestRetrievalError')));
  };

  const getColumns = (t: Function, c: Function) => [
    {
      title: t('purchaseOrderRef'),
      dataIndex: 'tenantPurchaseOrderReference',
      key: 'tenantPurchaseOrderReference',
    },
    {
      title: t('value'),
      dataIndex: 'value',
      key: 'value',
      align: 'right',
      render: (item: number) => formatCurrency(item),
    },
    {
      title: t('spendRemaining'),
      dataIndex: 'spendRemaining',
      key: 'spendRemaining',
      align: 'right',
      render: (item: number) => formatCurrency(item),
    },
    {
      title: t('createdDate'),
      dataIndex: 'dateCreated',
      key: 'dateCreated',
      render: (date: Date) => c('date', { value: new Date(date) }),
    },
    {
      title: t('purchaseOrderDescription'),
      dataIndex: 'description',
      key: 'description',
    },
  ];

  const formatRequestType = (item: string) =>
    `${t(
      requestTypes.find((c: PaymentRequestType) => c.requestTypeValue === item)
        ?.requestTypeName || item
    )}`;

  const formatStatus = (item: string) =>
    `${t(
      paymentStatuses.find(
        (c: PaymentStatus) => c.statusTypeNumber.toString() === item.toString()
      )?.statusTypeName || item
    )}`;

  const AssociatedPaymentRequests = (record: PurchaseOrder) => {
    const columns = [
      {
        title: t('paymentRequestIdentifier'),
        dataIndex: 'paymentRequestIdentifier',
        key: 'paymentRequestIdentifier',
      },
      {
        title: t('requestType'),
        dataIndex: 'discriminator',
        key: 'discriminator',
        render: (item: string) => formatRequestType(item),
      },
      {
        title: t('amount'),
        dataIndex: 'amount',
        key: 'amount',
        align: 'right',
        render: (item: number) => formatCurrency(item),
      },
      {
        title: t('dateCreated'),
        dataIndex: 'dateCreated',
        key: 'dateCreated',
        render: (date: Date) => c('date', { value: new Date(date) }),
      },
      {
        title: t('status'),
        dataIndex: 'status',
        key: 'status',
        render: (item: string) => formatStatus(item),
      },
      {
        title: t('notes'),
        dataIndex: 'notes',
        key: 'notes',
      },
    ];

    return (
      <Table
        size="small"
        rowKey="id"
        columns={columns}
        dataSource={record.associatedPaymentRequests}
        pagination={false}
      />
    );
  };

  return (
    <Content>
      <TitleRow>
        <h1>{t('purchaseOrdersTitle')}</h1>
        <Link to={`/admin/financial/purchaseorders/create`}>
          <Button disabled={!canCreatePO}>{t('newPurchaseOrder')}</Button>
        </Link>
      </TitleRow>

      <Row justify="center" style={{ margin: '16px 0' }}>
        <Space>
          <DatePicker.RangePicker
            value={[startDate, endDate]}
            onChange={changeDate}
            disabledDate={(date) => date > moment()}
          />
          <Input.Search
            value={state.purchaseOrderRef}
            onSearch={(value: string) =>
              onFinish({ ...state, purchaseOrderReference: value })
            }
            placeholder={t('enterPurchaseOrderRef')}
            allowClear
            onChange={(e) =>
              setState({ ...state, purchaseOrderReference: e.target.value })
            }
            enterButton={<Search style={{ marginTop: 5, fontSize: '1.2em' }} />}
          />
          <Select
            placeholder={t('selectPercentageRemaining')}
            allowClear
            options={remainingPercentages.map((percentage) => ({
              label: percentage.percentageLabel,
              value: percentage.percentageValue.toString(),
            }))}
            style={{ width: 200 }}
            onChange={(value) => {
              setPercentRemaining(value as string);
            }}
          />
        </Space>
      </Row>

      <Card style={{ marginTop: '2em' }}>
        <Table
          rowKey="id"
          size="small"
          loading={purchaseOrdersLoading}
          columns={getColumns(t, c) as any}
          pagination={pagination}
          dataSource={purchaseOrders}
          expandable={{
            expandedRowRender: AssociatedPaymentRequests,
            rowExpandable: (val: PurchaseOrder) =>
              val.associatedPaymentRequests === undefined
                ? false
                : val.associatedPaymentRequests.length > 0,
          }}
          onChange={handleTableChange}
        />
      </Card>
    </Content>
  );
};

export default Page;
