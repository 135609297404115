import { Button, Card, Space } from 'components/antd';
import { Right } from 'components/icons';
import TagList from 'components/shared/TagList';
import { News } from 'hooks/Resources/news';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';

type Props = {
  items: News[];
};

const Title = styled.h3`
  font-weight: bold;
  max-width: 85%;
  width: 85%;
  max-height: 3em;
  font-size: 1.5em;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ArticleDate = styled.h4`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  width: 15%;
  max-width: 15%;
  font-size: 14px;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 1em;
  text-align: right;
`;

const Content = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  max-height: 5em;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Image = styled.img`
  max-width: 100%;
  max-height: 90%;
  width: 100%;

  min-width: 250px;
  max-width: 250px;
  height: 200px;
  object-fit: contain;

  border: calc(2 * var(--border-width-base)) solid var(--border-color-base);
`;

const Row = styled.div`
  display: flex;
  justify-content: baseline;
  width: 100%;
`;

const CardRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  width: auto;
  gap: 1em;
`;

const CardHeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  width: auto;
  gap: 1em;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1em;
`;

const RightAlign = styled.div`
  text-align: right;
  width: 100%;
`;

function CardView({ items = [] }: Props) {
  const { t } = useTranslation('news');
  const [errors, setErrors] = useState<any>({});

  return (
    <Space
      size="large"
      direction="vertical"
      style={{ marginBottom: '2em', width: '100%' }}
    >
      {items.map((item) => (
        <Card key={item.articleID} style={{ width: '100%' }}>
          <CardRow>
            <div>
              <Image
                alt={
                  !errors[item.articleID] && item.articleHeadline
                    ? item.articleHeadline
                    : t('noImage')
                }
                src={
                  !errors[item.articleID] && item.articleHeadline
                    ? item.articleImagePath
                    : '/assets/no-image.png'
                }
                onError={() => setErrors({ ...errors, [item.articleID]: true })}
              />
              <Row style={{ marginTop: '0.5em' }}>
                <TagList
                  title={t('categories')}
                  tagData={item.newsArticleCategories.map((cat) => ({
                    id: cat.newsCategoryID?.toString(),
                    label: cat.newsCategoryName,
                  }))}
                  tagMaxWidth={'100px'}
                />
              </Row>
            </div>
            <Column style={{ width: '100%' }}>
              <div>
                <CardHeaderRow>
                  <Title>{item.articleHeadline}</Title>
                  <ArticleDate>
                    {moment(item.createdDate)
                      .local()
                      .format('DD MMM yyyy HH:mm')}
                  </ArticleDate>
                </CardHeaderRow>
                <Content
                  dangerouslySetInnerHTML={{ __html: item.articleSubHeading }}
                />
              </div>
              <RightAlign>
                <Link to={`/news/${item.articleID}`}>
                  <Button type="primary">
                    Read More{' '}
                    <Right
                      style={{ fontSize: '1.3em', marginBottom: '-0.2em' }}
                    />
                  </Button>
                </Link>
              </RightAlign>
            </Column>
          </CardRow>
        </Card>
      ))}
    </Space>
  );
}

export default CardView;
