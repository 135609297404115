import {
  Table,
  Select,
  DatePicker,
  Input,
  Form,
  Button,
  Space,
  Card,
  Col,
  message,
  Switch,
  Popover,
} from 'components/antd';
import { Create, Cross, Edit, ExclamationCircle, Save } from 'components/icons';
import { TitleRow } from 'components/shared';
import { AffiliateCompact } from 'hooks/Commissions';
import {
  CommissionMapping,
  IUseCommissionMappings,
} from 'hooks/Commissions/mapping';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  loading?: boolean;
  isLoadingCommission: boolean;
  useMapping: IUseCommissionMappings;
  commissionId: string;
  affiliates: AffiliateCompact[];
};

const Component = ({
  loading = false,
  isLoadingCommission,
  useMapping,
  commissionId,
  affiliates,
}: Props) => {
  const [editingKey, setEditingKey] = useState<string>('');
  const [data, setData] = useState<CommissionMapping[]>([]);
  const { t: c } = useTranslation('common');
  const { t } = useTranslation('commissions');
  const [form] = Form.useForm();
  const [allProducts, setAllProducts] = useState<boolean>();
  const [allAffiliates, setAllAffiliates] = useState<boolean>();

  const { mappings, load, add, update } = useMapping;

  useEffect(() => {
    setData(
      mappings.map((x) => ({
        ...x,
        effectiveFrom: x.effectiveFrom && (moment(x.effectiveFrom) as any),
        effectiveTo: x.effectiveTo && (moment(x.effectiveTo) as any),
      }))
    );
    setEditingKey('');
    form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mappings]);

  const isEditing = (record: CommissionMapping) =>
    record.id.toString() === editingKey;

  const edit = (record: CommissionMapping) => {
    form.setFieldsValue({
      ...record,
    });
    setAllAffiliates(record.allAffiliates);
    setAllProducts(record.allProducts);
    setEditingKey(record.id.toString());
  };

  const cancel = () => {
    setEditingKey('');
    form.resetFields();
    load(commissionId).catch(() => {
      message.error(t('commissions:mapping.load.error'));
    });
  };

  const save = async (item: CommissionMapping) => {
    try {
      await form.validateFields();
    } catch (err) {
      return;
    }

    const fields = form.getFieldsValue();
    const isNew = item.id === -1;

    (isNew
      ? add(commissionId, fields)
      : update(commissionId, { ...item, ...fields })
    )
      .then(() => {
        message.success(
          t(isNew ? 'mapping.add.success' : 'mapping.update.success')
        );
        setEditingKey('');
        form.resetFields();
        load(commissionId).catch(() => {
          message.error(t('commissions:mapping.load.error'));
        });
      })
      .catch(({ response }) => {
        message.error(
          t(isNew ? 'mapping.add.error' : 'mapping.update.error', {
            error: response?.data?.message || response?.data?.title,
          })
        );
      });
  };

  const addNew = () => {
    setData([
      {
        id: -1,
        effectiveFrom: undefined,
        effectiveTo: undefined,
        affiliates: [],
        products: [],
        allProducts: false,
        allAffiliates: false,
      },
      ...data,
    ]);
    setEditingKey('-1');
  };

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }: any) => {
    let inputNode = <Input />;
    switch (inputType) {
      case 'dateAddOnly':
        inputNode = <DatePicker disabled={editingKey !== '-1'} />;
        break;
      case 'date':
        inputNode = <DatePicker />;
        break;
      case 'switch':
        inputNode = <Switch />;
        break;
      case 'multiselect':
        inputNode = !allProducts ? (
          <Select
            mode="tags"
            style={{ minWidth: 100 }}
            allowClear
            defaultActiveFirstOption
          />
        ) : (
          <>{c('all')}</>
        );
        break;
      case 'affiliates':
        inputNode = !allAffiliates ? (
          <Select
            mode="multiple"
            style={{ minWidth: 100 }}
            allowClear
            optionFilterProp="label"
            options={affiliates.map((a) => ({ value: a.id, label: a.name }))}
          />
        ) : (
          <>{c('all')}</>
        );
        break;
      default:
        inputNode = <Input />;
    }

    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            rules={[{ required: inputType === 'dateAddOnly' }]}
            style={{
              margin: 0,
            }}
            valuePropName={inputType === 'switch' ? 'checked' : 'value'}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  const columns = [
    {
      title: c('effectiveFrom'),
      dataIndex: 'effectiveFrom',
      key: 'effectiveFrom',
      editable: true,
      required: true,
      inputType: 'dateAddOnly',
      width: 140,
      render: (date: Date) => date && c('date', { value: new Date(date) }),
    },
    {
      title: c('effectiveTo'),
      dataIndex: 'effectiveTo',
      editable: true,
      key: 'effectiveTo',
      inputType: 'date',
      width: 140,
      render: (date: Date) => date && c('date', { value: new Date(date) }),
    },
    {
      title: c('allProducts'),
      dataIndex: 'allProducts',
      editable: true,
      inputType: 'switch',
      key: 'allProducts',
      render: (value: boolean) => {
        return <Switch checked={value} disabled />;
      },
    },
    {
      title: c('products'),
      dataIndex: 'products',
      editable: true,
      inputType: 'multiselect',
      key: 'products',
      render: (values: any[], row: CommissionMapping) => {
        if (row.allProducts) return c('all');
        if (values.length === 0) return c('none');

        return (
          <>
            {`${values.length} products(s)`}
            <Popover
              content={<div style={{ maxWidth: 400 }}>{values.join(', ')}</div>}
              title={c('products')}
              trigger="hover"
            >
              <ExclamationCircle style={{ marginLeft: 4, marginBottom: -2 }} />
            </Popover>
          </>
        );
      },
    },
    {
      title: c('allAffiliates'),
      dataIndex: 'allAffiliates',
      editable: true,
      inputType: 'switch',
      key: 'allAffiliates',
      render: (value: boolean) => <Switch checked={value} disabled />,
    },
    {
      title: c('affiliates'),
      dataIndex: 'affiliates',
      editable: true,
      inputType: 'affiliates',
      key: 'affiliates',
      render: (values: any[], row: CommissionMapping) => {
        if (row.allAffiliates) return c('all');
        if (values.length === 0) return c('none');

        const results = affiliates
          .filter((x) => values.includes(x.id))
          .map((x) => x.name);

        return (
          <>
            {`${values.length} affiliate(s)`}
            <Popover
              content={
                <div style={{ maxWidth: 400 }}>{results.join(', ')}</div>
              }
              title={c('affiliates')}
              trigger="hover"
            >
              <ExclamationCircle style={{ marginLeft: 4, marginBottom: -2 }} />
            </Popover>
          </>
        );
      },
    },
    {
      title: c('common:action'),
      align: 'right' as 'right',
      dataIndex: 'action',
      width: 50,
      render: (_: any, record: CommissionMapping) => {
        const editable = isEditing(record);
        return editable ? (
          <Space>
            <Button
              icon={
                <Save
                  style={{
                    marginLeft: -3,
                    marginBottom: -3,
                    fontSize: '1.1em',
                  }}
                />
              }
              onClick={() => save(record)}
            />

            <Button
              onClick={cancel}
              icon={
                <Cross
                  style={{
                    marginLeft: -2,
                    marginBottom: -2,
                    fontSize: '0.9em',
                  }}
                />
              }
            />
          </Space>
        ) : (
          <Space>
            <Button
              disabled={editingKey !== ''}
              onClick={() => edit(record)}
              icon={
                <Edit
                  style={{
                    marginLeft: -2,
                    marginBottom: -2,
                  }}
                />
              }
            />
          </Space>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record: any) => ({
        record,
        inputType: col.inputType,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const onFieldsChanged = (values: any[]) => {
    if (!values || !values.length) return;

    // if you changed the all product switch set the all product variable
    if (values[0].name[0] === 'allProducts') {
      if (values[0].value === true) {
        form.setFields([{ name: 'products', value: [] }]);
        setAllProducts(true);
      } else {
        setAllProducts(false);
      }
    }

    // if you changed the allAffiliates switch set the all Affiliates variable
    if (values[0].name[0] === 'allAffiliates') {
      if (values[0].value === true) {
        form.setFields([{ name: 'affiliates', value: [] }]);
        setAllAffiliates(true);
      } else {
        setAllAffiliates(false);
      }
    }
  };

  return (
    <Col xs={24} xl={14} xxl={14}>
      <TitleRow>
        <h2>{c('commissions:commissionMappings')}</h2>
        <Button
          onClick={addNew}
          disabled={editingKey !== ''}
          icon={
            <Create
              style={{
                marginLeft: -2,
                marginBottom: -2,
                fontSize: '1.2em',
              }}
            />
          }
        >
          {c('add')}
        </Button>
      </TitleRow>
      <Card loading={isLoadingCommission}>
        <Form form={form} component={false} onFieldsChange={onFieldsChanged}>
          <Table
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            rowClassName="editable-row"
            rowKey="id"
            loading={loading}
            columns={mergedColumns as any}
            pagination={false}
            dataSource={data}
          />
        </Form>
      </Card>
    </Col>
  );
};

export default Component;
