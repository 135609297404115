import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

import { useNavigate, useParams } from 'react-router';
import type { IBulkEmailForm } from './IBulkEmailForm';

import { Card, Input, Form, Button, Checkbox, Row, Col, Space } from 'antd';

import { Content, TitleRow } from 'components/shared';
import Editor from 'components/shared/Editor';
import TemplateSelect from './TemplateSelect';
import RecipientGroupsSelect from './RecipientGroupsSelect';

import SendDateField from './SendDateField';
import PreviewModal from './PreviewModal';

import useCommunications, {
  TemplateType,
  IBulkEmail,
} from '../../../../../hooks/Communications/Emails';
import moment from 'moment';
import { BulkEmailRequest } from 'hooks/Communications/Models';
import { message } from 'components/antd';

const Page = () => {
  let { emailId } = useParams();

  const { t } = useTranslation('communicationsMaint');
  const navigate = useNavigate();

  const [form] = Form.useForm<IBulkEmailForm>();
  const [previewModalVisible, setPreviewModalVisible] = useState(false);
  const [initialFieldsValue, setInitialFieldsValue] = useState<any>({
    sendNow: true,
  });

  var {
    templates,
    recipientGroups,
    isLoading,
    isAddingOrUpdatingEmail,
    fetchEmailTemplates,
    fetchRecipientGroups,
    getBulkEmail,
    addOrUpdateEmail,
  } = useCommunications();

  useEffect(() => {
    form.resetFields();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialFieldsValue]);

  //onMount
  useEffect(() => {
    let promise = undefined;
    if (emailId !== undefined) {
      promise = getBulkEmail(parseInt(emailId)).then((email: IBulkEmail) => {
        const sendDateUtc = moment.utc(email.sendDate).local();
        setInitialFieldsValue({
          sendNow: false,
          sendDate: sendDateUtc,
          sendTime: sendDateUtc,
          bulkEmailID: email.bulkEmailID,
          emailSubject: email.emailSubject,
          emailFromAddress: email.emailFromAddress,
          emailFromName: email.emailFromName,
          emailCCList: email.emailCCList,
          emailBCCList: email.emailBCCList,
          trackActivity: email.trackActivity,
          emailMessageHTML: email.emailMessageHTML,
          affiliateGroups: email.affiliateGroups.map(
            (ag) => ag.affiliateGroupID
          ),
        });
      });
    }

    Promise.all([
      fetchEmailTemplates(TemplateType.User),
      fetchRecipientGroups(),
      promise,
      // props.emailId !== undefined ? : Promise.resolve(true)
    ]).catch((err) => {
      message.error('Unable to load data');
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeTemplate = (templateId?: number) => {
    if (templateId === null || templateId === undefined) return;
    const template = templates.find(
      (template) => template.emailTemplateID === templateId
    );

    setInitialFieldsValue({
      ...initialFieldsValue,
      emailSubject: template?.emailSubject,
      emailFromAddress: template?.emailFromAddress,
      emailFromName: template?.emailFromName,
      emailCCList: template?.emailCCList,
      emailBCCList: template?.emailBCCList,
      trackActivity: template?.trackActivity,
      emailMessageHTML: template?.emailMessageHTML,
    });
    setTimeout(() => {
      form.resetFields();
    }, 100);
  };

  const addEditEmail = async (form: IBulkEmailForm) => {
    var request = new BulkEmailRequest(
      form.emailSubject,
      form.emailMessageHTML,
      form.emailFromName,
      form.emailFromAddress,
      form.trackActivity,
      form.affiliateGroups
    );
    request.emailCCList = form.emailCCList;
    request.emailBCCList = form.emailBCCList;
    if (!form.sendNow) {
      const sendDateTime = form
        .sendDate!.startOf('day')
        .add(form.sendTime!.hour(), 'hour')
        .add(form.sendTime!.minute(), 'minute')
        .utc();
      request.sendDate = sendDateTime.toISOString();
    }
    request.bulkEmailID =
      emailId !== undefined ? parseInt(emailId!) : undefined;
    try {
      await addOrUpdateEmail(request);
      navigate(emailId === undefined ? 'list' : '../list');
      message.success('Successfully saved the template');
    } catch (error) {
      message.error('Error while saving data');
    }
  };

  return (
    <Content>
      <TitleRow>
        <h1>{t('bulkemails')}</h1>
        <Button onClick={() => navigate('../list')}>Cancel</Button>
      </TitleRow>
      <Card loading={isLoading}>
        <PreviewModal
          form={form}
          visible={previewModalVisible}
          onFinished={() => setPreviewModalVisible(false)}
          onCancel={() => setPreviewModalVisible(false)}
        />
        <Form
          form={form}
          layout="vertical"
          onFinish={addEditEmail}
          initialValues={initialFieldsValue}
        >
          <Row gutter={5}>
            <Col span={12}>
              <TemplateSelect
                label="Template"
                templates={templates}
                onSelect={changeTemplate}
              />
            </Col>
            <Col span={12}>
              <RecipientGroupsSelect
                label="Recipient Groups"
                recipientGroups={recipientGroups}
                form={form}
              />
            </Col>
          </Row>
          <Row gutter={5}>
            <Col span={12}>
              <Form.Item
                label="From Name"
                name="emailFromName"
                rules={[{ required: true }]}
              >
                <Input type="text" placeholder="Example" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="From Address"
                name="emailFromAddress"
                rules={[{ required: true }]}
              >
                <Input type="text" placeholder="example@example.com" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={5}>
            <Col span={12}>
              <Form.Item label="CC" name="emailCCList">
                <Input
                  type="text"
                  placeholder="example@example.com; example2@example.com"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="BCC" name="emailBCCList">
                <Input
                  type="text"
                  placeholder="example@example.com; example2@example.com"
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            name="emailSubject"
            label="Subject"
            rules={[{ required: true }]}
          >
            <Input type="text"></Input>
          </Form.Item>
          <Form.Item
            name="emailMessageHTML"
            label="Email Body"
            rules={[{ required: true }]}
          >
            <Editor />
          </Form.Item>
          <Row>
            <Form.Item
              valuePropName="checked"
              initialValue={true}
              name="trackActivity"
            >
              <Checkbox>Track Activity</Checkbox>
            </Form.Item>
            <SendDateField
              name="sendDate"
              form={form}
              sendNow={initialFieldsValue.sendNow}
            />
          </Row>
          <Form.Item style={{ textAlign: 'center' }}>
            <Space>
              <Button
                onClick={async () => {
                  form
                    .validateFields()
                    .then(() => {
                      setPreviewModalVisible(true);
                    })
                    .catch((e) => {
                      console.log('error', e);
                    });
                }}
              >
                Preview
              </Button>
              <Button
                loading={isAddingOrUpdatingEmail}
                onClick={() => form.submit()}
                type="primary"
              >
                {emailId === undefined ? 'Send' : 'Save'}
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Card>
    </Content>
  );
};

export default Page;
