import { Collapse, Form, message, Row, Select, Space } from 'components/antd';
import { Chart } from 'components/shared';
import { ChartTypes } from 'components/shared/Chart';
import ChartToggle from 'components/shared/ChartToggle';
import useReports, { AppliedFilter } from 'hooks/Reporting/reports';
import { ReportRequest } from 'hooks/Reporting/reports';
import { getReportData, REPORT_CHART_CONFIG } from 'libs/chart';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export type Properties = {
  reportRequest: ReportRequest;
  hideFrame?: boolean;
  setMetricForReport?: Function;
  selectedMetric?: any;
};

const ReportChart = ({
  reportRequest,
  hideFrame = false,
  setMetricForReport = () => {},
  selectedMetric = null,
}: Properties) => {
  const [form] = Form.useForm();

  const reportId = reportRequest?.ReportID;
  const hasConfig = reportId ? REPORT_CHART_CONFIG[reportId] : false;

  const [metric, setMetric] = useState<string>(
    hasConfig
      ? selectedMetric === undefined
        ? REPORT_CHART_CONFIG[reportId].DefaultMetric
        : selectedMetric!
      : undefined
  );
  const [metrics, setMetrics] = useState<any[]>(
    hasConfig ? REPORT_CHART_CONFIG[reportId].Metrics : []
  );
  const [showCharts, setShowCharts] = useState<boolean>(hasConfig);
  const [chartType, setChartType] = useState<string>(ChartTypes.Bar);
  const [chartLabels, setChartLabels] = useState<string[]>([]);
  const [chartDataSource, setChartDataSource] = useState<any[]>([]);
  const [chartDataTick, setChartDataTick] = useState<string>('');
  const { load, isLoading, report } = useReports();
  const { t } = useTranslation('reporting');

  const dateRanges: any = {};
  dateRanges[t('last.month')] = [
    moment().subtract(1, 'month').startOf('month'),
    moment().subtract(1, 'month').endOf('month'),
  ];
  dateRanges[t('last.year')] = [
    moment().subtract(1, 'year').startOf('year'),
    moment().subtract(1, 'year').endOf('year'),
  ];

  useEffect(() => {
    const reportId = reportRequest?.ReportID;
    const hasConfig = reportId ? REPORT_CHART_CONFIG[reportId] : false;
    setShowCharts(hasConfig);
    setMetric(
      hasConfig
        ? selectedMetric === undefined
          ? REPORT_CHART_CONFIG[reportId].DefaultMetric
          : selectedMetric!
        : undefined
    );
    setMetrics(hasConfig ? REPORT_CHART_CONFIG[reportId].Metrics : []);
    setShowCharts(hasConfig);

    // If make sure groupBy for chart is by a date value
    let filters = [
      ...reportRequest.Filters.filter((x: AppliedFilter) => x.Value),
    ];
    const gbf = filters.find((f) => f.ID === 'GroupBy');
    if (!gbf) {
      filters.push({ ID: 'GroupBy', Value: 'Day' });
    } else if (!['Day', 'Month', 'Year'].includes(gbf.Value)) {
      const index = filters.indexOf(gbf);
      filters.splice(index, 1);
      filters.push({ ID: 'GroupBy', Value: 'Day' });
    }

    if (!hasConfig) return;
    load({
      ...reportRequest,
      Filters: filters,
      SortBy: 'Date',
      Ascending: true,
      Current: undefined,
      PageSize: undefined,
    }).catch(() => {
      message.error(t('reporting.fetch.error'));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportRequest]);

  useEffect(() => {
    if (
      report === undefined ||
      !Object.keys(REPORT_CHART_CONFIG).includes(report.Header.ReportID)
    )
      return;

    setMetricForReport(metric);

    const { dataSource, labels, chartDataTick } = getReportData(
      report,
      metric,
      t
    );

    setChartDataSource(dataSource);
    setChartLabels(labels);
    setChartDataTick(chartDataTick);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report, metric]);

  //
  // EVENT HANDLERS
  //
  const onToggleChartChanged = (chart: string) => setChartType(chart);

  if (!showCharts) return null;

  if (hideFrame)
    return (
      <Chart
        chartLabels={chartLabels}
        chartType={chartType}
        dataSource={chartDataSource}
        dataTick={chartDataTick}
        loading={isLoading}
        style={{ marginBottom: '2em' }}
      />
    );

  return (
    <>
      <Collapse defaultActiveKey={['1']} style={{ marginBottom: '1em' }}>
        <Collapse.Panel header={t('charts')} key="1">
          <Row justify="end" style={{ marginBottom: '1em' }}>
            <Space style={{ marginRight: '1em' }}>
              <Form form={form} layout="inline">
                <Form.Item
                  label={t('metric')}
                  name="Metric"
                  initialValue={metric}
                >
                  <Select
                    disabled={isLoading}
                    allowClear={false}
                    style={{ width: '200px' }}
                    options={metrics}
                    onChange={(value) => {
                      if (!value) return;
                      setMetric(value?.toString());
                    }}
                  />
                </Form.Item>
              </Form>
            </Space>
            <ChartToggle
              chartType={chartType}
              toggleChartHandler={onToggleChartChanged}
            />
          </Row>
          <Chart
            chartLabels={chartLabels}
            chartType={chartType}
            dataSource={chartDataSource}
            dataTick={chartDataTick}
            loading={isLoading}
            style={{ marginBottom: '2em' }}
          />
        </Collapse.Panel>
      </Collapse>
    </>
  );
};

export default ReportChart;
