import styled from 'styled-components';

export enum Position {
  Center = 'center',
  Left = 'left',
  Right = 'right',
}

type Props = {
  padding?: number | string;
  position?: Position | string;
  children?: JSX.Element | JSX.Element[] | React.ReactNode;
  maxWidth?: number | string;
  // All other props
  [key: string]: any;
};

const PaddingDiv = styled.div<Props>`
  padding: ${(props) => props.padding};
  max-width: ${(props) => props.maxWidth};
  width: 100%;
`;

const AlignDiv = styled.div<Props>`
  display: flex;
  justify-content: ${(props) => props.position};
`;

const Content = ({
  padding = '1em 2em 2em 2em',
  position = Position.Left,
  children,
  maxWidth = '1900px',
  ...props
}: Props) => {
  let pad = padding;
  let maxW = maxWidth;

  if (typeof padding === 'number') pad = `${padding}px`;
  if (typeof maxWidth === 'number') maxW = `${maxWidth}px`;
  return (
    <AlignDiv position={position}>
      <PaddingDiv
        {...props}
        padding={pad}
        maxWidth={maxW}
        style={{ ...props.style }}
      >
        {children}
      </PaddingDiv>
    </AlignDiv>
  );
};

export default Content;
