import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Popconfirm,
  Card,
  Select,
  Form,
  message,
  Row,
  Table,
  Input,
} from 'components/antd';
import { Content } from 'components/shared';
import useAdminProductsTable from 'hooks/Product/productsTable';
import { Search } from 'components/icons';
import styled from 'styled-components';

const ApprovalContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;
`;

const GreyBox = styled.div`
  background-color: #f2f2f2;
  padding: 10px 10px 15px 10px;
  min-width: 600px;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  align-items: center;
`;

function Page() {
  const { t } = useTranslation('products');

  const { Option } = Select;

  const [state, setState] = useState<any>({});
  const [approvedOption, setApprovedOption] = useState('all');
  const [search, setSearch] = useState<string>('');
  const [searchColumn, setSearchColumn] = useState<string>('name');
  const [searchType, setSearchType] = useState<string>('in');

  const {
    products,
    columns,
    loadConfig,
    loadData,
    deslectAllProducts,
    selectAllProducts,
    approveSelectedProducts,
    pagination,
    isSelectAllDisabled,
    isDeselectDisabled,
    isApproveSelectedDisabled,
    isLoading,
    approvalSwitchOverride,
    doProductsRequireApproval,
  } = useAdminProductsTable();

  const fetchData = (params: any) => {
    loadConfig()
      .then(({ fieldNames, includeApprovalColumn }) => {
        return loadData(params, fieldNames, includeApprovalColumn);
      })
      .catch(() => {
        message.error(t('productsData.fetch.error'));
      });
  };

  useEffect(() => {
    fetchData({
      ...pagination,
      approved: approvedOption,
      searchType,
      searchColumn: !!search ? searchColumn : undefined,
      search,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [approvalSwitchOverride]);

  const handleTableChange = (pagination: any) => {
    setState({ ...state, ...pagination });
    fetchData({
      ...pagination,
      approved: approvedOption,
      searchType,
      searchColumn: !!search ? searchColumn : undefined,
      search,
      tableChange: true,
    });
  };

  const filterHandler = (approved: string, search: string) => {
    setApprovedOption(approved);
    setSearch(search || '');
    if (!searchColumn && search.length > 1) {
      setSearchColumn('name');
    }
    // Go back to page 1 after a new search or filter has been applied
    pagination.current = 1;
    loadData({
      ...pagination,
      approved,
      searchType,
      searchColumn: !!search ? searchColumn : undefined,
      search,
      tableChange: true,
    }).catch(() => {
      message.error(t('productsData.fetch.error'));
    });
  };

  return (
    <Content>
      <h1>{t('products')}</h1>
      <Form initialValues={{ approved: 'all', search_field: 'name' }}>
        <Row justify="center">
          {doProductsRequireApproval === true && (
            <Form.Item name="approved" label={`${t('show')}`}>
              <Select
                style={{ width: 200 }}
                onChange={(value) => filterHandler(value as string, search)}
              >
                <Select.Option value="all">{t('all')}</Select.Option>
                <Select.Option value="true">{t('approved')}</Select.Option>
                <Select.Option value="false">{t('unapproved')}</Select.Option>
              </Select>
            </Form.Item>
          )}
          <Form.Item
            name="search_type"
            label={t('searchType')}
            style={{ marginLeft: '20px' }}
          >
            <Select
              style={{ width: 200 }}
              onChange={(value: string) => value && setSearchType(value)}
              defaultValue="in"
            >
              <Option value="in">{t('exactMultiple')}</Option>
              <Option value="like">{t('like')}</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="search_field"
            label={`${t('searchColumn')}`}
            style={{ marginLeft: '20px' }}
          >
            <Select
              style={{ width: 200 }}
              onChange={(value: string) => value && setSearchColumn(value)}
              loading={isLoading}
              options={columns
                .filter((f) => f.title !== 'Action' && f.title !== 'Approved')
                .map((f) => ({
                  label: f.title,
                  value: f.dataIndex,
                }))}
            />
          </Form.Item>
          <Form.Item name="search" style={{ marginLeft: '20px' }}>
            <Input.Search
              allowClear
              value={search}
              onSearch={(value) => filterHandler(approvedOption, value)}
              placeholder={`${t('searchProducts')}`}
              enterButton={
                <Search style={{ marginTop: 5, fontSize: '1.2em' }} />
              }
            />
          </Form.Item>
        </Row>
      </Form>
      {doProductsRequireApproval === true && (
        <ApprovalContainer>
          <GreyBox>
            <h3 style={{ marginBottom: '15px' }}>{t('productApproval')}</h3>
            <div>
              <Button
                type="primary"
                disabled={isSelectAllDisabled}
                loading={isLoading}
                onClick={() => {
                  selectAllProducts();
                }}
              >
                {t('selectAll')}
              </Button>
              <Button
                style={{ marginLeft: '15px' }}
                type="primary"
                loading={isLoading}
                disabled={isDeselectDisabled}
                onClick={() => {
                  deslectAllProducts();
                }}
              >
                {t('deselectAll')}
              </Button>
              <Popconfirm
                placement="bottom"
                title={`${t('areYouSure')}`}
                onConfirm={() => {
                  approveSelectedProducts();
                }}
                okText="Yes"
                cancelText="No"
                disabled={isApproveSelectedDisabled}
              >
                <Button
                  style={{ marginLeft: '15px' }}
                  loading={isLoading}
                  disabled={isApproveSelectedDisabled}
                  type="primary"
                >
                  {t('approveSelected')}
                </Button>
              </Popconfirm>
            </div>
          </GreyBox>
        </ApprovalContainer>
      )}
      <Card>
        <Table
          rowKey="skuoffercodepackageid"
          columns={columns}
          dataSource={products}
          pagination={pagination}
          loading={isLoading}
          onChange={handleTableChange}
        />
      </Card>
    </Content>
  );
}

export default Page;
