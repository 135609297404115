import {
  Button,
  Card,
  Row,
  Form,
  Select,
  Switch,
  Table,
  Space,
  message,
} from 'components/antd';
import { Create, Edit, SwitchIcon, UpArrow, DownArrow } from 'components/icons';
import { Content, TitleRow } from 'components/shared';
import useFAQs, { FAQ } from 'hooks/Support/faqs';
import useFAQCategories from 'hooks/Support/faqCategories';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import useUser from 'hooks/User';
import { Permissions } from 'constants/permissions';

const getColumns = (
  t: Function,
  c: Function,
  toggle: Function,
  moveUp: Function,
  moveDown: Function,
  allowToggle: boolean,
  allowEdit: boolean,
  allowResequence: boolean,
  data: FAQ[]
) => [
  {
    title: t('common:isActive'),
    dataIndex: 'isActive',
    width: 100,
    editable: true,
    render: (isActive: boolean) => <Switch disabled checked={isActive} />,
  },
  {
    title: t('question'),
    dataIndex: 'question',
    key: 'question',
    fixed: 'left',
  },
  {
    title: c('action'),
    dataIndex: '',
    align: 'right',
    key: '',
    width: 200,
    render: (af: FAQ, _: FAQ, index: number) => (
      <Space>
        <Button
          disabled={
            !allowResequence ||
            index === 0 ||
            index > data.length - 1 ||
            af.isActive === false
          }
          onClick={() => moveUp(af)}
          icon={
            <UpArrow
              style={{
                marginLeft: -2,
                marginBottom: -2,
              }}
            />
          }
        />
        {/* Lets hide this when its up top and down bottom? */}
        <Button
          disabled={
            !allowResequence ||
            index < 0 ||
            index >= data.length - 1 ||
            af.isActive === false ||
            (index <= data.length - 2 && data[index + 1].isActive === false)
          }
          onClick={() => moveDown(af)}
          icon={
            <DownArrow
              style={{
                marginLeft: -2,
                marginBottom: -2,
              }}
            />
          }
        />
        <Link to={`/admin/faq/${af.faqid}/edit`}>
          <Button
            disabled={!allowEdit}
            icon={<Edit style={{ marginLeft: -2, marginBottom: -2 }} />}
          />
        </Link>
        <Button
          disabled={!allowToggle}
          onClick={() => toggle(af)}
          icon={
            <SwitchIcon
              style={{
                marginLeft: -2,
                marginBottom: -2,
              }}
            />
          }
        />
      </Space>
    ),
  },
];

const Page = () => {
  const { t } = useTranslation('supportFAQs');
  const { t: c } = useTranslation('common');
  const { filterCategories } = useFAQCategories();
  const { load, faqsLoading, faqs, activate, noLongerLoading, resequence } =
    useFAQs();
  const { hasPermission } = useUser();
  const [category, setCategory] = useState<number>(1);
  const [inactiveState, setInactiveState] = useState<boolean>(false);
  const allowToggle = hasPermission(Permissions['Content.FAQs.Admin']);
  const allowEdit = hasPermission(Permissions['Content.FAQs.Admin']);
  const allowResequence = hasPermission(Permissions['Content.FAQs.Admin']);
  const { search } = useLocation();
  const navigate = useNavigate();

  const fetchNewData = () => {
    load({
      includeInactive: inactiveState,
      categories: category ? [category] : undefined,
    });
  };

  useEffect(() => {
    const q = new URLSearchParams(search);
    const iS = q.get('inactiveState');
    const c = q.get('category');

    if (c) setCategory(Number(c));
    if (iS) setInactiveState(iS === 'true' ? true : false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchNewData();
    if (inactiveState || category)
      navigate(
        `/admin/faq/faqs?inactiveState=${inactiveState}&category=${category}`
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inactiveState, category]);

  const toggle = (record: FAQ) => {
    if (!record.faqid) return;

    activate(record.faqid, !record.isActive)
      .then(() => {
        message.success(t('faq.activationToggle.success'));
      })
      .catch((error) => {
        noLongerLoading();
        const errorMsg = error?.response?.data?.message;
        message.error(
          t('faq.activationToggle.error', {
            msg: errorMsg ? t(errorMsg) : undefined,
          })
        );
      })
      .finally(fetchNewData);
  };

  const moveUp = (record: FAQ) => {
    if (!record.faqid) return;

    resequence(record, -1, category)
      .then(fetchNewData)
      .catch((error) => {
        const errorMsg = error?.response?.data?.message;
        message.error(
          t('faq.resequence.error', {
            msg: errorMsg ? t(errorMsg) : undefined,
          })
        );
      });
    return;
  };

  const moveDown = (record: FAQ) => {
    if (!record.faqid) return;

    resequence(record, 1, category)
      .then(fetchNewData)
      .catch((error) => {
        const errorMsg = error?.response?.data?.message;
        message.error(
          t('faq.resequence.error', {
            msg: errorMsg ? t(errorMsg) : undefined,
          })
        );
      });
    return;
  };

  return (
    <Content>
      <TitleRow>
        <h1>{t('faqs')}</h1>
        <Link to={`/admin/faq/create`}>
          <Button
            icon={
              <Create
                style={{ marginLeft: -2, marginBottom: -2, fontSize: '1.2em' }}
              />
            }
          >
            {t('common:add')}
          </Button>
        </Link>
      </TitleRow>

      <Row justify="center" style={{ paddingTop: '2em', paddingBottom: '2em' }}>
        <Form layout="inline">
          <Form.Item>
            <Select
              placeholder={t('category')}
              options={filterCategories.map((item) => ({
                label: t(item.faqCategoryName),
                value: item.faqCategoryID!
                  ? item.faqCategoryID!.toString()
                  : '-1', // review this later
              }))}
              value={category.toString()}
              style={{ width: 200 }}
              onChange={(value) => setCategory(Number(value))}
            />
          </Form.Item>
          <Form.Item label={t('includeDeactivated')}>
            <Switch checked={inactiveState} onChange={setInactiveState} />
          </Form.Item>
        </Form>
      </Row>

      <Card>
        <Table
          rowKey="faqid"
          columns={
            getColumns(
              t,
              c,
              toggle,
              moveUp,
              moveDown,
              allowToggle,
              allowEdit,
              allowResequence,
              faqs
            ) as any
          }
          dataSource={faqs}
          loading={faqsLoading}
          pagination={false}
          expandable={{
            expandedRowRender: (record: FAQ) => (
              <div style={{ padding: '1em' }}>
                <div dangerouslySetInnerHTML={{ __html: record.answer }} />
              </div>
            ),
            rowExpandable: (record: FAQ) => (record.answer ? true : false),
          }}
        />
      </Card>
    </Content>
  );
};

export default Page;
