import { RcFile } from 'antd/lib/upload';
import {
  Button,
  Card,
  Form,
  Input,
  Switch,
  DatePicker,
  Select,
  message,
} from 'components/antd';
import { Content } from 'components/shared';
import Editor from 'components/shared/Editor';
import useNews from 'hooks/Resources/news';
import { NewsCategoryCompact } from 'hooks/Resources/newsCategories';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import { ImageState, ImageData } from 'components/shared/ImageSelector';
import ImageSelector from 'components/shared/ImageSelector';
import useNewsImages from 'hooks/Resources/newsImages';

type Props = {
  isNew?: boolean;
  categories?: NewsCategoryCompact[];
};

const Page = ({ isNew, categories = [] }: Props) => {
  const { t } = useTranslation('news');
  const [form] = Form.useForm();
  let { id } = useParams();
  const navigate = useNavigate();

  const { loadArticle, isLoading, article, store } = useNews();
  const {
    load: loadImages,
    upload: uploadImage,
    images,
    isLoading: isLoadingImages,
  } = useNewsImages();
  const [convertedImages, setConvertedImages] = useState<ImageData[]>([]);

  useEffect(() => {
    loadImages();
    if (!isNew && id) {
      loadArticle(id, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setConvertedImages(mapImagesToImageData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [images]);

  useEffect(() => {
    if (!article) return;

    form.resetFields();
    form.setFieldsValue({
      image: {
        id: article?.articleImage,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [article]);

  const onFinish = async (values: any) => {
    const results = await form.validateFields();
    const catIds = results.categories;
    delete results.categories;

    results.newsArticleCategories = catIds.map((id: any) => ({
      newsCategoryID: id,
    }));

    if (!isNew) results.articleID = id;
    const localePath = isNew ? 'add' : 'edit';

    if (values.image) {
      const image = convertedImages.find(
        (image) => image.id === values.image.id
      );
      results.ArticleImage = image?.id;
    }

    store(results)
      .then(() => {
        message.success(t(`news.${localePath}.success`));
        navigate(`/admin/news/list`);
      })
      .catch((error) => {
        const errorMsg = error?.response?.data?.message;
        message.error(
          t(`news.${localePath}.success`, {
            msg: errorMsg ? t(errorMsg) : undefined,
          })
        );
      });
  };

  function mapImagesToImageData(): ImageData[] {
    return images.map((image) => {
      return {
        id: image.fileName,
        source: image.fileLocation,
        state: ImageState.done,
        percentageUploaded: 50,
      };
    });
  }

  const onImageSelectorUpload = (
    file: RcFile,
    onComplete: (id: string) => void
  ) => {
    const uploadedImage: ImageData = {
      id: file.uid,
      state: ImageState.uploading,
      percentageUploaded: 0,
    };

    const r: ImageData[] = mapImagesToImageData();
    r.unshift(uploadedImage);

    setConvertedImages(r);

    return uploadImage(file, (progressEvent) => {
      uploadedImage.percentageUploaded = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );

      const r: ImageData[] = mapImagesToImageData();
      r.unshift(uploadedImage);
      setConvertedImages(r);
    }).then(
      (result) => {
        uploadedImage.id = result.data.fileName;
        uploadedImage.source = result.data.fileLocation;
        uploadedImage.state = ImageState.done;
        uploadedImage.percentageUploaded = 100;

        r[0] = uploadedImage;
        setConvertedImages(r);

        form.setFieldsValue({
          image: uploadedImage,
        });

        onComplete(uploadedImage.id);
      },
      () => {
        uploadedImage.state = ImageState.error;
        uploadedImage.percentageUploaded = 100;

        r[0] = uploadedImage;
        setConvertedImages(r);
      }
    );
  };

  const options = categories.map((c) => ({
    label: c.newsCategoryName,
    value: c.newsCategoryID?.toString(),
  }));

  return (
    <Content>
      <h1>{t(isNew ? 'newsAdminCreate' : 'newsAdminEdit')}</h1>
      <Card loading={isLoading}>
        <Form
          initialValues={article}
          name="basic"
          form={form}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 12 }}
          autoComplete="off"
          onFinish={onFinish}
        >
          <Form.Item
            label={t('articleHeadline')}
            name="articleHeadline"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={t('articleSubHeading')}
            name="articleSubHeading"
            rules={[{ required: true, max: 500 }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={t('articleImage')}
            name="image"
            rules={[{ required: false }]}
          >
            <ImageSelector
              height="350px"
              loading={isLoadingImages}
              images={convertedImages}
              onUpload={onImageSelectorUpload}
              hideDimensions={true}
            />
          </Form.Item>

          <Form.Item label={t('expiryDate')} name="expiryDate">
            <DatePicker />
          </Form.Item>

          <Form.Item
            label={t('forDashboard')}
            name="forDashboard"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>

          <Form.Item
            name="categories"
            label={t('categories')}
            rules={[{ required: true }]}
          >
            <Select
              mode="multiple"
              allowClear
              placeholder={t('categoriesPlaceholder')}
              options={options as any[]}
            />
          </Form.Item>

          <Form.Item
            label={t('body')}
            name="articleBody"
            style={{ height: 'auto' }}
            rules={[{ required: true }]}
          >
            <Editor />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8 }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </Content>
  );
};

export default Page;
