export class QueueEmailRequest {
  public emailMessageHtml: string;
  public emailSubject: string;
  public emailFromName: string;
  public emailFromAddress: string;
  public queuedDate?: string;

  constructor(
    public emailRecipients: string,
    subject: string,
    message: string,
    emailFromAddress: string,
    emailFromName: string
  ) {
    this.emailSubject = subject;
    this.emailMessageHtml = message;
    this.emailFromAddress = emailFromAddress;
    this.emailFromName = emailFromName;
  }
}
