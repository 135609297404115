import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, message, Steps, Form } from 'components/antd';

import AffiliateForm from './AffiliateForm';
import AddressForm from './AddressForm';
import PaymentsForm from './PaymentsForm';
import WebsiteForm from './WebsiteForm';
import ConfirmForm from './ConfirmForm';
import TermsAndServices from './TermsAndServices';
import PlatformAgreement from './PlatformAgreement';
import VatForm from './VatForm';
import useAffiliateDetails, {
  PromotionalMethod,
} from 'hooks/Account/affiliateDetails';
import useAgreements from 'hooks/Agreements/agreements';

const { Step } = Steps;

type Props = {
  onSubmit: (details: any) => Promise<void>;
};

function Page({ onSubmit }: Props) {
  const [current, setCurrent] = useState(0);
  const { t } = useTranslation('wizard');

  const [details, setDetails] = useState<any>({});
  const [methods, setMethods] = useState<PromotionalMethod[]>([]);

  const stepDataProperties = ['first', 'second', 'third', 'fourth', 'fifth'];

  const {
    loadLatestTerms,
    selectedTerms,
    loadLatestAgreement,
    selectedAgreement,
  } = useAgreements();
  const { fetchPromotionalMethods } = useAffiliateDetails();

  const [form] = Form.useForm();

  const fillTestData = () => {
    form.setFieldsValue({
      affiliateName: 'qqqq',
      affiliateWebsite: 'http://www.somewebsite.com',
      title: 'mr',
      firstName: 'qqq',
      surname: 'qq',
      phoneNumber: '123123123',
      addressLineOne: 'qqqq',
      addressLineTwo: 'q',
      city: 'qqq',
      county: 'qq',
      country: 'qqq',
      postCode: '160 000',
      accountName: 'qweqweqw',
      bankName: 'eqeweqweqw',
      accountNumber: '12345678',
      swiftCode: 'qweqweqqqq',
      iban: 'eqwqweqewqewqqqqqqqqqqqqqqq',
      sortCode: '11-11-11',
      vatRegistrationNumber: '123123123',
      websites: [
        {
          websiteName: 'qweewqwqe',
          websiteUrl: 'http://www.some.com',
          uniqueMonthlyVisitors: 1000,
          websiteDescription: 'qwwqeewq',
          promotionalMethodIds: ['37695288-f0e7-4f3c-9370-18586ebb27b2'],
          socialNetworks: [
            {
              id: 1,
              accountHandle: 'fb',
            },
          ],
        },
      ],
      termsandConditionsId: '1b92a1da-1371-40e9-067c-08d9b9996dc0',
      termsandConditionsIdAccepted: true,
      selfBillingAgreementId: '1b92a1da-1371-40e9-067c-08d9b9996dc0',
      selfBillingAgreementIdAccepted: true,
    });
  };

  useEffect(() => {
    const stepDataProperty = stepDataProperties[current];
    form.setFieldsValue(details[stepDataProperty]);
  }, [current]);

  useEffect(() => {
    loadLatestTerms().catch(() => {
      message.error(t('agreementLoadingError'));
    });

    loadLatestAgreement().catch(() => {
      message.error(t('platformAgreementLoadingError'));
    });

    fetchPromotionalMethods()
      .then(setMethods)
      .catch(() => {
        message.error(t('promoMethodsLoadingError'));
      });

    (window as any).__fillForm = fillTestData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const next = async () => {
    const values = await form.validateFields();

    const _details = { ...details };
    const stepDataProperty = stepDataProperties[current];

    _details[stepDataProperty] = values;
    setDetails(_details);

    const i = Math.min(current + 1, 6);
    setCurrent(i);
  };

  const prev = () => {
    const i = Math.max(current - 1, 0);
    setCurrent(i);
  };

  return (
    <>
      <Steps responsive={true} labelPlacement="vertical" current={current}>
        <Step subTitle={t('affiliate')} />
        <Step subTitle={t('payments')} />
        <Step subTitle={t('website')} />
        <Step subTitle={t('termsAndServices')} />
        <Step subTitle={t('platformAgreement')} />
        <Step subTitle={t('confirmation')} />
      </Steps>

      <div className="steps-content" style={{ marginTop: '3em' }}>
        {current === 0 && (
          <div>
            <AffiliateForm form={form} />
            <AddressForm form={form} />
          </div>
        )}
        {current === 1 && (
          <div>
            <VatForm form={form} vat={details?.second?.vatRegistrationNumber} />
            <PaymentsForm form={form} bank={details?.second} />
          </div>
        )}
        {current === 2 && (
          <WebsiteForm methods={methods} onFinish={() => {}} form={form} />
        )}
        {current === 3 && (
          <TermsAndServices
            terms={selectedTerms?.terms}
            termsId={selectedTerms?.id}
            form={form}
          />
        )}
        {current === 4 && (
          <PlatformAgreement
            agreementDetails={selectedAgreement?.agreementDetails}
            agreementId={selectedAgreement?.id}
            form={form}
          />
        )}
        {current === 5 && (
          <ConfirmForm result={details} methods={methods} onSubmit={onSubmit} />
        )}
      </div>
      <div className="steps-action">
        {current > 0 && (
          <Button style={{ margin: '0 8px' }} onClick={prev}>
            Previous
          </Button>
        )}
        {/* {current === 4 && (
          <Button
            type="primary"
            onClick={() => message.success('Processing complete!')}
          >
            Done
          </Button>
        )} */}
        {current < 5 && (
          <Button type="primary" onClick={next}>
            Next
          </Button>
        )}
      </div>
    </>
  );
}

export default Page;
