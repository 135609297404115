import {
  Button,
  Card,
  Form,
  Input,
  Select,
  message,
  DatePicker,
} from 'components/antd';
import styled from 'styled-components';
import { QA } from 'components/icons';
import { useTranslation } from 'react-i18next';
import { Content, InfoBox } from 'components/shared';
import Editor from 'components/shared/Editor';
import useSupport from 'hooks/Support/helpdesk';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AdditionalCaptureDetails, EnquiryType } from 'hooks/Support/requests';
import moment from 'moment';

const Columns = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 992px) {
    display: grid;
    grid-template-columns: 1fr 400px;
  }
`;

type Props = {
  enquiryTypes?: EnquiryType[];
};

const Page = ({ enquiryTypes = [] }: Props) => {
  const { t } = useTranslation('supportQuery');
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const {
    supportLoading,
    raise,
    supportQuery,
    supportSubmitting,
    noLongerLoading,
    prepareQuery,
  } = useSupport();
  const [extraCapture, setAdditionalCaptureDetails] =
    useState<AdditionalCaptureDetails>();

  const options = enquiryTypes.map((et) => ({
    label: et.contactReasonName,
    value: et.contactReasonID.toString(),
  }));

  useEffect(() => {
    prepareQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (supportQuery !== undefined) noLongerLoading();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supportQuery, extraCapture]);

  const onFinish = async (values: any) => {
    const results = await form.validateFields();
    results.contactReasonID = results.enquirytype;
    var additionalCapturedDetails: any = {};

    if (extraCapture !== undefined) {
      extraCapture.captureDetails?.forEach((capture) => {
        additionalCapturedDetails[capture.attributeName] = form.getFieldValue(
          capture.attributeName
        );
      });
      let jsonData = JSON.stringify(additionalCapturedDetails);
      results.additionalCapturedDetails = jsonData;
    }

    raise(results)
      .then(() => {
        message.success(t(`query.add.success`));
        navigate(`/support/requests/list`);
      })
      .catch((error) => {
        const errorMsg = error?.response?.data?.message;
        message.error(
          t(`query.add.error`, {
            msg: errorMsg ? t(errorMsg) : undefined,
          })
        );
      });
  };

  const setSelectedEnquiryType = async (selectedValue: number) => {
    enquiryTypes.forEach((enquiryType) => {
      if (enquiryType.contactReasonID == selectedValue) {
        setAdditionalCaptureDetails(enquiryType.additionalCaptureDetails);
      }
    });
  };

  return (
    <Content>
      <h1>{t('supportquery')}</h1>
      <Card loading={supportLoading}>
        <Columns>
          <Form
            initialValues={supportQuery}
            name="basic"
            form={form}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 12 }}
            autoComplete="off"
            onFinish={onFinish}
          >
            <Form.Item
              label={t('contactName')}
              name="contactName"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={t('contactPhone')}
              name="contactPhone"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={t('emailAddress')}
              name="emailAddress"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="enquirytype"
              label={t('enquirytype')}
              rules={[{ required: true }]}
            >
              <Select
                placeholder={t('selectEnquiryType')}
                options={options as any[]}
                onChange={(value) => {
                  setSelectedEnquiryType(value);
                }}
              />
            </Form.Item>

            <Form.Item
              label={t('queryMessage')}
              name="queryMessage"
              style={{ height: 'auto' }}
              rules={[{ required: true }]}
            >
              <Editor hideTracking={true} />
            </Form.Item>

            {extraCapture !== undefined &&
            extraCapture.captureDetails!.length > 0 ? (
              extraCapture.captureDetails!.map((element, index) => {
                switch (element.dataType) {
                  case 'datetime':
                    return (
                      <Form.Item
                        label={t(element.attributeName)}
                        name={element.attributeName}
                        rules={[{ required: element.required }]}
                        key={element.attributeName}
                      >
                        <DatePicker
                          allowClear={false}
                          format="DD-MM-yyyy"
                          placeholder={t(element.attributeName)}
                          style={{ marginRight: '5px' }}
                          disabledDate={(date) => date > moment()}
                        />
                      </Form.Item>
                    );
                  case 'decimal':
                    return (
                      <Form.Item
                        label={t(element.attributeName)}
                        name={element.attributeName}
                        key={element.attributeName}
                        rules={[
                          {
                            required: element.required,
                            message: t('invalidDecimalValue'),
                            pattern: new RegExp(
                              /^\s*(\d+(\.\d{1,2})?|\.\d{1,2})\s*$/
                            ),
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    );
                  default:
                    return (
                      <Form.Item
                        label={t(element.attributeName)}
                        name={element.attributeName}
                        key={element.attributeName}
                        rules={[{ required: element.required }]}
                      >
                        <Input />
                      </Form.Item>
                    );
                }
              })
            ) : (
              <></>
            )}

            <Form.Item wrapperCol={{ offset: 8 }}>
              <Button
                type="primary"
                htmlType="submit"
                loading={supportSubmitting}
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
          <InfoBox>
            <p>{t('welcomeMessage')}</p>
            <p>
              <Link to={`/support/faqs`}>
                <Button
                  icon={
                    <QA
                      style={{
                        marginLeft: -2,
                        marginBottom: -2,
                        marginRight: 5,
                        fontSize: '1.2em',
                      }}
                    />
                  }
                >
                  {t('faqs')}
                </Button>
              </Link>
            </p>
          </InfoBox>
        </Columns>
      </Card>
    </Content>
  );
};

export default Page;
