import CardView from './CardView';
import TableView from './TableView';
import { Empty, Row } from 'components/antd';
import ViewType from 'constants/viewType';
import { Banner } from 'hooks/Resources/banners';
import { useEffect, useState } from 'react';

type Props = {
  banners: Banner[];
  isAdmin: boolean;
  loading: boolean;
  viewType: ViewType;
};

function BannerListing({
  banners = [],
  isAdmin = false,
  loading = false,
  viewType = ViewType.Card,
}: Props) {
  const [sortedBanners, setSortedBanners] = useState<Banner[]>([]);

  useEffect(() => {
    banners.sort((a, b) => (a.dimensionX > b.dimensionX ? 1 : -1));
    setSortedBanners(banners);
  }, [banners]);

  return (
    <Row justify="center" style={{ width: '100%' }}>
      {!loading && sortedBanners.length === 0 && (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}></Empty>
      )}
      {!loading &&
        viewType === ViewType.Card &&
        sortedBanners.map((banner) => (
          <div
            key={banner.bannerID}
            style={{
              margin: '8px',
              width: 'auto',
            }}
          >
            {viewType === ViewType.Card && (
              <CardView banner={banner} isAdmin={isAdmin} />
            )}
          </div>
        ))}
      {!loading && viewType === ViewType.Row && (
        <TableView banners={sortedBanners} isAdmin={isAdmin} />
      )}
    </Row>
  );
}

export default BannerListing;
