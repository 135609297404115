import styled from 'styled-components';

const Title = styled.div`
  font-weight: normal;
`;

type Props = {
  affiliateName: string;
};

const component = ({ affiliateName }: Props) => {
  if (!affiliateName) return null;
  return (
    <Title>
      Logged in as <strong>{affiliateName}</strong>
    </Title>
  );
};

export default component;
