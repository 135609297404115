import { useEffect, useState } from 'react';
import api from 'libs/api';
import Pagination from 'constants/pagination';

export type AdminRecord = {
  affiliateId: string;
  affiliateName: string;
  affiliateExternalIdentifier: string;
  invoiceId: string;
  totalExcVat: number;
  totalIncVat: number;
  bankName: string;
  accountName: string;
  accountNumber: string;
  bankAccountType: string;
  iban: string;
  swiftCode: string;
  sortCode: string;
};

const useDashboard = () => {
  const [pagination, setPagination] = useState<any>(Pagination);
  const [dashboardLoading, setIsLoading] = useState(false);
  const [bankingRecords, setBankingDashboard] = useState<AdminRecord[]>([]);

  const noLongerLoading = async () => {
    setIsLoading(false);
  };

  const fetchBankingDashboard = async (props?: any) => {
    setIsLoading(true);

    try {
      const params = {
        offset: props?.current || 1,
        limit: props?.pageSize || Pagination.pageSize,
        month: props?.month || undefined,
        year: props?.year || undefined,
        affiliateId: props?.affiliateId || undefined,
      };

      const result: any = await api.get(`/finance/bankingDashboard`, {
        params,
      });

      setBankingDashboard(result.data.data);

      const newPagination = {
        current: result.data.offset,
        pageSize: result.data.limit,
        total: result.data.totalItems,
      };

      setPagination(newPagination);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    // Variables
    bankingRecords,
    dashboardLoading,
    pagination,
    //categories,
    loadBankingDashboard: fetchBankingDashboard,
    noLongerLoading,
  };
};

export default useDashboard;
