import styled from 'styled-components';

const PodiumContainer = styled.div`
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
`;

const Podium = styled.div`
  border-radius: 3px 3px 0px 0px;
  width: 250px;
  height: 133px;
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;

  &:nth-child(1) {
    height: 100px;
  }
  &:nth-child(2) {
    height: 134px;
  }
  &:nth-child(3) {
    height: 202px;
  }
  &:nth-child(4) {
    height: 168px;
  }
  &:nth-child(5) {
    height: 66px;
  }
`;

const Page = () => {
  return (
    <PodiumContainer>
      {Array.from({ length: 5 }, (_, i) => (
        <Podium className="brand-background" key={i}></Podium>
      ))}
    </PodiumContainer>
  );
};

export default Page;
