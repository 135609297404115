import { message } from 'antd';
import {
  Button,
  Card,
  DatePicker,
  Form,
  Row,
  Space,
  Table,
  Select,
} from 'components/antd';
import { Check, Cross } from 'components/icons';
import { Content } from 'components/shared';
import useBankChangeRequestApprove from 'hooks/bankChanges';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

export enum BankApprovalStatus {
  Pending = 1,
  Approved = 2,
  Declined = 3,
}

const Page = () => {
  const { t } = useTranslation('admin.affiliates');
  const { t: c } = useTranslation('common');
  const { isLoading, pagination, requests, load, approve, decline } =
    useBankChangeRequestApprove();
  const [state, setState] = useState<any>({
    status: 1,
  });

  const loadData = (s?: any) => {
    load(s || state).catch(() => {
      message.error('There was an error loading the bank change requests');
    });
  };

  const onApprove = (affiliateID: string, id: string) => {
    approve(affiliateID, id)
      .then(() => {
        message.success('Bank Details approved');
      })
      .catch(() => {
        message.error('Error approving bank details');
      })
      .finally(loadData);
  };

  const onDecline = (affiliateID: string, id: string) => {
    decline(affiliateID, id)
      .then(() => {
        message.success('Bank Details declined');
      })
      .catch(() => {
        message.error('Error declining bank details');
      })
      .finally(loadData);
  };

  const getColumns = (t: Function, lt: Function) => [
    // {
    //   title: t('changeId'),
    //   width: 100,
    //   dataIndex: 'id',
    //   key: 'id',
    //   fixed: 'left',
    // },
    {
      title: t('affiliateName'),
      width: 130,
      dataIndex: 'affiliateName',
      key: 'affiliateName',
      fixed: 'left',
    },
    {
      title: t('bankName'),
      width: 130,
      dataIndex: 'bankName',
      key: 'bankName',
      fixed: 'left',
    },
    {
      title: t('accountName'),
      dataIndex: 'accountName',
      key: 'accountName',
      fixed: 'left',
    },

    {
      title: t('accountNumber'),
      dataIndex: 'accountNumber',
      key: 'accountNumber',
      width: 150,
    },
    {
      title: t('sortCode'),
      dataIndex: 'sortCode',
      key: 'sortCode',
      width: 150,
    },
    {
      title: t('swiftCode'),
      dataIndex: 'swiftCode',
      key: 'swiftCode',
    },
    {
      title: t('ibanCode'),
      dataIndex: 'iban',
      key: 'iban',
    },
    {
      title: lt('changeDate'),
      dataIndex: '',
      key: '',
      render: (af: any) =>
        af.lastModified
          ? c('date', { value: new Date(af.lastModified) })
          : c('date', { value: new Date(af.createdOn) }),
    },
    {
      title: t('action'),
      dataIndex: '',
      width: 130,
      align: 'right',
      render: (af: any) => (
        <Space>
          {/* <Link to={`${af.affiliateId}/view/payment`}>
            <Button
              icon={<Search style={{ marginLeft: -2, marginBottom: -2 }} />}
            />
          </Link> */}
          <Button
            onClick={() => onApprove(af.affiliateId, af.id)}
            disabled={state.status !== BankApprovalStatus.Pending}
            icon={<Check style={{ marginLeft: -2, marginBottom: -2 }} />}
          />
          <Button
            onClick={() => onDecline(af.affiliateId, af.id)}
            disabled={state.status !== BankApprovalStatus.Pending}
            icon={
              <Cross
                style={{ fontSize: '0.8em', marginLeft: -1, marginBottom: -1 }}
              />
            }
          />
        </Space>
      ),
    },
  ];

  const columns = getColumns(c, t) as any;

  useEffect(() => {
    loadData(state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    setState({ ...state, ...pagination });
    loadData({ ...state, ...pagination });
  };

  const onFinish = (values: any) => {
    setState({ ...state, ...values, current: 1 });
    loadData({ ...state, ...values, current: 1 });
  };

  return (
    <Content>
      <h1>{t('affiliatesBank')}</h1>

      <Row justify="center" style={{ paddingTop: '2em', paddingBottom: '2em' }}>
        <Form layout="inline" initialValues={state}>
          <Form.Item name="date">
            <DatePicker.RangePicker
              allowClear
              onChange={(results: any) =>
                onFinish({
                  ...state,
                  fromDate: results && results[0],
                  toDate: results && results[1],
                })
              }
            />
          </Form.Item>

          <Form.Item name="status">
            <Select
              style={{ minWidth: 140 }}
              onChange={(value) => onFinish({ ...state, status: value })}
            >
              <Option value={BankApprovalStatus.Pending}>Pending</Option>
              <Option value={BankApprovalStatus.Approved}>Approved</Option>
              <Option value={BankApprovalStatus.Declined}>Declined</Option>
            </Select>
          </Form.Item>
        </Form>
      </Row>

      <Card>
        <Table
          loading={isLoading}
          columns={columns}
          rowKey="id"
          dataSource={requests}
          onChange={handleTableChange}
          pagination={pagination}
        />
      </Card>
    </Content>
  );
};

export default Page;
