import { useState } from 'react';
import api from 'libs/api';
import Pagination from 'constants/pagination';
import downloadFile from 'libs/downloadFile';

export type PaymentRequest = {
  id: string;
  affiliateName?: string;
  affiliateExternalIdentifier?: string;
  purchaseOrderId?: string;
  notes?: string;
  amount: number;
  dateCreated: string;
  lastModifiedDate: string;
  status?: string;
  invoiceReference?: string;
  invoiceFilePath?: string;
  orderReference?: string;
  paymentRequestType: string;
  paymentRequestIdentifier: string;
};

export type PaymentRequestType = {
  requestTypeValue: string;
  requestTypeName: string;
};

export type PaymentStatus = {
  statusTypeValue: string;
  statusTypeName: string;
};

export type UpdateRequest = {
  orderReference?: string;
  purchaseOrderId?: string;
  status: number;
};

export type UpdateRequestNotes = {
  notes?: string;
};

const usePaymentRequests = () => {
  const [paymentRequests, setPaymentRequests] = useState<PaymentRequest[]>([]);
  const [requestsLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState<any>(Pagination);
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest>();
  const [requestSubmitting, setIsSubmitting] = useState(false);
  const [uploadSubmitting, setIsUploading] = useState(false);
  const [requestTypes, setRequestTypes] = useState<PaymentRequestType[]>([
    { requestTypeValue: 'BonusPayment', requestTypeName: 'BonusPayment' },
    {
      requestTypeValue: 'Commission',
      requestTypeName: 'CommissionPayment',
    },
    { requestTypeValue: 'Tenancy', requestTypeName: 'TenancyPayment' },
    { requestTypeValue: 'CreditNote', requestTypeName: 'CreditNote' },
  ]);
  const [requestStatuses, setRequestStatuses] = useState<PaymentStatus[]>([]);

  const noLongerLoading = async () => {
    setIsLoading(false);
  };

  const noLongerSubmitting = async () => {
    setIsSubmitting(false);
  };

  const noLongerUploading = async () => {
    setIsUploading(false);
  };

  const fetchPaymentRequests = async (props?: any) => {
    setIsLoading(true);

    try {
      const params = {
        offset: props?.current || 1,
        limit: props?.pageSize || Pagination.pageSize,
        affiliateID: props?.affiliateID || undefined,
        fromDate: props?.rangeFrom || undefined,
        toDate: props?.rangeTo || undefined,
        paymentRequestStatus: props?.status || undefined,
        purchaseOrderID: props?.purchaseOrderID || undefined,
        paymentRequestType: props?.requestType || undefined,
        searchTerm: props?.searchTerm || undefined,
      };

      const result: any = await api.get(`/finance/paymentRequests`, { params });

      setPaymentRequests(result.data.data);

      const newPagination = {
        current: result.data.offset,
        pageSize: result.data.limit,
        total: result.data.totalItems,
      };

      setPagination(newPagination);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchPaymentRequest = async (id: string) => {
    setIsLoading(true);
    try {
      const result: any = await api.get(`finance/paymentrequests/${id}`);

      setPaymentRequest(result.data);
    } finally {
      setIsLoading(false);
    }
  };

  const uploadInvoiceFile = async (id: string, fileData: FormData) => {
    // api call
    setIsUploading(true);
    return await api
      .post(`/finance/paymentrequests/${id}/invoice`, fileData)
      .then((response) => {
        setIsUploading(false);
        return response.data;
      });
  };

  const downloadInvoiceFile = async (id: string, fileReference: string) => {
    return downloadFile(
      `/finance/paymentrequests/${id}/invoice`,
      fileReference,
      'pdf'
    );
  };

  const updatePaymentRequestDetails = async (
    id: string,
    item: UpdateRequest
  ) => {
    setIsSubmitting(true);
    return await api
      .put(`/finance/paymentrequests/${id}/details`, item)
      .then((response) => {
        setIsSubmitting(false);
        return response.data;
      });
  };

  const updatePaymentRequestNotes = async (
    id: string,
    item: UpdateRequestNotes
  ) => {
    setIsSubmitting(true);
    return await api
      .put(`/finance/paymentrequests/${id}/notes`, item)
      .then((response) => {
        setIsSubmitting(false);
        return response.data;
      });
  };

  const exportList = (props: any) => {
    let downloadLocation = `finance/paymentRequests/export?offset=${
      props?.current || 1
    }&limit=${props?.pageSize || Pagination.pageSize}`;

    downloadLocation += props?.affiliateID
      ? `&affiliateID=${props?.affiliateID}`
      : ``;

    downloadLocation += props?.rangeFrom ? `&fromDate=${props?.rangeFrom}` : ``;

    downloadLocation += props?.toDate ? `&toDate=${props?.toDate}` : ``;

    downloadLocation += props?.status
      ? `&paymentRequestStatus=${props?.status}`
      : ``;

    downloadLocation += props?.purchaseOrderID
      ? `&purchaseOrderID=${props?.purchaseOrderID}`
      : ``;

    downloadLocation += props?.requestType
      ? `&paymentRequestType=${props?.requestType}`
      : ``;

    downloadFile(downloadLocation);
  };

  const setStatusesForRequestStatus = async (status: string) => {
    switch (status) {
      case 'NotInvoiced':
        let returnStatusesNI: PaymentStatus[] = [
          { statusTypeValue: 'NotInvoiced', statusTypeName: 'NotInvoiced' },
        ];
        setRequestStatuses(returnStatusesNI);
        return;
      case 'Invoiced':
        let returnStatusesIN: PaymentStatus[] = [
          { statusTypeValue: 'Invoiced', statusTypeName: 'Invoiced' },
          { statusTypeValue: 'PaidByTenant', statusTypeName: 'PaidByTenant' },
        ];
        setRequestStatuses(returnStatusesIN);
        return;
      case 'PaidByTenant':
        let returnStatusesPT: PaymentStatus[] = [
          { statusTypeValue: 'PaidByTenant', statusTypeName: 'PaidByTenant' },
          {
            statusTypeValue: 'PaidToAffiliate',
            statusTypeName: 'PaidToAffiliate',
          },
        ];
        setRequestStatuses(returnStatusesPT);
        return;
      case 'PaidToAffiliate':
        let returnStatusesPA: PaymentStatus[] = [
          {
            statusTypeValue: 'PaidToAffiliate',
            statusTypeName: 'PaidToAffiliate',
          },
        ];
        setRequestStatuses(returnStatusesPA);
        return;
      case 'Created':
        let returnStatusesC: PaymentStatus[] = [
          { statusTypeValue: 'Created', statusTypeName: 'Created' },
          { statusTypeValue: 'PaidToClient', statusTypeName: 'PaidToClient' },
        ];
        setRequestStatuses(returnStatusesC);
        return;
      case 'PaidToClient':
        let returnStatusesPC: PaymentStatus[] = [
          { statusTypeValue: 'PaidToClient', statusTypeName: 'PaidToClient' },
        ];
        setRequestStatuses(returnStatusesPC);
        return;
    }
  };

  return {
    // Variables
    requestsLoading,
    pagination,
    paymentRequests,
    paymentRequest,
    requestSubmitting,
    uploadSubmitting,
    requestTypes,
    requestStatuses,
    //categories,
    loadPaymentRequests: fetchPaymentRequests,
    loadRequest: fetchPaymentRequest,
    uploadInvoice: uploadInvoiceFile,
    downloadInvoice: downloadInvoiceFile,
    setStatuses: setStatusesForRequestStatus,
    updatePaymentRequest: updatePaymentRequestDetails,
    updatePaymentRequestNotes,
    noLongerLoading,
    noLongerSubmitting,
    noLongerUploading,
    exportList,
  };
};

export default usePaymentRequests;
