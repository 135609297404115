import { Route, Routes } from 'react-router';

import List from './list';
import Detail from './detail';
import { message } from 'components/antd';
import { useEffect } from 'react';
import useCommitionCodes from 'hooks/Commissions/code';
import { useTranslation } from 'react-i18next';

const Page = () => {
  const { t } = useTranslation('commissions');

  const { productGroups, loadProductGroups } = useCommitionCodes();

  useEffect(() => {
    loadProductGroups().catch(() => {
      message.error(t('productGroup.load.error'));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Routes>
      <Route
        path="/:id/edit"
        element={<Detail isEdit groups={productGroups} />}
      />
      <Route path="/add" element={<Detail groups={productGroups} />} />
      <Route index element={<List groups={productGroups} />} />
    </Routes>
  );
};

export default Page;
