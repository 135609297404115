type DefaultPagination = {
  current: number;
  pageSize: number;
  total: number;
  position: string[];
  size: string;
  showSizeChanger: boolean;
};

const defaultPaginationValues: DefaultPagination = {
  current: 1,
  pageSize: 10,
  total: 0,
  position: ['topCenter', 'bottomCenter'],
  size: 'default',
  showSizeChanger: true,
};

export default defaultPaginationValues;
