import { Button, Card, Form, Input, Select, message } from 'components/antd';
import styled from 'styled-components';
import { QA } from 'components/icons';
import { useTranslation } from 'react-i18next';
import { Content, InfoBox } from 'components/shared';
import Editor from 'components/shared/Editor';
import useSupport, { EnquiryType } from 'hooks/Support/helpdesk';
import { Link } from 'react-router-dom';

const Columns = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 992px) {
    display: grid;
    grid-template-columns: 1fr 400px;
  }
`;

type Props = {
  enquiryTypes?: EnquiryType[];
};

const Page = ({ enquiryTypes = [] }: Props) => {
  const { t } = useTranslation('supportQuery');
  const [form] = Form.useForm();
  const { supportLoading, raise, supportQuery } = useSupport();

  const options = enquiryTypes.map((et) => ({
    label: et.contactReasonName,
    value: et.contactReasonID.toString(),
  }));

  const onFinish = async (values: any) => {
    const results = await form.validateFields();
    results.contactReasonID = results.enquirytype;

    raise(results)
      .then(() => {
        message.success(t(`query.add.success`));
        form.resetFields();
      })
      .catch((error) => {
        const errorMsg = error?.response?.data?.message;
        message.error(
          t(`query.add.error`, {
            msg: errorMsg ? t(errorMsg) : undefined,
          })
        );
      });
  };

  return (
    <Content>
      <h1>{t('supportquery')}</h1>
      <Card>
        <Columns>
          <Form
            initialValues={supportQuery}
            name="basic"
            form={form}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 12 }}
            autoComplete="off"
            onFinish={onFinish}
          >
            <Form.Item
              label={t('contactName')}
              name="contactName"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={t('contactPhone')}
              name="contactPhone"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={t('emailAddress')}
              name="emailAddress"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="enquirytype"
              label={t('enquirytype')}
              rules={[{ required: true }]}
            >
              <Select
                placeholder={t('selectEnquiryType')}
                options={options as any[]}
              />
            </Form.Item>

            <Form.Item
              label={t('queryMessage')}
              name="queryMessage"
              style={{ height: 'auto' }}
              rules={[{ required: true }]}
            >
              <Editor hideTracking={true} />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8 }}>
              <Button type="primary" htmlType="submit" loading={supportLoading}>
                Submit
              </Button>
            </Form.Item>
          </Form>
          <InfoBox>
            <p>{t('welcomeMessage')}</p>
            <p>
              <Link to={`/support/faqs`}>
                <Button
                  icon={
                    <QA
                      style={{
                        marginLeft: -2,
                        marginBottom: -2,
                        marginRight: 5,
                        fontSize: '1.2em',
                      }}
                    />
                  }
                >
                  {t('faqs')}
                </Button>
              </Link>
            </p>
          </InfoBox>
        </Columns>
      </Card>
    </Content>
  );
};

export default Page;
