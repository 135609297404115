import { Button, Form, Input, Popover } from 'components/antd';
import { AffiliateGroup } from 'hooks/Account/affiliateGroups';
import { Select } from 'components/antd';

type Props = {
  children: JSX.Element | JSX.Element[];
  groups?: AffiliateGroup[];
  onFinish: (values: any) => void;
  disabled?: boolean;
};

const Component = ({ children, groups, onFinish, disabled = false }: Props) => {
  if (disabled) return <>{children}</>;
  return (
    <Popover
      placement="top"
      title="Confirm"
      trigger="click"
      content={
        <Form onFinish={onFinish}>
          {groups && groups.length > 0 && (
            <Form.Item name="groupId" rules={[{ required: true }]}>
              <Select
                autoFocus={!!groups}
                placeholder="Assign an Affiliate Group"
                style={{ width: 250 }}
                options={groups.map((g) => ({
                  label: g.name,
                  value: g.id || '',
                }))}
              />
            </Form.Item>
          )}
          <Form.Item name="reason">
            <Input.TextArea
              autoFocus={!groups}
              placeholder="Enter your reason"
            ></Input.TextArea>
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 8 }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      }
    >
      {children}
    </Popover>
  );
};

export default Component;
