import wizardEn from './wizard/en.json';
import newsEn from './news/en.json';
import accountsMeEn from './accounts/me/en.json';
import affiliatesEn from './admin/affiliates/en.json';
import reportingEn from './reporting/en.json';
import feedsAdminEn from './admin/feeds/en.json';
import productsAdminEn from './admin/product/en.json';
import feedsEn from './creatives/feeds/en.json';
import faqEn from './faq/en.json';
import invoicesEn from './financials/invoices/en.json';
import commissionsEn from './financials/commissions/en.json';
import dashboardEn from './dashboard/en.json';
import bannersEn from './creatives/banners/en.json';
import linksEn from './creatives/links/en.json';
import supportRequestsEn from './support/requests/en.json';
import supportQueryEn from './support/query/en.json';
import supportFAQsEn from './support/faqs/en.json';
import unauthorisedDevicesEn from './admin/devices/en.json';
import bannersMaintEn from './admin/banners/en.json';
import communicationsMaintEn from './admin/communications/en.json';
import agreementsMaintEN from './admin/agreements/en.json';
import productHighlightingEn from './admin/product/highlighting/en.json';
import platformAgreementsMaintEN from './admin/platformagreements/en.json';
import supportPlatformAgreementEn from './support/platformagreement/en.json';
import supportTermsEn from './support/terms/en.json';
import dataReviewEn from './datareview/en.json';
import platformAgreementEn from './platformagreement/en.json';
import financialAdminPaymentsEn from './admin/financial/payments/en.json';
import financialAdminPaymentRequestsEn from './admin/financial/paymentRequests/en.json';
import financialAdminPurchaseOrdersEn from './admin/financial/purchaseOrders/en.json';
import paymentFeesEn from './admin/financial/paymentFees/en.json';
import financialBankingDashboardEn from './admin/financial/bankingDashboard/en.json';

const defaultLanguage = 'en';

const locales = {
  en: {
    common: {
      test: 'test',
      date: '{{value, }}',
      dateTime: '{{value, }}',
      number: '{{value, }}',
      hi: 'Hi, {{name}}',
      en: 'En',
      cz: 'Cz',
      default: 'Default',
      blue: 'Blue',
      therange: 'The Range',
      dark: 'Dark',
      three: 'Three',
      themes: 'Themes',
      format: 'Format',
      language: 'Language',
      feeds: 'Feeds',
      sales: 'Sales',
      readMore: 'Read More',
      noImage: 'No Image Available',
      resources: 'Resources',
      reporting: 'Reporting',
      accounts: 'Accounts',
      communication: 'Communication',
      content: 'Content',
      support: 'Support',
      system: 'System',
      admin: 'Admin',
      affiliates: 'Affiliates',
      approvals: 'Approvals',
      bankDetails: 'Bank',
      categories: 'Categories',
      logout: 'Log Out',
      tenant: 'Select Tenant',
      news: 'News',
      product: 'Product',
      producthighlighting: 'Product Highlighting',
      faq: 'FAQ',
      invoices: 'Invoices',
      next: 'Next',
      previous: 'Previous',
      preview: 'Preview',
      view: 'View',
      done: 'Done',
      edit: 'Edit',
      remove: 'Remove',
      // eslint-disable-next-line no-template-curly-in-string
      requiredMessage: "'${name}' is required!",
      yes: 'Yes',
      no: 'No',
      status: 'Status',
      name: 'Name',
      id: 'ID',
      company: 'Company',
      email: 'Email',
      groups: 'Groups',
      risk: 'Risk',
      action: 'Action',
      changeId: 'Change ID',
      affiliateId: 'Affiliate ID',
      accountName: 'Account Name',
      bankName: 'Bank Name',
      accountNumber: 'Account',
      archived: 'Archived',
      showArchived: 'Show Archived',
      swiftCode: 'Swift Code',
      ibanCode: 'IBAN Code',
      sortCode: 'Sort Code',
      pending: 'Pending',
      approved: 'Approved',
      declined: 'Declined',
      select: 'Select',
      selected: 'Selected',
      applications: 'Applications',
      paymentChangeRequests: 'Payment Changes',
      set: 'Set',
      reset: 'Reset',
      active: 'Active',
      closed: 'Closed',
      close: 'Close',
      description: 'Description',
      add: 'Add',
      createdDate: 'Created Date',
      lastUpdated: 'Last Updated',
      expires: 'Expires',
      isActive: 'Active',
      group: 'Group',
      dashboard: 'Dashboard',
      creatives: 'Creatives',
      banners: 'Banners',
      links: 'Link Generation',
      supportquery: 'Raise Query',
      supportrequests: 'Requests',
      faqs: 'FAQs',
      supportPlatformAgreement: 'Platform Agreement',
      supportTerms: 'Terms & Conditions',
      others: 'Miscellaneous',
      financials: 'Financials',
      topsellers: 'Top Sellers',
      commissions: 'Commissions',
      devices: 'Unauthorised Devices',
      bannergroups: 'Banner Groups',
      communications: 'Communications',
      bulkemails: 'Bulk Emails',
      emailtemplates: 'Email Templates',
      code: 'Code',
      productGroup: 'Product Group',
      standardCommissions: 'Standard Commissions',
      isTieredCommission: 'Tiered Commission',
      commissionIncrease: 'Commission Increase',
      totalMappings: 'Total Mappings',
      cancel: 'Cancel',
      loggingIn: 'Logging in',
      loggingOut: 'Logging out',
      pleaseWaitDotDotDot: 'Please wait...',
      downloadingStyles: 'Downloading styles',
      impersonatingUser: 'Impersonating user',
      impersonatingUserDetails: 'Downloading user info...',
      downloadingUserInfo: 'Downloading User Info',
      insufficientPermissions: 'You have insufficient permissions',
      insufficientFeatures: "You don't have this feature enabled",
      pageNotFound: 'pageNotFound',
      upload: 'Upload',
      uploading: 'Uploading',
      uploadErrorImage: 'Failed to upload image',
      filterbysize: 'Filter by size...',
      submit: 'Submit',
      amount: 'Amount',
      setupCommissionTier: 'Tiered Commission',
      effectiveFrom: 'Effective From',
      effectiveTo: 'Effective To',
      products: 'Products',
      all: 'All',
      none: 'None',
      period: 'Period',
      threshold: 'Threshold',
      backdate: 'Backdate',
      increaseAmount: 'Increase Amount',
      allProducts: 'All Products',
      allAffiliates: 'All Affiliates',
      affiliateEmail: 'Affiliate Email',
      affiliateName: 'Affiliate Name',
      affiliateGroup: 'Affiliate Group',
      statusChanges: 'Status Changes',
      bulkSalesStatusChange: 'Bulk Sales Status Change',
      groupName: 'Group Name',
      commissionCode: 'Commission Code',
      invoiceLines: 'Invoice Lines',
      totalCommission: 'Total Commission',
      totalCommissionIncludingVAT: 'Total (inc. VAT)',
      reference: 'Reference',
      applicationDate: 'Application Date',
      cautionlevel: 'Caution Level',
      alternativeText: 'Alternative Text',
      dimensions: 'Dimensions',
      hidden: 'Hidden',
      image: 'Image',
      dateRange: 'Date Range',
      filter: 'Filter',
      billingCycles: 'Billing Cycles',
      aggreement: 'Terms & Conditions',
      confirmAgreement: 'I agree with the Terms and Conditions',
      confirmPlatformAgreement: 'I agree with the Platform Agreement',
      agreementConfirmationRequired:
        'You must accept the Terms and Conditions!',
      platformAgreementConfirmationRequired:
        'You must accept the Platform Agreement!',
      agreementLoadingError: 'There was an error loading terms and conditions',
      platformAgreementLoadingError:
        'There was an error loading the platform agreement',
      promoMethodsLoadingError:
        'There was an error loading promotional methods',
      signInAs: 'Sign in as',
      triggerDate: 'Trigger Date',
      invoiceCount: 'Invoice Count',
      platformagreement: 'Platform Agreement',
      datareviewstatus: 'Data Review Status',
      indate: 'In Date',
      expiring: 'Expiring Soon',
      expired: 'Expired',
      triggerDate: 'Trigger Date',
      invoiceCount: 'Invoice Count',
      bonusPayments: 'Bonus Payments',
      commissionPayments: 'Commission Payments',
      tenancyPayments: 'Tenancy Payments',
      paymentRequests: 'Payment Requests',
      purchaseOrders: 'Purchase Orders',
      creditNotePayments: 'Credit Notes',
      fees: 'Platform Fees',
      bankingDashboard: 'Banking Dashboard',
    },
    feeds: feedsAdminEn,
    reporting: reportingEn,
    wizard: wizardEn,
    news: newsEn,
    'accounts.me': accountsMeEn,
    'admin.affiliates': affiliatesEn,
    faqs: faqEn,
    products: productsAdminEn,
    invoices: invoicesEn,
    dashboard: dashboardEn,
    banners: bannersEn,
    links: linksEn,
    supportQuery: supportQueryEn,
    supportRequests: supportRequestsEn,
    supportFAQs: supportFAQsEn,
    commissions: commissionsEn,
    feedsBuild: feedsEn,
    devicesMaint: unauthorisedDevicesEn,
    bannersMaint: bannersMaintEn,
    communicationsMaint: communicationsMaintEn,
    agreementsMaint: agreementsMaintEN,
    productHighlighting: productHighlightingEn,
    platformAgreementsMaint: platformAgreementsMaintEN,
    supportPlatformAgreement: supportPlatformAgreementEn,
    supportTerms: supportTermsEn,
    dataReview: dataReviewEn,
    platformAgreement: platformAgreementEn,
    financialAdminPayments: financialAdminPaymentsEn,
    financialAdminPaymentRequests: financialAdminPaymentRequestsEn,
    financialAdminPurchaseOrders: financialAdminPurchaseOrdersEn,
    paymentFees: paymentFeesEn,
    financialBankingDashboard: financialBankingDashboardEn,
  },
  cs: {
    common: {
      test: 'testovací',
      date: '{{value, }}',
      number: '{{value, }}',
      hi: 'Zdařbůh {{name}}',
      en: 'En',
      cz: 'Cz',
      default: 'Výchozí',
      blue: 'Modrá',
      dark: 'Tmavá',
      three: 'Three',
      themes: 'Témata',
      format: 'Formát',
      language: 'Jazyk',
      feeds: 'Krmiva',
      sales: 'Prodeje',
      resources: 'Zdroje',
      reporting: 'Hlášení',
      accounts: 'Úĝty',
      communication: 'Komunikace',
      content: 'Obsah',
      support: 'Podpora',
      system: 'Systém',
      admin: 'Správce',
      logout: 'Odhlásit se',
      news: 'Zprávy',
    },
  },
};
const namespaces = Object.keys(locales[defaultLanguage]);
const defaultNamespace = namespaces[0];

export const lng = defaultLanguage;
export const fallbackLng = defaultLanguage;
export const resources = locales;
export const ns = namespaces;
export const defaultNS = defaultNamespace;
