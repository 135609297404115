import { PrivatePage } from 'components/shared';
import { Navigate, Route, Routes } from 'react-router';

import BulkEmails from './BulkEmails/index';
import EmailTemplates from './EmailTemplates/index';

import { Permissions } from 'constants/permissions';

const Page = () => {
  return (
    <Routes>
      <Route
        path="/bulkemails/*"
        element={
          <PrivatePage
            requirement={Permissions['Communications.BulkEmails.Admin']}
            component={BulkEmails}
          />
        }
      />
      <Route
        path="/emailtemplates/*"
        element={
          <PrivatePage
            requirement={Permissions['Communications.EmailTemplates.Admin']}
            component={EmailTemplates}
          />
        }
      />

      <Route index element={<Navigate to="bulkemails" />} />
    </Routes>
  );
};

export default Page;
