// @ts-nocheck
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Tooltip } from 'components/antd';

const StyledTenantLogo = styled.div`
  width: 50px;
  height: 50px;
  background: #000;
  background-image: ${(props) => `url(${props.backgroundImage})`};
  background-size: cover;
  border-radius: 100px;
  border-color: #000;
  border-style: solid;
  border-width: 1px;
  margin-right: 10px;
`;
type Props = {
  tenantLogo: string;
  tenantLink: string;
  tenantName: string;
};

const TenantLogo = ({
  tenantLogo,
  tenantLink,
  tenantName,
  ...props
}: Props) => {
  return (
    <>
      <Tooltip title={tenantName}>
        <Link to={{ pathname: '//' + tenantLink }}>
          <StyledTenantLogo backgroundImage={tenantLogo} />
        </Link>
      </Tooltip>
    </>
  );
};

export default TenantLogo;
