/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Card,
  Form,
  Input,
  message,
  Row,
  Space,
  Switch,
  Table,
} from 'components/antd';
import {
  Create,
  Cross,
  Edit,
  Save,
  SwitchIcon,
  UpArrow,
  DownArrow,
} from 'components/icons';
import { Content, TitleRow } from 'components/shared';
import { IUseFAQCategories, FAQCategory } from 'hooks/Support/faqCategories';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useUser from 'hooks/User';
import { Permissions } from 'constants/permissions';

type Props = {
  useFAQCategories: IUseFAQCategories;
};

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}: any) => {
  const inputNode = inputType === 'switch' ? <Switch /> : <Input />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          valuePropName={inputType === 'switch' ? 'checked' : 'value'}
          initialValue={inputType === 'switch' ? true : ''}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const Page = ({ useFAQCategories }: Props) => {
  const { t } = useTranslation('supportFAQs');
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState<any>('');
  const [includeDeactivated, setIncludeDeactivated] = useState(false);
  const [data, setData] = useState<FAQCategory[]>([]);
  const { hasPermission } = useUser();

  const showDeactivated = hasPermission(
    Permissions['Content.FAQCategories.Extra']
  );
  const allowAdd = hasPermission(Permissions['Content.FAQCategories.Admin']);
  const allowEdit = hasPermission(Permissions['Content.FAQCategories.Admin']);
  const allowToggle = hasPermission(Permissions['Content.FAQCategories.Admin']);
  const allowResequence = hasPermission(
    Permissions['Content.FAQCategories.Admin']
  );

  useEffect(() => {
    useFAQCategories.load(includeDeactivated).catch((error) => {
      useFAQCategories.noLongerLoading();
      const errorMsg = error?.response?.data?.message;
      message.error(
        t('FAQCategories.retrieval.error', {
          msg: errorMsg ? t(errorMsg) : undefined,
        })
      );
    });
  }, [includeDeactivated]);

  useEffect(() => {
    setData(useFAQCategories.categories);
  }, [useFAQCategories.categories]);

  const isEditing = (record: FAQCategory) =>
    record.faqCategoryID === editingKey;

  const edit = (record: FAQCategory) => {
    form.setFieldsValue({
      ...record,
    });
    setEditingKey(record.faqCategoryID);
  };

  const cancel = () => {
    setEditingKey('');
    form.resetFields();
    useFAQCategories.load(includeDeactivated).catch((error) => {
      const errorMsg = error?.response?.data?.message;
      message.error(
        t('faqcategory.loading.error', {
          msg: errorMsg ? t(errorMsg) : undefined,
        })
      );
    });
  };

  const moveUp = (record: FAQCategory) => {
    if (!record.faqCategoryID) return;

    useFAQCategories.resequence(record, -1).finally(() => {
      useFAQCategories.load(includeDeactivated).catch((error) => {
        const errorMsg = error?.response?.data?.message;
        message.error(
          t('faqcategory.loading.error', {
            msg: errorMsg ? t(errorMsg) : undefined,
          })
        );
      });
    });
    return;
  };

  const moveDown = (record: FAQCategory) => {
    if (!record.faqCategoryID) return;

    useFAQCategories.resequence(record, 1).finally(() => {
      useFAQCategories.load(includeDeactivated).catch((error) => {
        const errorMsg = error?.response?.data?.message;
        message.error(
          t('faqcategory.loading.error', {
            msg: errorMsg ? t(errorMsg) : undefined,
          })
        );
      });
    });
    return;
  };

  const toggle = (record: FAQCategory) => {
    if (!record.faqCategoryID) return;
    useFAQCategories
      .activate(record.faqCategoryID, !record.isActive)
      .then(() => {
        message.success(t('FAQCategories.activationToggle.success'));
        useFAQCategories.load(includeDeactivated).catch((error) => {
          const errorMsg = error?.response?.data?.message;
          message.error(
            t('faqcategory.loading.error', {
              msg: errorMsg ? t(errorMsg) : undefined,
            })
          );
        });
      })
      .catch((error) => {
        useFAQCategories.noLongerLoading();
        const errorMsg = error?.response?.data?.message;
        message.error(
          t('FAQCategories.activationToggle.error', {
            msg: errorMsg ? t(errorMsg) : undefined,
          })
        );
      });
  };

  const save = async (key: any) => {
    let row;
    try {
      row = await form.validateFields();
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
      return;
    }

    const newData = [...data];
    const index =
      key > -1
        ? newData.findIndex((item: any) => key === item.faqCategoryID)
        : -1;
    const isEdit = index > -1;
    const localePath = `FAQCategories.${isEdit ? 'edit' : 'save'}`;

    (isEdit
      ? useFAQCategories.edit({ ...newData[index], ...row })
      : useFAQCategories.add(row)
    )
      .then(() => {
        message.success(t(`${localePath}.success`));
      })
      .catch((error) => {
        useFAQCategories.noLongerLoading();
        const errorMsg = error?.response?.data?.message;
        message.error(
          t(`${localePath}.error`, {
            msg: errorMsg ? t(errorMsg) : undefined,
          })
        );
      })
      .finally(() => {
        form.resetFields();
        setEditingKey('');
        useFAQCategories.load(includeDeactivated).catch((error) => {
          const errorMsg = error?.response?.data?.message;
          message.error(
            t('faqcategory.loading.error', {
              msg: errorMsg ? t(errorMsg) : undefined,
            })
          );
        });
      });
  };

  const columns = [
    {
      title: t('common:isActive'),
      dataIndex: 'isActive',
      width: 100,
      editable: true,
      render: (isActive: boolean) => <Switch disabled checked={isActive} />,
    },
    {
      title: t('common:name'),
      dataIndex: 'faqCategoryName',
      width: '55%',
      editable: true,
    },
    {
      title: t('faqsCount'),
      dataIndex: 'totalFAQCount',
    },
    {
      title: t('faqsActiveCount'),
      dataIndex: 'totalFAQActiveCount',
    },
    {
      title: t('faqsInactiveCount'),
      dataIndex: 'totalFAQInactiveCount',
    },
    {
      title: t('common:action'),
      align: 'right' as 'right',
      dataIndex: 'action',
      width: 200,
      render: (_: any, record: any, index: number) => {
        const editable = isEditing(record);
        return editable ? (
          <Space>
            <Button
              icon={
                <Save
                  style={{
                    marginLeft: -3,
                    marginBottom: -3,
                    fontSize: '1.1em',
                  }}
                />
              }
              onClick={() => save(record.faqCategoryID)}
            />

            <Button
              onClick={cancel}
              icon={
                <Cross
                  style={{
                    marginLeft: -2,
                    marginBottom: -2,
                    fontSize: '0.9em',
                  }}
                />
              }
            />
          </Space>
        ) : (
          <Space>
            <Button
              disabled={
                !allowResequence ||
                editingKey !== '' ||
                index === 0 ||
                index > data.length - 1 ||
                record.isActive === false
              }
              onClick={() => moveUp(record)}
              icon={
                <UpArrow
                  style={{
                    marginLeft: -2,
                    marginBottom: -2,
                  }}
                />
              }
            />
            <Button
              disabled={
                !allowResequence ||
                editingKey !== '' ||
                index < 0 ||
                index >= data.length - 1 ||
                record.isActive === false ||
                (index <= data.length - 2 && data[index + 1].isActive === false)
              }
              onClick={() => moveDown(record)}
              icon={
                <DownArrow
                  style={{
                    marginLeft: -2,
                    marginBottom: -2,
                  }}
                />
              }
            />
            <Button
              disabled={!allowEdit || editingKey !== ''}
              onClick={() => edit(record)}
              icon={
                <Edit
                  style={{
                    marginLeft: -2,
                    marginBottom: -2,
                  }}
                />
              }
            />
            <Button
              disabled={!allowToggle || editingKey !== ''}
              onClick={() => toggle(record)}
              icon={
                <SwitchIcon
                  style={{
                    marginLeft: -2,
                    marginBottom: -2,
                  }}
                />
              }
            />
          </Space>
        );
      },
    },
  ];
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record: any) => ({
        record,
        inputType: col.dataIndex === 'isActive' ? 'switch' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const addNew = () => {
    setData([
      {
        isActive: true,
        faqCategoryID: -1,
        faqCategoryName: '',
        sequenceNumber: 0,
        totalFAQCount: 0,
        totalFAQActiveCount: 0,
        totalFAQInactiveCount: 0,
      },
      ...data,
    ]);
    setEditingKey(-1);
  };

  const onChange = (values: any) => {
    setIncludeDeactivated(values.includeDeactivated);
  };

  return (
    <Content>
      <TitleRow>
        <h1>{t('faqCategoriesMaint')}</h1>
        <Button
          disabled={!allowAdd || editingKey !== ''}
          icon={
            <Create
              style={{ marginLeft: -2, marginBottom: -2, fontSize: '1.2em' }}
            />
          }
          onClick={addNew}
        >
          {t('common:add')}
        </Button>
      </TitleRow>

      <Row justify="center" style={{ paddingTop: '2em', paddingBottom: '2em' }}>
        <Form layout="inline" onValuesChange={onChange}>
          <Form.Item
            name="includeDeactivated"
            valuePropName="checked"
            initialValue={false}
            label={t('includeDeactivated')}
          >
            <Switch disabled={!showDeactivated} />
          </Form.Item>
        </Form>
      </Row>

      <Card>
        <Form form={form} component={false}>
          <Table
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            loading={useFAQCategories.categoriesLoading}
            rowKey="faqCategoryID"
            bordered
            dataSource={data}
            columns={mergedColumns}
            rowClassName="editable-row"
            pagination={false}
          />
        </Form>
      </Card>
    </Content>
  );
};

export default Page;
