import api from 'libs/api';
import { useState } from 'react';
import { FAQCategory } from './faqCategories';

export type FAQ = {
  faqid: number;
  sequenceNumber: number;
  question: string;
  answer: string;
  isActive?: boolean;
  faqCategories: FAQCategory[];
};

//export type NewsCategoryIDRequestType = {};

/*export type NewsFetchProps = {
  articleSearchText?: string;
  newsCategories?: { newsCategoryID: number }[];
  rangeFrom?: string;
  rangeTo?: string;
  dashboardOnly?: boolean;
  includeExpired?: boolean;
  itemsPerPage?: number;
  pageNumber: number;
};*/

const useFAQs = () => {
  const [faqsLoading, setIsLoading] = useState<boolean>(false);
  const [faqs, setFAQs] = useState<FAQ[]>([]);
  const [faq, setFAQ] = useState<FAQ>();

  const fetchFAQsForCategory = async (id: number) => {
    setIsLoading(true);
    try {
      const result: any = await api.get(`content/GetFAQs`, {
        params: { FAQCategoryID: [id] },
      });

      setFAQs(result.data);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchFAQ = async (id: number) => {
    setIsLoading(true);
    try {
      const result: any = await api.get(`content/GetFAQ`, {
        params: { FAQID: [id] },
      });

      result.data.categories = result.data.faqCategories.map((cat: any) =>
        cat.faqCategoryID.toString()
      );

      setFAQ(result.data);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchFAQs = async (props?: any) => {
    setIsLoading(true);
    try {
      const params = {
        IncludeInactiveFAQs: props?.includeInactive || undefined,
        FAQCategoryID: props?.categories || undefined,
      };

      const result: any = await api.get(`/content/GetFAQs`, { params });

      setFAQs(result.data);
    } finally {
      setIsLoading(false);
    }
  };

  const storeFAQ = (item: FAQ) => {
    // api call
    setIsLoading(true);
    return api.post('/content/StoreFAQ', item).then((response) => {
      //fetchFilterCategories();
      setIsLoading(false);
      return response.data;
    });
  };

  const activate = (id: number, value: boolean) => {
    setIsLoading(true);
    return api
      .post('/content/FAQActivation', {
        faqID: id,
        isActive: value,
      })
      .then((response) => {
        setIsLoading(false);
        return response.data;
      });
  };

  const resequence = async (
    faqChanged: FAQ,
    change: number,
    category: number
  ) => {
    const newSequence = {
      faqID: faqChanged.faqid,
      faqCategoryID: category,
      sequenceNumber: faqChanged.sequenceNumber + change,
      sequenceChange: change,
    };

    // api call
    setIsLoading(true);
    return api.post('/content/FAQResequence', newSequence).then((response) => {
      setIsLoading(false);
      return response.data;
    });
  };

  const noLongerLoading = async () => {
    setIsLoading(false);
  };

  return {
    // Variables
    faqsLoading,
    faqs,
    faq,
    // Methods
    load: fetchFAQs,
    loadForCategory: fetchFAQsForCategory,
    loadFAQ: fetchFAQ,
    store: storeFAQ,
    activate,
    noLongerLoading,
    resequence,
  };
};

export default useFAQs;
