import { Content, InfoBox, TitleRow } from 'components/shared';
import { Button, Card, Col, Form, Input, message, Row } from 'components/antd';
import useLinks from 'hooks/Resources/links';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const InfoOrderedList = styled.ol`
  padding-inline-start: 24px;
  padding-top: 12px;
`;

const Page = () => {
  const { TextArea } = Input;
  const { t } = useTranslation('links');
  const { generate } = useLinks();
  const [form] = Form.useForm();

  const onFinish = async (values: any) => {
    await form.validateFields();
    generate(values.linkUrl, values.linkRef)
      .then((result) => {
        form.setFieldsValue({ result: result.data.generatedLinkURL });
        navigator.clipboard.writeText(result.data.generatedLinkURL);
        message.success(t(`links.copied`));
      })
      .catch((error) => {
        message.error(t(`links.error`));
      });
  };

  return (
    <Content>
      <TitleRow>
        <h1>{t('links')}</h1>
      </TitleRow>
      <Card>
        <Row>
          <Col span={12}>
            <Form
              form={form}
              name="basic"
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 16 }}
              initialValues={{ remember: true }}
              autoComplete="off"
              onFinish={onFinish}
            >
              <Form.Item
                label="URL"
                name="linkUrl"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Reference"
                name="linkRef"
                rules={[{ required: false }]}
              >
                <Input />
              </Form.Item>
              <Form.Item wrapperCol={{ offset: 6 }}>
                <Button type="primary" htmlType="submit">
                  {t('generate.and.copy')}
                </Button>
              </Form.Item>
              <Form.Item
                label="Result"
                name="result"
                rules={[{ required: false }]}
              >
                <TextArea readOnly={true} />
              </Form.Item>
            </Form>
          </Col>
          <Col span={12}>
            <InfoBox style={{ width: '100%', height: '100%' }}>
              <InfoOrderedList>
                <li>{t('info.line.one')}</li>
                <li>{t('info.line.two')}</li>
                <li>{t('info.line.three')}</li>
              </InfoOrderedList>
            </InfoBox>
          </Col>
        </Row>
      </Card>
    </Content>
  );
};

export default Page;
