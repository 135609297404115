import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Form, Radio, Button, Input } from 'components/antd';
import /* InfoBox, */ 'components/shared';
import { FormInstance } from 'antd';

const Column = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 992px) {
    display: grid;
    grid-template-columns: 1fr 400px;
  }
`;

const InnerColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

type Props = {
  showSubmitButton?: boolean;
  onFinish?: (isVat: boolean, values: any) => any;
  form?: FormInstance<any>;
  readonly?: boolean;
  vat?: string;
  dataReviewDisplay?: boolean;
};

function Page({
  showSubmitButton,
  onFinish,
  form,
  readonly,
  vat,
  dataReviewDisplay,
}: Props) {
  const [registered, setRegistered] = useState(false);
  const { t } = useTranslation('wizard');
  const [newForm] = Form.useForm();
  form = form || newForm;
  const disabled = readonly;

  useEffect(() => {
    if (vat && vat.length > 0) {
      setRegistered(true);
    } else {
      setRegistered(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vat]);

  return (
    <Column style={dataReviewDisplay === true ? { display: 'flex' } : {}}>
      <InnerColumn>
        <Form
          labelCol={{ span: 12, offset: 4 }}
          wrapperCol={{ span: 12, offset: 8 }}
          layout="vertical"
        >
          <Form.Item label={t('registeredQuestion')}>
            <Radio.Group
              disabled={disabled}
              value={registered}
              onChange={(e) =>
                e?.target?.value !== undefined &&
                setRegistered(e?.target?.value)
              }
            >
              <Radio.Button value={true}>{t('common:yes')}</Radio.Button>
              <Radio.Button value={false}>{t('common:no')}</Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Form>
        <Form
          form={form}
          onFinish={(values) => onFinish && onFinish(registered, values)}
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 8 }}
          initialValues={{ vatRegistrationNumber: vat }}
          autoComplete="off"
        >
          {registered && (
            <Form.Item
              label={t('vat')}
              name="vatRegistrationNumber"
              rules={[
                { required: true },
                { pattern: /^[0-9]+$/, message: t('mustBeANumber') },
                { len: 9 },
              ]}
            >
              <Input
                disabled={disabled}
                addonBefore="GB"
                // mask="999999999"
                // placeholder=""
              />
            </Form.Item>
          )}
          {showSubmitButton && (
            <Form.Item wrapperCol={{ offset: 8 }}>
              <Button type="primary" htmlType="submit" disabled={disabled}>
                Submit
              </Button>
            </Form.Item>
          )}
        </Form>
      </InnerColumn>
      {/* <InfoBox>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec vel sem
        </p>
      </InfoBox> */}
    </Column>
  );
}

export default Page;
