import { message } from 'components/antd';
import Pagination from 'constants/pagination';
import api from 'libs/api';
import downloadFile from 'libs/downloadFile';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export type ReportRequest = {
  ReportID: string;
  Current?: number;
  PageSize?: number;
  Ascending?: boolean;
  SortBy?: string;
  Filters: AppliedFilter[];
};

export type ReportDetail = {
  ID: string;
  Name: string;
  Enabled: boolean;
};

export type ReportHead = {
  ReportID: string;
  ReportName: string;
  Columns: Column[];
  Filters: Filter[];
  Limit: number;
  Offset: number;
  Total: number;
  Sort: Sorting;
  RemovePagination: boolean;
};

export type ReportOverrideFilter = {
  filterName: string;
  filterValue: any;
};

export type DrilldownData = {
  ParentKey: string;
  DataRows: any[];
};

export type DrilldownReport = {
  Columns: Column[];
  ReportData: DrilldownData[];
};

export type Summary = {
  Name: string;
  Type: string;
  Value: any;
};

export type Report = {
  Header: ReportHead;
  Body: any[];
  DrillDownReport: DrilldownReport;
  structuredBody: any;
  Summaries: Summary[];
  HiddenColumns?: string;
};

export type Column = {
  ID: string;
  Name: string;
  Type: string;
  InternalUse: boolean;
  IsParentKey: boolean;
  DisplayClass?: string;
};

export type AppliedFilter = {
  ID: string;
  Value: string;
};

export type Filter = {
  ID: string;
  Name: string;
  Type: string;
  Value?: string;
  Values?: FilterValue[];
};

export type FilterValue = {
  Value: string;
  Display: string;
};

export type Sorting = {
  ColumnID: string;
  Ascending: string;
};

export type HiddenColumnsRequest = {
  UserID: string;
  Columns: string;
};

export interface IUseReports {
  isDownloading: boolean;
  isLoading: boolean;
  report?: Report;
  reports: ReportDetail[];
  load: (props: ReportRequest) => Promise<void>;
  downloadCSV: (props: ReportRequest) => Promise<void>;
  loadReports: () => void;
  saveColumns: (
    props: HiddenColumnsRequest,
    moreProps: string
  ) => Promise<void>;
}

const prepareData = ({ Header, Body }: Report) => {
  const headers = Header.Columns.map((x) => x.ID);
  let r = Body.map((r) => {
    return headers.reduce((o: any, k: any, i: any) => {
      o[k] = r[i];
      return o;
    }, {});
  });

  return r;
};

const useReports = (): IUseReports => {
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [reports, setReports] = useState<ReportDetail[]>([]);
  const [report, setReport] = useState<Report>();
  const { t } = useTranslation('reporting');

  const prepareReportRequestParams: any = (
    request: ReportRequest,
    includePagination: boolean
  ) => {
    const params: any = {
      sortBy: request?.SortBy || undefined,
      ascending: request?.Ascending || undefined,
    };

    if (includePagination && request.Current !== undefined) {
      params['offset'] =
        request.Current && request.PageSize
          ? (request.Current - 1) * request.PageSize
          : 0;
      params['limit'] = request?.PageSize || Pagination.pageSize;
    }

    request.Filters.forEach(
      (af: AppliedFilter) =>
        (params[`Filters[${af.ID}]`] = af.Value || undefined)
    );

    return params;
  };

  const fetchReports = async () => {
    setIsLoading(true);
    const result: any = await api.get(`/reporting/report`);

    const reportsData = result.data;

    // Remove Top Selling Products auto generated page from the list if it exists
    // This is so we can remove it from the sidebar
    // Get index of Top Selling Products, it will return -1 if it doesn't exist
    const indexOfTopSellers = reportsData.findIndex((productsObj: any) => {
      return productsObj.ID === 'TopSellers';
    });

    // If TopSellers report is being returned remove it from the list
    if (indexOfTopSellers !== -1) {
      // Remove Top sellers report page from the list
      reportsData.splice(indexOfTopSellers, 1);
    }

    setReports(reportsData);
    setIsLoading(false);
  };

  const fetchData = async (reportRequest: ReportRequest) => {
    setIsLoading(true);

    try {
      const { data } = await api.get(
        `/reporting/report/${reportRequest.ReportID}`,
        {
          params: prepareReportRequestParams(reportRequest, true),
        }
      );

      setReport({ ...data, structuredBody: prepareData(data) });
      return data;
    } finally {
      setIsLoading(false);
    }
  };

  const downloadDataCSV = async (reportRequest: ReportRequest) => {
    try {
      setIsDownloading(true);
      return downloadFile(
        `/reporting/report/${reportRequest.ReportID}`,
        undefined,
        undefined,
        prepareReportRequestParams(reportRequest, false),
        { Accept: 'text/csv' }
      ).then(
        () => setIsDownloading(false),
        () => {
          message.error(t('reporting.download.error'));
          setIsDownloading(false);
        }
      );
    } catch (err) {
      throw err;
    }
  };

  const saveHiddenColumns = async (
    columns: HiddenColumnsRequest,
    reportID: string
  ) => {
    return api
      .post(`/reporting/report/${reportID}/columns`, columns)
      .then((response) => {
        return response.data;
      });
  };

  return {
    // Variables
    isDownloading,
    isLoading,
    report,
    reports,
    // Methods
    load: fetchData,
    downloadCSV: downloadDataCSV,
    loadReports: fetchReports,
    saveColumns: saveHiddenColumns,
  };
};

export default useReports;
