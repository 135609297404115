import api from './api';
import { saveAs } from 'file-saver';

const getFileNameFromHeaders = (
  headers: any,
  name?: string,
  extension?: string
) => {
  if (name) {
    let forcedName = name;

    if (name && extension) {
      forcedName = name.toLowerCase().endsWith(`.${extension}`)
        ? name
        : `${name}.${extension}`;
    }
    return forcedName;
  }

  try {
    const splitDisposition = headers['content-disposition'].split(';');
    const rawFilename = splitDisposition[splitDisposition.length - 1];
    return rawFilename
      .split(['='])[1]
      .replace(/["']/g, '')
      .replace(/UTF-8/g, '');
  } catch (error) {
    return 'download';
  }
};

const downloadFile = (
  url: string,
  name?: string,
  extension?: string,
  params?: any,
  headers?: any
) => {
  return api
    .get(url, {
      responseType: 'blob',
      timeout: 0,
      params: params,
      headers: headers,
    })
    .then(({ data, headers }) => {
      saveAs(
        new Blob([data], {
          type: headers['content-type'],
        }),
        getFileNameFromHeaders(headers, name, extension)
      );
    });
};

export default downloadFile;
