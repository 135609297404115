import React, { useState } from 'react';
import AuthService from 'libs/auth';
import { SigninRequest, User } from 'oidc-client';
import { clientId, identityServerUrl } from 'constants/env';

type Props = {
  children: JSX.Element | JSX.Element[];
};

type Tenant = {
  id: string;
  bannerUrl: string;
  name: string;
  domain: string;
};

export interface IUser {
  name: string;
  email: string;
  permissions: { [key: string]: boolean };
  features: { [key: string]: { show: boolean; enabled: boolean } };
  id: string;
  affiliateId: string;
  tenantId: string;
  hasNewAgreement: boolean;
  enforceAgreement: boolean;
  hasNewSelfBillingAgreement: boolean;
  enforceSelfBillingAgreement: boolean;
  enforceDataReview: boolean;
  reviewRequiredDate: string;
  returnUrl: string;
  availableTenants: Tenant[];
}

export interface IUserProvider {
  user: IUser;
  approveAgreement: () => void;
  approvePlatformAgreement: () => void;
  setUser: (user: IUser | null) => void;
  setBackupUser: (user: IUser | null) => void;
  isImpersonated: boolean;
  signinRedirectCallback: () => Promise<User>;
  logout: () => void;
  signoutRedirectCallback: () => void;
  isAuthenticated: () => boolean;
  signinRedirect: (returnUrl?: string | null) => void;
  signinSilentCallback: () => void;
  createSigninRequest: () => Promise<SigninRequest>;
  getUser: (reset: Function) => (func?: Function) => Promise<User | null>;
  impersonate: (newUser: IUser | null) => void;
}

const UserContext = React.createContext<IUserProvider>({} as IUserProvider);

const UserProvider = ({ children }: Props) => {
  const [user, setUser] = useState<IUser | null>(null);
  const [backupUser, setBackupUser] = useState<IUser | null>(null);
  const settings = {
    authority: identityServerUrl,
    client_id: clientId,
    redirect_uri: `https://${window.location.host}/login/callback`,
    post_logout_redirect_uri: `https://${window.location.host}/logout/callback`,
    response_type: 'code',
    scope: 'openid gateway',
    loadUserInfo: false,
  };
  const authService = new AuthService(settings);

  const impersonate = (newUser: IUser | null) => {
    if (!newUser) {
      setUser(backupUser);
      setBackupUser(null);
      return;
    }

    setBackupUser(user);
    setUser(newUser);
  };

  return (
    <UserContext.Provider
      value={{
        ...authService,
        approveAgreement: () => {},
        approvePlatformAgreement: () => {},
        user: user as IUser,
        setUser,
        isImpersonated: !!backupUser,
        setBackupUser,
        impersonate,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserProvider };
