import { Button } from 'components/antd';
import { PromotionalMethod } from 'hooks/Account/affiliateDetails';
import { useEffect, useState } from 'react';
import Confirmation from './Components/Confirmation';

const convertToCorrectDataStructure = (struct: any) => {
  const {
    title,
    firstName,
    surname,
    affiliateName,
    affiliateWebsite,
    vatRegistrationNumber,
    phoneNumber,
    termsandConditionsIdAccepted,
    termsandConditionsId,
    selfBillingAgreementIdAccepted,
    selfBillingAgreementId,
    websites,
  } = struct;

  const { addressLineOne, addressLineTwo, city, county, country, postCode } =
    struct;
  const address = {
    addressLineOne,
    addressLineTwo,
    city,
    county,
    country,
    postCode,
  };

  const { bankName, accountName, accountNumber, sortCode, swiftCode, iban } =
    struct;
  const bankAccount = {
    bankName,
    accountName,
    accountNumber,
    sortCode: sortCode ? sortCode.replaceAll('-', '') : undefined,
    swiftCode,
    iban,
  };

  return {
    // basic
    title,
    firstName,
    surname,
    company: affiliateName,
    companyWebsite: affiliateWebsite,
    vatRegistrationNumber,
    phoneNumber,
    termsandConditionsIdAccepted:
      termsandConditionsIdAccepted && termsandConditionsId,
    selfBillingAgreementIdAccepted:
      selfBillingAgreementIdAccepted && selfBillingAgreementId,
    // composed properties
    address,
    bankAccount,
    websites,
  };
};

type Props = {
  result?: any;
  methods: PromotionalMethod[];
  onSubmit: (details: any) => Promise<void>;
};

function Page({ result, methods = [], onSubmit }: Props) {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [details, setDetails] = useState({
    ...result.first,
    ...result.second,
    ...result.third,
    ...result.fourth,
    ...result.fifth,
  });
  useEffect(() => {
    setDetails({
      ...result.first,
      ...result.second,
      ...result.third,
      ...result.fourth,
      ...result.fifth,
    });
  }, [result]);

  const onConfirm = (details: any) => {
    setIsSubmitting(true);
    onSubmit(convertToCorrectDataStructure(details)).finally(() => {
      setIsSubmitting(false);
    });
  };

  return (
    <>
      <Confirmation details={details} methods={methods} />

      <div style={{ textAlign: 'center', marginTop: '2em' }}>
        <Button
          loading={isSubmitting}
          disabled={isSubmitting}
          size="large"
          style={{ padding: '1em 2em', height: 'auto' }}
          onClick={() => onConfirm(details)}
        >
          Submit
        </Button>
      </div>

      {/* <pre>
        {JSON.stringify(convertToCorrectDataStructure(details), null, 2)}
      </pre> */}

      {/* <p>
        Aenean auctor egestas ipsum ac laoreet. Sed tempor mollis sapien, sit
        amet aliquam tortor lacinia vel. Duis porttitor nibh tellus, non
        ullamcorper metus dignissim quis. Praesent pharetra eleifend tellus, nec
        ultricies turpis malesuada at. Cras vitae ultrices purus. Lorem ipsum
        dolor sit amet, consectetur adipiscing elit. Praesent sed ultrices
        purus, sit amet consectetur sem.
      </p>
      <p>
        Aenean efficitur rhoncus augue eu rhoncus. Pellentesque iaculis blandit
        tellus sed finibus. Duis vel ultricies velit. Orci varius natoque
        penatibus et magnis dis parturient montes, nascetur ridiculus mus.
        Curabitur fringilla tellus est, quis consequat quam ultrices id. Morbi
        rutrum ex id nulla eleifend viverra a sed tellus. Nullam faucibus diam
        vehicula vulputate eleifend
      </p> */}
    </>
  );
}

export default Page;
