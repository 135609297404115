import { Navigate, Route, Routes } from 'react-router';
import BulkEmailsAddEdit from '././AddEditNew/addedit';
import BulkEmailsList from './list';

const Page = () => {
  return (
    <Routes>
      <Route path="/list" element={<BulkEmailsList />} />
      <Route path="/add" element={<BulkEmailsAddEdit />} />
      <Route path="/edit/:emailId" element={<BulkEmailsAddEdit />} />
      <Route index element={<Navigate to="list" />} />
    </Routes>
  );
};

export default Page;
