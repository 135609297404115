import { useTranslation } from 'react-i18next';

import { Card } from 'components/antd';
import { Content } from 'components/shared';

const Page = () => {
  const { t } = useTranslation('devicesMaint');

  return (
    <Content>
      <h1>{t('devices')}</h1>
      <Card></Card>
    </Content>
  );
};

export default Page;
