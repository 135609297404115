import { useState } from 'react';
import api from 'libs/api';

export type BannerDimension = {
  dimensionTypeID: number;
  dimensionDisplay: string;
  dimensionX: number;
  dimensionY: number;
};

export interface IUseBannerDimensions {
  dimensions: BannerDimension[];
  isLoading: boolean;
  load: () => Promise<void>;
}

const useBannerDimensions = (): IUseBannerDimensions => {
  const [dimensions, setDimensions] = useState<BannerDimension[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const load = async () => {
    setIsLoading(true);

    const result: any = await api.get('/resources/BannerDimensions');

    setDimensions(result.data);
    setIsLoading(false);
  };

  return {
    // Variables
    dimensions,
    isLoading,
    // Methods
    load,
  };
};

export default useBannerDimensions;
