import { Button, Card, Row, Space } from 'components/antd';
import { CardsIcon, RowsIcon } from 'components/icons';
import { Check, Content, TitleRow } from 'components/shared';
import ViewType from 'constants/viewType';
import useNews from 'hooks/Resources/news';
import useUser from 'hooks/User';
import CardView from 'pages/News/CardView';
import RowView from 'pages/News/RowView';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import ComparisonChart from './Components/ComparisonChart';
import { Features } from 'constants/features';
import { Permissions } from 'constants/permissions';

const Page = () => {
  const { user } = useUser();
  const { news, load, isLoading } = useNews();
  const firstName = user.name ? user.name.split(' ')[0] : '';
  const { t } = useTranslation('dashboard');
  const [view, setView] = useState<ViewType>(ViewType.Row);
  const [tab, setTab] = useState<string>('month');

  useEffect(() => {
    load({ dashboardOnly: true, pageSize: 5 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Content>
      <TitleRow>
        <h1>
          {t('dashboard')} - {t('welcome')} {firstName}
        </h1>
      </TitleRow>

      <Check
        features={[
          Features.Reporting,
          Features['Reporting.Report.SalesTrafficClicks'],
        ]}
        permissions={[
          Permissions.Reporting,
          Permissions['Reporting.Report.SalesTrafficClicks'],
        ]}
      >
        <Card
          onTabChange={setTab}
          activeTabKey={tab}
          tabList={[
            { key: 'month', tab: 'Last Month' },
            { key: 'year', tab: 'Last Year' },
          ]}
        >
          <ComparisonChart type={tab} />
        </Card>
      </Check>
      <Card loading={isLoading} style={{ marginTop: '1em' }}>
        <TitleRow>
          <h2>{t('latestNews')}</h2>
          <Space>
            <div>{t('common:view')}:</div>
            <Button
              value="row"
              type={view === ViewType.Row ? 'primary' : 'default'}
              onClick={() => setView(ViewType.Row)}
              icon={<RowsIcon style={{ marginBottom: '-2px' }} />}
              size="small"
            />
            <Button
              value="card"
              type={view === ViewType.Card ? 'primary' : 'default'}
              onClick={() => setView(ViewType.Card)}
              icon={<CardsIcon style={{ marginBottom: '-2px' }} />}
              size="small"
            />
          </Space>
        </TitleRow>
        <Row>
          {view === ViewType.Card && <CardView items={news} />}
          {view === ViewType.Row && <RowView items={news} />}
        </Row>
      </Card>
    </Content>
  );
};

export default Page;
